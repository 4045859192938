import { buildCollection, buildProperty, EntityReference } from "@camberi/firecms";

type UnrealProject = {
	private: boolean;
	job: EntityReference;
	name: string;
	title: string;
	desc: string;
	git: string;
	used_projects: EntityReference[];
	sub_projects: EntityReference[];
	used_assets: EntityReference[];
	creator: EntityReference;
	created_on: Date;
	status: string;
	fio_root_asset_id: string;
};

export default buildCollection<UnrealProject>({
	icon: "Folder",
	name: "Projects",
	singularName: "Project",
	description: "List of all Projects",
	path: "projects",
	group: "Admin",

	/*initialSort: ["created_on", "desc"],*/
	permissions: ({ authController }) => ({
		edit: true,
		create: true,
		// we have created the roles object in the navigation builder
		delete: true,
	}),
	properties: {
		private: buildProperty({
			name: "Private",
			dataType: "boolean",
			readOnly: true,
		}),
		job: buildProperty({
			name: "Job",
			description: "Required: The job the project belongs to e.g. s0025_nike_tvc",
			dataType: "reference",
			path: "jobs",
			previewProperties: ["name"],
			validation: { required: true },
		}),
		name: {
			name: "Project Name (generated)",
			description: "Is automatically generated",
			dataType: "string",
			readOnly: true,
			validation: { unique: true, max: 20 },
		},
		title: {
			name: "Project Title",
			description: "Required: Project Title e.g. 'env_desert_02'",
			dataType: "string",
			validation: {
				required: true,
				max: 14,
				min: 2,

				matchesMessage: "Please use numbers and letters only. Leading or tailing whitespaces are not allowed.",
			},
		},
		git: {
			name: "Git Link",
			description: "Link to Git Repository ",
			dataType: "string",
			url: true,
		},
		desc: {
			name: "Description",
			description: "Optional Information",
			dataType: "string",
		},
		used_projects: {
			dataType: "array",
			name: "All Used Projects",
			description: "List all Project you want to use in this project.",
			of: {
				previewProperties: ["name"],
				dataType: "reference",
				path: "projects",
			},
		},
		sub_projects: {
			readOnly: true,
			dataType: "array",
			name: "Subreferenced Projects (generated)",
			description: "Is automatically generated",
			of: {
				previewProperties: ["name"],
				dataType: "reference",
				path: "projects",
			},
		},
		used_assets: {
			readOnly: true,
			dataType: "array",
			name: "All assets referenced (generated)",
			description: "Is automatically generated",
			of: {
				previewProperties: ["name"],
				dataType: "reference",
				path: "assets",
			},
		},
		fio_root_asset_id: {
			name: "Frame.io Root Asset ID",
			dataType: "string",
			readOnly: true,
		},
		status: {
			name: "Status (generated)",
			description: "Is automatically generated",
			dataType: "string",
			readOnly: true,
		},
		creator: {
			readOnly: true,
			name: "Creator (generated)",
			description: "Is automatically generated",
			dataType: "reference",
			path: "users",
			previewProperties: ["email"],
		},
		created_on: {
			dataType: "date",
			name: "Creation Date (generated)",
			description: "Is automatically generated",
			readOnly: true,
		},
	},
});
