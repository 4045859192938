import React from "react";
import FolderIcon from "@mui/icons-material/Folder";

import ProjectUnrealAssetIconOld from "@mui/icons-material/Folder";
import ProjectGenericIconOld from "@mui/icons-material/Attachment";
import { SvgIcon, SvgIconProps } from "@mui/material";

import { ReactComponent as RectIcon } from "../img/rectangle-2.svg";

import { ReactComponent as HomeSvg } from "../img/home.svg";

import { ReactComponent as TopologySvg } from "../img/topology.svg";
import AssetIcon from "@mui/icons-material/Tag";

import ErrorIcon from "@mui/icons-material/Error";

import { ReactComponent as ProjectGenericSvg } from "../img/projectGeneric.svg";
import { ReactComponent as projectGenericLinkedSvg } from "../img/projectGeneric_v02_Linked.svg";

import { ReactComponent as ProjectUnrealSceneSvg } from "../img/projectUnrealScene_v03.svg";
import { ReactComponent as TopologySvg_V02 } from "../img/git-pull-request.svg";
import { ReactComponent as CreditCard } from "../img/credit-card.svg";
import { ReactComponent as TimelineSvg } from "../img/timeline.svg";
import { ReactComponent as projectUnrealEditSvg } from "../img/projectUnrealEdit_v02.svg";
import { ReactComponent as projectUnrealEditLinkedSvg } from "../img/projectUnrealEdit_v02_Linked.svg";
import { ReactComponent as assetShotSvg } from "../img/assetShot.svg";
import { ReactComponent as ProjectUnrealAssetIconSvg } from "../img/projectUnrealasset.svg";
import { ReactComponent as ProjectUnrealAssetIconLinkedSvg } from "../img/projectUnrealassetLinked.svg";
import { ReactComponent as LogoSvg } from "../img/logoWhite.svg";
import { ReactComponent as UnrealEngineLogo } from "../img/ue_logo.svg";

import CameraRoll from "@mui/icons-material/CameraRoll";
import BurstMode from "@mui/icons-material/BurstMode";
import ProjectUnrealSceneIcon from "@mui/icons-material/PhotoCameraBack";

import { ReactComponent as FilmSvg } from "../img/film.svg";
import { ReactComponent as ImageSvg } from "../img/image.svg";

import { AssetType, ProjectType, ActionType } from "../Types/types";

const JobIcon = (props: SvgIconProps) => {
  return <SvgIcon component={RectIcon} viewBox="0 0 700 700" {...props} />;
};

const HomeIcon = (props: SvgIconProps) => {
  return <SvgIcon component={HomeSvg} viewBox="0 0 24 24" {...props} />;
};

// const TopologyIcon = (props: SvgIconProps) => {
//   return <SvgIcon component={CreditCard} viewBox="0 0 24 24" {...props} />;
// };
const ProjectUnrealEditIcon = (props: SvgIconProps) => {
  return <SvgIcon component={projectUnrealEditSvg} viewBox="0 0 24 24" {...props} />;
};
const LogoIcon = (props: SvgIconProps) => {
  return <SvgIcon component={LogoSvg} viewBox="0 0 24 24" {...props} />;
};

const UnrealEngineLogoIcon = (props: SvgIconProps) => {
  return <SvgIcon component={UnrealEngineLogo} viewBox="0 0 24 24" {...props} />;
};

const ProjectUnrealAssetIcon = (props: SvgIconProps) => {
  return <SvgIcon component={ProjectUnrealAssetIconSvg} viewBox="0 0 24 24" {...props} />;
};

const ProjectUnrealAssetLinkedIcon = (props: SvgIconProps) => {
  return <SvgIcon component={ProjectUnrealAssetIconLinkedSvg} viewBox="0 0 24 24" {...props} />;
};

const ProjectUnrealEditLinkedIcon = (props: SvgIconProps) => {
  return <SvgIcon component={projectUnrealEditLinkedSvg} viewBox="0 0 24 24" {...props} />;
};

const ProjectUnrealSceneIconOld = (props: SvgIconProps) => {
  return <SvgIcon component={ProjectUnrealSceneSvg} viewBox="0 0 24 24" {...props} />;
};

const ProjectGenericIcon = (props: SvgIconProps) => {
  return <SvgIcon component={ProjectGenericSvg} viewBox="0 0 24 24" {...props} />;
};

const ProjectGenericLinkedIcon = (props: SvgIconProps) => {
  return <SvgIcon component={projectGenericLinkedSvg} viewBox="0 0 24 24" {...props} />;
};

const AssetTimelineIcon = (props: SvgIconProps) => {
  return <SvgIcon component={TimelineSvg} viewBox="0 0 24 24" {...props} />;
};

const AssetShotIcon = (props: SvgIconProps) => {
  return <SvgIcon component={assetShotSvg} viewBox="0 0 24 24" {...props} />;
};

const FilmIcon = (props: SvgIconProps) => {
  return <SvgIcon component={FilmSvg} viewBox="0 0 24 24" {...props} />;
};
const ImageIcon = (props: SvgIconProps) => {
  return <SvgIcon component={ImageSvg} viewBox="0 0 24 24" {...props} />;
};

// const ProjectUnrealEditIcon = (props: SvgIconProps) => {
//   return (
//     <SvgIcon {...props} viewBox="0 0 24 24">
//       <mask id="myMask">
//         {/*   <!-- Everything under a white pixel will be visible -->*/}
//         <rect x="0" y="0" width="24" height="24" fill="white" />

//         {/*  <!-- Everything under a black pixel will be invisible -->*/}
//         <polygon points="8 5 8 19 19 12" fill="black" transform="translate(12.00000, 13.000000) scale(.65 , .65) translate(-12.00000, -13.000000)" />
//         {/*<path d="M10,35 A20,20,0,0,1,50,35 A20,20,0,0,1,90,35 Q90,65,50,95 Q10,65,10,35 Z" fill="black" />*/}
//       </mask>

//       <path d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z" mask="url(#myMask)" />

//       {/*<path d="M1,1 L3,1 L3,23 L1,23 L1,1 Z M3,1 L6,1 L6,3 L3,3 L3,1 Z M3,21 L6,21 L6,23 L3,23 L3,21 Z" />
//       <path d="M18,1 L20,1 L20,23 L18,23 L18,1 Z M20,1 L23,1 L23,3 L20,3 L20,1 Z M20,21 L23,21 L23,23 L20,23 L20,21 Z" transform="translate(20.500000, 12.000000) scale(-1, 1) translate(-20.500000, -12.000000)" />
//       <polygon points="8 5 8 19 19 12" />*/}
//     </SvgIcon>
//   );
// };

const TopologyIcon = (props: SvgIconProps) => (
  <svg width="24pt" height="24pt" viewBox="0 -3 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="white" {...props}>
    <g>
      <path d="m7.537 5.288c-2.382 0 -4.312 1.931 -4.312 4.312s1.931 4.312 4.312 4.312 4.312 -1.931 4.312 -4.312 -1.931 -4.312 -4.312 -4.312zm-0.409 6.156c-0.115 0.131 -0.438 0.292 -0.728 0.025l-1.29 -1.165 0.702 -0.778 0.914 0.826 2.509 -2.596 0.753 0.728z" />
      <path d="m20.212 11.55h-4.125c-0.311 0 -0.562 -0.252 -0.562 -0.562v-2.775c0 -0.311 0.252 -0.562 0.562 -0.562h4.125c0.311 0 0.562 0.252 0.562 0.562v2.775c0 0.311 -0.252 0.562 -0.562 0.562zm-3.563 -1.125h3v-1.65h-3z" />
      <path d="m11.55 9.037h4.537v1.125h-4.537z" />
      <path d="m14.7 2.588h4.125v2.775h-4.125z" />
      <path d="m18.825 5.925h-4.125c-0.311 0 -0.562 -0.252 -0.562 -0.562v-2.775c0 -0.311 0.252 -0.562 0.562 -0.562h4.125c0.311 0 0.562 0.252 0.562 0.562v2.775c0 0.311 -0.252 0.562 -0.562 0.562zm-3.563 -1.125h3v-1.65h-3z" />
      <path d="m12.15 3.412h1.088v1.125h-1.088z" />
      <path d="m8.112 4.875h-1.125v-0.9c0 -0.311 0.252 -0.562 0.562 -0.562h3.662v1.125h-3.1z" />
      <path d="m14.7 13.837h4.125v2.775h-4.125z" />
      <path d="m18.825 17.175h-4.125c-0.311 0 -0.562 -0.252 -0.562 -0.562v-2.775c0 -0.311 0.252 -0.562 0.562 -0.562h4.125c0.311 0 0.562 0.252 0.562 0.562v2.775c0 0.311 -0.252 0.562 -0.562 0.562zm-3.563 -1.125h3v-1.65h-3z" />
      <path d="m12.15 14.7h1.088v1.125h-1.088z" />
      <path d="m11.212 15.825h-3.662c-0.311 0 -0.562 -0.252 -0.562 -0.562v-0.938h1.125v0.375h3.1z" />
    </g>
  </svg>
);

const labelIconByAssetType = (assetType: AssetType) => {
  //type AssetType = "timeline" | "shot" | "prop" | "character" | "fx" | "environment" | "generic" | "generic_render";
  switch (assetType) {
    case "timeline":
      return AssetTimelineIcon;
    case "shot":
      return AssetShotIcon;
    case "prop":
      return AssetIcon;
    case "character":
      return AssetIcon;
    case "fx":
      return AssetIcon;
    case "environment":
      return AssetIcon;
    case "generic":
      return AssetIcon;
    case "generic_render":
      return AssetIcon;
  }
  //fallback
  return AssetIcon;
};

const labelIconByProjectType = (projectType: ProjectType, linked: boolean = false) => {
  //type ProjectType = "unrealasset" | "unrealedit" | "unrealscene" | "generic";
  switch (projectType) {
    case "unrealasset":
      return linked ? ProjectUnrealAssetLinkedIcon : ProjectUnrealAssetIcon;
    case "unrealedit":
      return linked ? ProjectUnrealEditLinkedIcon : ProjectUnrealEditIcon;
    case "unrealscene":
      return ProjectUnrealSceneIcon;
    case "generic":
      return linked ? ProjectGenericLinkedIcon : ProjectGenericIcon;
  }
  //fallback
  return ProjectGenericIcon;
};

export {
  JobIcon,
  ProjectUnrealEditIcon,
  ProjectUnrealAssetIcon,
  ProjectGenericIcon,
  HomeIcon,
  AssetIcon,
  ProjectUnrealSceneIcon,
  TopologyIcon,
  ErrorIcon,
  AssetTimelineIcon,
  AssetShotIcon,
  labelIconByAssetType,
  labelIconByProjectType,
  FilmIcon,
  ImageIcon,
  LogoIcon,
  UnrealEngineLogoIcon,
};
