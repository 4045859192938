import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useLocalStorage } from "usehooks-ts";

import { labelIconByAssetType, labelIconByProjectType, JobIcon } from "../../../CustomIcons/CustomIcons";
const AntTabs = styled(Tabs)(({ theme }) => ({
	height: "40px",
	minHeight: "40px",
	"& .MuiTabs-indicator": {},
	"& .MuiTabs-scrollButtons": {
		width: "20px",
		marginLeft: 0,
		marginRight: 0,
		disableRipple: true,
	},
	marginRight: theme.spacing(0),
	marginLeft: theme.spacing(0),
	paddingLeft: theme.spacing(0),
	marginBottom: 2,
}));

interface StyledTabProps {
	label: string;
	value: string | null;
	sx?: any;
	iconPosition?: any;
	icon?: any;
}

const AntTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
	height: "40px",
	minHeight: "40px",

	textTransform: "none",
	minWidth: 0,
	[theme.breakpoints.up("sm")]: {
		minWidth: 0,
	},
	fontWeight: theme.typography.fontWeightBold,
	marginRight: theme.spacing(0),
	marginLeft: theme.spacing(0),
	paddingRight: theme.spacing(1.25),
	paddingLeft: theme.spacing(1.25),
	opacity: 0.5,

	"&:hover": { opacity: 1 },
	"&.Mui-selected": {
		//background: theme.palette.primary.main,
		opacity: 1,
	},
	"&.Mui-focusVisible": {},
}));

export default function ScrollableTabsButtonAuto({ value, setMainProjectTypesFilter, isJobNameShown, setIsJobNameShown }: { value: string | null; setMainProjectTypesFilter: any; isJobNameShown: any; setIsJobNameShown: any }) {
	//const [value, setValue] = React.useState(1);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setMainProjectTypesFilter(newValue);
	};

	const toggleJobNamePrefix = () => {
		setIsJobNameShown((prevValue: boolean) => !prevValue);
	};

	return (
		<Box sx={{ width: "100%", borderBottom: 1, borderColor: "divider", paddingLeft: 1, display: "flex", alignItems: "center" }}>
			<Typography variant={"body2"} sx={{ ml: 1.1, mb: 0, mr: 0 }}>
				Filter:
			</Typography>
			<AntTabs value={value} onChange={handleChange} sx={{ textTransform: "none", paddingLeft: 0, margin: 0 }} variant="scrollable" scrollButtons={true} aria-label="scrollable auto tabs example">
				<AntTab label="All" value={null} />
				<AntTab icon={<Box component={labelIconByProjectType("unrealedit")} sx={{ width: "15px" }} />} iconPosition="start" label="Edits" value={"unrealedit"} />
				<AntTab icon={<Box component={labelIconByProjectType("unrealasset")} sx={{ width: "15px" }} />} iconPosition="start" label="Assets" value={"unrealasset"} />
				<AntTab icon={<Box component={labelIconByProjectType("generic")} sx={{ width: "15px" }} />} iconPosition="start" label="Collections" value={"generic"} />
			</AntTabs>
			<Tooltip placement="top" title={isJobNameShown ? "Hide job prefix" : "Show job prefix"} arrow>
				<IconButton onClick={toggleJobNamePrefix} aria-label="delete" size="small" sx={{ ml: "auto", borderRadius: "5px", pl: 1, pr: 1, mr: 1.5 }}>
					{isJobNameShown ? <VisibilityIcon fontSize="inherit" sx={{ opacity: 0.5 }} /> : <VisibilityOffIcon fontSize="inherit" />}
				</IconButton>
			</Tooltip>
		</Box>
	);
}
