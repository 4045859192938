import React, { useEffect, useState } from "react";
import { Scroller } from "./Scroller";
import { Comment } from "./types";

const wait = (time: number) =>
	new Promise<void>((resolve) => {
		setTimeout(() => {
			resolve();
		}, time);
	});

const numberOfComments = 500;
const commentsPerLoad = 5;

const comments: Comment[] = Array.from(Array(numberOfComments).keys()).map((number) => ({ content: number.toString() }));

const loadComments = async (callNumber: number) => {
	await wait(1800);

	if (!comments.length) return { isMore: false, comments: [] };

	const loadedComments = comments.splice(-commentsPerLoad);

	return {
		isMore: !!comments.length,
		comments: loadedComments,
	};
};

export const Container = ({ hasMore, versions, assets }: any) => {
	console.log("Re-load Container");
	const [numberOfLoads, setNumberOfLoads] = useState(0);
	const [isMoreData, setIsMoreData] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState(true);
	const [comments, setComments] = useState<Comment[]>([]);

	const loadMoreData = async () => {
		setIsLoading(true);
		const { comments: newComments, isMore } = await loadComments(numberOfLoads + 1);
		setComments((prev) => [...newComments, ...prev]);
		setIsMoreData(isMore);
		setNumberOfLoads((prev) => ++prev);
		setIsLoading(false);
	};

	useEffect(() => {
		loadMoreData();
	}, []);

	return <Scroller versions={versions} assets={assets} comments={comments} hasMore={isMoreData} loadMoreData={loadMoreData} isLoading={isLoading} />;
};
