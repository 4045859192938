import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import InputAdornment from "@mui/material/InputAdornment";
import Divider from "@mui/material/Divider";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import GitHubIcon from "@mui/icons-material/GitHub";

import { useAuthController, useSnackbarController } from "@camberi/firecms";
import { useNavigate } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import AutocompleteReferences from "./Components/AutocompleteReferences";
import SwitchPrivate from "./Components/SwitchPrivate";
import TextFieldPlus from "./Components/TextFieldPlus";
import { DeleteDialog } from "./Components/DeleteDialog";

import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";

//keyboard adorment
import { ProjectUnrealEditIcon, ProjectUnrealAssetIcon, ProjectGenericIcon, ProjectUnrealSceneIcon } from "../../CustomIcons/CustomIcons";
import { useReducerWithCallback } from "../../utils/usehooks-ts";
import { ActionType, ReferenceType, ProjectType } from "../../Types/types";
import { isEmpty, diff } from "../../utils/basicFunctions";

const adminCollaborator: ReferenceType = { id: "admin", name: "Admins", fixed_comment: "", type: "group" };

const makeCollectionIcon = (project_type: ProjectType) => {
	switch (project_type) {
		case "unrealasset":
			return <ProjectUnrealAssetIcon />;
		case "unrealedit":
			return <ProjectUnrealEditIcon />;
		case "unrealscene":
			return <ProjectUnrealSceneIcon />;
		case "generic":
			return <ProjectGenericIcon />;
	}
};
const collectionDescription = (project_type: ProjectType) => {
	switch (project_type) {
		case "unrealasset":
			return "Unreal Asset Project";
		case "unrealedit":
			return "Unreal Edit Project";
		case "generic":
			return "Generic Collection";
		case "unrealscene":
			return "Scene";
	}
};

const entityName = (project_type: ProjectType) => {
	switch (project_type) {
		case "unrealasset":
			return "Project";
		case "unrealedit":
			return "Project";
		case "generic":
			return "Collection";
		case "unrealscene":
			return "Scene";
	}
};

const createProjectTitlePlaceholder = (projectType: ProjectType) => {
	switch (projectType) {
		case "unrealedit":
			return "e01_social_ad";
		case "unrealasset":
			return "env_kitchen";
		case "generic":
			return "storyboard";
		case "unrealscene":
			return "s01";
	}
};

export const TreeViewMenuModalCollection = React.forwardRef(function TreeViewMenuModalCollection(
	{
		job,
		projects,
	}: {
		job: any;
		projects: any;
	},
	ref
) {
	const deleteDialogRef = useRef<any>();
	const defaultValue = {
		action: { target: undefined, value: "create", error: " " },
		project_type: { target: undefined, value: "", error: " " },
		project_id: { target: undefined, value: "", error: " " },
		parent_project: { target: undefined, value: {}, error: " " },
		project_private: { target: undefined, value: false, error: " " },
		project_title: { target: undefined, value: "", error: " " },
		project_name: { target: undefined, value: "", error: " " },
		project_description: { target: undefined, value: "", error: " " },
		project_git_link: { target: undefined, value: "", error: " " },
		project_collaborators: { target: undefined, value: [], error: " " },
		project_used_projects: { target: undefined, value: [], error: " " },
		project_sub_projects: { target: undefined, value: [], error: " " },
		project_owner: { target: undefined, values: {}, error: " " },
	};
	const [author, setAuthor] = useState<ReferenceType | boolean>(false);

	// eslint-disable-next-line  @typescript-eslint/no-unused-vars
	const [initialFormInput, setInitialFormInput] = useReducerWithCallback((state: any, newState: any) => ({ ...state, ...newState }), defaultValue);

	const [formInput, setFormInput] = useReducerWithCallback((state: any, newState: any) => ({ ...state, ...newState }), defaultValue);
	/*viewState*/
	const [onPostToDatabase, setOnPostToDatabase] = React.useState(false);

	/*hooks*/
	const snackbarController = useSnackbarController();
	const authController = useAuthController();
	// eslint-disable-next-line  @typescript-eslint/no-unused-vars
	const navigate = useNavigate();

	useEffect(() => {
		if (authController?.user) {
			const authorCollaborator: ReferenceType = { id: authController?.user?.uid || "", name: authController?.user?.email + " " || "", fixed_comment: "Owner" };
			setAuthor(authorCollaborator);
		} else {
			setAuthor(false);
		}
	}, [authController]);

	/*global*/
	const isActionCreate: boolean = formInput?.action?.value === "create";

	const color = "primary";

	const collectionIcon = makeCollectionIcon(formInput?.project_type?.value as ProjectType);
	const inputFocusOnOpen = useRef<HTMLInputElement>(null);

	//global modal states
	const [open, setOpen] = React.useState(false);
	const [firstTimeHelperExpired, setFirstTimeHelperExpired] = React.useState(!isActionCreate);

	useImperativeHandle(ref, () => ({
		openDialog: (projectid: string, parenttype: ProjectType, action: ActionType) => {
			const parent_project_id = action === "create" ? projectid : projects.find((x: any) => x.id === projectid)?.values?.project?.id;
			//console.log(parent_project_id);
			const parent_project = projects?.find((p: any) => p.id === parent_project_id) || {};
			if (action === "create") {
				setFormInput(
					{
						parent_project: { target: undefined, value: parent_project, error: " " },
						action: { target: undefined, value: action, error: " " },
						project_id: { target: undefined, value: projectid, error: " " },
						project_type: { target: undefined, value: parenttype, error: " " },
					},
					() => {
						setOpen(true);
						setTimeout(() => {
							inputFocusOnOpen?.current?.focus();
						}, 100);
					}
				);
			} else {
				const project = projects.find((x: any) => x.id === projectid);

				let owner_entry;
				const owner_id = project?.values?.creator?.id;
				if (owner_id === (author as ReferenceType)?.id) {
					//self owned
					owner_entry = { id: owner_id, name: (author as ReferenceType)?.name, fixed_comment: "owner" };
				} else {
					//foreign owned
					const owner_values = Object.entries(job?.values?.roles?.details || {}).find(([key, value]: any) => key === owner_id)?.[1] || {};
					owner_entry = { id: owner_id, name: !isEmpty(owner_values) ? (owner_values as any).name : owner_id, fixed_comment: "owner" };
				}

				const used_projects = (project?.values?.used_projects || []).map((used: any) => {
					const usedProject = projects.find((x: any) => x.id === used.id);
					const usedProjectName = usedProject?.values?.name;
					const isDisabled = usedProject?.values?.project?.id === projectid;
					return { id: used.id, name: usedProjectName, disabled: isDisabled, name_adornment: undefined, tooltip: undefined };
				});

				const user_collaborators = Object.entries(project?.values?.roles?.details || {})
					.filter(([key, value]: any) => value.role_paths?.includes("collab_project"))
					.map(([key, value]: any) => ({ id: key, name: value?.name }));

				setFormInput(
					{
						action: { target: undefined, value: "edit", error: " " },
						parent_project: { target: undefined, value: parent_project, error: " " },
						project_type: { target: undefined, value: project?.values?.type, error: " " },
						project_id: { target: undefined, value: project?.id, error: " " },
						project_private: { target: undefined, value: project?.values?.private, error: " " },
						project_title: { target: undefined, value: project?.values?.title, error: " " },
						project_name: { target: undefined, value: project?.values?.name, error: " " },
						project_description: { target: undefined, value: project?.values?.desc, error: " " },
						project_git_link: { target: undefined, value: project?.values?.git, error: " " },
						project_used_projects: { target: undefined, value: used_projects, error: " " },
						project_collaborators: { target: undefined, value: user_collaborators, error: " " },
						project_owner: { target: undefined, value: owner_entry, error: " " },
					},
					(inputState) => {
						setInitialFormInput({ ...inputState });

						setOpen(true);
						setTimeout(() => {
							inputFocusOnOpen?.current?.focus();
						}, 100);
					}
				);
			}
		},
	}));

	const hasChanged = () => {
		const curInput = Object.fromEntries(Object.entries(formInput || {}).map(([key, value]: any) => [`${key}`, JSON.stringify(value?.value)]));
		const initForm = Object.fromEntries(Object.entries(initialFormInput || {}).map(([key, value]: any) => [`${key}`, JSON.stringify(value?.value)]));
		//console.log("initForm", initForm.project_used_projects);
		//console.log("diff", diff(initForm, curInput));
		return Object.keys(diff(initForm, curInput));
	};

	const handleClose = (forced = false) => {
		if (onPostToDatabase && !forced) return;
		setOpen(false);
		setTimeout(() => {
			setOnPostToDatabase(false);
			setFirstTimeHelperExpired(false);
			setFormInput(defaultValue);
		}, 150);
	};

	//console.log(formInput?.parent_project?.value?.values?.roles?.details || {});
	const private_status = formInput?.project_type?.value === "unrealscene" ? formInput?.parent_project?.value?.values?.private : formInput?.project_private?.value;
	const fixed_collaborators = [
		...Object.entries(formInput?.parent_project?.value?.values?.roles?.details || {})
			.filter((user: any) => {
				const user_rolepaths = user?.[1]?.role_paths || [];
				return user_rolepaths.includes("owner") || (!formInput?.project_private?.value && user_rolepaths.includes("collab_project")) ? true : false;
			})
			.map(([key, value]: any) => {
				return { id: key, name: value.name, fixed_comment: value.role === "collaborator" ? "edit collaborator" : value.role === "owner" ? "edit owner" : value.role };
			}),
		...Object.entries(job?.values?.roles?.details || {})
			.filter((user: any) => {
				const user_rolepaths = user?.[1]?.role_paths || [];
				return user_rolepaths.includes("owner") || (!private_status && user_rolepaths.includes("collab_job")) ? true : false;
			})
			.map(([key, value]: any) => {
				return { id: key, name: value.name, fixed_comment: value.role === "collaborator" ? "job collaborator" : value.role === "owner" ? "job owner" : value.role };
			}),
	];

	fixed_collaborators.sort((a, b): any => {
		return (
			(b.fixed_comment === "job owner" ? 1 : 0) - (a?.fixed_comment === "job owner" ? 1 : 0) ||
			(b.fixed_comment === "job collaborator" ? 1 : 0) - (a?.fixed_comment === "job collaborator" ? 1 : 0) ||
			(b.fixed_comment === "edit owner" ? 1 : 0) - (a?.fixed_comment === "edit owner" ? 1 : 0) ||
			(b.fixed_comment === "edit collaborator" ? 1 : 0) - (a?.fixed_comment === "edit collaborator" ? 1 : 0) ||
			a?.name?.localeCompare(b?.name)
		);
	});

	const _curProjecId = formInput?.project_id?.value;
	//subunreal projects don't include project_id
	const project_options = (projects || [])
		.filter((project: any) => {
			const project_subs = project?.values?.sub_projects || [];

			if (project.id === _curProjecId) return false;

			project_subs.forEach((sub: any) => {
				if (sub.id === _curProjecId) {
					return false;
				}
			});
			return true;
		})
		.map((filteredProject: any) => {
			const project_use_all = filteredProject?.values?.used_projects?.filter((used_project: any) => used_project.id === _curProjecId) || [];
			const project_is_used = project_use_all.length > 0 || false;
			const project_is_sub = filteredProject?.values?.sub_projects?.filter((sub_project: any) => sub_project.id === _curProjecId).length > 0 || false;

			return {
				id: filteredProject.id,
				name: filteredProject.values?.name,
				disabled: project_is_used || project_is_sub,
				name_adornment: project_is_used ? `Direct reference` : project_is_sub ? `Nested reference` : undefined,
				tooltip: project_is_used
					? `You can't reference ${filteredProject.values?.name}, it already references this project (${formInput?.project_name.value}).\n\nCircle references are not supported.`
					: project_is_sub
					? `You can't reference ${filteredProject.values?.name}, it has one or more references that already reference this project (${formInput?.project_name.value}).\n\nCircle references are not supported.`
					: undefined,
			};
		});

	const handleSubmit = async (evt?: any, data: any = formInput) => {
		evt.preventDefault();

		const error = Object.values(data).filter((item: any) => item.error !== " ").length > 0;
		if (error) return;

		setOnPostToDatabase(true);
		const job_id = job.id;
		const job_ident = job.values?.ident;
		const project_id = data.project_id.value;
		const project_name = data.project_name.value;
		const project_title = data.project_title.value;
		const project_type = data.project_type.value;
		const project_private = data.project_private.value;
		const project_permissions = data.project_collaborators.value.map((user: any) => user.id);
		const project_references = data.project_used_projects.value.map((project: any) => project.id);
		const project_git_link = data.project_git_link.value;
		const project_description = data.project_description.value;

		const parent_project_id = data.parent_project.value?.id;

		let respData;
		try {
			const response = await axios({
				url: `${authController?.extra?.apiUrl}/project/${isActionCreate ? "create" : "edit"}`,
				method: "POST",
				headers: {
					Authorization: `boostToken ${authController?.extra?.user_data?.values?.boost_token}`,
				},
				data: { project_id, job_id, job_ident, project_name, project_title, project_type, project_private, project_permissions, project_references, project_git_link, project_description, parent_project_id },
			});
			respData = await response.data;

			if (respData.success) {
				console.log("respData", respData);
				handleClose(true);
				//setOpen(false);
				//setFormInput(defaultValue);
				snackbarController.open({
					type: "success",
					title: "Done",
					message: `${entityName(formInput?.project_type?.value)} ${isActionCreate ? "created" : "edited"}`,
				});
			}
		} catch (err: any) {
			setOnPostToDatabase(false);

			const message = typeof err.response?.data?.message !== "undefined" ? err.response.data.message : `Failed to ${isActionCreate ? "creation" : "edit"} project`;

			snackbarController.open({
				type: "error",
				title: `${entityName(formInput?.project_type?.value)} ${isActionCreate ? "creation" : "edit"} failed`,
				message: message,
			});
			console.log(err);
		}
	};
	const handleInput = (evt: any) => {
		const name = evt.target.name;
		const newValue = evt.target.value;

		let error = " ";
		switch (name) {
			case "project_title":
				if (!/^[A-Za-z0-9_ ]*$/.test(newValue)) error = "Letters and numbers only.";
				break;
			case "title":
				if (!/^[A-Za-z0-9]*$/.test(newValue)) error = "Letters and numbers only.";
				break;
			case "client_name":
				if (!/^[A-Za-z0-9_ ]*$/.test(newValue)) error = "Letters and numbers only.";
				break;
			case "job_title":
				if (!/^[A-Za-z0-9_ ]*$/.test(newValue)) error = "Letters and numbers only.";
				break;
		}

		setFormInput({ [name]: { value: evt.target.value, target: evt.target, error: error } });
	};

	const handleBlur = (evt: any, submit: boolean = false) => {
		if (!open) return;
		const name = evt.target.name;

		let newValue = evt.target.value;
		let startValue = newValue;

		let error = " ";
		if (name === "project_description") {
			newValue = newValue.trim();
		} else {
			newValue = newValue.trim().toLowerCase();
		}
		switch (name) {
			case "project_private":
				newValue = newValue || newValue === "on" ? true : false;
				break;
			case "project_title":
				newValue = newValue.replace("_ ", "_").replace(" _", "_").replace(/\s/g, "_").replace(/_+$/, "").replace(/^_/, "");
				if (formInput?.project_type?.value === "unrealedit") {
					if (newValue.length > 0) {
						const validStart = (newValue.length >= 4 && /^e\d{2}_/.test(newValue.slice(0, 4))) || (newValue.length == 3 && /^e\d{2}/.test(newValue.slice(0, 3)));
						if (!validStart) {
							newValue = "e01_" + newValue;
						}
					}
				}

				if (formInput?.project_type?.value === "unrealscene") {
					if (newValue.length > 0) {
						const validStart = (newValue.length >= 4 && /^s\d{2}_/.test(newValue.slice(0, 4))) || (newValue.length == 3 && /^s\d{2}/.test(newValue.slice(0, 3)));
						if (!validStart) {
							newValue = "s01_" + newValue;
						}
					}
					newValue = newValue.replace(/_$/, "").slice(0, 8);
				}

				newValue = newValue.slice(0, 12).replace(/_$/, "");

				if (newValue.length === 0) error = "Input required";
				else if (!/^[A-Za-z0-9_ ]*$/.test(newValue)) error = "Letters and numbers only.";
				break;
			case "title":
				if (newValue.length !== 5) error = "Must be 5 characters long";
				else if (!/^[a-z]{1}\d{4}$/i.test(newValue)) error = "One letter, four digits: s0001";
				break;

			case "project_git_link":
				if (!/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm.test(newValue) && newValue !== "") error = "The provided is not valid";
				break;
		}
		let inputTitlePrefix = "xxx";

		if (isActionCreate) {
			inputTitlePrefix = projects?.find((x: any) => x.id === formInput?.project_id?.value)?.values?.title.slice(0, 3);
		} else {
			const sceneParentId = projects?.find((x: any) => x.id === formInput?.project_id?.value)?.values?.project?.id;
			inputTitlePrefix = projects?.find((y: any) => y.id === sceneParentId)?.values?.title.slice(0, 3);
		}

		if (submit) {
			setFormInput(
				{
					[name]: { value: newValue, target: evt.target, error: error },
					...(name === "project_title" && {
						project_name: { value: newValue.length > 0 ? (formInput.project_type.value === "unrealscene" ? `${job.values?.ident}_${inputTitlePrefix}_${newValue}` : `${job.values?.ident}_${newValue}`) : "", target: undefined, error: " " },
					}),
				},
				(newState) => {
					handleSubmit(evt, newState);
				}
			);
		} else {
			const shouldHelp = !firstTimeHelperExpired && newValue.length;
			if (shouldHelp) setFirstTimeHelperExpired(true);
			setFormInput({
				[name]: { value: newValue, target: evt.target, error: error },
				...(name === "project_title" && {
					project_name: { value: newValue.length > 0 ? (formInput.project_type.value === "unrealscene" ? `${job.values?.ident}_${inputTitlePrefix}_${newValue}` : `${job.values?.ident}_${newValue}`) : "", target: undefined, error: " " },
				}),
				...(name === "project_title" && !formInput.project_description?.value.length && newValue.length && shouldHelp && { project_description: { value: "(" + startValue + ")", target: undefined, error: " " } }),
			});
		}
	};

	const all_sub_projects = (projects || [])
		.filter((project: any) => {
			let found = false;
			(formInput.project_used_projects?.value || []).forEach((used_project: any) => {
				if (used_project.id === project.id) found = true;
			});

			return found;
		})
		?.map((used_project: any) => used_project.values?.sub_projects?.map((sub: any) => sub.id));

	const sub_projects = Array.from(new Set(Array.prototype.concat.apply([], all_sub_projects)))
		.map((unique_sub: string) => {
			return projects.find((x: any) => x.id === unique_sub);
		})
		.map((y: any) => {
			return { id: y?.id, name: y?.values?.name };
		});

	const addAuthorTag = (references: ReferenceType[]) => {
		return references.map((ref: ReferenceType) => ({ ...ref, ...(ref.id === (author as ReferenceType)?.id && { name_adornment: " (You)" }) }));
	};

	return (
		<Dialog
			open={open}
			maxWidth={"md"}
			sx={{
				borderRadius: "15px",
				boxShadow: 24,
				outline: "none",
			}}
			PaperProps={{
				sx: { borderRadius: "15px", height: "75%", width: "600px", backgroundColor: "background.paper", backgroundImage: "none" },
			}}
			onClose={(evt) => handleClose(false)}
			scroll={"paper"}
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
		>
			<DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "left", backgroundColor: isActionCreate ? "secondary.main" : "primary.main", color: "text.primary", p: 0, m: 0, height: "60px" }} component="h6">
				<Box sx={{ ml: 2, mr: 2, display: "flex", alignItems: "center" }}>{collectionIcon}</Box>
				{isActionCreate ? `New` : `Edit`}
				{isActionCreate ? ` ${collectionDescription(formInput.project_type.value)}` : ` ${formInput.project_name.value}`}
			</DialogTitle>
			<Divider />
			<DialogContent
				sx={{ overflowY: "scroll", "&::-webkit-scrollbar": { background: "rgba(0,0,0,0)", width: "10px" }, "&::-webkit-scrollbar-thumb": { background: "rgba(125,125,125,1)", borderRadius: "10px" } }}
				onKeyPress={(ev: any) => {
					if (ev.shiftKey && ev.key === "Enter") {
						ev.stopPropagation();
						ev.preventDefault();
						handleBlur(ev, true);
					}
				}}
			>
				<Box
					display={"flex"}
					sx={{
						mt: 2,
						pl: "40px",
						pr: "40px",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Typography variant="h6" sx={{ width: "100%", mb: 1 }}>
						{formInput.project_name?.value ? `${entityName(formInput?.project_type?.value)} Name: ` : `Enter ${entityName(formInput?.project_type?.value)} Title`} <b> {formInput.project_name?.value}</b>
					</Typography>
					<Typography variant="body2" sx={{ width: "100%", mt: -1, mb: 2, opacity: 0.5 }}>
						{`${job?.values?.name} >`}
						{formInput?.project_type?.value === "unrealscene" ? ` ${formInput.parent_project?.value?.values?.name} >` : ``}
						{formInput.project_name?.value ? ` ${formInput.project_name?.value}` : ` New ${entityName(formInput?.project_type?.value)}`}
					</Typography>
					<TextFieldPlus
						refinput={inputFocusOnOpen}
						label={`${entityName(formInput?.project_type?.value)} Title`}
						placeholder={createProjectTitlePlaceholder(formInput?.project_type?.value)}
						name="project_title"
						value={formInput.project_title?.value}
						helpertext={formInput.project_title?.error}
						inputprops={{ maxLength: 14, readOnly: isActionCreate || true ? false : true }}
						disabled={onPostToDatabase}
						onBlur={handleBlur}
						errorvalue={formInput.project_title?.error !== " " ? true.toString() : undefined}
						onChange={handleInput}
						onKeyDown={(ev: any) => {
							if (ev.key === "Enter" && !ev.shiftKey) {
								ev.stopPropagation();
								ev.preventDefault();
								handleBlur(ev);
							}
						}}
					/>
					<TextFieldPlus
						multilinevalue={"true"}
						label={`${entityName(formInput?.project_type?.value)} Description`}
						placeholder={"Optional"}
						name="project_description"
						value={formInput.project_description?.value}
						helpertext={formInput.project_description?.error}
						errorvalue={formInput.project_description?.error !== " " ? true.toString() : undefined}
						inputprops={{
							readOnly: false,
							startAdornment: <Box />,
						}}
						disabled={onPostToDatabase}
						onBlur={handleBlur}
						onChange={handleInput}
					/>
					<Typography sx={{ width: "100%", mb: 1, mt: 1 }}>{`${entityName(formInput?.project_type?.value)}`} Settings</Typography>
					<SwitchPrivate
						tabindex={-1}
						name="project_private"
						parent={formInput?.project_type?.value === "unrealscene" ? formInput?.parent_project?.value?.values?.name : undefined}
						label={`Private ${entityName(formInput?.project_type?.value)} ${formInput?.project_type?.value === "unrealscene" ? "(Linked to parent " + formInput?.parent_project?.value?.values?.name + ")" : ""}`}
						checked={formInput?.project_type?.value === "unrealscene" ? formInput?.parent_project?.value?.values?.private : formInput.project_private?.value}
						setChecked={handleInput}
					/>

					<AutocompleteReferences
						name="project_collaborators"
						label="Permissions"
						placeholder="Add project colloborators"
						noOptionsText={"Collaborator not found"}
						color={color}
						options={addAuthorTag([
							adminCollaborator,
							...(!isActionCreate && !isEmpty(formInput?.project_owner?.value || {}) ? [formInput.project_owner.value] : []),
							...(isActionCreate && author ? [author] : []),
							...authController?.extra?.user_collaborators,
						]).sort((a: ReferenceType, b: ReferenceType) => a.name.localeCompare(b.name))}
						fixedOptions={addAuthorTag([adminCollaborator, ...(!isActionCreate && !isEmpty(formInput?.project_owner?.value || {}) ? [formInput.project_owner.value] : []), ...(isActionCreate && author ? [author] : []), ...fixed_collaborators])}
						selectedOptions={addAuthorTag(formInput.project_collaborators?.value)}
						setSelectedOptions={handleInput}
						sx={{ width: "100%", marginTop: "15px" }}
						avatar={<PersonIcon sx={{ opacity: 0.5 }} />}
						groupAvatar={<GroupIcon sx={{ opacity: 0.5 }} />}
					/>
					<AutocompleteReferences
						name="project_used_projects"
						label="References"
						placeholder="Add references"
						noOptionsText={"Project not found"}
						color={color}
						keepfixed={true}
						options={project_options.sort((a: ReferenceType, b: ReferenceType) => (a.disabled ? 1 : 0) - (b.disabled ? 1 : 0) || a.name.localeCompare(b.name))}
						selectedOptions={formInput.project_used_projects?.value?.sort((a: ReferenceType, b: ReferenceType) => (b.disabled ? 1 : 0) - (a.disabled ? 1 : 0) || a.name.localeCompare(b.name))}
						setSelectedOptions={handleInput}
						sx={{ width: "100%", marginTop: "15px" }}
					/>
					<AutocompleteReferences
						disabled
						name="project_sub_projects"
						label={sub_projects.length === 0 ? "No projects linked" : "Linked Projects"}
						placeholder=""
						noOptionsText={""}
						color={color}
						options={sub_projects}
						selectedOptions={sub_projects.sort((a: ReferenceType, b: ReferenceType) => a.name.localeCompare(b.name))}
						setSelectedOptions={handleInput}
						sx={{ width: "100%", marginTop: "15px" }}
					/>
					<TextFieldPlus
						label="Git Link"
						placeholder={""}
						name="project_git_link"
						value={formInput.project_git_link?.value}
						helpertext={formInput.project_git_link?.error}
						errorvalue={formInput.project_git_link?.error !== " " ? true.toString() : undefined}
						inputprops={{
							readOnly: false,
							startAdornment: (
								<InputAdornment position="start">
									<GitHubIcon />
								</InputAdornment>
							),
						}}
						disabled={onPostToDatabase}
						onBlur={handleBlur}
						onChange={handleInput}
						onKeyDown={(ev: any) => {
							if (ev.key === "Enter" && !ev.shiftKey) {
								ev.stopPropagation();
								ev.preventDefault();
								handleBlur(ev);
							}
						}}
					/>

					{isActionCreate ? null : (
						<Box sx={{ pl: 2, pr: 2, display: "flex", justifyContent: "center" }}>
							<LoadingButton
								disabled={false}
								sx={{ width: "100%", whiteSpace: "nowrap", ml: "auto", mr: "auto", color: "rgba(255,255,255,.5)", textTransform: "none" }}
								color="error"
								size={"medium"}
								variant="outlined"
								onClick={() => {
									deleteDialogRef.current?.openDialog();
								}}
							>
								{`Delete ${entityName(formInput?.project_type?.value)}`}
							</LoadingButton>
						</Box>
					)}
				</Box>
			</DialogContent>
			<Divider />
			<DialogActions sx={{ display: "flex", flexDirection: "column", height: "85px" }}>
				<LoadingButton
					disabled={!hasChanged().length}
					sx={{ whiteSpace: "nowrap", color: "text.primary", textTransform: "none", pl: 10, pr: 10 }}
					loading={onPostToDatabase}
					color={isActionCreate ? "secondary" : "primary"}
					onClick={handleSubmit}
					size={"large"}
					loadingPosition="end"
					variant="contained"
					endIcon={<CloudUploadIcon />}
				>
					{!onPostToDatabase ? (isActionCreate ? `Create ${entityName(formInput?.project_type?.value)}` : "Save Changes") : isActionCreate ? `Creating ${entityName(formInput?.project_type?.value)}...` : "Saving Changes..."}
				</LoadingButton>
				<Box fontSize={"0.7em"} sx={{ pt: "2px", opacity: 0.5 }}>
					<b>Shift + Return</b> to {isActionCreate ? " create" : " save"} {` ${entityName(formInput?.project_type?.value)?.toLowerCase()}`}
				</Box>
			</DialogActions>
			<DeleteDialog ref={deleteDialogRef} onCloseCallback={() => handleClose(true)} itemToDelete={{ job_ident: job.values?.ident, item_name: formInput.project_name?.value, item_id: formInput.project_id?.value, item_type: "project" }} />
		</Dialog>
	);
});
