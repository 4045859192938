import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { useAuthController, useSnackbarController } from "@camberi/firecms";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import LayersClearIcon from "@mui/icons-material/LayersClear";

import TextFieldPlus from "./TextFieldPlus";

import { GroupType } from "../../../Types/types";
import { useNavigate } from "react-router-dom";

type typeDeleteItem = {
	job_ident: string;
	item_name: string;
	item_id: string;
	item_type: GroupType;
};

export const DeleteDialog = React.forwardRef(({ itemToDelete, onCloseCallback }: { itemToDelete: typeDeleteItem; onCloseCallback: () => void }, ref) => {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [inputValue, setInputValue] = useState<string>("");
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [onDelete, setOnDelete] = useState<boolean>(false);
	const [btnEnabled, setBtnEnabled] = useState(false);

	const theme = useTheme();

	const snackbarController = useSnackbarController();
	const authController = useAuthController();

	const close = (evt: any) => {
		handleCloseDialog(evt);
		setOpen(false);
		setOnDelete(false);
		setInputValue("");
	};

	const defaultPrevent = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
	};

	const handleSubmit = async (evt: any) => {
		evt.preventDefault();
		if (onDelete) return;

		setOnDelete(true);

		const job_ident = itemToDelete.job_ident;

		let respData;
		let response: any;
		console.log(itemToDelete?.item_id);
		try {
			if (itemToDelete.item_type === "version") {
				const project_name = inputValue;
				response = await axios({
					url: `${authController?.extra?.apiUrl}/version/delete`,
					method: "POST",
					headers: {
						Authorization: `boostToken ${authController?.extra?.user_data?.values?.boost_token}`,
					},
					data: { version_id: itemToDelete?.item_id },
				});
			}

			if (itemToDelete.item_type === "project") {
				const project_name = inputValue;
				response = await axios({
					url: `${authController?.extra?.apiUrl}/project/delete`,
					method: "POST",
					headers: {
						Authorization: `boostToken ${authController?.extra?.user_data?.values?.boost_token}`,
					},
					data: { job_ident, project_name },
				});
			}

			if (itemToDelete.item_type === "asset") {
				const asset_name = inputValue;
				response = await axios({
					url: `${authController?.extra?.apiUrl}/asset/delete`,
					method: "POST",
					headers: {
						Authorization: `boostToken ${authController?.extra?.user_data?.values?.boost_token}`,
					},
					data: { job_ident, asset_name },
				});
			}

			if (itemToDelete.item_type === "job") {
				const job_name = inputValue;
				response = await axios({
					url: `${authController?.extra?.apiUrl}/job/delete`,
					method: "POST",
					headers: {
						Authorization: `boostToken ${authController?.extra?.user_data?.values?.boost_token}`,
					},
					data: { job_ident, job_name },
				});
			}

			respData = await response?.data;
			console.log(respData);

			if (respData.success) {
				if (itemToDelete.item_type === "job") {
					navigate(`/`);
				}
				snackbarController.open({
					type: "success",
					title: "Done",
					message: `Deleted ${itemToDelete.item_type}`,
				});
				close(evt);
				onCloseCallback();
			} else {
				setOnDelete(false);
			}
		} catch (err: any) {
			setOnDelete(false);

			const message = typeof err.response?.data?.message !== "undefined" ? err.response.data.message : `Failed to delete ${itemToDelete.item_type}`;
			setErrorMessage(`${err.response?.data?.errorMessage}\n\nError: ${message}`);

			snackbarController.open({
				type: "error",
				title: `${itemToDelete.item_type} deletion failed`,
				message: message,
			});
			console.log(err);
		}
	};

	useImperativeHandle(ref, () => ({
		openDialog: () => {
			console.log("open");
			setOpen(true);

			if (itemToDelete?.item_type === "version") setBtnEnabled(true);
		},
	}));

	const handleClose = (evt: any) => {
		defaultPrevent(evt);
		if (onDelete) return;
		setInputValue("");
		setOpen(false);
	};

	const handleChange = (evt: any) => {
		defaultPrevent(evt);
		const inputValue = evt.target.value;

		setInputValue(evt.target.value);

		if (inputValue && inputValue !== "" && inputValue === itemToDelete.item_name) {
			setBtnEnabled(true);
		} else {
			setBtnEnabled(false);
		}
	};

	const handleCloseDialog = (evt: any) => {
		defaultPrevent(evt);
		if (evt.code && !onDelete) handleClose(evt);
	};

	return (
		<Dialog
			onClose={handleCloseDialog}
			open={open}
			maxWidth={"md"}
			sx={{
				borderRadius: "15px",
				boxShadow: 24,
				outline: "none",
			}}
			PaperProps={{
				sx: { borderRadius: "15px", width: "500px", backgroundColor: "background.paper", backgroundImage: "none" },
			}}
			scroll={"paper"}
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
		>
			<DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "left", backgroundColor: "error.main", color: "text.primary", p: 0, m: 0, height: "60px", textTransform: "capitalize" }} component="h6">
				<Box sx={{ ml: 2, mr: 0.5, display: "flex", alignItems: "center" }}>
					<IconButton disableRipple>{itemToDelete?.item_type !== "version" ? <DeleteIcon /> : <LayersClearIcon />}</IconButton>
				</Box>
				{itemToDelete?.item_type !== "version" ? `Delete ${itemToDelete.item_type}?` : "Mark Deleted?"}
				{!onDelete ? (
					<IconButton sx={{ ml: "auto", mr: 1 }} onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				) : null}
			</DialogTitle>
			<Divider />
			<DialogContent sx={{ overflowY: "scroll", "&::-webkit-scrollbar": { background: "rgba(0,0,0,0)", width: "10px" }, "&::-webkit-scrollbar-thumb": { background: "rgba(125,125,125,1)", borderRadius: "10px" } }}>
				<Box
					display={"flex"}
					sx={{
						mt: 2,
						pl: 1,
						pr: 1,
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{itemToDelete?.item_type !== "version" ? (
						<Typography sx={{ mb: 2 }} align={"left"} variant="body2">
							Are you sure you want to delete <strong>{itemToDelete?.item_name || "N/A"}</strong>?<br />
							<br />
							All associated
							{{ job: " projects, assets and versions ", project: " assets and versions ", asset: " versions ", subasset: "", subproject: "", version: "" }[itemToDelete?.item_type]}
							will be deleted. This action is PERMANENT. No restore, no mercy.
						</Typography>
					) : (
						<Typography sx={{ mb: 2 }} align={"left"} variant="body2">
							Are you sure you want to mark <strong>{itemToDelete?.item_name || "N/A"}</strong> as deleted?
						</Typography>
					)}
					{itemToDelete?.item_type !== "version" ? (
						<TextFieldPlus
							label={
								<div>
									<b>Type {itemToDelete.item_type} name </b> to delete {itemToDelete.item_type}
								</div>
							}
							placeholder={""}
							name="project_git_link"
							value={inputValue}
							disabled={onDelete}
							color={errorMessage ? "warning" : undefined}
							disableendadornment
							sx={{
								...(!errorMessage && {
									// input label when focused
									"& label.Mui-focused": {
										color: theme.palette.text.secondary,
									},
									// focused color for input with variant='standard'
									"& .MuiInput-underline:after": {
										borderBottomColor: theme.palette.text.secondary,
									},
									// focused color for input with variant='filled'
									"& .MuiFilledInput-underline:after": {
										borderBottomColor: theme.palette.text.secondary,
									},
									// focused color for input with variant='outlined'
									"& .MuiOutlinedInput-root": {
										"&.Mui-focused fieldset": {
											borderColor: theme.palette.text.secondary,
										},
									},
								}),
							}}
							focused
							onChange={handleChange}
						/>
					) : null}

					{itemToDelete?.item_type !== "version" ? (
						<Typography sx={{ color: "warning.main", fontWeight: "bold", mb: 2, whiteSpace: "pre-line" }} align={"left"} variant="body2">
							<br />
							{errorMessage}
						</Typography>
					) : null}
				</Box>
			</DialogContent>
			<Divider />
			<DialogActions sx={{ display: "flex", flexDirection: "column", height: "85px", alignItems: "center", justifyContent: "center" }}>
				<LoadingButton
					disabled={!btnEnabled}
					sx={{ whiteSpace: "nowrap", color: "text.primary", textTransform: "none", pl: 2, pr: 2 }}
					loading={onDelete}
					color={"error"}
					onClick={handleSubmit}
					size={"large"}
					loadingPosition="end"
					variant="contained"
					endIcon={itemToDelete?.item_type !== "version" ? <DeleteIcon /> : <LayersClearIcon />}
				>
					<Box sx={{ textTransform: "capitalize" }}>{!onDelete ? (itemToDelete?.item_type !== "version" ? `delete forever` : "Mark deleted") : `bye bye ${itemToDelete.item_type}...`}</Box>
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
});
