import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";

import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Skeleton from "@mui/material/Skeleton";
import { isEqualSimple, isUndefined } from "../utils/basicFunctions";
import { expiresIn } from "../utils/signedUrls";
import { useCountdown, useDebounce } from "usehooks-ts";

import { FilmIcon, ImageIcon } from "../CustomIcons/CustomIcons";

import { useAuthController } from "@camberi/firecms";

import axios from "axios";

function clamp(number: number, min: number, max: number) {
	return Math.max(min, Math.min(number, max));
}
export const PreviewImage = React.forwardRef(function PreviewImage(props: any, ref) {
	const theme = useTheme();
	const smMatch = useMediaQuery(theme.breakpoints.up("sm"));
	const { status, assetId, version, img_type, autoplay = false, is_visible = false, is_round = false, show_favorite = false, is_selected = false, img_fit_view = false, sx, onclick, ...newProps } = props;

	const [isScrubable, setIsScrubable] = useState<boolean>(false);
	const [curAssetId, setCurAssetId] = useState<string>("");

	const [thumbInfo, setThumbInfo] = useState<any>(undefined);
	const [bounds, setBounds] = useState<any>(undefined);

	const authController = useAuthController();
	const [requiredSources, setRequiredSources] = useState<string[]>([]);

	const [curImgUrl, setCurImgUrl] = useState<string>("");
	const [imgLoaded, setImgLoaded] = useState<boolean>(false);
	const [requestedSign, setRequestedSign] = useState<boolean>(false);

	const img = new Image();

	img.onload = () => {
		setImgLoaded(true);
	};

	const [curImgUrlCover, setCurImgUrlCover] = useState<string>("");
	const [imgLoadedCover, setImgLoadedCover] = useState<boolean>(false);
	const [requestedSignCover, setRequestedSignCover] = useState<boolean>(false);

	const imgCover = new Image();

	imgCover.onload = () => {
		setImgLoadedCover(true);
	};

	const debugIfVersion = (message: any, versionId: string) => {
		if (versionId === version.id) {
			console.log(message);
		}
	};

	const requestSign = (version_id: string, src_fields: any[]) => {
		return axios({
			url: `${authController?.extra?.apiUrl}/resign_url`,
			method: "POST",
			headers: {
				Authorization: `boostToken ${authController?.extra?.user_data?.values?.boost_token}`,
			},
			data: { src_fields, version_id: version_id },
		});
	};

	useEffect(() => {
		const _assetId = assetId === "fav" ? version?.values?.cur_preview_fav : assetId;

		if (typeof _assetId !== "undefined" && _assetId !== "" && !isEqualSimple(_assetId, curAssetId)) {
			setCurAssetId(_assetId);
		}
	}, [assetId, version]);

	useEffect(() => {
		if (typeof curAssetId === "undefined" || curAssetId === "") {
			return;
		}

		let img_type_to_load = img_type;
		//conform Input
		//validate existestence or replace
		if (img_type === "thumb_scrub") {
			const scrub_src = version?.values?.fio_asset_ids?.[curAssetId]?.[`img_${img_type}`];
			if (isUndefined(scrub_src) || scrub_src === "" || scrub_src === "error" || !smMatch) {
				//scrub image is requested but doesn't exists
				//change image type to cover
				img_type_to_load = "full";
			}
		}
		if (img_type_to_load === "full") {
			const full_src = version?.values?.fio_asset_ids?.[curAssetId]?.[`img_full`];
			if (isUndefined(full_src) || full_src === "" || full_src === "error") {
				//scrub image is requested but doesn't exists
				//change image type to cover
				img_type_to_load = "small";
			}
		}
		setThumbInfo(version?.values?.fio_asset_ids?.[curAssetId]?.thumb_info);
		const _isScrub = img_type_to_load === "thumb_scrub";
		setIsScrubable(_isScrub);
		const src_required = _isScrub && !autoplay ? [img_type_to_load, "cover"] : [img_type_to_load];
		setRequiredSources(src_required);
		// debugIfVersion("valid " + src_required, "v8337013873559");
		src_required.forEach((src, index) => {
			const _imgUrl = version?.values?.fio_asset_ids?.[curAssetId]?.[`img_${src}_signedUrl`];
			const _curImgUrl = index === 0 ? curImgUrl : curImgUrlCover;
			const alreadyRequestedSign = index === 0 ? requestedSign : requestedSignCover;
			const imgUrlAlreadyDefined = isEqualSimple(_imgUrl, _curImgUrl);

			const imgSrcExists = typeof version?.values?.fio_asset_ids?.[curAssetId]?.[`img_${src}`] !== "undefined" && version?.values?.fio_asset_ids?.[curAssetId]?.[`img_${src}`] !== "";
			let requiresSigning = false;
			let imageIsValid = false;

			if (_curImgUrl !== "") {
				return;
			}

			if (!imgUrlAlreadyDefined && typeof _imgUrl !== "undefined" && _imgUrl !== "" && typeof version !== "undefined") {
				//check if url is too expire
				if (expiresIn(_imgUrl) > 1000 * 60 * 60 * 2) {
					imageIsValid = true;
					// debugIfVersion("valid " + version.id, "v8337013873559");
					// debugIfVersion("valid " + _imgUrl, "v8337013873559");
				} else {
					//reqSignedUrl
					// debugIfVersion("invalid " + version.id, "v8337013873559");
					// debugIfVersion("invalid " + _imgUrl, "v8337013873559");
					if (!alreadyRequestedSign) {
						requiresSigning = true;
						// console.log("reqSignedUrl1");
						// console.log(version.id);
					}
				}
			} else if (!imgUrlAlreadyDefined && imgSrcExists) {
				//img src exists but has no signed url
				if (!alreadyRequestedSign) {
					requiresSigning = true;
					// console.log("reqSignedUrl2");
					// console.log(version.id);
				}
			}

			if (imageIsValid) {
				if (index === 0) {
					setCurImgUrl(_imgUrl);
				} else {
					setCurImgUrlCover(_imgUrl);
				}
			} else if (requiresSigning) {
				if (index === 0) {
					setRequestedSign(true);
				} else {
					setRequestedSignCover(true);
				}

				requestSign(version.id, [{ src_path: `fio_asset_ids.${curAssetId}.img_${src}` }]).catch((err) => console.log(err));
			}
		});
	}, [curAssetId, version, smMatch]);

	useEffect(() => {
		if (curImgUrl !== "") {
			setImgLoaded(false);
			img.src = curImgUrl;
		}
	}, [curImgUrl]);

	useEffect(() => {
		if (curImgUrlCover !== "") {
			setImgLoadedCover(false);
			imgCover.src = curImgUrlCover;
		}
	}, [curImgUrlCover]);

	const [mousePosition, setMousePosition] = useState<number[]>([-1, -1]);
	const [mouseHover, setMouseHover] = useState<boolean>(false);
	const debouncedMouseHover = useDebounce<boolean>(mouseHover, autoplay ? 150 : 0);
	const [isShown, setIsShown] = useState<boolean>(false);

	const [scrubIntervalValue, setScrubIntervalValue] = useState<number>(25); //ms per frame

	const [count, { startCountdown, stopCountdown, resetCountdown }] = useCountdown({
		countStart: 100,
		intervalMs: scrubIntervalValue,
	});

	//Stop autoplay on Mousemove and calculate position
	const onMousemove = (e: any) => {
		setMouseHover(true);
		var bounds = e.target.getBoundingClientRect();
		var x = e.clientX - bounds.left;
		var y = e.clientY - bounds.top;

		setMousePosition([x, y]);
		setBounds(bounds);
		resetCountdown();
	};

	//Restart autoplay on MouseLeave
	const onMouseLeave = (e: any) => {
		setMouseHover(false);
	};

	useEffect(() => {
		if (debouncedMouseHover === false && mouseHover === false) {
			if (is_visible && autoplay) {
				resetCountdown();
				startCountdown();
			}
		}
	}, [debouncedMouseHover]);

	//Loop VideoThumb
	useEffect(() => {
		if (count === 0 && is_visible && autoplay) {
			resetCountdown();
			startCountdown();
		}
	}, [count]);

	// Start auto play if turning visible
	useEffect(() => {
		if (is_visible && autoplay) {
			startCountdown();
		}
	}, [is_visible]);

	const allPreloaded = requiredSources.length !== 0 ? (requiredSources.length > 1 ? imgLoaded && imgLoadedCover : imgLoaded) : false;

	const isVideo = version?.values?.fio_asset_ids?.[curAssetId]?.asset_info?.fps > 0;

	const statusSwitch = (status: string | undefined) => {
		switch (status) {
			case "pending.fio.asset.created":
				//display skeleton
				return "test";
			case "pending.fio.asset.ready":
				//display skeleton
				return "test";
			case "pending.announced":
				//display uploading
				return;
			default:
				return;
		}
	};

	const handleStarClick = (evt: any) => {
		evt.preventDefault();
		evt.stopPropagation();
		console.log("click star");
	};
	return (
		<Box
			sx={{ ...sx, ...(is_selected && { border: 2, borderColor: `${theme.palette.primary.dark}` }), ...(is_round && { borderRadius: "18px!important", overflow: "hidden!important" }) }}
			{...newProps}
			ref={ref}
			onMouseMove={onMousemove}
			onMouseLeave={onMouseLeave}
			onClick={(evt: any) => {
				if (typeof onclick !== "undefined") onclick();

				authController?.extra?.playerDialogHandleOpen(version?.values?.asset?.id, version?.id, assetId === "fav" ? version?.values?.cur_preview_fav : assetId);
			}}
		>
			{allPreloaded ? (
				isScrubable ? (
					<Box sx={{ ...(is_round && { borderRadius: 4, overflow: "hidden" }), position: "relative", width: "100%", height: "100%" }}>
						<Box
							style={{
								cursor: "pointer",
								width: "100%",
								height: "100%",
								backgroundImage: `url("${curImgUrl}")`,
								backgroundSize: `auto ${thumbInfo?.thumbs}00%`,

								backgroundPosition: `50% ${
									!debouncedMouseHover && autoplay ? Math.floor(((100 - count) / 100) * (thumbInfo?.thumbs - 1)) * -100 : clamp(Math.floor((mousePosition[0] / bounds?.width) * (thumbInfo?.thumbs - 1)), 0, thumbInfo?.thumbs - 1) * -100
								}%`,
							}}
						>
							<Box
								sx={{
									opacity: mouseHover ? 1 : 0,
									position: "relative",
									pointerEvents: "none",
									top: 0,
									height: "100%",
									width: "2px",
									transform: `translate(${mousePosition[0]}px, 0px)`,
									backgroundColor: `${theme.palette.error.main}`,
								}}
							/>
							<Box
								style={{
									opacity: mouseHover ? 0 : 1,
									position: "absolute",
									top: 0,
									bottom: 0,
									width: "100%",
									height: "100%",
									backgroundSize: "auto 100%",
									backgroundPosition: `50% 50%`,
									backgroundImage: `url("${curImgUrlCover}")`,
								}}
							/>
							<Box component={FilmIcon} sx={{ opacity: 0.85, fontSize: "medium", pointerEvents: "none", position: "absolute", bottom: 5, right: 5 }}></Box>
						</Box>
					</Box>
				) : (
					<Box sx={{ ...(is_round && { borderRadius: 4, overflow: "hidden" }), position: "relative", width: "100%", height: "100%" }}>
						<Box
							style={{
								cursor: "pointer",
								width: "100%",
								height: "100%",
								backgroundSize: "cover",
								backgroundPosition: `50% 50%`,
								backgroundImage: `url("${curImgUrl}")`,
								...(img_fit_view && { backgroundImage: `url("${curImgUrl}1")`, backgroundRepeat: "no-repeat", backgroundSize: "contain" }),
							}}
						>
							{isVideo ? <Box component={FilmIcon} sx={{ opacity: 0.85, fontSize: "medium", pointerEvents: "none", position: "absolute", bottom: "5px", right: "5px" }}></Box> : null}
							{/*<Box component={ImageIcon} sx={{ opacity: 0.85, fontSize: "medium", pointerEvents: "none", position: "absolute", bottom: 15, right: 15 }}></Box>*/}
						</Box>
					</Box>
				)
			) : (
				//<Box sx={{ ...sx, backgroundColor: "black" }} />
				<Skeleton sx={{ ...sx, ...(is_round && { borderRadius: "18px!important", overflow: "hidden!important" }) }} variant="rectangular" />
			)}
		</Box>
	);
});
