import { useCallback } from "react";
import { Handle, Position } from "react-flow-renderer";
import { Box } from "@mui/material";

import { useTheme } from "@mui/material/styles";

import { IconButton } from "@mui/material";

import FolderIcon from "@mui/icons-material/Folder";
import { JobIcon, ProjectUnrealEditIcon, ProjectUnrealAssetIcon, ProjectGenericIcon, ErrorIcon } from "../../../CustomIcons/CustomIcons";

import { labelIconByAssetType, labelIconByProjectType } from "../../../CustomIcons/CustomIcons";

import { AssetType, ProjectType, ActionType } from "../../../Types/types";

const handleStyle = { opacity: 0, left: "75" };

function CustomNode({ data }: { data: any }) {
	const theme = useTheme();
	const onChange = useCallback((evt: any) => {
		console.log(evt.target.value);
	}, []);

	return (
		<div
			className="text-updater-node"
			style={{
				pointerEvents: "none",
				opacity: data?.selected ? 1 : 0.25,
				borderRadius: "4px",
				height: data?.isJobNameShown ? "38px" : "28px",
				width: data?.isJobNameShown ? "150px" : "120px",
				background: data?.type === "job" ? `${theme.palette.secondary.main}` : data?.type === "project" ? `${theme.palette.primary.main}` : data?.type === "used_project" ? `${theme.palette.primary.dark}` : "black",
			}}
		>
			<Handle type="target" position={Position.Top} style={handleStyle} />

			<Box sx={{ typography: "body2", height: "100%", display: "flex", justifyContent: "Center", alignItems: "Center", borderRadius: "4px" }}>
				{data?.type === "job" ? <JobIcon sx={{ ml: 1, mr: 1, width: "15px" }} /> : <Box component={labelIconByProjectType(data?.project_type, data?.type !== "project")} sx={{ ml: 1, mr: 1, width: "15px" }} />}

				<Box component="div" fontSize={9} sx={{ pr: 1, width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
					{data?.isJobNameShown ? data?.label : data?.label?.replace(data?.job_prefix + "_", "")}
				</Box>
			</Box>

			<Handle type="source" position={Position.Bottom} id="a" style={handleStyle} />
		</div>
	);
}

export default CustomNode;
