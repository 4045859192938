import React, { useEffect, useState, useCallback, useRef } from "react";
import { Box } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

//DRAWER
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { isEqualSimple } from "../utils/basicFunctions";
import ModalCreateJob from "./NavModalCreateJob";
import ModalDeleteJob from "./NavModalDeleteJob";

import { TreeViewMenuModalPlayer } from "../Components/TreeView/TreeViewMenuModalPlayer";
import { TreeViewMenuModalJob } from "../Components/TreeView/TreeViewMenuModalJob";
import { JobIcon, HomeIcon, LogoIcon } from "../CustomIcons/CustomIcons";
// import HomeIcon from "../img/home-icon.svg";
import RectIcon from "../img/rectangle-2.svg";
import { useAuthController, useDataSource, useFireCMSContext } from "@camberi/firecms";
import { useLocation, Link } from "react-router-dom";
import { ReferenceType, ActionType } from "../Types/types";
import { ConfirmationServiceProvider } from "../Components/TreeView/Components/ConfirmationService";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
const drawerWidth = 240;

/**
 * Sample CMS view not bound to a collection, customizable by the developer
 * @constructor
 */
function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}
const createMobileHeaderCenter = (text: string, icon: any) => {
	return (
		<Box sx={{ width: "100%", pr: 1, pl: 0.5, pt: 1, pb: 1, borderRadius: "5px", display: "flex", alignItems: "center", justifyItems: "center", backgroundColor: "secondary.main" }}>
			<Box component={icon} sx={{ mr: 1 }} />
			<Typography sx={{ mr: 1, overflow: "hidden", textOverflow: "ellipsis" }} variant="body2">
				{text}
			</Typography>
		</Box>
	);
};

const JobListItem = (props: any) => {
	const [hover, setHover] = useState<boolean>(false);
	const { userIsAdmin } = props;

	const job_has_setup = Object.keys(props.job?.values.roles?.details || {}).length > 0;

	return (
		<ListItem onMouseMove={() => setHover(true)} onMouseLeave={() => setHover(false)} key={props.job.id + "_li"} disablePadding>
			<ListItemButton
				onClick={() => {
					props.createAndSetMobileNavCenter(props?.job?.values?.name.replace(`_${props?.job?.values?.client}_`, "_"), JobIcon);
				}}
				component={Link}
				to={`/jobs/${props.job.id}/${job_has_setup ? "versions" : "topology"}`}
				disableRipple
			>
				<JobIcon sx={{ mr: 0.5 }} />

				<ListItemText
					primary={
						<Typography sx={{ width: 160, overflow: "hidden", textOverflow: "ellipsis" }} variant="body2">
							{props?.job?.values?.name.replace(`_${props?.job?.values?.client}_`, "_")}
						</Typography>
					}
				/>
				{(userIsAdmin && hover) || (userIsAdmin && props.mobileOpen) ? (
					<IconButton
						size="small"
						onClick={() => {
							props.jobDialogHandleOpen(props.job.id, "edit");
						}}
					>
						<MoreVertIcon fontSize="inherit" />
					</IconButton>
				) : null}
			</ListItemButton>
		</ListItem>
	);
};
export function NavDrawer({ jobsCollection, usersCollection }: any) {
	const jobDialogRef = useRef<any>();
	const playerDialogRef = useRef<any>();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down(1920));
	const [authUser, setAuthUser] = useState<any>();
	const [jobs, setJobs] = useState<any[]>([]);
	const [groupedJobs, setGroupedJobs] = useState<any>({});
	const [users, setUsers] = useState<any[]>([]);
	const [userIsAdmin, setUserIsAdmin] = useState<boolean>(false);
	const [userDataListenerInit, setUserDataListenerInit] = useState<void>(undefined);
	const [userJobsListenerInit, setUserJobsListenerInit] = useState<void>(undefined);
	const [adminUsersListenerInit, setAdminUsersListenerInit] = useState<void>(undefined);

	const dataSource = useDataSource();
	const authController = useAuthController();
	const context = useFireCMSContext();

	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [adminOpen, setAdminOpen] = React.useState(false);
	const [userData, setUserData] = useState<any>();

	const [mobileNavCenter, setMobileNavCenter] = useState<any>(<CircularProgress sx={{ p: 1 }} color="secondary" />);

	useEffect(() => {
		if (authController?.user?.uid) {
			if (!isEqualSimple(authUser, authController?.user)) {
				setAuthUser(authController.user);
			}
		}
	}, [authController]);

	const onUserDataUpdate = (evt: any) => {
		if (!isEqualSimple(evt[0], userData)) {
			if (evt[0].values?.role === "admin") setUserIsAdmin(true);
			setUserData(evt[0]);
		}
	};

	useEffect(() => {
		if (authUser?.uid) {
			if (typeof userDataListenerInit === "undefined" && usersCollection && dataSource.listenCollection) {
				const userDataListener = dataSource.listenCollection({
					path: usersCollection.path,
					collection: usersCollection,
					filter: { __name__: ["==", authUser.uid] },
					onUpdate: onUserDataUpdate,
				});
				setUserDataListenerInit(() => () => userDataListener);
			}
		}
	}, [dataSource, usersCollection, authUser]);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	let location = useLocation();
	React.useEffect(() => {}, [location]);

	let query = useQuery();
	useEffect(() => {
		if (userData?.values?.status === "active") {
			if (authUser?.uid) {
				if (typeof userJobsListenerInit === "undefined" && jobsCollection && dataSource.listenCollection) {
					const searchFilter = { "roles.any": ["array-contains", authUser.uid] };

					const userJobListener = dataSource.listenCollection({
						path: jobsCollection.path,
						collection: jobsCollection,
						filter: !userIsAdmin ? { "roles.any": ["array-contains", authUser.uid] } : {},
						onUpdate: onJobsUpdate,
					});
					setUserJobsListenerInit(() => () => userJobListener);
				}
			}
		} else {
			setJobs([]);
			setGroupedJobs({});
		}
	}, [dataSource, jobsCollection, authUser, userData]);

	const groupItemBy = function (array: any, property: any) {
		var hash: any = {},
			props: any = property.split(".");
		for (var i = 0; i < array.length; i++) {
			var key: any = props.reduce(function (acc: any, prop: any) {
				return acc && acc[prop];
			}, array[i]);
			if (!hash[key]) hash[key] = [];
			hash[key].push(array[i]);
		}
		return hash;
	};

	const onJobsUpdate = (evt: any) => {
		evt.sort((a: any, b: any) => (b?.values?.client === "stunning" ? 1 : 0) - (a?.values?.client === "stunning" ? 1 : 0) || a?.values?.client.localeCompare(b.values?.client) || a?.values?.ident.localeCompare(b.values?.ident));
		setGroupedJobs(groupItemBy(evt, "values.client"));
		setJobs(evt);
	};

	const onAdminUsersUpdate = (evt: any) => {
		if (!isEqualSimple(evt, users)) {
			setUsers(evt);
		}
	};

	useEffect(() => {
		if (userIsAdmin) {
			if (typeof adminUsersListenerInit === "undefined" && usersCollection && dataSource.listenCollection) {
				const adminUsersListener = dataSource.listenCollection({
					path: usersCollection.path,
					collection: usersCollection,
					onUpdate: onAdminUsersUpdate,
				});
				setAdminUsersListenerInit(() => () => adminUsersListener);
			}
		}
	}, [userIsAdmin, dataSource]);
	const createAndSetMobileNavCenter = (text: string, icon: any) => {
		setMobileNavCenter(createMobileHeaderCenter(text, icon));
		//setMobileNavCenter(<CircularProgress sx={{ p: 1 }} color="secondary" />);
		setMobileOpen(false);
	};
	useEffect(() => {
		//console.log("callback");
		const user_jobs = jobs || [];
		const user_data = userData || undefined;

		let all_collabs: any = [];

		if (userIsAdmin) {
			all_collabs =
				users
					?.filter((user) => (user.values?.status === "active" || user.values?.status === "created") && user.values?.role !== "service")
					?.map((user) => {
						return { id: user.id, name: user.values?.email };
					}) || [];
		} else {
			user_jobs.forEach((job) => {
				Object.entries(job.values?.roles?.details || {}).forEach(([key, value]: any) => {
					all_collabs.push({ id: key, name: value?.name });
				});
			});
		}

		const user_collaborators = Array.from(new Set(all_collabs.map(JSON.stringify))).map((user: any) => JSON.parse(user)) as ReferenceType[];
		//console.log(user_collaborators);

		if (!isEqualSimple(authController?.extra?.user_jobs, user_jobs) || !isEqualSimple(authController?.extra?.user_data, user_data) || !isEqualSimple(authController?.extra?.user_collaborators, user_collaborators)) {
			authController.setExtra({
				user_jobs,
				user_data,
				user_collaborators,
				playerDialogHandleOpen,
				apiUrl: "https://us-central1-stunning-boost.cloudfunctions.net/api",
				mobileNavHandleOpen: () => {
					setMobileOpen(true);
				},
				createAndSetMobileNavCenter,
			});
		}
	}, [userData, jobs, users]);

	const jobDialogHandleOpen = (originId: string, action: ActionType) => {
		jobDialogRef?.current?.openDialog(originId, action);
	};

	const playerDialogHandleOpen = (assetId: string, versionId: string = "", fioAssetId: string = "") => {
		playerDialogRef?.current?.openDialog(assetId, versionId, fioAssetId, () => {
			if ("URLSearchParams" in window) {
				var newRelativePathQuery = window.location.pathname;

				window.history.replaceState(null, "", newRelativePathQuery);
			}
		});
		if ("URLSearchParams" in window) {
			var searchParams = new URLSearchParams(window.location.search);
			searchParams.set("a", assetId);
			searchParams.set("v", versionId);
			searchParams.set("f", fioAssetId);
			var newRelativePathQuery = window.location.pathname + "?" + searchParams.toString();
			//console.log(newRelativePathQuery);
			window.history.replaceState(null, "", newRelativePathQuery);
			//window.history.replaceState(null, "New Page Title", `/jobs/${jobId}/${currentJobView}`);
		}
	};

	const playerHandleClose = () => {
		playerDialogRef?.current?.closeDialog();
	};

	useEffect(() => {
		const assetId = query.get("a") || "";
		const versionId = query.get("v") || "";
		const fioAssetId = query.get("f") || "";

		if (assetId?.length) {
			playerDialogHandleOpen(assetId, versionId, fioAssetId);
		} else {
			playerHandleClose();
		}
	}, [query, location]);

	const drawer = (
		<div>
			<ConfirmationServiceProvider>
				<TreeViewMenuModalPlayer ref={playerDialogRef} key={"PlayerDialog"} />
				<TreeViewMenuModalJob ref={jobDialogRef} key={"JobDialog"} jobs={jobs} />
			</ConfirmationServiceProvider>
			{/*{!matches ? <Toolbar /> : null}*/}
			<Toolbar />
			<List sx={{ paddingBottom: "0px" }}>
				<ListItem disablePadding>
					<ListItemButton component={Link} to={`/`} disableRipple disabled>
						<ListItemText primary={<Typography variant="h6">{authUser?.displayName || ""}</Typography>} />
					</ListItemButton>
				</ListItem>

				<ListItem sx={{ paddingTop: "0px" }} disablePadding>
					<ListItemButton onClick={() => createAndSetMobileNavCenter("Dashboard", HomeIcon)} component={Link} to={`/`} disableRipple>
						<HomeIcon sx={{ mr: 0.5 }} />
						<ListItemText primary={<Typography variant="body2">{"Dashboard"}</Typography>} />
					</ListItemButton>
				</ListItem>
			</List>
			<List sx={{ p: 0, m: 0 }} dense={true}>
				<ListItem disablePadding disabled>
					<ListItemButton disableRipple>
						<KeyboardArrowDownRoundedIcon sx={{ mr: 0.5 }} />

						<ListItemText primary={<Typography variant="body2">{"Jobs"}</Typography>} />
						{!userIsAdmin ? null : (
							<IconButton
								size="small"
								onClick={() => {
									jobDialogHandleOpen("", "create");
								}}
							>
								<AddIcon fontSize="inherit" />
							</IconButton>
						)}
					</ListItemButton>
				</ListItem>
				{Object.keys(groupedJobs || {}).map((client: any) => {
					const clientVals = groupedJobs[client];
					return (
						<Box key={client} sx={{ mt: -1 }}>
							<List sx={{ paddingBottom: "0px", cursor: "pointer" }} dense={true}>
								<ListItem disablePadding>
									<ListItemButton disabled disableRipple>
										{true ? <Box sx={{ mr: 0.5 }} /> : <Box sx={{ mr: 0.5 }} />}

										<ListItemText
											primary={
												<Typography sx={{ textTransform: "capitalize" }} variant="body2">
													{client}
												</Typography>
											}
										/>
									</ListItemButton>
								</ListItem>
							</List>
							<Collapse in={true} timeout="auto" unmountOnExit>
								{clientVals.map((job: any, index: number) => {
									return (
										<JobListItem
											jobDialogHandleOpen={jobDialogHandleOpen}
											userIsAdmin={userIsAdmin}
											mobileOpen={mobileOpen}
											createAndSetMobileNavCenter={createAndSetMobileNavCenter}
											setMobileOpenClose={() => setMobileOpen(false)}
											key={job.id}
											job={job}
										/>
									);
								})}
							</Collapse>
						</Box>
					);
				})}

				{/*				{jobs.map((job: any, index: number) => {
					return (
						<JobListItem jobDialogHandleOpen={jobDialogHandleOpen} userIsAdmin={userIsAdmin} mobileOpen={mobileOpen} createAndSetMobileNavCenter={createAndSetMobileNavCenter} setMobileOpenClose={() => setMobileOpen(false)} key={job.id} job={job} />
					);
				})}*/}
			</List>

			{!userIsAdmin ? null : (
				<Box>
					<List sx={{ paddingBottom: "0px", cursor: "pointer" }} dense={true}>
						<ListItem
							disablePadding
							onClick={() => {
								setAdminOpen(!adminOpen);
							}}
						>
							<ListItemButton disabled disableRipple>
								{adminOpen ? <KeyboardArrowDownRoundedIcon sx={{ mr: 0.5 }} /> : <KeyboardArrowRightRoundedIcon sx={{ mr: 0.5 }} />}

								<ListItemText primary={<Typography variant="body2">{"Admin"}</Typography>} />
							</ListItemButton>
						</ListItem>
					</List>
					<Collapse in={adminOpen} timeout="auto" unmountOnExit>
						<List sx={{ p: 0, m: 0 }} dense={true}>
							{context.navigation.collections.map((collection) => {
								return (
									<ListItem key={collection.path} disablePadding>
										<ListItemButton onClick={() => setMobileOpen(false)} component={Link} to={`/c/${collection.path}`} disableRipple>
											<JobIcon sx={{ mr: 0.5, color: "rgba(150,0,0,1)" }} />

											<ListItemText primary={<Typography variant="body2">{collection.name}</Typography>} />
										</ListItemButton>
									</ListItem>
								);
							})}
						</List>
					</Collapse>
				</Box>
			)}
		</div>
	);
	const container = window !== undefined ? () => window.document.body : undefined;

	return (
		<Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
			<Box
				sx={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "calc(100% - 20px)",
					height: "50px",
					display: { xs: "flex", sm: "none" },
					justifyContent: "center",
					mt: 1,
					ml: "10px",
					borderRadius: "15px",
					alignItems: "center",
					backgroundColor: "background.paper",
					zIndex: 1500,
				}}
			>
				<IconButton sx={{ flex: "1 1 0px", display: "flex", justifyContent: "start", alignItems: "start" }} size={"small"} disableRipple onClick={handleDrawerToggle}>
					{mobileOpen ? <CloseIcon sx={{ ml: 2 }} fontSize="inherit" /> : <ArrowBackIcon sx={{ ml: 2 }} fontSize="inherit" />}
				</IconButton>
				{/*	<Button size={"small"} color="info" sx={{ color: "white", textTransform: "none", flex: "1 1 0px" }} disableRipple onClick={handleDrawerToggle}>
					{"< Version View"}
				</Button>*/}
				{mobileNavCenter !== null && !mobileOpen ? (
					<Box sx={{ display: "flex", maxWidth: "60%", overflow: "hidden" }}>{mobileNavCenter}</Box>
				) : (
					<IconButton sx={{ flex: "1 1 0px" }} color={"secondary"} size={"large"} disableRipple>
						<LogoIcon fontSize="inherit" />
					</IconButton>
				)}
				<IconButton sx={{ flex: "1 1 0px" }} color={"primary"} size={"large"} disableRipple></IconButton>
			</Box>

			{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
			<Drawer
				container={container}
				variant="temporary"
				open={mobileOpen}
				onClose={handleDrawerToggle}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{
					display: { xs: "block", sm: "none" },
					"& .MuiDrawer-paper": { boxSizing: "border-box", width: "100%", display: "flex", alignItems: "center", backgroundImage: "none" },
				}}
			>
				{drawer}
			</Drawer>
			<Drawer
				variant="permanent"
				sx={{
					display: { xs: "none", sm: "block" },
					"& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
				}}
				open
			>
				{drawer}
			</Drawer>
		</Box>
	);
}
