import { buildCollection } from "@camberi/firecms";

type Job = {
	name: string;
	ident: string;
	client: string;
	title: string;
	created_on: Date;
	clickup_ws_id: string;
	fio_token: string;
	fio_root_asset_id: string;
	fio_project_id: string;
	fio_team_id: string;
	fio_default_password: string;
	roles: any;
};

export default buildCollection<Job>({
	icon: "Topic",
	name: "Jobs",
	singularName: "Job",
	description: "List of all Jobs",
	path: "jobs",
	group: "Main",
	inlineEditing: false,
	permissions: ({ authController }) => ({
		edit: true,
		create: true,
		// we have created the roles object in the navigation builder
		delete: true,
	}),
	properties: {
		name: {
			name: "Job Name (generated)",
			dataType: "string",
			description: "Is automatically generated",
			disabled: true,
			multiline: false,
			//validation: { required: true, unique: true, max: 5, min: 5 },
		},
		ident: {
			name: "Ident",
			dataType: "string",
			description: "Job Ident e.g. 's0025'",
			multiline: false,
			validation: {
				required: true,
				unique: true,
				max: 5,
				min: 5,
				matches: "/^(?![ _])[A-Za-z0-9]*(?<![ _])$/",
				matchesMessage: "Please use numbers and letters only. Whitespaces are not allowed.",
			},
		},
		client: {
			name: "Client",
			dataType: "string",
			multiline: false,
			description: "Client Name e.g. 'Nike'",
			validation: {
				required: true,
				min: 2,
				matches: "/^(?![ _])[A-Za-z0-9 ]*(?<![ _])$/",
				matchesMessage: "Please use numbers and letters only. Leading or tailing whitespaces are not allowed.",
			},
		},
		title: {
			name: "Title",
			dataType: "string",
			multiline: false,
			description: "Job Name e.g. 'Musicvideo'",
			validation: {
				required: true,
				min: 2,
				matches: "/^(?![ _])[A-Za-z0-9 ]*(?<![ _])$/",
				matchesMessage: "Please use numbers and letters only. Leading or tailing whitespaces are not allowed.",
			},
		},
		clickup_ws_id: {
			name: "Clickup Workstation ID",
			dataType: "string",
			multiline: false,
			description: "The Workspace ID from Clickup e.g. 'Musicvideo'",
			validation: {
				min: 2,
				matches: "/^(?![ _])[A-Za-z0-9 ]*(?<![ _])$/",
				matchesMessage: "Please use numbers and letters only. Leading or tailing whitespaces are not allowed.",
			},
		},
		created_on: {
			readOnly: true,
			dataType: "date",
			name: "created_on",
			mode: "date",
		},
		fio_token: {
			name: "Frame.io Token",
			description: "Job fio-token is used if user doesn't have own one. e.g. 'fio-xxxx-xxx-xxx'",
			dataType: "string",
			multiline: false,
			validation: {
				matches: "/^(?![ _])[A-Za-z0-9-]*(?<![ _])$/",
				matchesMessage: "Please use numbers and letters only. Whitespaces are not allowed.",
			},
		},
		fio_root_asset_id: {
			name: "Frame.io Root Asset ID",
			dataType: "string",
		},
		fio_project_id: {
			name: "Frame.io Project ID",
			dataType: "string",
		},
		fio_team_id: {
			name: "Frame.io Team ID",
			dataType: "string",
		},
		fio_default_password: {
			name: "Frame.io Reviewlink Default Password",
			dataType: "string",
		},
		roles: {
			name: "User Roles",
			dataType: "map",

			properties: {
				any: {
					name: "any",
					dataType: "array",
					of: {
						dataType: "string",
					},
				},
			},
		},
	},
});
