import { buildCollection, buildProperty, EntityReference } from "@camberi/firecms";

type Version = {
	name: string;
	created_on: Date;
	status: string;
	asset: EntityReference;
	job: EntityReference;
	asset_version_number: number;
	announced_filecount: number;
	fio_root_id: string;

	fio_asset_ids: any;
	fio_reviewlink: string;
	author_email: string;
	author: EntityReference;
	commit_message: string;
	review_links: any;
};

export default buildCollection<Version>({
	icon: "WorkHistory",
	name: "Versions",
	singularName: "Version",
	description: "List of all Versions",
	path: "versions",
	group: "Main",
	inlineEditing: false,
	initialSort: ["created_on", "desc"],
	permissions: ({ authController }) => ({
		edit: false,
		create: true,
		// we have created the roles object in the navigation builder
		delete: true,
	}),
	properties: {
		job: buildProperty({
			name: "Job",
			dataType: "reference",
			path: "jobs",
			description: "Job",
			previewProperties: ["name"],
		}),
		created_on: {
			dataType: "date",
			name: "Created on",
			mode: "date_time",
		},
		name: {
			name: "Name",
			dataType: "string",
		},
		status: {
			name: "Status",
			dataType: "string",
		},
		asset: buildProperty({
			name: "Asset",
			dataType: "reference",
			path: "assets",
			description: "Can be assigned with any reference. (e.g. projects, assets, jobs, users)",
			previewProperties: ["name"],
		}),
		asset_version_number: {
			name: "Version Number",
			dataType: "number",
		},
		announced_filecount: {
			name: "Announced Files",
			dataType: "number",
		},
		fio_root_id: {
			name: "Frame.io Root ID",
			dataType: "string",
		},

		fio_reviewlink: {
			name: "Frame.io Reviewlink",
			dataType: "string",
		},
		author_email: {
			name: "Author Email",
			dataType: "string",
		},
		author: buildProperty({
			name: "Author",
			dataType: "reference",
			path: "users",
			previewProperties: ["email"],
		}),
		commit_message: {
			name: "Commit Message",
			dataType: "string",
			multiline: true,
		},
		fio_asset_ids: buildProperty({
			name: "Frame.io Assets",
			dataType: "map",
			properties: {
				fio_asset_id: {
					name: "Id",
					description: "fio_asset_id",
					dataType: "map",
					properties: {
						file_name: {
							name: "file_name",
							dataType: "string",
						},
						file_size: {
							name: "file_size",
							dataType: "number",
						},
						file_type: {
							name: "file_type",
							dataType: "string",
						},
						fio_asset_id: {
							name: "fio_asset_id",
							dataType: "string",
						},
						img_cover: {
							name: "img_cover",
							dataType: "string",
						},
						img_full: {
							name: "img_full",
							dataType: "string",
						},
						img_small: {
							name: "img_small",
							dataType: "string",
						},
						status: {
							name: "status",
							dataType: "string",
						},
					},
				},
			},
		}),
		review_links: buildProperty({
			name: "review_links",
			dataType: "map",
			properties: {
				review_link_id: {
					name: "review_link_id",
					dataType: "map",
					properties: {
						name: {
							name: "name",
							dataType: "string",
						},
						//global or asset id
						type: {
							name: "type",
							dataType: "string",
						},
						url: {
							name: "url",
							dataType: "string",
						},
						secure: {
							name: "secure",
							dataType: "boolean",
						},
						enable_comments: {
							name: "enable_comments",
							dataType: "boolean",
						},
						password: {
							name: "password",
							dataType: "string",
						},
						fio_asset_ids: {
							name: "fio_asset_ids",
							dataType: "array",
							of: {
								dataType: "string",
							},
						},
					},
				},
			},
		}),
	},
});
