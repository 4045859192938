const parseISO8601basic = (input: string) => {
	return new Date(Date.UTC(parseInt(input.slice(0, 4), 10), parseInt(input.slice(4, 6), 10) - 1, parseInt(input.slice(6, 8), 10), parseInt(input.slice(9, 11), 10), parseInt(input.slice(11, 13), 10), parseInt(input.slice(13, 15), 10)));
};

const expiresIn = (signedUrl: string) => {
	let expires = -1;

	const params = new Proxy(new URLSearchParams(signedUrl), {
		get: (searchParams: any, prop: any) => searchParams.get(prop),
	});

	let xGoogExpires: any = params?.["X-Goog-Expires"] || false;
	let xGoogDate: any = params?.["X-Goog-Date"] || false;

	if (xGoogExpires && xGoogDate) {
		const now = Date.now();
		const signExpires = xGoogExpires * 1000;
		const signDateInMs = parseISO8601basic(xGoogDate).getTime();
		const expirationDateInMs = signDateInMs + signExpires;
		const expiresIn = expirationDateInMs - now;
		expires = expiresIn;
	}
	return expires;
};

export { expiresIn };
