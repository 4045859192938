import React, { useState, useEffect, useCallback, useRef, useImperativeHandle } from "react";
import { findDOMNode } from "react-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { isSafari, isMobile } from "react-device-detect";
import Divider from "@mui/material/Divider";
import { useDebounce } from "usehooks-ts";

import { useAuthController, useSnackbarController, CircularProgressCenter } from "@camberi/firecms";

import ReactPlayer from "react-player";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useConfirmation } from "./Components/ConfirmationService";
import versionsCollection from "../../Collections/versionsCollection";
import assetsCollection from "../../Collections/assetsCollection";

import Slider from "@mui/material/Slider";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { PreviewImage } from "../PreviewImage";
import VersionCardPreviewItem from "../VersionCardPreviewItem";

//keyboard adorment
import { ProjectGenericIcon, AssetIcon, AssetShotIcon, AssetTimelineIcon, JobIcon } from "../../CustomIcons/CustomIcons";

import { useDataSource } from "@camberi/firecms";

import { ActionType, ReferenceType, AssetType, ProjectType } from "../../Types/types";
import { isEmpty, diff } from "../../utils/basicFunctions";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import axios from "axios";

import { isEqualSimple, isUndefined, nonselect } from "../../utils/basicFunctions";
import { expiresIn } from "../../utils/signedUrls";

import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HdOutlinedIcon from "@mui/icons-material/HdOutlined";
import HdIcon from "@mui/icons-material/Hd";
import LoopIcon from "@mui/icons-material/Loop";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";

import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useInterval } from "usehooks-ts";

import screenfull from "screenfull";
import { create, smpteToFrame, frameToSmpte, fromTag, frameToSeconds } from "framerate-utils";

import VersionMenu from "./Components/VersionMenu";
import DownloadMenu from "./Components/DownloadMenu";

function useWindowSize() {
	// Initialize state with undefined width/height so server and client renders match
	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});
	useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			// Set window width/height to state
			setWindowSize({
				width: window?.innerWidth,
				height: window?.innerHeight,
			});
		}
		// Add event listener
		window.addEventListener("resize", handleResize);
		// Call handler right away so state gets updated with initial window size
		handleResize();
		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, []); // Empty array ensures that effect is only run on mount
	return windowSize;
}

function off<T extends Window | Document | HTMLElement | EventTarget>(obj: T | null, ...args: Parameters<T["removeEventListener"]> | [string, Function | null, ...any]): void {
	if (obj && obj.removeEventListener) {
		obj.removeEventListener(...(args as Parameters<HTMLElement["removeEventListener"]>));
	}
}

function on<T extends Window | Document | HTMLElement | EventTarget>(obj: T | null, ...args: Parameters<T["addEventListener"]> | [string, Function | null, ...any]): void {
	if (obj && obj.addEventListener) {
		obj.addEventListener(...(args as Parameters<HTMLElement["addEventListener"]>));
	}
}

export const TreeViewMenuModalPlayer = React.forwardRef(function TreeViewMenuModalPlayer({}: {}, ref) {
	const authController = useAuthController();

	const [assetId, setAssetId] = useState<string>("");
	const [open, setOpen] = useState<boolean>(false);
	const [filterSelects, setFilterSelects] = useState<boolean>(false);
	const curLclOverwrites = useRef<any>({});

	const windowSize = useWindowSize();
	const [overlaySize, setOverlaySize] = useState({ width: 0, height: 0 });

	useImperativeHandle(ref, () => ({
		openDialog: (assetIdInput: string, versionId: string = "", fioAssetId: string = "", inputOnCloseCallback: any = () => {}) => {
			//handleCloseReset();
			// if (typeof versionListener != "undefined") {
			// 	//versionListener.current();
			// 	versionListener.current = undefined;
			// }

			if (!open) {
				setFilterSelects(false);
				setCurFiltered([]);
				setMuted(true);
				curLclOverwrites.current = {};
			}

			onCloseCallback.current = inputOnCloseCallback;
			//console.log(assetIdInput);
			setCurFioAssetId(fioAssetId);
			curPrefVersion.current = versionId;
			setVersionId(versionId);
			setAssetId(assetIdInput);
			setOpen(true);
		},
		closeDialog: () => {
			handleClose();
		},
	}));

	const [interaction, setInteraction] = useState<boolean>(false);

	const dataSource = useDataSource();
	const [versionId, setVersionId] = useState<string>("");

	const [version, setVersion] = useState<any>({});
	const versionListener = useRef<any>();
	const curPrefVersion = useRef<any>();
	const [curVideoUrl, setCurVideoUrl] = useState<string>("");
	const requestedSign = useRef<boolean>(false);

	const [asset, setAsset] = useState<any>({});
	const [assetNotLoadAble, setAssetNotLoadAble] = useState<boolean>(false);
	const assetListener = useRef<any>();
	const onCloseCallback = useRef<any>(null);

	const [curFioAssetID, setCurFioAssetId] = useState<string>("");

	const [desiredVideoQuality, setDesiredVideoQuality] = useState<number>(2160);

	const [requiredSources, setRequiredSources] = useState<string[]>([]);

	const handleClose = () => {
		//reset asset, url,
		setOpen(false);
		setAssetNotLoadAble(false);
		if (onCloseCallback.current !== null) onCloseCallback.current();
		onCloseCallback.current = null;
		setTimeout(() => {
			handleCloseReset();
		}, 150);
	};

	const handleCloseReset = () => {
		requestedSign.current = false;
		setAssetId("");
		setVersionId("");
		setVersion({});
		setAsset({});
		setCurFioAssetId("");
		setRequiredSources([]);
		setCurVideoUrl("");
	};

	const onVersionsUpdate = (evt: any) => {
		//console.log("VERSION UPDATE", evt);
		setVersion(evt);

		if (!curFioAssetID.length) {
			const _curFioAssetFavorite = Object.values(evt?.values?.fio_asset_ids || {})?.filter((fio_asset: any) => fio_asset.fio_asset_id === evt?.values?.cur_preview_fav) || [];
			const _curFioFav_Id = (_curFioAssetFavorite?.[0] as any)?.fio_asset_id || "";
			if (curFioAssetID !== _curFioFav_Id) handleChange({}, _curFioFav_Id);
		}
	};

	const requestSign = (version_id: string, src_fields: any[]) => {
		console.log("sent sign request");
		return axios({
			url: `${authController?.extra?.apiUrl}/resign_url`,
			method: "POST",
			headers: {
				Authorization: `boostToken ${authController?.extra?.user_data?.values?.boost_token}`,
			},
			data: { src_fields, version_id: version_id },
		});
	};

	useEffect(() => {
		if (typeof versionListener.current !== "undefined") {
			versionListener.current();
			versionListener.current = undefined;
		}
		if (typeof versionListener.current === "undefined" && dataSource.listenEntity && versionId.length) {
			//console.log("mount");
			versionListener.current = dataSource.listenEntity({
				path: versionsCollection.path,
				entityId: versionId,
				collection: versionsCollection,
				onUpdate: (evt) => onVersionsUpdate(evt),
			});
			return () => {
				//console.log("Unmount version listener Child unmounted");
				versionListener.current();
				versionListener.current = undefined;
			};
		}
	}, [dataSource, versionId]);

	useEffect(() => {
		if (typeof curFioAssetID === "undefined" || curFioAssetID === "") {
			return;
		}

		//check available video files
		const _all_vids =
			Object.keys(version?.values?.fio_asset_ids?.[curFioAssetID] || {})?.filter(
				(key: any) =>
					key.startsWith("vid") &&
					!key.endsWith("signedUrl") &&
					version?.values?.fio_asset_ids?.[curFioAssetID]?.[key] !== "error" &&
					version?.values?.fio_asset_ids?.[curFioAssetID]?.[`${key}`] !== "error" &&
					version?.values?.fio_asset_ids?.[curFioAssetID]?.[`${key}`] !== "" &&
					typeof version?.values?.fio_asset_ids?.[curFioAssetID]?.[`${key}`] !== "undefined",
			) || [];

		_all_vids.sort((a: any, b: any) => {
			return parseInt(a?.split("_")?.[2] || -1) > parseInt(b?.split("_")?.[2] || -1) ? 1 : -1;
		});

		let desiredVid: any = [];
		if (desiredVideoQuality > 0) {
			const allQualities = _all_vids?.map((x: any) => parseInt(x?.split("_")?.[2] || -1)) || [];
			var closest = allQualities?.length
				? allQualities?.reduce(function (prev: number, curr: number) {
						return Math.abs(curr - desiredVideoQuality) < Math.abs(prev - desiredVideoQuality) ? curr : prev;
				  })
				: -1;

			desiredVid = _all_vids.filter((x: any) => parseInt(x?.split("_")?.[2]) === closest);
		}

		let bestVid = _all_vids.length ? _all_vids?.[_all_vids.length - 1] : "";

		if (desiredVid.length) {
			bestVid = desiredVid[0];
		}

		if (bestVid === "vid_h264_2160" && isMobile) bestVid = _all_vids.length ? _all_vids?.[_all_vids.length - 2] : "";

		if (typeof bestVid === "undefined" || bestVid === "") return;

		const src_required = [bestVid];
		setRequiredSources(src_required);
		// debugIfVersion("valid " + src_required, "v8337013873559");
		src_required.forEach((src, index) => {
			const _vidUrl = version?.values?.fio_asset_ids?.[curFioAssetID]?.[`${src}_signedUrl`];
			const _curVideoUrl = curVideoUrl;

			const imgUrlAlreadyDefined = isEqualSimple(_vidUrl, _curVideoUrl);

			let requiresSigning = false;
			let imageIsValid = false;

			/*	if (_curVideoUrl !== "") {
				return;
			}*/

			if (!imgUrlAlreadyDefined && typeof _vidUrl !== "undefined" && _vidUrl !== "" && typeof version !== "undefined") {
				//console.log("expiresIn", "st");
				//check if url is too expire
				if (expiresIn(_vidUrl) > 1000 * 60 * 60 * 2) {
					imageIsValid = true;
				} else {
					//reqSignedUrl

					if (!requestedSign.current) {
						requiresSigning = true;
					}
				}
			} else if (!imgUrlAlreadyDefined) {
				//img src exists but has no signed url
				if (!requestedSign.current) {
					requiresSigning = true;
				}
			}

			if (imageIsValid) {
				if (index === 0) {
					//console.log("setCurVideoUrl", _vidUrl);
					setCurVideoUrl(_vidUrl);

					setProgress({ played: 0 });

					handlePlay();
				}
			} else if (requiresSigning) {
				if (index === 0) {
					requestedSign.current = true;
					setCurVideoUrl("");
				}

				requestSign(version.id, [{ src_path: `fio_asset_ids.${curFioAssetID}.${src}` }]).catch((err) => console.log(err));
			}
		});
	}, [curFioAssetID, version, desiredVideoQuality]);

	const onAssetUpdate = (evt: any) => {
		//console.log("asset", evt);
		if (asset?.id !== evt?.id) {
			//set prefered version
			let latestVersionId;
			if (curPrefVersion.current?.length) latestVersionId = curPrefVersion.current;
			else {
				latestVersionId = evt.values?.versions.map((v: any) => v?.ref?.id)?.sort((a: any, b: any) => a?.localeCompare(b))?.[0] || "";
			}
			//setVersionId("");
			setVersionId(latestVersionId);
			setShowTC(0);

			//setLooping(true);
		}
		setAsset(evt);
	};

	useEffect(() => {
		if (typeof assetListener.current === "undefined" && dataSource.listenEntity && assetId.length) {
			//console.log("mount");
			assetListener.current = dataSource.listenEntity({
				path: assetsCollection.path,
				entityId: assetId,
				collection: assetsCollection,
				onUpdate: (evt) => onAssetUpdate(evt),
				onError: (evt) => {
					setAssetNotLoadAble(true);
				},
			});
			return () => {
				//console.log("Child unmounted");
				assetListener.current();
				assetListener.current = undefined;
			};
		}
	}, [dataSource, assetId]);

	const config = {
		attributes: {
			disablePictureInPicture: true,
			controlsList: "nodownload",
		},
	};

	const handleChange = (event: any, newValue: string) => {
		if (curFioAssetID !== newValue) {
			requestedSign.current = false;
			setCurFioAssetId(newValue);
		}
	};

	//check available video files

	const _curFioAssetFavorite = Object.values(version?.values?.fio_asset_ids || {})?.filter((fio_asset: any) => fio_asset.fio_asset_id === version?.values?.cur_preview_fav) || [];

	const _curFioAssetsWithOutFav =
		Object.values(version?.values?.fio_asset_ids || {})
			?.filter((fio_asset: any) => fio_asset.fio_asset_id !== version?.values?.cur_preview_fav)
			?.sort((a: any, b: any) => a?.file_name?.localeCompare(b?.file_name) || 1) || [];
	const _curFioAssets = [..._curFioAssetFavorite, ..._curFioAssetsWithOutFav];

	let fioAssetIsVideo = true;
	const scrub_src = version?.values?.fio_asset_ids?.[curFioAssetID]?.[`img_thumb_scrub`];
	const fio_asset_info = version?.values?.fio_asset_ids?.[curFioAssetID]?.asset_info;
	const fio_asset_name = version?.values?.fio_asset_ids?.[curFioAssetID]?.file_name || "";
	if (typeof scrub_src === "undefined" || scrub_src === "" || scrub_src === "error") {
		fioAssetIsVideo = false;
	}

	const playerRef = useRef<any>();

	const playing = useRef<any>();

	const [seeking, setSeeking] = useState<boolean>(false);
	const [prefPlaying, setPrefPlaying] = useState<boolean>(true);
	const [progress, setProgress] = useState<any>({});
	const [fullscreenMode, setFullscreenMode] = useState(false);
	const [looping, setLooping] = useState<boolean>(true);
	const [muted, setMuted] = useState<boolean>(true);

	const [buffering, setBuffering] = useState<boolean>(true);
	const [overlayVisible, setOverlayVisible] = useState<boolean>(false);

	const [lastPause, setLastPause] = useState<any>(undefined);
	const debouncedLastPause = useDebounce<string>(lastPause, 2000);

	const [curFrame, setCurFrame] = useState<number>(0);
	const [showTC, setShowTC] = useState<number>(0);

	const toogleShowTC = () => {
		const nextOption = showTC + 1;
		const frameRate = frameRateFromFio(fio_asset_info?.fps);

		setShowTC(nextOption > 2 || (nextOption === 2 && typeof frameRate === "undefined") ? 0 : nextOption);
	};

	const toogleQuality = () => {
		setDesiredVideoQuality(360);

		if (desiredVideoQuality === 0 || desiredVideoQuality === 2160) {
			setDesiredVideoQuality(540);
		} else {
			setDesiredVideoQuality(2160);
		}
	};

	const toogleOverlay = () => {
		//console.log("toogleOverlay");
		setOverlayVisible(!overlayVisible);
	};

	const handlePlay = () => {
		//console.log("handlePlay");
		if (getCurFrame() === getLastFrame() - (isSafari ? 0 : 1)) goToFrame(0);
		setPrefPlaying(true);
		play();
	};
	const handleToogleLoop = () => {
		setLooping(!looping);
	};
	const handlePause = () => {
		//console.log("handlePause");
		setPrefPlaying(false);
		pause(true);
	};
	const play = () => {
		//console.log("play");
		setBuffering(false);
		playing.current = true;
		playerRef.current
			?.getInternalPlayer()
			?.play()
			.catch((e: any) => {
				console.log(e);
			});
		setLastPause(undefined);
		//setPlaying(true);
	};
	const pause = (forced: boolean = false) => {
		if (prefPlaying && !forced) return;
		//console.log("pause");
		playing.current = false;
		playerRef.current?.getInternalPlayer()?.pause();
		setLastPause(Date.now());
		//setPlaying(false);
	};

	const moveFrame = (move: number) => {
		handlePause();
		setLastPause(undefined);
		//get current frame
		goToFrame(getCurFrame() + move);
	};

	const getLastFrame = () => {
		return fio_asset_info?.frames - (isSafari ? 1 : 0);
	};
	const getCurFrame = () => {
		const fio_asset_info = version?.values?.fio_asset_ids?.[curFioAssetID]?.asset_info;
		const player = playerRef.current?.getInternalPlayer();
		if (typeof fio_asset_info === "undefined" || typeof player === "undefined" || player === null) return -1;

		let frame = Math.floor(player?.currentTime * fio_asset_info?.fps) - 1 + (isSafari ? 1 : 0);

		const maxFrames = fio_asset_info.frames - (isSafari ? 1 : 0);
		if (frame < 0) frame = 0;
		if (frame > maxFrames) {
			frame = maxFrames;
		}
		return frame as number;
	};

	const frameRateFromFio = (fioFrameRate: number) => {
		const frameRateToString = fioFrameRate.toString();
		const split = frameRateToString.split(".");
		const tagOn = split[1]?.slice(0, 2) || "00";

		let frameRate;
		try {
			frameRate = fromTag("FPS_" + split[0] + tagOn);
		} catch {
			return undefined;
		}
		return frameRate;
	};

	const getCurTC = () => {
		const fio_asset_info = version?.values?.fio_asset_ids?.[curFioAssetID]?.asset_info;
		if (typeof fio_asset_info === "undefined") return "00:00:00:00";

		const frameRate = frameRateFromFio(fio_asset_info?.fps);
		if (typeof frameRate === "undefined") return "Unkown Framerate";

		const startTimecode = fio_asset_info.metadata.timecode;
		const startTCInFrames = smpteToFrame(frameRate, startTimecode);
		const curFrame = startTCInFrames + getCurFrame();
		const currentTc = frameToSmpte(frameRate, curFrame);
		return currentTc;
	};

	const getLastTC = () => {
		const fio_asset_info = version?.values?.fio_asset_ids?.[curFioAssetID]?.asset_info;
		if (typeof fio_asset_info === "undefined") return "00:00:00:00";

		const frameRate = frameRateFromFio(fio_asset_info?.fps);
		if (typeof frameRate === "undefined") return "Unkown Framerate";

		const startTimecode = fio_asset_info.metadata.timecode;
		const startTCInFrames = smpteToFrame(frameRate, startTimecode);
		const curFrame = startTCInFrames + getLastFrame() - 1;
		const currentTc = frameToSmpte(frameRate, curFrame);
		return currentTc;
	};

	const secondsToMedia = (secondsInput: number) => {
		let totalSeconds = secondsInput;
		if (secondsInput < 0) totalSeconds = 0;
		const hours = Math.floor(totalSeconds / 3600);
		totalSeconds %= 3600;
		let minutes = Math.floor(totalSeconds / 60);
		let seconds = Math.round(totalSeconds % 60);

		return hours < 1 ? `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}` : `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
	};

	const getCurMedia = () => {
		const fio_asset_info = version?.values?.fio_asset_ids?.[curFioAssetID]?.asset_info;
		if (typeof fio_asset_info === "undefined") return "00:00";

		return secondsToMedia(getCurFrame() / fio_asset_info?.fps);
	};

	const getLastMedia = () => {
		const fio_asset_info = version?.values?.fio_asset_ids?.[curFioAssetID]?.asset_info;
		if (typeof fio_asset_info === "undefined") return "00:00";

		return secondsToMedia(getLastFrame() / fio_asset_info?.fps);
	};

	useEffect(() => {
		if (typeof lastPause === "undefined") return;

		goToFrame(getCurFrame());
	}, [debouncedLastPause]);

	const handleTooglePlayPause = () => {
		if (playing.current) {
			handlePause();
		} else {
			handlePlay();
		}
	};
	const handleProgress = (evt: any) => {
		handleTCChange();
		setProgress({ played: playerRef.current?.getInternalPlayer().currentTime / (fio_asset_info?.frames / fio_asset_info?.fps) });
	};

	const handleSeek = (event: Event, newValue: any | number[]) => {
		event.preventDefault();
		event.stopPropagation();
		setSeeking(true);
		pause();
		let frame = newValue;

		if (frame > fio_asset_info.frames) {
			frame = fio_asset_info.frames;
		}

		const getFramesInSec = ((frame - (isSafari ? 1 : 0)) / fio_asset_info?.frames) * (fio_asset_info?.frames / fio_asset_info?.fps);

		const player = playerRef.current?.getInternalPlayer();
		player.currentTime = getFramesInSec + 0.01;
	};

	const handleSeekStop = (evt: any) => {
		evt.preventDefault();
		evt.stopPropagation();
		setSeeking(false);
		if (prefPlaying) {
			handlePlay();
		}
	};

	const onWebkitEndFullscreen = () => {
		const _player = playerRef.current?.getInternalPlayer();
		if (typeof _player !== "undefined" && _player !== null) {
			off(_player, "webkitendfullscreen", onWebkitEndFullscreen);
		}
		setMuted(_player.muted ? true : false);
		handlePause();
	};

	const handleClickFullscreen = () => {
		if (screenfull.isEnabled) {
			if (!screenfull.isFullscreen) {
				setFullscreenMode(true);

				screenfull.request(findDOMNode(playerRef.current?.getInternalPlayer()) as any);
			} else {
				setFullscreenMode(false);
				screenfull.exit();
			}
		} else {
			//try to fullscreen ios
			const _player = playerRef.current?.getInternalPlayer();

			if (typeof _player !== "undefined" && _player !== null) {
				if (_player.webkitEnterFullScreen) {
					// Toggle fullscreen in Safari for iPad
					_player.webkitEnterFullScreen();
					on(_player, "webkitendfullscreen", onWebkitEndFullscreen);
				}
			}
		}
	};

	const handleTCChange = () => {
		setCurFrame(getCurFrame());
	};

	const onFullScreenChange = () => {
		if (!screenfull.isFullscreen) {
		}
		setFullscreenMode(screenfull.isFullscreen);
		console.log("Am I fullscreen?", screenfull.isFullscreen ? "Yes" : "No");
	};

	const handleToogleMute = () => {
		setMuted(!muted);
	};
	const handleEnded = () => {
		if (!looping) handlePause();
		//setPrefPlaying(false);
	};

	useEffect(() => {
		if (screenfull.isEnabled) {
			screenfull.on("change", onFullScreenChange);

			return screenfull.off("change", onFullScreenChange);
		}
	}, []);

	const goToFrame = (frame: any) => {
		const player = playerRef.current?.getInternalPlayer();
		if (typeof player === "undefined" || player === null) return;
		const getFramesInSec = ((frame + 1 - (isSafari ? 1 : 0)) / fio_asset_info?.frames) * (fio_asset_info?.frames / fio_asset_info?.fps); //chrome +1 frame
		player.currentTime = getFramesInSec + 0.01; // chrome
	};

	const goToNextFioAsset = (direction: number) => {
		//get all fio assets currently visible
		const allVisibleFioAssets = _curFioAssets?.filter((x: any) => !filterSelects || (curFiltered || []).indexOf(x?.fio_asset_id) > -1 || getCurSelected().indexOf(x?.fio_asset_id) > -1).map((x: any) => x?.fio_asset_id);

		//find current asset
		const curIndex = allVisibleFioAssets.indexOf(curFioAssetID);

		if (curIndex === -1) return;

		let nextIndex = curIndex + direction;

		if (nextIndex < 0) {
			//return;
			nextIndex = allVisibleFioAssets?.length - 1;
		}

		if (nextIndex > allVisibleFioAssets?.length - 1) {
			nextIndex = 0;
		}

		setCurFioAssetId(allVisibleFioAssets[nextIndex]);
	};

	const goToNextVersion = (direction: Number) => {
		const allVersions = asset?.values?.versions?.sort((a: any, b: any) => b?.ref?.id?.localeCompare(a?.ref?.id)) || [];
		const currentVersion = allVersions.find((x: any) => x?.ref?.id === versionId);
		const currentIndex = allVersions.indexOf(currentVersion);

		let desiredIndex = currentIndex + direction;

		if (desiredIndex > allVersions.length - 1) {
			desiredIndex = 0;
		} else if (desiredIndex < 0) {
			desiredIndex = allVersions.length - 1;
		}

		const nextVersion = allVersions?.[desiredIndex] || {};

		if (nextVersion?.ref?.id === versionId) return;

		authController?.extra?.playerDialogHandleOpen(assetId, nextVersion?.ref?.id, "");
	};

	//console.log(_curFioAssets.filter((x: any) => x?.fio_asset_id === curFioAssetID).length);

	//console.log(fio_asset_info?.metadata?.blob?.has_audio || false);

	//console.log(version?.values?.fio_asset_ids?.[`${curFioAssetID}`]);

	const overlay = () => {
		const curFps = fio_asset_info?.fps;
		const allFrames = fio_asset_info.frames - 1;

		const allFrames30 = Math.floor((30 / curFps) * allFrames);
		const allFrames60 = Math.floor((60 / curFps) * allFrames);
		const allFrames240 = Math.floor((240 / curFps) * allFrames);

		const curFrame30 = Math.floor((30 / curFps) * curFrame);
		const curFrame60 = Math.floor((60 / curFps) * curFrame);
		const curFrame240 = Math.floor((240 / curFps) * curFrame);

		return (
			<div
				className="markerOverlayContainer"
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					position: "absolute",
					width: "100%",
					height: "calc(100% - 196px - 51px)",
					top: 51,
					left: 0,
					outline: "none!important",
					opacity: overlaySize.height > 0 ? 1 : 0,
					pointerEvents: "none",
				}}
			>
				<div
					className="elementContainer"
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						// border: "2px solid white",
						zIndex: 100000000,
						position: "absolute",

						height: `${overlaySize.height}px`,
						width: `${overlaySize.width}px`,
					}}
				>
					<div className="plus alt" style={{ width: "75px", height: "75px" }}></div>
				</div>
				<div
					className="elementContainer"
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						// border: "2px solid white",
						zIndex: 100000000,
						position: "absolute",

						height: `${overlaySize.height}px`,
						width: `${overlaySize.width}px`,
					}}
				>
					<div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
						<div style={{ height: "calc(100% /3 )", display: "flex", width: "100%" }}>
							<div style={{ height: "100%", width: "100%", border: "1px solid white", borderWidth: "0px 0px 0px 0px" }}></div>
							<div style={{ height: "100%", width: "100%", border: "1px solid white", borderWidth: "0px 0px 0px 1px" }}></div>
							<div style={{ height: "100%", width: "100%", border: "1px solid white", borderWidth: "0px 0px 0px 1px" }}></div>
						</div>
						<div style={{ height: "calc(100% /3 )", display: "flex", width: "100%" }}>
							<div style={{ height: "100%", width: "100%", border: "1px solid white", borderWidth: "1px 0px 1px 0px" }}></div>
							<div style={{ height: "100%", width: "100%", border: "1px solid white", borderWidth: "1px 0px 1px 1px" }}></div>
							<div style={{ height: "100%", width: "100%", border: "1px solid white", borderWidth: "1px 0px 1px 1px" }}></div>
						</div>
						<div style={{ height: "calc(100% /3 )", display: "flex", width: "100%" }}>
							<div style={{ height: "100%", width: "100%", border: "1px solid white", borderWidth: "0px 0px 0px 0px" }}></div>
							<div style={{ height: "100%", width: "100%", border: "1px solid white", borderWidth: "0px 0px 0px 1px" }}></div>
							<div style={{ height: "100%", width: "100%", border: "1px solid white", borderWidth: "0px 0px 0px 1px" }}></div>
						</div>
					</div>
				</div>
			</div>
			// <Box sx={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0, paddingBottom: "196px", paddingTop: "51px", outline: "none!important", opacity: 1 }}>
			// 	<Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", flexWrap: "nowrap", justifyContent: "space-between", alignItems: "center", alignContent: "center" }}>
			// 		<Box sx={{ p: 2, typography: "body2", flex: "0 0 auto;", pt: 2 }}>{fio_asset_name}</Box>
			// 		<Box sx={{ typography: "caption", opacity: 0.5, flex: "0 0 auto;", pb: 2, display: "flex", alignItems: "flex-end", pr: 2, flexDirection: "column", width: "100%" }}>
			// 			<Box>{`${curFrame.toString().padStart(allFrames.toString().length, "0")}@${fio_asset_info?.fps}p`}</Box>
			// 			{curFps === 30 ? null : <Box>{`${curFrame30.toString().padStart(allFrames30.toString().length, "0")}@30p`}</Box>}
			// 			{curFps === 60 ? null : <Box>{`${curFrame60.toString().padStart(allFrames60.toString().length, "0")}@60p`}</Box>}
			// 			{curFps === 240 ? null : <Box>{`${curFrame240.toString().padStart(allFrames240.toString().length, "0")}@240p`}</Box>}
			// 		</Box>
			// 	</Box>
			// </Box>
		);
	};

	const [dummy, setDummy] = useState<any>([]);
	const mark_fio_asset_selected = (evt: any, _fio_asset_id: string, _mark_selected: boolean) => {
		evt.preventDefault();
		evt.stopPropagation();

		curLclOverwrites.current[`${_fio_asset_id}`] = { marked: _mark_selected, date: Date.now() };

		const asset_id = assetId;
		const version_id = versionId;
		const fio_asset_id = _fio_asset_id;
		const mark_selected = _mark_selected;

		const body = { asset_id, version_id, fio_asset_id, mark_selected };
		setDummy(Date.now());

		return axios({
			url: `${authController?.extra?.apiUrl}/version/edit/selects`,
			method: "POST",
			headers: {
				Authorization: `boostToken ${authController?.extra?.user_data?.values?.boost_token}`,
			},
			data: body,
		});
	};

	const [curFiltered, setCurFiltered] = useState<any>([]);

	const handleToogleFilterSelects = (evt: any) => {
		evt.preventDefault();
		evt.stopPropagation();
		const shouldShowSelected = !filterSelects;

		setFilterSelects(!filterSelects);

		if (shouldShowSelected) {
			//filter all currently selected

			let selectedFioAssets = getCurSelected();

			//if selection length 0 select all
			if (selectedFioAssets.length < 1) {
				selectedFioAssets = _curFioAssets.map((x: any) => x.fio_asset_id);
			}

			if (selectedFioAssets?.filter((x: any) => x?.curFioAssetID)?.length < 1) {
				setCurFioAssetId(selectedFioAssets?.[0]);
			}

			setCurFiltered(selectedFioAssets);
		}
	};

	const getCurSelected = () => {
		let intermedSelected = JSON.parse(JSON.stringify(version?.values?.selected_fio_assets || []));
		//push all posivite overwrites

		Object.entries(curLclOverwrites.current).forEach(([key, value]: any) => {
			const timeDistance = Date.now() - value.date;
			if (timeDistance < 5000 && value.marked && intermedSelected.indexOf(key) === -1) {
				intermedSelected.push(key);
			} else if (timeDistance < 5000 && !value.marked && intermedSelected.indexOf(key) !== -1) {
				//remove index from array

				intermedSelected.splice(intermedSelected.indexOf(key), 1);
			}
		});

		return intermedSelected;
	};

	useEffect(() => {
		//calculate Overlaysize

		const currentRect = playerRef.current?.getInternalPlayer()?.getBoundingClientRect();
		const videoAspectRatio = playerRef.current?.getInternalPlayer()?.videoWidth / playerRef.current?.getInternalPlayer()?.videoHeight;
		const currentPlayerAspect = currentRect?.width / currentRect?.height;
		if (currentPlayerAspect) {
			let calcSize = { width: 0, height: 0 };
			if (currentPlayerAspect > videoAspectRatio) {
				//wide

				calcSize = { width: currentRect.height * videoAspectRatio, height: currentRect.height };
			} else if (currentPlayerAspect <= videoAspectRatio) {
				//height

				calcSize = { width: currentRect.width, height: currentRect.width * (1 / videoAspectRatio) };
			} else {
				//no values
			}

			setOverlaySize(calcSize);

			//console.log(currentRect.width, currentRect.height, calcSize);
		}
	}, [windowSize, curFioAssetID, progress]);

	return (
		<Dialog
			open={open}
			maxWidth={false}
			sx={{
				borderRadius: "15px",
				boxShadow: 24,
				outline: "none",

				".MuiPaper-root": {
					margin: 0,
				},
			}}
			PaperProps={{
				sx: { m: 0, ml: 0, height: "100%", width: "100%", overflow: "hidden", backgroundColor: "black", backgroundImage: "none" },
			}}
			scroll={"paper"}
			onClose={handleClose}
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
			onKeyDown={(e: any) => {
				const preventAll = (e: any) => {
					(document.activeElement as HTMLElement).blur();
					e.stopPropagation();
					e.preventDefault();
				};

				if (e.key === "PageUp") {
					//GoToNextVersion
					preventAll(e);
					goToNextVersion(+1);
				} else if (e.key === "PageDown") {
					preventAll(e);
					goToNextVersion(-1);
				}

				if (e.keyCode === 73) {
					//i key for info
					preventAll(e);
					toogleOverlay();
				}

				if (e.keyCode === 70) {
					// f key for filter
					preventAll(e);
					handleToogleFilterSelects(e);
				}
				if (e.keyCode === 77) {
					//m key for mute
					preventAll(e);
					handleToogleMute();
				}
				if (e.keyCode === 81) {
					//q key for quality
					preventAll(e);
					toogleQuality();
				}

				if (e.keyCode === 48 || e.keyCode === 96) {
					// 0 key for deselect
					preventAll(e);
					mark_fio_asset_selected(e, curFioAssetID, false);
				}
				if (e.keyCode === 49 || e.keyCode === 97) {
					// 1 key for select
					preventAll(e);
					mark_fio_asset_selected(e, curFioAssetID, true);
				}

				if (e.shiftKey && e.key === "ArrowLeft") {
					goToNextFioAsset(-1);
				}
				if (e.shiftKey && e.key === "ArrowRight") {
					goToNextFioAsset(1);
				}

				if (!fioAssetIsVideo) return;

				if (e.key == " " || e.code == "Space" || e.keyCode == 32) {
					preventAll(e);
					handleTooglePlayPause();
				} else if (e.keyCode === 75) {
					//press k
					preventAll(e);
					handleTooglePlayPause();
				} else if (!e.shiftKey && e.key === "ArrowLeft") {
					preventAll(e);
					// left arrow
					moveFrame(-1);
				} else if (e.key === ",") {
					preventAll(e);
					// left arrow
					moveFrame(-25);
				} else if (!e.shiftKey && e.key === "ArrowRight") {
					// right arrow
					preventAll(e);
					moveFrame(+1);
				} else if (e.key === ".") {
					preventAll(e);
					moveFrame(+25);
				} else if (e.key === "ArrowDown") {
					// right arrow
					preventAll(e);
					handlePause();
					goToFrame(getLastFrame());
				} else if (e.key === "ArrowUp") {
					// right arrow

					preventAll(e);
					goToFrame(0);
				}
			}}
		>
			<DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "background.paper", color: "text.primary", p: 0, m: 0, height: "50px" }} component="h6">
				<IconButton sx={{ zIndex: 6000, flex: "1 1 0px", display: "flex", justifyContent: "start", alignItems: "start" }} size={"small"} disableRipple onClick={handleClose}>
					<ArrowBackIcon sx={{ ml: 2 }} fontSize="inherit" />
				</IconButton>
				{assetNotLoadAble ? (
					<Box sx={{ backgroundColor: "error.main", border: 0, borderColor: "rgba(255,255,255,0.05)", pl: 1, pr: 1, borderRadius: 2, display: "flex", alignItems: "center", typography: "body1" }}>
						<b>Not available</b>
					</Box>
				) : (
					<>
						<Box sx={{ background: "rgba(0,0,0,.5)", border: 0, borderColor: "rgba(255,255,255,0.05)", pl: 1, pr: 1, borderRadius: 2, display: "flex", alignItems: "center", typography: "body1" }}>
							<b>{asset?.values?.name}</b>
						</Box>
						{/*	<Box sx={{ background: "rgba(255,255,255,0.08)", border: 0, borderColor: "rgba(255,255,255,0.05)", ml: 0.5, pl: 1, pr: 1, borderRadius: 2, display: "flex", alignItems: "center", typography: "body1" }}>
												<b>{`${asset?.values?.versions?.length ? "v" + version?.values?.asset_version_number : ""}`}</b>
											</Box>*/}
					</>
				)}
				{React.useMemo(() => {
					return (
						<>
							<VersionMenu onClick={handlePause} asset={asset} version={version} />
						</>
					);
				}, [asset?.id, asset?.values?.versions?.length, version?.id])}
				<Box sx={{ zIndex: 6000, flex: "1" }}></Box>
			</DialogTitle>
			<Divider />
			<DialogContent
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					background: "black!important",
					height: 1000,
					maxHeight: "95%",
					overflowY: "hidden",
					"&::-webkit-scrollbar": { background: "rgba(0,0,0,0)", width: "10px" },
					overflow: "hidden",
					"&::-webkit-scrollbar-thumb": { background: "rgba(125,125,125,1)", borderRadius: "10px" },
				}}
			>
				{assetNotLoadAble ? (
					<Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
						<Box sx={{ width: 400, borderRadius: "25px", backgroundColor: "background.paper", overflow: "hidden" }}>
							<Box sx={{ height: 50, backgroundColor: "error.main", display: "flex", alignItems: "center", pl: 4 }}>
								<Typography variant="h6">{"Nothing to see here."}</Typography>
							</Box>
							<Box sx={{ pl: 4, pr: 4, pt: 3, mb: 3, display: "flex" }}>
								<Typography variant="body2">
									You can't review this version. It has been deleted or you are missing the permissions. <br />
									<br />
									Feel free to ask your project lead or admin for clarification.
								</Typography>
							</Box>
						</Box>
					</Box>
				) : null}

				<Box>
					{fioAssetIsVideo ? (
						<Box sx={{ ...nonselect, pt: "56.25%" }}>
							<ReactPlayer
								ref={playerRef}
								autoPlay
								playsinline
								muted={muted}
								//className="react-player"
								playing={playing.current}
								onBuffer={() => setBuffering(true)}
								onBufferEnd={() => setBuffering(false)}
								style={{ position: "absolute", top: 0, left: 0, paddingBottom: "196px", paddingTop: "51px", outline: "none!important", opacity: 1 }}
								width="100%"
								height="100%"
								config={{ file: { ...config } }}
								//controls={fullscreenMode}
								url={curVideoUrl}
								//onSeek={() => setPlaying(false)}
								onPlay={play}
								onPause={pause}
								progressInterval={30}
								onProgress={handleProgress}
								loop={looping && !seeking}
								onEnded={handleEnded}
								onError={(e: any) => console.log(e)}
							></ReactPlayer>

							{/*							<div className="test" style={{ position: "relative", paddingBottom: aspectRatio * 100 + "%", width: "100%", height: "calc(100% - 50%)", top: "calc(50% / 2)", backgroundColor: "red" }}></div>
							 */}
							{overlayVisible ? overlay() : null}
							{fioAssetIsVideo ? (
								<Box onClick={handleTooglePlayPause} sx={{ position: "absolute", top: 51, left: 0, width: "100%", height: "calc(100% - 243px)", zIndex: 1000, display: "flex", flexDirection: "column", justifyContent: "flex-end" }}></Box>
							) : null}
						</Box>
					) : (
						<PreviewImage
							key={curFioAssetID + "_preview_image"}
							version={version}
							assetId={curFioAssetID}
							autoplay={false}
							is_visible={true}
							img_type={"full"}
							img_fit_view={true}
							sx={{ position: "absolute", top: 51, left: 0, width: "100%", height: "calc(100% - 233px)" }} //padding offset no scrollbar
						/>
					)}

					{(!curVideoUrl.length || (buffering && prefPlaying)) && fioAssetIsVideo ? (
						<Box sx={{ position: "absolute", top: 51, left: 0, width: "100%", height: "calc(100% - 243px)", backgroundColor: buffering ? "rgba(0,0,0,0)" : "black", zIndex: 1000 }}>
							<CircularProgressCenter />
						</Box>
					) : null}
					<Box sx={{ ...nonselect, position: "absolute", top: 51, left: 0, width: "100%", height: "calc(100% - 203px)", zIndex: 900, display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
						<Box sx={{ width: "100%", height: "40px", display: "flex", flexDirection: "column" }}>
							<Slider
								size="small"
								step={1}
								sx={{
									opacity: fioAssetIsVideo ? 1 : 0,
									borderRadius: 0,
									height: "12px",

									p: 0,

									"& .MuiSlider-rail": {
										opacity: 0.28,
									},
									"& .MuiSlider-track": {
										transition: curFrame < 10 ? "0s linear" : ".1s linear",
									},
									"& .MuiSlider-thumb": {
										//ml: "1px",

										transition: curFrame < 10 ? "0s linear" : ".1s linear",
										height: 12,
										width: 2,
										borderRadius: 0,
										backgroundColor: "#fff",
										boxShadow: "none!important",
										"&focused, &activated, &jumped, &:hover": {
											boxShadow: "none!important",
										},
									},
								}}
								aria-label="Temperature"
								min={1}
								max={fio_asset_info?.frames}
								value={Math.floor(progress?.played * fio_asset_info?.frames) + (isSafari ? 1 : 0) || 0}
								color="primary"
								onChange={handleSeek}
								onChangeCommitted={handleSeekStop}
							/>
							<Box sx={{ backgroundColor: "background.paper", width: "100%", height: "34px", p: 0, mt: { sm: "0", xs: isMobile ? "-17px" : "0" }, display: "flex", zIndex: 1500 }}>
								<Box sx={{ display: fioAssetIsVideo ? "flex" : "none", flex: "1 1 0px" }}>
									<IconButton sx={{ ml: 1 }} aria-label="play/pause" disableRipple size="small" onClick={handleTooglePlayPause}>
										{playing.current || prefPlaying ? <PauseIcon fontSize="inherit" /> : <PlayArrowIcon fontSize="inherit" />}
									</IconButton>
									<IconButton color={looping ? "primary" : undefined} aria-label="loop" disableRipple size="small" onClick={handleToogleLoop}>
										{playing.current ? <LoopIcon fontSize="inherit" /> : <LoopIcon fontSize="inherit" />}
									</IconButton>
									{fio_asset_info?.metadata?.blob?.has_audio || false ? (
										<IconButton aria-label="muted" disableRipple size="small" onClick={handleToogleMute}>
											{muted ? <VolumeOffIcon fontSize="inherit" /> : <VolumeUpIcon fontSize="inherit" />}
										</IconButton>
									) : null}
									<IconButton aria-label="overlay" disableRipple size="small" color={overlayVisible ? "primary" : undefined} onClick={toogleOverlay}>
										<Grid3x3Icon fontSize="inherit" />
									</IconButton>
								</Box>
								<Box sx={{ height: "34px", overflow: "hidden", justifyContent: "center" }}>
									{fioAssetIsVideo ? (
										<Button color="info" sx={{ color: "white" }} onClick={toogleShowTC}>
											{showTC === 0 ? (
												<Box sx={{ display: "flex" }}>
													<Box sx={{ display: "flex", mr: 0.5 }}>
														{getCurMedia()
															?.split(":")
															?.map((t, index) => {
																return (
																	<Box key={"tc_0_" + versionId + "_" + index} sx={{ display: "flex" }}>
																		{index !== 0 ? <Box sx={{ width: 6, mr: 0.1, ml: 0.1 }}>:</Box> : null}
																		<Box sx={{ width: 18, textAlign: "left" }}>{t}</Box>
																	</Box>
																);
															})}
													</Box>
													<Box sx={{ width: 8, opacity: 0.5 }}>/</Box>

													<Box sx={{ display: "flex", opacity: 0.5, ml: 0.35 }}>
														{getLastMedia()
															?.split(":")
															?.map((t, index) => {
																return (
																	<Box key={"tc_00_" + versionId + "_" + index} sx={{ display: "flex" }}>
																		{index !== 0 ? <Box sx={{ width: 6, mr: 0.1, ml: 0.1 }}>:</Box> : null}
																		<Box sx={{ width: 18, textAlign: "left" }}>{t}</Box>
																	</Box>
																);
															})}
													</Box>
												</Box>
											) : showTC === 1 ? (
												<Box sx={{ display: "flex" }}>
													<Box sx={{ textAlign: "left", width: (fio_asset_info.frames - 1).toString().length * 10 }}>{curFrame.toString().padStart((fio_asset_info.frames - 1).toString().length, "0")}</Box>
													<Box sx={{ width: 8, opacity: 0.5 }}>/</Box>
													<Box sx={{ pl: 0.25, width: (fio_asset_info.frames - 1).toString().length * 10, opacity: 0.5 }}>{fio_asset_info.frames - 1}</Box>
												</Box>
											) : showTC === 2 ? (
												<Box sx={{ display: "flex" }}>
													{getCurTC()
														?.split(":")
														?.map((t, index) => {
															return (
																<Box key={"tc_2_" + versionId + "_" + index} sx={{ display: "flex" }}>
																	{index !== 0 ? <Box sx={{ width: 6, mr: 0.25 }}>:</Box> : null}
																	<Box sx={{ width: 20, textAlign: "left" }}>{t}</Box>
																</Box>
															);
														})}
												</Box>
											) : null}
										</Button>
									) : null}
								</Box>
								<Box sx={{ display: "flex", alignItems: "center", flex: "1 1 0px" }}>
									<Box sx={{ display: "flex", ml: "auto", mr: 1 }}>
										<IconButton aria-label="favorite" disableRipple size="small" onClick={handleToogleFilterSelects}>
											{filterSelects ? <StarIcon color={"primary"} fontSize="inherit" /> : <StarIcon sx={{ opacity: 0.25 }} fontSize="inherit" />}
										</IconButton>
										{React.useMemo(() => {
											return (
												<>
													<DownloadMenu curFioAssetID={curFioAssetID} onClick={handlePause} version={version} />
												</>
											);
										}, [asset?.id, asset?.values?.versions?.length, version?.id, curFioAssetID])}
										{fioAssetIsVideo ? (
											<>
												<IconButton color={desiredVideoQuality === 0 || desiredVideoQuality === 2160 ? "primary" : undefined} aria-label="fullscreen" disableRipple size="small" onClick={toogleQuality}>
													<HdIcon fontSize="inherit" />
												</IconButton>

												<IconButton aria-label="fullscreen" disableRipple size="small" onClick={handleClickFullscreen}>
													<FullscreenIcon fontSize="inherit" />
												</IconButton>
											</>
										) : null}
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</DialogContent>
			<Divider />
			<DialogActions sx={{ height: "150px", backgroundColor: "background.paper" }}>
				{/*	<Box sx={{ mr: "-40px", mt: 3.7, display: isMobile ? "none" : "flex", height: "100%", justifyContent: "flex-start", alignItems: "flex-start", width: "24px", typography: "body2" }}>
								<IconButton sx={{ pointerEvents: "auto", zIndex: 100000 }} aria-label="play/pause" size="small" onClick={handleToogleFilterSelects}>
									{filterSelects ? <StarIcon color={"primary"} fontSize="inherit" /> : <StarIcon sx={{ opacity: 0.25 }} fontSize="inherit" />}
								</IconButton>
							</Box>*/}
				{_curFioAssets.length && _curFioAssets.filter((x: any) => x?.fio_asset_id === curFioAssetID).length ? (
					<Tabs
						TabIndicatorProps={{
							children: (
								<span
									style={{
										height: "10px",
										width: "10px",
										backgroundColor: "#635ee7",
										borderRadius: "10px",
									}}
								/>
							),
						}}
						sx={{
							"& .MuiTabs-indicator": {
								height: "10px",
								display: "flex",
								justifyContent: "center",
								backgroundColor: "transparent",
							},
							width: "100%",
						}}
						value={curFioAssetID}
						onChange={handleChange}
						indicatorColor="primary"
						textColor="primary"
						scrollButtons={true}
						variant="scrollable"
					>
						{_curFioAssets
							?.filter((x: any) => !filterSelects || (curFiltered || []).indexOf(x?.fio_asset_id) > -1 || getCurSelected().filter((x: any) => JSON.stringify(x) === JSON.stringify(x?.fio_asset_id)).length > 0)
							.map((fio_a: any, index: number) => {
								const name = fio_a?.file_name;
								const fio_asset_id = fio_a?.fio_asset_id;
								const selected_fio_assets = getCurSelected();
								const is_selected = selected_fio_assets.filter((x: any) => JSON.stringify(x) === JSON.stringify(fio_asset_id)).length > 0;

								return (
									<Tab
										key={fio_asset_id + "_vidPlayer"}
										value={fio_asset_id}
										sx={{ width: 140 }}
										disableRipple
										label={
											<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
												{true ? (
													<Box sx={{ opacity: 0.85, right: "5px", mt: 0, top: "12px", position: "absolute", zIndex: 10000, pointerEvents: "none" }}>
														<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
															<IconButton
																component="div"
																sx={{ ml: 1, pointerEvents: "auto" }}
																aria-label="play/pause"
																size="small"
																onClick={(evt) => {
																	mark_fio_asset_selected(evt, fio_asset_id, is_selected ? false : true);
																}}
															>
																{is_selected ? <StarIcon color={"primary"} fontSize="inherit" /> : <StarIcon sx={{ opacity: 0.25 }} fontSize="inherit" />}
															</IconButton>
														</Box>
													</Box>
												) : null}
												<PreviewImage
													key={fio_asset_id + "_vidPlayer_img"}
													status={fio_a?.status}
													version={version}
													assetId={fio_asset_id}
													autoplay={false}
													is_visible={true}
													show_favorite={true}
													is_round={true}
													is_selected={fio_asset_id === curFioAssetID}
													img_type={"thumb_scrub"}
													sx={{ width: 130, height: 73 }}
												/>

												<Tooltip placement="bottom" sx={{ ...nonselect, zIndex: "10000000!important" }} title={name}>
													<Box fontSize={"0.7em"} sx={{ ...nonselect, mt: 1, pb: 1, width: 120, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", pt: "2px", opacity: 0.5 }}>
														{name}
													</Box>
												</Tooltip>
											</Box>
										}
									/>
								);
							})}
					</Tabs>
				) : null}
			</DialogActions>
		</Dialog>
	);
});
