import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import InputAdornment from "@mui/material/InputAdornment";
import Divider from "@mui/material/Divider";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import GitHubIcon from "@mui/icons-material/GitHub";

import { useAuthController, useSnackbarController } from "@camberi/firecms";
import { useNavigate } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import AutocompleteReferences from "./Components/AutocompleteReferences";
import SwitchPrivate from "./Components/SwitchPrivate";
import TextFieldPlus from "./Components/TextFieldPlus";
import { DeleteDialog } from "./Components/DeleteDialog";

import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";

//keyboard adorment
import { ProjectGenericIcon, AssetIcon, AssetShotIcon, AssetTimelineIcon } from "../../CustomIcons/CustomIcons";

import { useReducerWithCallback } from "../../utils/usehooks-ts";
import { ActionType, ReferenceType, AssetType, ProjectType } from "../../Types/types";
import { isEmpty, diff } from "../../utils/basicFunctions";

const adminCollaborator: ReferenceType = { id: "admin", name: "Admins", fixed_comment: "", type: "group" };

const makeCollectionIcon = (asset_type: AssetType) => {
	switch (asset_type) {
		case "timeline":
			return <AssetTimelineIcon />;
		case "shot":
			return <AssetShotIcon />;
		case "prop":
			return <AssetIcon />;
		case "character":
			return <AssetIcon />;
		case "fx":
			return <AssetIcon />;
		case "environment":
			return <AssetIcon />;
		case "generic":
			return <ProjectGenericIcon />;
	}
};
const collectionDescription = (asset_type: AssetType) => {
	switch (asset_type) {
		case "timeline":
			return "Timeline";
		case "shot":
			return "Shot";
		case "prop":
			return "Prop";
		case "character":
			return "Character";
		case "fx":
			return "Special FX";
		case "environment":
			return "Environment";
		case "generic":
			return "Generic Asset";
		case "generic_render":
			return "Generic Render";
	}
};

const entityName = (asset_type: AssetType) => {
	switch (asset_type) {
		case "timeline":
			return "Timeline";
		case "shot":
			return "Shot";
		case "prop":
			return "Prop";
		case "character":
			return "Character";
		case "fx":
			return "Special FX";
		case "environment":
			return "Environment";
		case "generic":
			return "Generic Asset";
		case "generic_render":
			return "Generic Render";
	}
};

const projectPermissionName = (project_type: ProjectType) => {
	switch (project_type) {
		case "unrealasset":
			return "project";
		case "unrealedit":
			return "edit";
		case "generic":
			return "collection";
		case "unrealscene":
			return "scene";
	}
};

const createAssetTitlePlaceholder = (asset_type: AssetType) => {
	switch (asset_type) {
		case "timeline":
			return "tvc_edit_v02";
		case "shot":
			return "sh0010";
		case "prop":
			return "prop_car";
		case "character":
			return "ch_alex";
		case "fx":
			return "fx_smoke";
		case "environment":
			return "env_playground";
		case "generic":
			return "storyboard_frames";
		case "generic_render":
			return "artdirection_test";
	}
};
export const TreeViewMenuModalAsset = React.forwardRef(function TreeViewMenuModalAsset(
	{
		job,
		projects,
		assets,
	}: {
		job: any;
		projects: any;
		assets: any;
	},
	ref
) {
	const deleteDialogRef = useRef<any>();
	const defaultValue = {
		action: { target: undefined, value: "create", error: " " },
		asset_type: { target: undefined, value: "", error: " " },
		asset_id: { target: undefined, value: "", error: " " },
		asset_title: { target: undefined, value: "", error: " " },
		asset_name: { target: undefined, value: "", error: " " },
		asset_description: { target: undefined, value: "", error: " " },
		asset_git_link: { target: undefined, value: "", error: " " },
		asset_collaborators: { target: undefined, value: [], error: " " },

		asset_owner: { target: undefined, values: {}, error: " " },
	};
	const [author, setAuthor] = useState<ReferenceType | boolean>(false);

	// eslint-disable-next-line  @typescript-eslint/no-unused-vars
	const [initialFormInput, setInitialFormInput] = useReducerWithCallback((state: any, newState: any) => ({ ...state, ...newState }), defaultValue);

	const [formInput, setFormInput] = useReducerWithCallback((state: any, newState: any) => ({ ...state, ...newState }), defaultValue);
	/*viewState*/
	const [onPostToDatabase, setOnPostToDatabase] = React.useState(false);

	/*hooks*/
	const snackbarController = useSnackbarController();
	const authController = useAuthController();
	// eslint-disable-next-line  @typescript-eslint/no-unused-vars
	const navigate = useNavigate();

	useEffect(() => {
		if (authController?.user) {
			const authorCollaborator: ReferenceType = { id: authController?.user?.uid || "", name: authController?.user?.email + " " || "", fixed_comment: "Owner" };
			setAuthor(authorCollaborator);
		} else {
			setAuthor(false);
		}
	}, [authController]);

	/*global*/
	const isActionCreate: boolean = formInput?.action?.value === "create";

	const color = "primary";

	const collectionIcon = makeCollectionIcon(formInput?.asset_type?.value as AssetType);
	const inputFocusOnOpen = useRef<HTMLInputElement>(null);

	//global modal states
	const [open, setOpen] = React.useState(false);
	const [firstTimeHelperExpired, setFirstTimeHelperExpired] = React.useState(!isActionCreate);

	useImperativeHandle(ref, () => ({
		openDialog: (originId: string, assetType: AssetType, action: ActionType) => {
			const asset = assets.find((x: any) => x.id === originId);

			const project = projects.find((x: any) => x.id === (action === "create" ? originId : asset?.values?.project?.id));
			const project_parent = projects?.find((x: any) => project?.values?.project?.id === x.id) || {};

			const user_collaborators = Object.entries(project?.values?.roles?.details || {})
				.filter(([key, value]: any) => value.role_paths?.includes("collab_project"))
				.map(([key, value]: any) => ({ id: key, name: value?.name }));

			if (action === "create") {
				setFormInput(
					{
						action: { target: undefined, value: action, error: " " },
						project: { target: undefined, value: project, error: " " },
						project_parent: { target: undefined, value: project_parent, error: " " },
						asset_type: { target: undefined, value: assetType, error: " " },
					},
					() => {
						setOpen(true);
						setTimeout(() => {
							inputFocusOnOpen?.current?.focus();
						}, 100);
					}
				);
			} else {
				let owner_entry;
				const owner_id = asset.values?.creator?.id;
				if (owner_id === (author as ReferenceType)?.id) {
					//self owned
					owner_entry = { id: owner_id, name: (author as ReferenceType)?.name, fixed_comment: "owner" };
				} else {
					//foreign owned
					const owner_values = Object.entries(job?.values?.roles?.details || {}).find(([key, value]: any) => key === owner_id)?.[1] || {};
					owner_entry = { id: owner_id, name: !isEmpty(owner_values) ? (owner_values as any).name : owner_id, fixed_comment: "owner" };
				}

				const user_collaborators = Object.entries(asset.values?.roles?.details || {})
					.filter(([key, value]: any) => value.role_paths?.includes("collab_asset"))
					.map(([key, value]: any) => ({ id: key, name: value?.name }));

				setFormInput(
					{
						action: { target: undefined, value: "edit", error: " " },
						project: { target: undefined, value: project, error: " " },
						project_parent: { target: undefined, value: project_parent, error: " " },
						asset_type: { target: undefined, value: asset.values?.type, error: " " },
						asset_id: { target: undefined, value: asset.id, error: " " },
						asset_title: { target: undefined, value: asset.values?.title, error: " " },
						asset_name: { target: undefined, value: asset.values?.name, error: " " },
						asset_description: { target: undefined, value: asset.values?.desc, error: " " },
						asset_git_link: { target: undefined, value: asset.values?.git, error: " " },
						asset_collaborators: { target: undefined, value: user_collaborators, error: " " },
						asset_owner: { target: undefined, value: owner_entry, error: " " },
					},
					(inputState) => {
						setInitialFormInput({ ...inputState });

						setOpen(true);
						setTimeout(() => {
							inputFocusOnOpen?.current?.focus();
						}, 100);
					}
				);
			}
		},
	}));

	const hasChanged = () => {
		const curInput = Object.fromEntries(Object.entries(formInput || {}).map(([key, value]: any) => [`${key}`, JSON.stringify(value?.value)]));
		const initForm = Object.fromEntries(Object.entries(initialFormInput || {}).map(([key, value]: any) => [`${key}`, JSON.stringify(value?.value)]));

		//console.log("diff", diff(initForm, curInput));
		return Object.keys(diff(initForm, curInput));
	};

	const handleClose = (forced = false) => {
		if (onPostToDatabase && !forced) return;
		setOpen(false);
		setTimeout(() => {
			setOnPostToDatabase(false);
			setFirstTimeHelperExpired(false);
			setFormInput(defaultValue);
		}, 150);
	};

	//add project owner, project collaborators, forced project owner, forced project collaborator

	//console.log(formInput?.project?.value?.values?.roles?.details);

	const fixed_collaborators = [
		...Object.entries(formInput?.project_parent?.value?.values?.roles?.details || {})
			.filter((user: any) => {
				const user_rolepaths = user?.[1]?.role_paths || [];
				return user_rolepaths.includes("owner") || (!formInput?.project_private?.value && user_rolepaths.includes("collab_project")) ? true : false;
			})
			.map(([key, value]: any) => {
				return {
					id: key,
					name: value.name,
					fixed_comment:
						value.role === "collaborator"
							? `${projectPermissionName(formInput?.project_parent?.value?.values?.type)} collaborator`
							: value.role === "owner"
							? `${projectPermissionName(formInput?.project_parent?.value?.values?.type)} owner`
							: value.role,
				};
			}),

		...Object.entries(formInput?.project?.value?.values?.roles?.details || {})
			.filter((user: any) => {
				const user_rolepaths = user?.[1]?.role_paths || [];
				return user_rolepaths.includes("owner") || (!formInput?.project_private?.value && user_rolepaths.includes("collab_project")) ? true : false;
			})
			.map(([key, value]: any) => {
				return {
					id: key,
					name: value.name,
					fixed_comment:
						value.role === "collaborator" ? `${projectPermissionName(formInput?.project?.value?.values?.type)} collaborator` : value.role === "owner" ? `${projectPermissionName(formInput?.project?.value?.values?.type)} owner` : value.role,
				};
			}),
		...Object.entries(job?.values?.roles?.details || {})
			.filter((user: any) => {
				const user_rolepaths = user?.[1]?.role_paths || [];
				return user_rolepaths.includes("owner") || (!formInput?.project_private?.value && user_rolepaths.includes("collab_job")) ? true : false;
			})
			.map(([key, value]: any) => {
				return { id: key, name: value.name, fixed_comment: value.role === "collaborator" ? "job collaborator" : value.role === "owner" ? "job owner" : value.role };
			}),
	];

	fixed_collaborators.sort((a, b): any => {
		return (
			(b.fixed_comment === "job owner" ? 1 : 0) - (a?.fixed_comment === "job owner" ? 1 : 0) ||
			(b.fixed_comment === "job collaborator" ? 1 : 0) - (a?.fixed_comment === "job collaborator" ? 1 : 0) ||
			(b.fixed_comment === "edit owner" ? 1 : 0) - (a?.fixed_comment === "edit owner" ? 1 : 0) ||
			(b.fixed_comment === "edit collaborator" ? 1 : 0) - (a?.fixed_comment === "edit collaborator" ? 1 : 0) ||
			(b.fixed_comment === "scene owner" ? 1 : 0) - (a?.fixed_comment === "scene owner" ? 1 : 0) ||
			(b.fixed_comment === "scene collaborator" ? 1 : 0) - (a?.fixed_comment === "scene collaborator" ? 1 : 0) ||
			(b.fixed_comment === "project owner" ? 1 : 0) - (a?.fixed_comment === "project owner" ? 1 : 0) ||
			(b.fixed_comment === "project collaborator" ? 1 : 0) - (a?.fixed_comment === "project collaborator" ? 1 : 0) ||
			(b.fixed_comment === "collection owner" ? 1 : 0) - (a?.fixed_comment === "collection owner" ? 1 : 0) ||
			(b.fixed_comment === "collection collaborator" ? 1 : 0) - (a?.fixed_comment === "collection collaborator" ? 1 : 0) ||
			a?.name?.localeCompare(b?.name)
		);
	});

	const handleSubmit = async (evt?: any, data: any = formInput) => {
		evt.preventDefault();

		const error = Object.values(data).filter((item: any) => item.error !== " ").length > 0;
		if (error) return;

		setOnPostToDatabase(true);
		//{ job_id, job_ident, asset_name, asset_title, asset_type, asset_permissions, asset_git_link, asset_description, project_parent_id }
		//const asset_id = data.asset_id.value; // asset id !!!!
		const job_id = job.id;
		const job_ident = job.values?.ident;
		const asset_name = data.asset_name.value;
		const asset_title = data.asset_title.value;
		const asset_type = data.asset_type.value;
		const asset_permissions = data.asset_collaborators.value.map((user: any) => user.id);
		const asset_git_link = data.asset_git_link.value;
		const asset_description = data.asset_description.value;
		const project_id = data.project.value?.id;
		const asset_id = data.asset_id.value;
		//console.log(data.project_parent);
		//const parent_project_id = isActionCreate ? project_id : projects.find((x: any) => x.id === formInput?.project_id?.value)?.values?.project?.id;
		console.log("data_sent", { job_id, job_ident, asset_name, asset_title, asset_type, asset_permissions, asset_git_link, asset_description, project_id, asset_id });
		let respData;
		try {
			const response = await axios({
				url: `${authController?.extra?.apiUrl}/asset/${isActionCreate ? "create" : "edit"}`,
				method: "POST",
				headers: {
					Authorization: `boostToken ${authController?.extra?.user_data?.values?.boost_token}`,
				},
				data: { job_id, job_ident, asset_name, asset_title, asset_type, asset_permissions, asset_git_link, asset_description, project_id, asset_id },
			});
			respData = await response.data;

			if (respData.success) {
				console.log("respData", respData);
				handleClose(true);

				snackbarController.open({
					type: "success",
					title: "Done",
					message: `${entityName(formInput?.asset_type?.value)} ${isActionCreate ? "created" : "edited"}`,
				});
			}
		} catch (err: any) {
			setOnPostToDatabase(false);

			const message = typeof err.response?.data?.message !== "undefined" ? err.response.data.message : `Failed to ${isActionCreate ? "creation" : "edit"} project`;

			snackbarController.open({
				type: "error",
				title: `${entityName(formInput?.asset_type?.value)} ${isActionCreate ? "creation" : "edit"} failed`,
				message: message,
			});
			console.log(err);
		}
	};
	const handleInput = (evt: any) => {
		const name = evt.target.name;
		const newValue = evt.target.value;

		let error = " ";
		switch (name) {
			case "asset_title":
				if (!/^[A-Za-z0-9_ ]*$/.test(newValue)) error = "Letters and numbers only.";
				break;
		}

		setFormInput({ [name]: { value: evt.target.value, target: evt.target, error: error } });
	};

	const handleBlur = (evt: any, submit: boolean = false) => {
		if (!open) return;
		const name = evt.target.name;

		let newValue = evt.target.value;
		let startValue = newValue;

		let error = " ";
		if (name === "asset_description") {
			newValue = newValue.trim();
		} else {
			newValue = newValue.trim().toLowerCase();
		}
		switch (name) {
			case "asset_title":
				const asset_title_max_length = 20;
				let prefix = "xxx";
				newValue = newValue.replace("_ ", "_").replace(" _", "_").replace(/\s/g, "_").replace(/_+$/, "").replace(/^_/, "");
				if (newValue.length > 0) {
					switch (formInput?.asset_type?.value) {
						case "shot":
							newValue = newValue.slice(0, 6);
							const validStart = newValue.length === 6 && /^sh\d{4}/.test(newValue); //sh0101
							if (!validStart) {
								//check if starts with sh followed by digits
								if (/^sh\d{1,3}/.test(newValue)) {
									const digits = parseInt(newValue.slice(2, 5));
									newValue = "sh" + String(digits).padStart(4, "0");
								}
								//consists only of numbers
								else if (/^\d{1,4}/.test(newValue.slice(0, 4))) {
									const digits = parseInt(newValue.slice(0, 4));
									newValue = "sh" + String(digits).padStart(4, "0");
								} else {
									newValue = "sh0010";
								}
							}
							break;
						case "fx":
						case "prop":
						case "character":
						case "environment":
							switch (formInput?.asset_type?.value) {
								case "fx":
									prefix = "fx";
									break;
								case "prop":
									prefix = "prop";
									break;
								case "character":
									prefix = "ch";
									break;
								case "environment":
									prefix = "env";
									break;
							}

							const assetRegex = new RegExp("^" + prefix + "_");
							const assetValidStart = newValue.length >= prefix.length + 1 && assetRegex.test(newValue.slice(0, prefix.length + 1));
							if (!assetValidStart) {
								newValue = prefix + "_" + newValue;
							}
							break;
					}
				}
				newValue = newValue.slice(0, asset_title_max_length).replace(/_$/, "");

				if (newValue.length === 0) error = "Input required";
				else if (!/^[A-Za-z0-9_ ]*$/.test(newValue)) error = "Letters and numbers only.";
				break;

			case "asset_git_link":
				if (!/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm.test(newValue) && newValue !== "") error = "The provided is not valid";
				break;
		}
		let inputTitlePrefix = "xxx";
		const parent_project = formInput?.project?.value;
		const parent_project_type = parent_project?.values?.type;
		const isShotOrTimeline = formInput?.asset_type?.value === "shot" || formInput?.asset_type?.value === "timeline";

		if (parent_project_type === "unrealscene" && isShotOrTimeline) {
			inputTitlePrefix = parent_project?.values?.name.slice(6, 13);
		}
		if (parent_project_type === "unrealedit" && isShotOrTimeline) {
			inputTitlePrefix = parent_project?.values?.name.slice(6, 9);
		}

		const shotPrefix = (parent_project_type === "unrealscene" || parent_project_type === "unrealedit") && isShotOrTimeline;

		if (submit) {
			setFormInput(
				{
					[name]: { value: newValue, target: evt.target, error: error },
					...(name === "asset_title" && {
						asset_name: { value: newValue.length > 0 ? (shotPrefix ? `${job.values?.ident}_${inputTitlePrefix}_${newValue}` : `${job.values?.ident}_${newValue}`) : "", target: undefined, error: " " },
					}),
				},
				(newState) => {
					handleSubmit(evt, newState);
				}
			);
		} else {
			setFormInput({
				[name]: { value: newValue, target: evt.target, error: error },
				...(name === "asset_title" && {
					asset_name: { value: newValue.length > 0 ? (shotPrefix ? `${job.values?.ident}_${inputTitlePrefix}_${newValue}` : `${job.values?.ident}_${newValue}`) : "", target: undefined, error: " " },
				}),
			});
		}
	};

	const addAuthorTag = (references: ReferenceType[]) => {
		return references.map((ref: ReferenceType) => ({ ...ref, ...(ref.id === (author as ReferenceType)?.id && { name_adornment: " (You)" }) }));
	};

	return (
		<Dialog
			open={open}
			maxWidth={"md"}
			sx={{
				borderRadius: "15px",
				boxShadow: 24,
				outline: "none",
			}}
			PaperProps={{
				sx: { borderRadius: "15px", height: "75%", width: "600px", backgroundColor: "background.paper", backgroundImage: "none" },
			}}
			onClose={(evt) => handleClose(false)}
			scroll={"paper"}
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
		>
			<DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "left", backgroundColor: isActionCreate ? "secondary.main" : "primary.main", color: "text.primary", p: 0, m: 0, height: "60px" }} component="h6">
				<Box sx={{ ml: 2, mr: 2, display: "flex", alignItems: "center" }}>{collectionIcon}</Box>
				{isActionCreate ? `New` : `Edit`}
				{isActionCreate ? ` ${collectionDescription(formInput.asset_type.value)}` : ` ${formInput.asset_name.value}`}
			</DialogTitle>
			<Divider />
			<DialogContent
				sx={{ overflowY: "scroll", "&::-webkit-scrollbar": { background: "rgba(0,0,0,0)", width: "10px" }, "&::-webkit-scrollbar-thumb": { background: "rgba(125,125,125,1)", borderRadius: "10px" } }}
				onKeyPress={(ev: any) => {
					if (ev.shiftKey && ev.key === "Enter") {
						ev.stopPropagation();
						ev.preventDefault();
						handleBlur(ev, true);
					}
				}}
			>
				<Box
					display={"flex"}
					sx={{
						mt: 2,
						pl: "40px",
						pr: "40px",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Typography variant="h6" sx={{ width: "100%", mb: 1 }}>
						{formInput.asset_name?.value ? `${entityName(formInput?.asset_type?.value)} Name: ` : `Enter ${entityName(formInput?.asset_type?.value)} Title`} <b> {formInput.asset_name?.value}</b>
					</Typography>
					<Typography variant="body2" sx={{ width: "100%", mt: -1, mb: 2, opacity: 0.5 }}>
						{`${job?.values?.name} >`}
						{`${formInput?.project_parent?.value?.values ? ` ${formInput?.project_parent?.value?.values?.name} >` : ""}`}
						{` ${formInput?.project?.value?.values?.name} >`}
						{formInput.asset_name?.value ? ` ${formInput.asset_name?.value}` : ` New ${entityName(formInput?.asset_type?.value)}`}
					</Typography>
					<TextFieldPlus
						refinput={inputFocusOnOpen}
						label={`${entityName(formInput?.asset_type?.value)} Title`}
						placeholder={createAssetTitlePlaceholder(formInput?.asset_type?.value)}
						name="asset_title"
						value={formInput.asset_title?.value}
						helpertext={formInput.asset_title?.error}
						inputprops={{ readOnly: isActionCreate || true ? false : true }}
						disabled={onPostToDatabase}
						onBlur={handleBlur}
						errorvalue={formInput.asset_title?.error !== " " ? true.toString() : undefined}
						onChange={handleInput}
						onKeyDown={(ev: any) => {
							if (ev.key === "Enter" && !ev.shiftKey) {
								ev.stopPropagation();
								ev.preventDefault();
								handleBlur(ev);
							}
						}}
					/>
					<TextFieldPlus
						multilinevalue={"true"}
						label={`${entityName(formInput?.asset_type?.value)} Description`}
						placeholder={"Optional"}
						name="asset_description"
						value={formInput.asset_description?.value}
						helpertext={formInput.asset_description?.error}
						errorvalue={formInput.asset_description?.error !== " " ? true.toString() : undefined}
						inputprops={{
							readOnly: false,
							startAdornment: <Box />,
						}}
						disabled={onPostToDatabase}
						onBlur={handleBlur}
						onChange={handleInput}
					/>
					<Typography sx={{ width: "100%", mb: 1, mt: 1 }}>{`${entityName(formInput?.asset_type?.value)}`} Settings</Typography>

					<AutocompleteReferences
						name="asset_collaborators"
						label="Permissions"
						placeholder={`Add ${entityName(formInput?.asset_type?.value)} colloborators`}
						noOptionsText={"Collaborator not found"}
						color={color}
						options={addAuthorTag([
							adminCollaborator,
							...(!isActionCreate && !isEmpty(formInput?.asset_owner?.value || {}) ? [formInput.asset_owner.value] : []),
							...(isActionCreate && author ? [author] : []),
							...authController?.extra?.user_collaborators,
						]).sort((a: ReferenceType, b: ReferenceType) => a.name.localeCompare(b.name))}
						fixedOptions={addAuthorTag([adminCollaborator, ...(!isActionCreate && !isEmpty(formInput?.asset_owner?.value || {}) ? [formInput.asset_owner.value] : []), ...(isActionCreate && author ? [author] : []), ...fixed_collaborators])}
						selectedOptions={addAuthorTag(formInput.asset_collaborators?.value)}
						setSelectedOptions={handleInput}
						sx={{ width: "100%", marginTop: "15px" }}
						avatar={<PersonIcon sx={{ opacity: 0.5 }} />}
						groupAvatar={<GroupIcon sx={{ opacity: 0.5 }} />}
					/>

					<TextFieldPlus
						label="Git Link"
						placeholder={""}
						name="asset_git_link"
						value={formInput.asset_git_link?.value}
						helpertext={formInput.asset_git_link?.error}
						errorvalue={formInput.asset_git_link?.error !== " " ? true.toString() : undefined}
						inputprops={{
							readOnly: false,
							startAdornment: (
								<InputAdornment position="start">
									<GitHubIcon />
								</InputAdornment>
							),
						}}
						disabled={onPostToDatabase}
						onBlur={handleBlur}
						onChange={handleInput}
						onKeyDown={(ev: any) => {
							if (ev.key === "Enter" && !ev.shiftKey) {
								ev.stopPropagation();
								ev.preventDefault();
								handleBlur(ev);
							}
						}}
					/>

					{isActionCreate ? null : (
						<Box sx={{ pl: 2, pr: 2, display: "flex", justifyContent: "center" }}>
							<LoadingButton
								disabled={false}
								sx={{ width: "100%", whiteSpace: "nowrap", ml: "auto", mr: "auto", color: "rgba(255,255,255,.5)", textTransform: "none" }}
								color="error"
								size={"medium"}
								variant="outlined"
								onClick={() => {
									deleteDialogRef.current?.openDialog();
								}}
							>
								{`Delete ${entityName(formInput?.asset_type?.value)}`}
							</LoadingButton>
						</Box>
					)}
				</Box>
			</DialogContent>
			<Divider />
			<DialogActions sx={{ display: "flex", flexDirection: "column", height: "85px" }}>
				<LoadingButton
					disabled={!hasChanged().length}
					sx={{ whiteSpace: "nowrap", color: "text.primary", textTransform: "none", pl: 10, pr: 10 }}
					loading={onPostToDatabase}
					color={isActionCreate ? "secondary" : "primary"}
					onClick={handleSubmit}
					size={"large"}
					loadingPosition="end"
					variant="contained"
					endIcon={<CloudUploadIcon />}
				>
					{!onPostToDatabase ? (isActionCreate ? `Create ${entityName(formInput?.asset_type?.value)}` : "Save Changes") : isActionCreate ? `Creating ${entityName(formInput?.asset_type?.value)}...` : "Saving Changes..."}
				</LoadingButton>
				<Box fontSize={"0.7em"} sx={{ pt: "2px", opacity: 0.5 }}>
					<b>Shift + Return</b> to {isActionCreate ? " create" : " save"} {` ${entityName(formInput?.asset_type?.value)?.toLowerCase()}`}
				</Box>
			</DialogActions>
			<DeleteDialog ref={deleteDialogRef} onCloseCallback={() => handleClose(true)} itemToDelete={{ job_ident: job.values?.ident, item_name: formInput.asset_name?.value, item_id: formInput.asset_id?.value, item_type: "asset" }} />
		</Dialog>
	);
});
