import React, { useState, useEffect, useRef } from "react";

import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import UpdateIcon from "@mui/icons-material/Update";
import Tooltip from "@mui/material/Tooltip";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Box from "@mui/material/Box";

import VersionCardPreviewItem from "./VersionCardPreviewItem";
import VersionMenu from "./VersionMenu";

import { capitalizeWords } from "../utils/basicFunctions";

import { EntityReference, useDataSource, useAuthController } from "@camberi/firecms";
import versionsCollection from "../Collections/versionsCollection";
import LayersClearIcon from "@mui/icons-material/LayersClear";

import { UnrealEngineLogoIcon } from "../CustomIcons/CustomIcons";

import { useDebounce } from "usehooks-ts";
const styles = {
	version_overlay: {
		height: "32px",
		ml: -9,
		mt: 2,
		color: "black",
		pr: "5px",
		pl: "5px",
		background: "rgba(0,0,0,.5)",
		borderRadius: "5px",
		zIndex: 1,
	},

	time: {
		top: "20px",

		pr: "5px",
		pl: "5px",

		zIndex: 1,
	},
	container_rows: {
		display: "flex",
		position: "relative",
		flexDirection: "row",
		width: "100%",
	},
	container_row_item_right: {
		position: "relative",
		width: "50%",
		height: "50%",
		pl: "3px",
		pr: "8px",
		pt: "8px",
		pb: "8px",
	},
};

export default function VersionCard({ index, asset, versionId, sx, setinview, jobIdent, isJobNameShown, markDeleted }: any) {
	const authController = useAuthController();
	const _versionId = versionId;

	const [loaded, setLoaded] = useState<boolean>(false);
	const loadedDebounced = useDebounce<boolean>(loaded, 250);
	const [version, setVersion] = useState<any>({});
	const versionListener = useRef<any>();

	const dataSource = useDataSource();

	const onVersionsUpdate = (evt: any) => {
		//console.log("versioncard", evt);
		setVersion((prevState: any) => ({ ...prevState, ...evt }));
	};

	useEffect(() => {
		if (typeof versionListener.current === "undefined" && dataSource.listenEntity && _versionId) {
			versionListener.current = dataSource.listenEntity({
				path: versionsCollection.path,
				entityId: _versionId,
				collection: versionsCollection,
				onUpdate: (evt) => onVersionsUpdate(evt),
			});
			return () => {
				console.log("Child unmounted");
				versionListener.current();
				versionListener.current = undefined;
			};
		}
	}, [dataSource, versionId]);

	const [isHovering, setIsHovering] = useState(false);

	const handleMouseOver = () => {
		setIsHovering(true);
	};

	const handleMouseOut = () => {
		setIsHovering(false);
	};

	const version_number = version?.values?.asset_version_number;
	const version_file_count = version?.values?.announced_filecount;
	const version_string = (version_number < 10 ? "00" : version_number < 100 ? "0" : "") + version_number?.toString();
	const author_name_arr = version?.values?.author_email?.split("@")[0]?.split(".");

	//const fio_all_ready = version?.values?.fio_asset_ids?.map().filter((item: any) => item.status === "ready.fio.asset.ready");

	const fio_asset_ids = version?.values?.fio_asset_ids || {};
	const fio_assets_arr = Object.values(fio_asset_ids).sort((a: any, b: any) => a?.file_name?.localeCompare(b?.file_name) || 1);

	const fio_cur_fav = fio_assets_arr?.find((item: any) => item.fio_asset_id === version?.values?.cur_preview_fav) || {};
	const fio_assets_all_ready = version?.values?.status === "ready.fio.all.ready";
	const fio_assets_all_created = fio_assets_arr.slice().filter((item: any) => item.status === "pending.fio.asset.created").length === fio_assets_arr.length && fio_assets_arr.length === version_file_count;

	let filetype_string = "";
	if (fio_assets_all_ready) {
		const fio_assets_arr_filetypes = fio_assets_arr.map((item: any) => {
			const filetypeString = item?.file_type?.split("/")?.[1] || "";
			const value: string = filetypeString === "quicktime" ? "mov" : filetypeString;
			return value;
		});
		filetype_string = Array.from(new Set(fio_assets_arr_filetypes)).join(", ") || "";
		if (filetype_string === "") filetype_string = " ";
	}

	//remove fav from fio_assets_arr
	const fav_valid = Object.keys(fio_cur_fav).length > 0;
	//console.log(fav_valid, version?.id);
	//console.log(!fav_valid ? fio_assets_arr[0] : fio_cur_fav);

	let fio_asset_spliced = fio_assets_arr.slice();
	if (fav_valid) fio_asset_spliced.splice(fio_asset_spliced.indexOf(fio_cur_fav), 1);

	let image_00: string = "",
		image_01: string = "",
		image_02: string = "";

	/*	const sortable = Object.values(fio_asset_ids);
	sortable.sort((a: any, b: any) => a?.file_name?.localeCompare(b?.file_name));

	console.log(sortable);*/

	const firstImage: any = fio_cur_fav;
	const secondImage: any = fio_asset_spliced[0];
	const thirdImage: any = fio_asset_spliced[1];

	if (typeof firstImage !== "undefined") {
		if (typeof firstImage.status !== "undefined" && firstImage.status === "ready.fio.asset.ready") {
			image_00 = firstImage;
		}
	}
	if (typeof secondImage !== "undefined") {
		if (typeof secondImage.status !== "undefined" && secondImage.status === "ready.fio.asset.ready") {
			image_01 = secondImage;
		}
	}
	if (typeof thirdImage !== "undefined") {
		if (typeof thirdImage.status !== "undefined" && thirdImage.status === "ready.fio.asset.ready") {
			image_02 = thirdImage;
		}
	}

	const author_caps = (author_name_arr?.length > 1 ? author_name_arr[0][0] + "" + author_name_arr[author_name_arr.length - 1][0] : typeof author_name_arr !== "undefined" ? author_name_arr[0] : "").toUpperCase();
	const author_name = typeof author_name_arr === "object" ? capitalizeWords(author_name_arr).join(" ") : "";
	const commit_messsage = version?.values?.commit_message;

	const renderPreviewImages = () => {
		const firstItem = image_00;
		const secondItem = image_01;
		const thirdItem = image_02;

		if (version_file_count > 0 && version_file_count < 3)
			switch (version_file_count) {
				case 1:
					return (
						<Box sx={styles.container_rows}>
							<VersionCardPreviewItem asset={firstItem} status={firstImage?.status} type={"full"} version={version} filecount={version_file_count} />
						</Box>
					);
				case 2:
					return (
						<Box sx={styles.container_rows}>
							<VersionCardPreviewItem asset={firstItem} status={firstImage?.status} type={"half_left"} version={version} filecount={version_file_count} />
							<VersionCardPreviewItem asset={secondItem} status={secondImage?.status} type={"half_right"} version={version} filecount={version_file_count} />
						</Box>
					);
			}
		else if (version_file_count > 2) {
			return (
				<Box sx={styles.container_rows}>
					<VersionCardPreviewItem asset={firstItem} status={firstImage?.status} type={"half_left"} version={version} filecount={version_file_count} />
					<Box sx={styles.container_row_item_right}>
						<VersionCardPreviewItem asset={secondItem} status={secondImage?.status} type={"third_right_top"} version={version} filecount={version_file_count} />
						<VersionCardPreviewItem
							asset={thirdItem}
							onclick={() => authController?.extra?.playerDialogHandleOpen(version?.values?.asset?.id, version?.id, (fio_asset_spliced?.[1] as any)?.fio_asset_id)}
							status={thirdImage?.status}
							type={"third_right_bottom"}
							version={version}
							filecount={version_file_count}
						/>
					</Box>
				</Box>
			);
		}
		return <Box sx={{ height: { sm: "250px", xs: "125px" }, width: "100%", m: "8px" }}></Box>;
	};

	const status = asset?.values?.status;
	const version_status = version?.values?.status;
	const minutes = String(version?.values?.created_on?.getMinutes()).padStart(2, "0");
	const hours = String(version?.values?.created_on?.getHours()).padStart(2, "0");

	useEffect(() => {
		const loaded = typeof minutes !== "undefined";
		setLoaded(loaded);
	}, []);

	return (
		<Box key={index} sx={{ ...sx, maxWidth: "100%", minWidth: { sm: 500, xs: "100%" }, width: "100%", p: "8px", borderRadius: 2, display: "flex", justifyContent: "center" }}>
			<Box sx={{ display: { xs: "none", sm: "inherit" }, width: 50, mr: 1, opacity: loadedDebounced ? 1 : 0, transition: ".5s cubic-bezier(.39,.41,.33,.99)" }}>
				{hours}:{minutes}
			</Box>

			<Card sx={{ width: "100%", minWidth: { sm: 400, xs: "100%" }, maxWidth: { sm: 400, xs: "100%" }, backgroundColor: "background.paper", backgroundImage: "none" }} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
				{version ? (
					<Box sx={{ opacity: loadedDebounced ? 1 : 0, transition: ".5s cubic-bezier(.39,.41,.33,.99)" }}>
						<Box sx={{ display: { sm: "none", xs: markDeleted ? "none" : "inherit" }, width: "100%", pl: 1, pr: 1, mt: 1, mr: 1, opacity: loadedDebounced ? 1 : 0, transition: ".5s cubic-bezier(.39,.41,.33,.99)" }}>
							<b>{version?.values?.author_name}</b>
							{`  at ${hours}:${minutes}`}
						</Box>

						{markDeleted ? null : renderPreviewImages()}
						<Box sx={{ display: "flex" }}>
							<CardContent sx={{ width: "100%" }}>
								<Box sx={{ display: "flex", flexGrow: 1, flexDirection: "row", alignItems: "center" }}>
									<Box sx={{ flexGrow: 1 }}>
										{markDeleted ? null : (
											<Box
												sx={{
													display: { sm: markDeleted ? "none" : "flex", xs: "none" },
													alignItems: "center",
													height: "22px",
												}}
											>
												{version?.values?.origin === "unreal" ? (
													<Box sx={{ display: "flex", mr: 0.5 }}>
														<Tooltip title={"Unreal Engine Boost Plugin"}>
															<Box component={UnrealEngineLogoIcon} color="inherit" sx={{ mr: 0.5, width: 20, color: "white" }} />
														</Tooltip>
														&#183;
													</Box>
												) : null}

												{status && !status?.startsWith("ready") ? (
													<Tooltip title={status ? status : ""}>
														<Box component={ErrorOutlineIcon} color="inherit" sx={{ mr: 0.5, width: 20, color: "orange" }} />
													</Tooltip>
												) : null}
												{filetype_string !== "" ? null : (
													<Tooltip title={status ? status : ""}>
														<Box component={UpdateIcon} color="inherit" sx={{ mr: 0.5, width: 20, color: "white" }} />
													</Tooltip>
												)}

												<Typography variant="body2" color="text.secondary" align={"left"}>
													{version_file_count} {version_file_count > 1 ? "items" : "item"} &#183; {filetype_string !== "" ? filetype_string : version_status}
												</Typography>
											</Box>
										)}

										<Box sx={{ display: "flex", mt: { sm: markDeleted ? -1 : 0.5, xs: markDeleted ? -1 : -2 } }}>
											<Typography variant="body1" color="text.primary" align={"left"} sx={{ opacity: markDeleted ? 0.5 : 1, bgcolor: "rgba(0,0,0,0.5)", ml: -1, pl: 1, pr: 1, borderRadius: "4px" }}>
												<b>{isJobNameShown ? asset?.values?.name : asset?.values?.name?.replace(jobIdent + "_", "")}</b>
											</Typography>
											<Typography variant="body1" color="text.primary" align={"left"} sx={{ opacity: markDeleted ? 0.5 : 1, bgcolor: "rgba(255,255,255,0.08)", ml: 0.5, pl: 1, pr: 1, borderRadius: "4px" }}>
												<b>v{version_number}</b>
											</Typography>
											{markDeleted ? (
												<Typography
													variant="body2"
													color="text.primary"
													align={"left"}
													sx={{ display: "flex", justifyContent: "center", alignItems: "center", opacity: markDeleted ? 0.5 : 1, bgcolor: "error.main", ml: 0.5, pl: 1, pr: 1, borderRadius: "4px" }}
												>
													<LayersClearIcon />
												</Typography>
											) : null}

											<Box sx={{ flexGrow: 1 }}></Box>
										</Box>
										<Box>
											<Typography sx={{ mt: 1, overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: "1", WebkitBoxOrient: "vertical" }} variant="body2" color="text.secondary" align={"left"}>
												<b>
													{version?.values?.author_name}
													{version?.values?.author_name && commit_messsage ? ": " : ""}
												</b>

												{commit_messsage}
											</Typography>
										</Box>
									</Box>
									<Box>
										{!isHovering ? null : (
											<VersionMenu
												sx={{ display: { sm: markDeleted ? "none" : "flex", xs: "none" }, ml: "auto", pointerEvents: "auto" }}
												fio_asset_ids={version?.values?.fio_asset_ids}
												single_link={version?.values?.fio_review_link?.short_url}
												single_password={version?.values?.fio_review_link?.password}
												version_number_string={version_string}
												all_link={asset?.values.fio_review_link?.short_url}
												version_name={version?.values?.name}
												version_id={version?.id}
											/>
										)}
									</Box>
								</Box>
							</CardContent>
						</Box>
					</Box>
				) : null}
			</Card>

			<Box sx={{ display: { xs: "none", sm: "inherit" }, width: 50, ml: 1 }}></Box>
		</Box>
	);
}
