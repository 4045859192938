import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
export default function SwitchPrivate(props: any) {
	const [showTooltip, setShowTooltip] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [isFocused, setIsFocused] = useState<boolean>(false);

	const handleOnTooltipClose = () => {
		setShowTooltip(false);
	};

	const onCopy = (content: any) => {
		navigator.clipboard.writeText(props.value || "");
		setShowTooltip(true);
	};
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	return (
		<Box sx={{ width: "100%" }} {...props}>
			<TextField
				multiline={props.multilinevalue ? true : false}
				spellCheck={(props?.spellcheck || false).toString()}
				error={props?.errorvalue || undefined ? true : false}
				helperText={props?.helpertext || undefined}
				//autoComplete={props.autocomplete || false}
				inputRef={props.refinput}
				autoComplete={"off"}
				{...props}
				onBlur={(evt) => {
					setIsFocused(false);
					if (props.onBlur) {
						props.onBlur(evt);
					}
				}}
				onFocus={() => setIsFocused(true)}
				sx={{ ...props.sx, marginTop: "15px", width: "100%" }}
				label={!props.inputprops?.readOnly ? props.label : `${props.label} (read-only)`}
				color={props.color}
				disabled={props.disabled || false}
				InputProps={{
					...props.inputprops,
					endAdornment:
						isFocused && !props.inputprops?.readOnly && !props.disableendadornment && !props.multilinevalue ? (
							<InputAdornment position="start" sx={{ opacity: 0.75 }}>
								<Box fontSize={"10px"} sx={{ height: "25px", opacity: 0.5, display: "flex", alignItems: "center", background: "rgba(255,255,255,0.1)", borderRadius: "5px", p: "2px" }}>
									<Box sx={{ pl: "5px", pr: "5px" }}>Return</Box>
								</Box>
							</InputAdornment>
						) : null,
				}}
				type={props.ispassword === "true" ? (isFocused ? "text" : "password") : "text"}
			/>
		</Box>
	);
}
