import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import { useAuthController } from "@camberi/firecms";

import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import LayersIcon from "@mui/icons-material/Layers";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

export default function VersionMenu({ onClick, asset, version }: { asset: any; version: any; onClick: any }) {
	const authController = useAuthController();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [curAsset, setCurAsset] = useState<any>({});
	const [curVersion, setCurVersion] = useState<any>({});

	const [versions, setVersions] = useState<any>([]);

	useEffect(() => {
		setCurAsset(asset);
	}, [asset]);

	useEffect(() => {
		setCurVersion(version);
	}, [version]);

	useEffect(() => {
		if (typeof curAsset?.values?.versions !== "undefined") {
			setCurAsset(asset);
			const _versions = asset?.values?.versions;
			setVersions(_versions);
		}
	}, [curAsset]);

	const defaultPrevent = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
	};

	const handleClose = (event: any) => {
		defaultPrevent(event);
		setAnchorEl(null);
	};

	const handleClick = (event: any) => {
		//onClick();
		defaultPrevent(event);
		setAnchorEl(event.currentTarget);
	};

	const open = Boolean(anchorEl);
	let navigate = useNavigate();
	const theme = useTheme();

	const VersionItem = (props: any) => {
		const isSelectedVersion = version?.id?.length && version?.id === props?.version?.ref?.id;

		return (
			<MenuItem
				sx={{ "&:hover": { opacity: 1 }, opacity: isSelectedVersion ? 1 : 0.25 }}
				autoFocus={isSelectedVersion}
				//sx={{ background: `${theme.palette.secondary.main}!important`, opacity: "1!important", pl: 2.5, pt: 1, pb: 1, m: 0, mt: -1 }}
				dense
				onClick={(evt) => {
					if (!isSelectedVersion) {
						authController?.extra?.playerDialogHandleOpen(asset?.id, props?.version?.ref?.id, "");
					}
					handleClose(evt);
				}}
			>
				<ListItemText sx={{ display: "flex", flexDirection: "column" }}>
					<b>v{props.number}</b>
					<Box sx={{ maxWidth: "300px", textOverflow: "ellipsis", overflow: "hidden" }}>{`${props?.version?.commit_message || ""}`}</Box>
				</ListItemText>
			</MenuItem>
		);
	};
	return (
		<div>
			<Button
				color="info"
				sx={{
					color: "white",
					textTransform: "lowercase",
					zIndex: 50000,

					m: 0,
					ml: 0.5,
					p: 0,
					display: "flex",
					minWidth: "inherit",
				}}
				onClick={handleClick}
				disableRipple
				disableElevation
			>
				<Box sx={{ background: "rgba(255,255,255,0.08)", border: 0, borderColor: "rgba(255,255,255,0.05)", pl: 1, pr: 1, borderRadius: 2, display: "flex", alignItems: "center", typography: "body1" }}>
					<b>{`${curAsset?.values?.versions?.length ? "v" + curVersion?.values?.asset_version_number : ""}`}</b>
				</Box>
			</Button>
			{/*			<IconButton onClick={handleClick}>
				<MoreVertIcon sx={{ cursor: "pointer" }} />
			</IconButton>*/}
			<Menu
				onClick={defaultPrevent}
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
				PaperProps={{
					style: {
						maxHeight: "33%",
						left: "50%",
						transform: "translateY(10px)",
					},
				}}
			>
				{versions?.map((v: any, n: number) => {
					return <VersionItem key={v?.ref?.id + "_selector"} version={v} number={n + 1} />;
				})}
			</Menu>
		</div>
	);
}
