import { useRef, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import { PreviewImage } from "./PreviewImage";
import { useState } from "react";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";

import { EntityReference, useDataSource, useAuthController } from "@camberi/firecms";
import versionsCollection from "../Collections/versionsCollection";

export default function PermissionListPreviewImage({ versionId }: { versionId: string }) {
	const _versionId = versionId;

	const [version, setVersion] = useState<any>({});
	const versionListener = useRef<any>();

	const dataSource = useDataSource();

	const onVersionsUpdate = (evt: any) => {
		setVersion((prevState: any) => ({ ...prevState, ...evt }));
	};

	useEffect(() => {
		if (typeof versionListener.current === "undefined" && dataSource.listenEntity && _versionId) {
			//console.log("mount");
			versionListener.current = dataSource.listenEntity({
				path: versionsCollection.path,
				entityId: _versionId,
				collection: versionsCollection,
				onUpdate: (evt) => onVersionsUpdate(evt),
			});
			return () => {
				//console.log("Child unmounted");
				versionListener.current();
				versionListener.current = undefined;
			};
		}
	}, [dataSource, versionId]);

	const [tooltipIsOpen, setTooltipIsOpen] = useState<boolean>(false);
	const fio_assets = version?.values?.fio_asset_ids || {};
	const numberOfAsset = Object.keys(fio_assets).length;

	return numberOfAsset > 0 ? (
		<Tooltip
			onClick={() => setTooltipIsOpen(false)}
			onOpen={() => setTooltipIsOpen(true)}
			onClose={() => setTooltipIsOpen(false)}
			arrow
			componentsProps={{
				tooltip: {
					sx: {
						m: 0,
						p: 0,

						bgcolor: "transparent",
						"& .MuiTooltip-arrow": {
							color: "background.paper",
						},
					},
				},
			}}
			open={tooltipIsOpen}
			title={
				<PreviewImage
					version={version}
					assetId={"fav"}
					autoplay
					is_visible={tooltipIsOpen}
					img_type={"thumb_scrub"}
					onclick={() => setTooltipIsOpen(false)}
					sx={{ width: "240px", height: "135px", cursor: "pointer", borderRadius: "5px", overflow: "hidden", zIndex: 0 }}
				/>
			}
			placement="left"
		>
			<Badge
				invisible={numberOfAsset < 2}
				sx={{
					cursor: "pointer",
					"& .MuiBadge-badge": {
						backgroundColor: "rgba(0,0,0,.75)",
					},
				}}
				badgeContent={
					<Typography variant="body2" color="text.primary" align={"center"}>
						{numberOfAsset}
					</Typography>
				}
				color="primary"
			>
				<PreviewImage
					version={version}
					assetId={"fav"}
					img_type={"thumb"}
					onclick={() => {
						setTooltipIsOpen(false);
					}}
					sx={{ cursor: "pointer", borderRadius: "5px", overflow: "hidden", width: "72px", height: "40px", zIndex: 0 }}
				/>
			</Badge>
		</Tooltip>
	) : null;
}
