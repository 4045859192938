import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AttachmentIcon from "@mui/icons-material/Attachment";
import FolderIcon from "@mui/icons-material/Folder";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import Divider from "@mui/material/Divider";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { ProjectUnrealEditIcon, ProjectUnrealAssetIcon, ProjectGenericIcon, AssetIcon, AssetShotIcon, AssetTimelineIcon, ProjectUnrealSceneIcon } from "../../CustomIcons/CustomIcons";

import { GroupType, ActionType, ProjectType } from "../../Types/types";
import { useDebounce } from "usehooks-ts";

export default function TreeViewMenu({
	onclick,
	onclose,
	width,
	height,
	dialogCollection,
	dialogAsset,
	grouptype,
	parentid,
	actiontype,
	projecttype,
}: {
	onclick: any;
	onclose: any;
	dialogCollection: any;
	dialogAsset: any;
	width?: string | undefined;
	height?: string | undefined;
	grouptype: GroupType;
	projecttype?: ProjectType;
	parentid: string;
	actiontype: ActionType;
}) {
	const theme = useTheme();
	/*	const { dialogCollection, grouptype, parentid, actiontype }: { dialogCollection: () => void; grouptype: GroupType; parentid: string; actiontype: ActionType } = props;*/

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
	const showTooltipDebounce = useDebounce<boolean>(showTooltip, 1000);
	const [showControllerTooltip, setShowControllerTooltip] = React.useState<boolean>(false);

	const open = Boolean(anchorEl);

	const defaultPrevent = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
	};
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		defaultPrevent(event);
		onclick();

		setAnchorEl(event.currentTarget);
	};

	const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
		defaultPrevent(event);
		setAnchorEl(null);

		onclose();
	};

	const handleClickMenuItem = (event: React.MouseEvent<HTMLButtonElement>, callback: any) => {
		handleClose(event);
		callback();
	};

	const tooltipTitle = (grouptype: GroupType, parentid: string, actiontype: ActionType) => {
		let toolTipString = "";

		switch (actiontype) {
			case "create":
				switch (grouptype) {
					case "job":
						toolTipString = "Create Project or Collection";
						break;
					case "project":
						toolTipString = "Create Asset or Shot";
						break;
					case "asset":
						toolTipString = "Create Version";
						break;
				}
				break;
			case "edit":
				switch (grouptype) {
					case "job":
						toolTipString = "Job settings";
						break;
					case "project":
						toolTipString = "Project settings";
						break;
					case "asset":
						toolTipString = "Project settings";
						break;
				}
				break;
		}
		return toolTipString as string;
	};

	useEffect(() => {
		if (showTooltipDebounce) {
			setShowControllerTooltip(true);
		}
	}, [showTooltipDebounce]);

	useEffect(() => {
		if (!showTooltip) {
			setShowControllerTooltip(false);
		}
	}, [showTooltip]);

	return (
		<Box >
			<IconButton
				sx={{ width: width, height: height || width }}
				aria-label="more"
				id="long-button"
				aria-controls={open ? "long-menu" : undefined}
				aria-expanded={open ? "true" : undefined}
				aria-haspopup="true"
				onClick={(evt) => {
					if (actiontype === "create") {
						handleClick(evt);
					} else {
						handleClose(evt);
						if (grouptype === "project") {
							dialogCollection(parentid, "unrealedit", "edit");
						} else if (grouptype === "asset" || grouptype === "subasset") {
							dialogAsset(parentid, "shot", "edit");
						}
					}
				}}
			>
				<Tooltip
					open={showControllerTooltip}
					placement="top"
					title={tooltipTitle(grouptype, parentid, actiontype)}
					arrow
					onMouseLeave={(evt: any) => {
						setShowTooltip(false);
					}}
				>
					<Box
						sx={{ display: "flex", alignItems: "center" }}
						onMouseMove={(evt: any) => {
							setShowTooltip(true);
						}}
					>
						{actiontype === "create" ? <AddIcon sx={{ width: width, height: height || width }} /> : <MoreHorizIcon sx={{ width: width, height: height || width }} />}
					</Box>
				</Tooltip>
			</IconButton>

			{grouptype === "job" ? (
				actiontype === "create" ? (
					<Menu
						onClick={defaultPrevent}
						id="basic-menu"
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						MenuListProps={{
							"aria-labelledby": "basic-button",
						}}
						PaperProps={{
							style: {
								left: "50%",
								transform: "translateY(10px)",
							},
						}}
					>
						<MenuItem sx={{ background: `${theme.palette.secondary.main}!important`, opacity: "1!important", pl: 2.5, pt: 1, pb: 1, m: 0, mt: -1 }} dense disabled>
							<ListItemText>Create Project</ListItemText>
						</MenuItem>

						<MenuItem
							sx={{ mt: 0.75 }}
							dense
							onClick={(evt: any) =>
								handleClickMenuItem(evt, () => {
									dialogCollection(parentid, "unrealedit", "create");
								})
							}
						>
							<ListItemIcon>
								<ProjectUnrealEditIcon />
							</ListItemIcon>
							<ListItemText>Unreal Edit Project</ListItemText>
						</MenuItem>
						<MenuItem
							dense
							onClick={(evt: any) =>
								handleClickMenuItem(evt, () => {
									dialogCollection(parentid, "unrealasset", "create");
								})
							}
						>
							<ListItemIcon>
								<ProjectUnrealAssetIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>Unreal Asset Project</ListItemText>
						</MenuItem>
						<MenuItem disabled>
							<Divider sx={{ p: 0, height: "2px", width: "100%", background: "rgba(255,255,255,0.25)", zIndex: 1 }} />
						</MenuItem>

						<MenuItem
							dense
							onClick={(evt: any) =>
								handleClickMenuItem(evt, () => {
									dialogCollection(parentid, "generic", "create");
								})
							}
						>
							<ListItemIcon>
								<AttachmentIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>Generic Collection</ListItemText>
						</MenuItem>
					</Menu>
				) : null
			) : null}

			{grouptype === "project" ? (
				actiontype === "create" ? (
					<Menu
						onClick={defaultPrevent}
						id="basic-menu"
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						MenuListProps={{
							"aria-labelledby": "basic-button",
						}}
						PaperProps={{
							style: {
								left: "50%",
								transform: "translateY(10px)",
							},
						}}
					>
						<MenuItem sx={{ background: `${theme.palette.secondary.main}!important`, opacity: "1!important", pl: 2.5, pt: 1, pb: 1, m: 0, mt: -1 }} dense disabled>
							<ListItemText>Create</ListItemText>
						</MenuItem>
						{projecttype !== "unrealedit" ? null : (
							<div>
								<MenuItem
									dense
									sx={{ mt: 0.75 }}
									onClick={(evt: any) =>
										handleClickMenuItem(evt, () => {
											dialogCollection(parentid, "unrealscene", "create");
										})
									}
								>
									<ListItemIcon>
										<ProjectUnrealSceneIcon color={"secondary"} />
									</ListItemIcon>
									<ListItemText>Scene</ListItemText>
								</MenuItem>
								<MenuItem disabled>
									<Divider sx={{ p: 0, height: "2px", width: "100%", background: "rgba(255,255,255,0.25)", zIndex: 1 }} />
								</MenuItem>
								<MenuItem
									dense
									sx={{ mt: 0 }}
									onClick={(evt: any) =>
										handleClickMenuItem(evt, () => {
											dialogAsset(parentid, "shot", "create");
										})
									}
								>
									<ListItemIcon>
										<AssetShotIcon color={"secondary"} />
									</ListItemIcon>
									<ListItemText>Shot</ListItemText>
								</MenuItem>
								<MenuItem
									dense
									sx={{ mt: 0.75 }}
									onClick={(evt: any) =>
										handleClickMenuItem(evt, () => {
											dialogAsset(parentid, "timeline", "create");
										})
									}
								>
									<ListItemIcon>
										<AssetTimelineIcon color={"secondary"} />
									</ListItemIcon>
									<ListItemText>Timeline</ListItemText>
								</MenuItem>

								<MenuItem disabled>
									<Divider sx={{ p: 0, height: "2px", width: "100%", background: "rgba(255,255,255,0.25)", zIndex: 1 }} />
								</MenuItem>
							</div>
						)}
						{projecttype !== "unrealscene" ? null : (
							<div>
								<MenuItem
									dense
									sx={{ mt: 0.75 }}
									onClick={(evt: any) =>
										handleClickMenuItem(evt, () => {
											dialogAsset(parentid, "shot", "create");
										})
									}
								>
									<ListItemIcon>
										<AssetShotIcon color={"secondary"} />
									</ListItemIcon>
									<ListItemText>Scene Shot</ListItemText>
								</MenuItem>
								<MenuItem
									dense
									sx={{ mt: 0.75 }}
									onClick={(evt: any) =>
										handleClickMenuItem(evt, () => {
											dialogAsset(parentid, "timeline", "create");
										})
									}
								>
									<ListItemIcon>
										<AssetTimelineIcon color={"secondary"} />
									</ListItemIcon>
									<ListItemText>Scene Timeline</ListItemText>
								</MenuItem>

								<MenuItem disabled>
									<Divider sx={{ p: 0, height: "2px", width: "100%", background: "rgba(255,255,255,0.25)", zIndex: 1 }} />
								</MenuItem>
							</div>
						)}
						<MenuItem
							dense
							onClick={(evt: any) =>
								handleClickMenuItem(evt, () => {
									dialogAsset(parentid, "prop", "create");
								})
							}
						>
							<ListItemIcon>
								<AssetIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>Prop</ListItemText>
						</MenuItem>
						<MenuItem
							dense
							onClick={(evt: any) =>
								handleClickMenuItem(evt, () => {
									dialogAsset(parentid, "character", "create");
								})
							}
						>
							<ListItemIcon>
								<AssetIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>Character</ListItemText>
						</MenuItem>
						<MenuItem
							dense
							onClick={(evt: any) =>
								handleClickMenuItem(evt, () => {
									dialogAsset(parentid, "environment", "create");
								})
							}
						>
							<ListItemIcon>
								<AssetIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>Environment</ListItemText>
						</MenuItem>
						<MenuItem
							dense
							onClick={(evt: any) =>
								handleClickMenuItem(evt, () => {
									dialogAsset(parentid, "fx", "create");
								})
							}
						>
							<ListItemIcon>
								<AssetIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>FX</ListItemText>
						</MenuItem>

						<MenuItem
							dense
							onClick={(evt: any) =>
								handleClickMenuItem(evt, () => {
									dialogAsset(parentid, "generic", "create");
								})
							}
						>
							<ListItemIcon>
								<AttachmentIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>Generic Asset</ListItemText>
						</MenuItem>
						<MenuItem disabled>
							<Divider sx={{ p: 0, height: "2px", width: "100%", background: "rgba(255,255,255,0.25)", zIndex: 1 }} />
						</MenuItem>
						<MenuItem
							dense
							sx={{ mt: 0 }}
							onClick={(evt: any) =>
								handleClickMenuItem(evt, () => {
									dialogAsset(parentid, "generic_render", "create");
								})
							}
						>
							<ListItemIcon>
								<AssetShotIcon />
							</ListItemIcon>
							<ListItemText>Generic Render</ListItemText>
						</MenuItem>
					</Menu>
				) : null
			) : null}
		</Box>
	);
}
