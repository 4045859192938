import React from "react";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";

import Box from "@mui/material/Box";

const PrivateSwitchComponent = styled((props: SwitchProps) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
	width: 50,
	height: 26,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,

		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(24px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.primary.main,
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: theme.palette.primary.main,
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 22,
		height: 22,
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
		"&:before": {
			content: "''",
			position: "absolute",
			width: "100%",
			height: "100%",
			left: 7,
			backgroundRepeat: "no-repeat",
			backgroundPosition: "left",
			top: 0,
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
				theme.palette.getContrastText(theme.palette.primary.main)
			)}" d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" /></svg>')`,
		},
	},
}));

export default function SwitchPrivate({ checked, setChecked, name, label, tabindex, parent }: { name: string; checked: boolean; setChecked: any; label: string; tabindex?: number; parent?: string }) {
	return (
		<Box sx={{ display: "flex", alignItems: "right", justifyItems: "center", width: "100%" }}>
			<FormControlLabel
				control={
					<PrivateSwitchComponent
						disabled={parent ? true : false}
						tabIndex={tabindex || undefined}
						checked={checked}
						name={name}
						onChange={(evt) => {
							setChecked({ target: { name: name, value: evt.target.checked ? true : false } });
						}}
						inputProps={{ "aria-label": "controlled" }}
						sx={{ m: 1, ml: 1.5 }}
					/>
				}
				label={label}
			/>
		</Box>
	);
}
