import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";

export default function TooltipClipboard(props: any) {
	const [showTooltip, setShowTooltip] = useState<boolean>(false);
	const handleOnTooltipClose = () => {
		setShowTooltip(false);
	};

	const onCopy = (content: any) => {
		navigator.clipboard.writeText(props.value || "");
		setShowTooltip(true);
	};

	return (
		<Tooltip onDoubleClick={onCopy} open={showTooltip} title={"Copied to clipboard!"} leaveDelay={1500} onClose={handleOnTooltipClose}>
			{props.children}
		</Tooltip>
	);
}
