import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useIsScrollable } from "./useIsScrollable";
import { Comment } from "./types";
import { Box } from "@mui/material";
import VersionCard from "../../Components/VersionCard";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

type ScrollerProps = {
	assets: any;
	versions: any;
	comments?: Comment[];
	hasMore: boolean;
	isLoading: boolean;
	loadMoreData: () => Promise<void>;
	setOldestDate?: any;
	jobIdent?: string;
	jobIdentShown?: boolean;
};

export const Scroller = ({ assets, versions, loadMoreData, isLoading, hasMore, setOldestDate, jobIdent, jobIdentShown }: ScrollerProps) => {
	const convertDateToText = (input: Date) => {
		if (Object.prototype.toString.call(input) === "[object Date]") {
			if (isNaN(input.getTime())) {
				return "Invalid date";
			}
		} else {
			return "Invalid date";
		}

		let output: string = "";

		const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
		const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		const getOrdinalNum = (n: number) => {
			return n + (n > 0 ? ["th", "st", "nd", "rd"][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : "");
		};
		const dateYesterday = () => {
			const intermedDate = new Date();
			intermedDate.setDate(intermedDate.getDate() - 1);
			return intermedDate;
		};
		const yesterday = input.toDateString() === dateYesterday().toDateString();
		const today = input.toDateString() === new Date().toDateString();
		const thisYear = input.getFullYear() === new Date().getFullYear();

		output = thisYear ? `${weekday[input.getDay()]}, ${months[input.getMonth()]} ${getOrdinalNum(input.getDate())}` : `${months[input.getMonth()]} ${getOrdinalNum(input.getDate())}, ${input.getFullYear()}`;

		return today ? "Today" : yesterday ? "Yesterday" : output;
	};

	const oldestVisibleDate = (inView: any) => {
		if (inView.length > 0) {
			const currentInView = [...inView];

			currentInView.sort();
			currentInView.reverse();
			const version = versions.find(({ id }: any) => id === currentInView[0]);
			// console.log(version?.values?.created_on || "");
			return convertDateToText(version?.values?.created_on) || "";
		}
		return "";
	};
	const [isScrollable, ref, node] = useIsScrollable([versions]);
	const [inView, setInView] = useState<any[]>([]);
	const [versionCards, setVersionCards] = useState<any[]>([]);
	const isInView = (id: any, visible: boolean) => {
		const indexInArray = inView.indexOf(id);
		const isAlreadyInView = indexInArray !== -1;

		if (!visible && isAlreadyInView) {
			const currentInView = [...inView];
			currentInView.splice(indexInArray);
			//remove
			updateInView(currentInView, false);
		} else if (visible && !isAlreadyInView) {
			//add
			updateInView([...inView, id], true);
		}

		inView.forEach((inViewItem: any) => {
			const version = versions.find(({ id }: any) => id === inViewItem);
			/*	console.log(version?.values?.name);*/
		});
	};

	useEffect(() => {
		if (!isScrollable && !isLoading) {
			loadMoreData();
		} else {
			setInView([]);
		}
	}, [isLoading, versions, assets, isScrollable, hasMore, node]);

	const updateInView = (newArray: string[], shouldRefreshOldestDate: boolean) => {
		console.log("update in view");
		setInView(newArray);
		const currentOldest = oldestVisibleDate(newArray);
		if (currentOldest !== "" && currentOldest !== "Invalid date") {
			setOldestDate(currentOldest);
		}
	};

	const reverseVersions = versions?.slice(0).reverse();

	const createVersionCards = () => {
		const renderObj: any[] = [];

		reverseVersions.forEach((version: any, versionindex: number) => {
			const thisVersionDate = version.values.created_on;

			let dateToUse: Date = thisVersionDate;

			let nextItemHasOtherDate = false;

			let prevVersionHasOtherDate = false;

			if (versionindex + 1 <= reverseVersions.length - 1) {
				const prevVersionDate = new Date(reverseVersions[versionindex + 1].values.created_on);

				if (thisVersionDate.toDateString() === prevVersionDate.toDateString()) {
					dateToUse = thisVersionDate;
				} else {
					prevVersionHasOtherDate = true;
					dateToUse = prevVersionDate;
				}
			}

			const isFirstItem = versionindex === 0;

			const dateString = convertDateToText(dateToUse);

			renderObj.push(
				<VersionCard
					jobIdent={jobIdent || undefined}
					isJobNameShown={jobIdentShown}
					sx={{ width: 400, position: "relative", maxWidth: 400, mt: "20px", mb: isFirstItem ? "15px" : "0px" }}
					key={"v_card_" + version.id}
					index={"v_card_" + version.id}
					asset={assets.find((x: any) => x.id === version.values.asset.id)}
					versionInput={version}
					setinview={isInView}
				/>
			);

			if (!isFirstItem) {
				renderObj.push(
					<Divider key={"dividers_" + version.id} sx={{ height: "15px", visibility: prevVersionHasOtherDate && !isFirstItem ? "visible" : "hidden" }}>
						<Box>
							<Chip avatar={<ArrowDropDownIcon />} label={dateString} />
						</Box>
					</Divider>
				);
			}
		});
		return renderObj;
	};

	useEffect(() => {
		//console.log("scroller:", versions);
		setVersionCards(createVersionCards());
	}, [versions, assets]);

	return (
		<Box
			id="scrollableDiv"
			sx={{
				width: "100%",
				height: "100%",
				display: "flex",
				flexDirection: "column-reverse",

				overflowX: "hidden",
				overflowY: "scroll",
				"&::-webkit-scrollbar": { background: "rgba(0,0,0,0)", width: "10px" },
				"&::-webkit-scrollbar-thumb": { background: "rgba(125,125,125,1)", borderRadius: "10px" },
			}}
			ref={ref}
		>
			{/*<Box style={{ position: "absolute", top: 0 }}>Scrossllable - {`${isScrollable}`}</Box>*/}
			<InfiniteScroll
				dataLength={versions.length}
				next={loadMoreData}
				style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
				inverse={true}
				hasMore={hasMore}
				loader={true ? undefined : <Box sx={{ mr: "auto", ml: "auto", height: 400, width: 400, background: "rgba(255,255,255,0)", textAlign: "center" }}> Loading</Box>}
				scrollableTarget="scrollableDiv"
				initialScrollY={0}
			>
				<Box sx={{ height: 60 }}></Box>
				<div style={{ display: "flex", flexDirection: "column" }}>{versionCards}</div>
			</InfiniteScroll>
		</Box>
	);
};
