import React, { useEffect, useRef, useState } from "react";

import { useDebounce } from "../utils/usehooks-ts";

import { EntityCollection, useDataSource, useAuthController } from "@camberi/firecms";

import { VersionUploader } from "../Components/VersionUploader";
import VersionCard from "../Components/VersionCard";

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SvgIconProps } from "@mui/material/SvgIcon";
import TreeView from "@mui/lab/TreeView";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Tooltip from "@mui/material/Tooltip";
import UpdateIcon from "@mui/icons-material/Update";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import TagIcon from "@mui/icons-material/Tag";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { alpha } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";

/**
 * Sample CMS view not bound to a collection, customizable by the developer
 * @constructor
 */

declare module "react" {
  interface CSSProperties {
    "--tree-view-color"?: string;
    "--tree-view-bg-color"?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon?: React.ElementType<SvgIconProps>;
  boldText?: boolean;
  labelInfo?: string;
  labelEndNumber?: number | string;
  labelText: string;
  statusIcon?: React.ElementType<SvgIconProps>;
  statusInfo?: string;
  expanded?: boolean;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
}));

function StyledTreeItem(props: StyledTreeItemProps) {
  const { bgColor, color, labelIcon: LabelIcon, labelInfo, labelEndNumber, labelText, statusIcon, statusInfo, boldText, expanded, ...other } = props;

  return (
    <StyledTreeItemRoot
      key={other.nodeId + "_1"}
      label={
        <Box
          key={other.nodeId + "_2"}
          sx={{
            display: "flex",
            alignItems: "center",
            p: 0.5,
            pr: 0,
          }}
        >
          {LabelIcon ? <Box component={LabelIcon} color="inherit" sx={{ mr: 1, width: 20 }} /> : null}

          <Typography variant="body2" sx={{ fontWeight: boldText ? "bold" : "inherit", flexGrow: 1 }}>
            {labelText}
          </Typography>

          {/* statusIcon={project.values.status.startsWith("error") ? ErrorOutlineIcon : undefined}*/}
          {statusInfo?.startsWith("error") ? (
            <Tooltip title={statusInfo ? statusInfo : ""}>
              <Box component={ErrorOutlineIcon} color="inherit" sx={{ mr: 1, width: 20, color: "red" }} />
            </Tooltip>
          ) : statusInfo?.startsWith("pending") ? (
            <Tooltip title={statusInfo ? statusInfo : ""}>
              <Box component={UpdateIcon} color="inherit" sx={{ mr: 1, width: 20, color: "white" }} />
            </Tooltip>
          ) : (
            <Typography variant="caption" color="inherit">
              {labelInfo}
              <Badge badgeContent={labelEndNumber} sx={{ mr: 4, width: 15, color: "white" }} color="default" />
            </Typography>
          )}
        </Box>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      {...other}
    />
  );
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(0),
  marginLeft: 0,

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(0),
    width: "100%",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function ExampleCMSView({
  assetsCollection,
  unrealprojectsCollection,
  usersCollection,
  versionsCollection,
}: {
  assetsCollection: EntityCollection;
  unrealprojectsCollection: EntityCollection;
  usersCollection: EntityCollection;
  versionsCollection: EntityCollection;
}) {
  // hook to display custom snackbars
  // hook to do operations related to authentication
  const authController = useAuthController();

  useEffect(() => {
    if (authController) {
      console.log(authController.user?.uid);
    }
  }, [authController]);

  const filterAssets = (queryInput: string, assetsInput: any[]) => {
    if (typeof queryInput === "undefined" || queryInput === "") {
      const filteredVersions = versions.filter((version) => assetsInput.filter((asset) => asset.id === version.values.asset.id).length);
      return [assetsInput, filteredVersions];
    } else {
      const queryArr = queryInput.split(" ");

      const filteredAsset = assetsInput.filter((asset) => queryArr.every((item) => asset.values?.name?.toLowerCase().includes(item.toLowerCase())));
      const filteredVersions = versions.filter((version) => filteredAsset.filter((asset) => asset.id === version.values.asset?.id).length);

      return [filteredAsset, filteredVersions];
    }
  };

  const dataSource = useDataSource();

  const [assets, setAssets] = useState<any[]>([]);
  const [projects, setProjects] = useState<any[]>([]);
  const [versions, setVersions] = useState<any[]>([]);

  const [query, setQuery] = useState<any>("");
  const debouncedValue = useDebounce<string>(query, 300);

  /*  const viewFiltered = filterAssets(query, assets);*/
  const [assetsFiltered, setassetsFiltered] = useState<any[]>([]);
  const [filteredVersions, setfilteredVersions] = useState<any[]>([]);
  /*const assetsFiltered = [];
  const filteredVersions = [];*/

  const [filtered, setFiltered] = useState<boolean>(false);

  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);

  const [forceRefresh, setForceRefresh] = useState<Date>(new Date());

  const scrollRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef?.current) {
      const smooth: ScrollBehavior = "auto";
      scrollRef.current.scrollIntoView({ behavior: smooth });
    }
  }, [assetsFiltered]);

  useEffect(() => {
    if (assetsCollection && dataSource.listenCollection) {
      dataSource.listenCollection({
        path: assetsCollection.path,
        collection: assetsCollection,
        onUpdate: onUpdateAssets,
      });
    }

    if (unrealprojectsCollection && dataSource.listenCollection) {
      dataSource.listenCollection({
        path: unrealprojectsCollection.path,
        collection: unrealprojectsCollection,
        onUpdate: onProjectUpdate,
      });
    }

    if (versionsCollection && dataSource.listenCollection) {
      dataSource.listenCollection({
        path: versionsCollection.path,
        collection: versionsCollection,
        onUpdate: onVersionsUpdate,
      });
    }
  }, [dataSource, assetsCollection, unrealprojectsCollection, versionsCollection]);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setSelected(nodeIds);
  };

  const onUpdateAssets = (evt: any) => {
    //console.log("Asset Update");

    setAssets(evt);
  };

  const onProjectUpdate = (evt: any) => {
    //console.log("Project Update Update");

    setProjects(evt);
  };

  const onVersionsUpdate = (evt: any) => {
    //console.log("Project Update Update");

    setVersions(evt);
  };
  //console.log("Selection from ExampleCMSView", selectionController.selectedEntities);

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    if (debouncedValue === "") {
      setFiltered(false);
    } else {
      const viewFiltered = filterAssets(query, assets);
      setassetsFiltered(viewFiltered[0]);
      setfilteredVersions(viewFiltered[1]);

      setFiltered(true);
      setForceRefresh(new Date());
    }
  }, [debouncedValue, assets, versions, projects]);

  return (
    <Box>
      <Grid container spacing={0}>
        <Grid item xs={6} md={4}>
          <Box width={"100%"} marginLeft={"0px"}>
            <Box display="flex" width={"100%"} paddingLeft={"10px"} paddingRight={"10px"} flexDirection={"column"} alignItems={"center"} justifyItems={"center"}>
              <p>You see {assetsFiltered.length} assets</p>

              <Search
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  width: "100%",
                  overflowY: "auto",
                  marginBottom: "10px",
                }}
              >
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search assets…"
                  onChange={onSearchChange}
                  inputProps={{ "aria-label": "search" }}
                  sx={{
                    flexGrow: 1,
                    width: "100%",
                    overflowX: "hidden",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": { display: "none" },
                  }}
                />
              </Search>
              <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={
                  filtered
                    ? projects
                        .map((project, Index) => {
                          return project.id;
                        })
                        .concat(
                          projects.map((project, Index) => {
                            return project.id + "_m";
                          })
                        )
                    : expanded
                }
                selected={selected}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
                /* defaultExpanded={["3"]}*/
                /*        expanded={
                    filtered
                      ? projects.map((project, Index) => {
                          return Index.toString();
                        })
                      : undefined
                  }*/
                sx={{
                  height: 800,
                  flexGrow: 1,
                  width: "100%",
                  overflowX: "hidden",
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": { background: "rgba(0,0,0,0)", width: "10px" },
                  "&::-webkit-scrollbar-thumb": { background: "rgba(125,125,125,1)", borderRadius: "10px" },
                }}
              >
                {/* main projects*/}
                {projects
                  ?.sort((a, b) => (a.values.sub_projects.length < b.values.sub_projects.length ? 1 : -1))
                  .map((project, index_value) => {
                    let numberOfAsset = filtered ? assetsFiltered?.filter((asset) => asset.values.project?.id === project.id).length : project.values?.used_assets.length;
                    numberOfAsset = numberOfAsset > 0 ? numberOfAsset : "";

                    let numberOfAssetMaster = assetsFiltered?.filter((asset) => asset.values.project?.id === project.id).length;

                    let used_projects_str = "";
                    project.values?.used_projects?.forEach((single: any, index: any) => {
                      const projectFull = projects.find((x) => x.id === single.id);
                      const lastItem = index === project.values?.used_projects.length - 1;

                      used_projects_str = used_projects_str + `${projectFull.values.name}${lastItem ? "" : " / "}`;
                    });

                    return filtered && numberOfAssetMaster < 1 ? null : (
                      <StyledTreeItem
                        key={project.id}
                        nodeId={project.id}
                        labelText={project.values.name}
                        statusInfo={project.values.status}
                        labelInfo={filtered || used_projects_str === "" ? "" : "(" + used_projects_str + ") "}
                        labelEndNumber={numberOfAsset}
                        boldText={true}
                      >
                        {filtered && numberOfAssetMaster < 1 ? null : (
                          <StyledTreeItem key={project.id + "_m"} nodeId={project.id + "_m"} labelText={project.values.name} labelIcon={FolderIcon} statusInfo={project.values.status} labelEndNumber={numberOfAssetMaster} boldText={true}>
                            {/* main project assets*/}
                            {assetsFiltered
                              ?.filter((asset) => asset.values.project?.id === project.id)
                              .sort((a, b) => {
                                return a.values.type.localeCompare(b.values.type) || a.values.name.localeCompare(b.values.name);
                              })
                              .map((asset, mainassetIndex) => {
                                return <StyledTreeItem key={`${project.id}_m_${asset.id}`} nodeId={`${project.id}_m_${asset.id}`} labelText={asset.values.name} labelIcon={TagIcon} labelEndNumber={"#" + asset.values.type} />;
                              })}
                          </StyledTreeItem>
                        )}
                        {/* sub projects*/}
                        {!filtered
                          ? project.values?.sub_projects?.map((sub_project: any, subIndex: any) => {
                              const sub_projectFull = projects.find((x) => x.id === sub_project.id);
                              const sub_project_assetsFiltered = assetsFiltered?.filter((asset) => asset.values.project?.id === sub_project.id);
                              sub_project_assetsFiltered.sort((a, b) => {
                                return a.values.type.localeCompare(b.values.type) || a.values.name.localeCompare(b.values.name);
                              });

                              return (
                                <StyledTreeItem
                                  key={`${project.id}_sub_${sub_project.id}`}
                                  nodeId={`${project.id}_sub_${sub_project.id}`}
                                  labelText={sub_projectFull.values.name}
                                  labelEndNumber={sub_project_assetsFiltered.length}
                                  labelIcon={FolderOpenIcon}
                                >
                                  {/* sub project assets*/}
                                  {sub_project_assetsFiltered.map((asset, Index) => {
                                    return (
                                      <StyledTreeItem
                                        key={`${project.id}_sub_${sub_project.id}_${asset.id}`}
                                        nodeId={`${project.id}_sub_${sub_project.id}_${asset.id}`}
                                        labelText={asset.values.name}
                                        labelIcon={TagIcon}
                                        labelEndNumber={"#" + asset.values.type}
                                      />
                                    );
                                  })}
                                </StyledTreeItem>
                              );
                            })
                          : null}
                      </StyledTreeItem>
                    );
                  })}
              </TreeView>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} md={8}>
          <Item sx={{ height: "100%", overflowY: "scroll", "&::-webkit-scrollbar": { background: "rgba(0,0,0,0)", width: "10px" }, "&::-webkit-scrollbar-thumb": { background: "rgba(125,125,125,1)", borderRadius: "10px" } }}>
            <Box height="0px">
              <Stack spacing={2}>
                <Divider>
                  <Box>
                    <Chip avatar={<ArrowDropDownIcon />} label="Tuesday, August 9th" />
                  </Box>
                </Divider>

                {/* <VersionCard asset={} version={} />*/}
                {filteredVersions
                  ?.sort((a, b) => (a.values.created_on > b.values.created_on ? 1 : -1))
                  .map((version, versionindex) => (
                    <VersionCard key={"v_card_" + version.id} index={"v_card_" + version.id} asset={assetsFiltered.find((x) => x.id === version.values.asset.id)} version={version} />
                  ))}
                <Box ref={scrollRef} />
              </Stack>
            </Box>
            {React.useMemo(
              () => (
                <VersionUploader assetsFiltered={assetsFiltered} setForceRefresh={setForceRefresh} />
              ),
              [forceRefresh, assetsFiltered?.length]
            )}
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
