import React from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import Typography from "@mui/material/Typography";
import { PreviewImage } from "./PreviewImage";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: "rgba(255,255,255,0.025)",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
	width: "100%",
	marginTop: "3px",
	height: "100%",
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
}));
const styles = {
	container_full: {
		position: "relative",
		width: "100%",
		pr: "8px",
		pl: "8px",

		pt: "8px",
		pb: "8px",
	},
	container_row_item_left: {
		position: "relative",
		flexGrow: 1,
		width: "70%",
		pr: "3px",
		pl: "8px",

		pt: "8px",
		pb: "8px",
	},
	container_row_item_right: {
		position: "relative",
		width: "50%",
		height: "50%",
		pl: "3px",
		pr: "8px",
		pt: "8px",
		pb: "8px",
	},
	c_l_card_item: { borderRadius: "5px", height: { sm: "250px", xs: "125px" }, cursor: "pointer", overflow: "hidden" },
	c_r_card_item_left_bottom: { position: "relative", borderRadius: "5px", mb: "3px", height: { sm: "122px", xs: "61px" }, overflow: "hidden" },
	c_r_card_item_right_bottom: { position: "relative", borderRadius: "5px", top: "3px", height: { sm: "122px", xs: "61px" }, overflow: "hidden" },
	skeleton_container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	skeleton_container_more: {
		height: { sm: "122px", xs: "61px" },
		userSelect: "none",
		cursor: "pointer",
		marginTop: "3px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
};

const VersionCardPreviewItem = (props: any) => {
	const assetId = props?.asset?.fio_asset_id || "";

	const renderSwitch = () => {
		switch (props.type) {
			case "full":
				return (
					<Box sx={styles.container_full}>
						<PreviewImage key={assetId} status={props.status} version={props.version} assetId={assetId} autoplay={false} is_visible={true} img_type={"thumb_scrub"} sx={styles.c_l_card_item} />
					</Box>
				);
			case "half_left":
				return (
					<Box sx={styles.container_row_item_left}>
						<PreviewImage key={assetId} status={props.status} version={props.version} assetId={assetId} autoplay={false} is_visible={true} img_type={"thumb_scrub"} sx={styles.c_l_card_item} />
					</Box>
				);
			case "half_right":
				return (
					<Box sx={styles.container_row_item_right}>
						<PreviewImage key={assetId} status={props.status} version={props.version} assetId={assetId} autoplay={false} is_visible={true} img_type={"thumb_scrub"} sx={styles.c_l_card_item} />
					</Box>
				);
			case "third_right_top":
				return (
					<Box>
						<PreviewImage key={assetId} status={props.status} version={props.version} assetId={assetId} autoplay={false} is_visible={true} img_type={"thumb_scrub"} sx={styles.c_r_card_item_left_bottom} />
					</Box>
				);
			case "third_right_bottom":
				return props.filecount > 3 ? (
					<Box onClick={() => props.onclick()} sx={styles.skeleton_container_more}>
						<Item color="text.primary">
							<Typography variant="h6" color="text.secondary">
								{`+`}
							</Typography>
							<Typography variant="h6" color="text.primary">
								{`${props.filecount - 2}`}
							</Typography>
						</Item>
					</Box>
				) : (
					<Box>
						<PreviewImage key={assetId} version={props.version} status={props.status} assetId={assetId} autoplay={false} is_visible={true} img_type={"thumb_scrub"} sx={styles.c_r_card_item_right_bottom} />
					</Box>
				);
			default:
				return null;
		}
	};

	return renderSwitch();
};

export default VersionCardPreviewItem;
