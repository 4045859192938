import React from "react";

import { GoogleAuthProvider } from "firebase/auth";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";
import { NavDrawer } from "./Nav/NavDrawer";
import { tooltipClasses } from "@mui/material/Tooltip";

import "typeface-rubik";
import "@fontsource/ibm-plex-mono";

import {
  CircularProgressCenter,
  FirebaseAuthController,
  FirebaseLoginView,
  FireCMS,
  NavigationRoutes,
  SideDialogs,
  useFirebaseAuthController,
  useBuildModeController,
  useFirebaseStorageSource,
  useFirestoreDataSource,
  useInitialiseFirebase,
  useValidateAuthenticator,
  CMSView,
} from "@camberi/firecms";

import usersCollection from "./Collections/usersCollection";
import jobsCollection from "./Collections/jobsCollection";
import versionsCollection from "./Collections/versionsCollection";
import logsCollection from "./Collections/logsCollection";
import unrealProjectsCollection from "./Collections/unrealProjectsCollection";
import assetsCollection from "./Collections/assetsCollection";
import webhooksCollection from "./Collections/webhooksCollection";

import ExampleCMSView from "./CustomViews/ExampleCMSView";
import VersionsView from "./CustomViews/VersionsView";
import JobView from "./CustomViews/JobView";
import PersonalDashboard from "./CustomViews/PersonalDashboard";

import InfiniteScrollView from "./CustomViews/InfiniteScrollView";
import { Container } from "./CustomViews/VersionsView/Container";

import { Box } from "@mui/material";

//DRAWER
import ResponsiveDrawer from "./CustomViews/ResponsiveDrawer";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";

import HomeIcon from "./img/home-icon.svg";
import RectIcon from "./img/rectangle-2.svg";
import { labelIconByAssetType, labelIconByProjectType, JobIcon } from "./CustomIcons/CustomIcons";
import logo from "./img/logo.svg";

//FONT
import "./fonts/inter/inter.css";

const drawerWidth = 240;

export const HomeIconBtn = () => (
  <IconButton disableRipple>
    <img src={HomeIcon} alt={"home icon"} height={24} width={24} />
  </IconButton>
);

export const RectIconBtn = () => (
  <IconButton disableRipple>
    <img src={RectIcon} alt={"job icon"} height={20} width={20} />
  </IconButton>
);

const DEFAULT_SIGN_IN_OPTIONS = [GoogleAuthProvider.PROVIDER_ID];

// TODO: Replace with your config
const firebaseConfig = {
  apiKey: "AIzaSyBZLsTMEMw9XQ3skhWoIbSkF0g-ZhdgHho",
  authDomain: "stunning-boost.firebaseapp.com",
  projectId: "stunning-boost",
  storageBucket: "stunning-boost.appspot.com",
  messagingSenderId: "1024561909917",
  appId: "1:1024561909917:web:be44ee893c5020a4e98474",
};

/**
 * This is an example of how to use the components provided by FireCMS for
 * a better customisation.
 * @constructor
 */
export function CustomCMSApp() {
  const signInOptions = DEFAULT_SIGN_IN_OPTIONS;

  const { firebaseApp, firebaseConfigLoading, configError, firebaseConfigError } = useInitialiseFirebase({ firebaseConfig });

  /* const container = window !== undefined ? () => window.document.body : undefined;*/

  const authController: FirebaseAuthController = useFirebaseAuthController({
    firebaseApp,
    signInOptions,
  });

  const dataSource = useFirestoreDataSource({
    firebaseApp,
    // You can add your `FirestoreTextSearchController` here
  });

  const storageSource = useFirebaseStorageSource({ firebaseApp: firebaseApp });

  const modeController = useBuildModeController();
  /*  const theme = useMemo(() => createCMSDefaultTheme({ mode: modeController.mode }), []);*/
  const customViews: CMSView[] = [
    {
      icon: "Layers",
      path: "versions",
      name: "Versions",
      description: "You can access all versions here",
      // This can be any React component
      view: <ExampleCMSView assetsCollection={assetsCollection} unrealprojectsCollection={unrealProjectsCollection} usersCollection={usersCollection} versionsCollection={versionsCollection} />,
    },
    // {
    //   icon: "Layers",
    //   path: "versions-v2",
    //   name: "Versions-v2",
    //   description: "You can access all versions here",
    //   // This can be any React component
    //   view: <VersionsView jobId={""} />,
    // },
    {
      icon: "Layers",
      path: "/jobs/:jobId/:jobView",
      name: "Job View",
      description: "You can access all versions here",
      // This can be any React component
      view: <JobView key={"jobview"} />,
    },
    {
      icon: "Layers",
      path: "/jobs/:jobId/:jobView/:searchParam",
      name: "Job View",
      description: "You can access all versions here",
      // This can be any React component
      view: <JobView key={"jobview"} />,
    },
    {
      path: "infinite",
      name: "InfiniteScroll",
      description: "You can access all versions here",
      // This can be any React component
      view: <InfiniteScrollView assetsCollection={assetsCollection} unrealprojectsCollection={unrealProjectsCollection} usersCollection={usersCollection} versionsCollection={versionsCollection} />,
    },
    {
      path: "/",
      name: "dashboard",
      description: "You can access all versions here",
      // This can be any React component
      view: <PersonalDashboard />,
    },
    {
      path: "infinite-v2",
      name: "InfiniteScroll v2",
      description: "You can access all versions here",
      // This can be any React component
      view: <Container />,
    },
    {
      path: "responsive-drawer",
      name: "Responsive Drawer",
      description: "You can access all versions here",
      // This can be any React component
      view: <ResponsiveDrawer />,
    },
  ];

  const theme = createTheme({
    zIndex: {
      appBar: 500,
      modal: 2000,
      drawer: 1000,
    },
    //root: { "&.react-flow__attribution": { display: "none" } },
    components: {
      MuiCssBaseline: {
        styleOverrides: (themeParam) => ({
          html: {
            overflow: "hidden",
          },
          body: {
            overflow: "hidden",
          },
          "*:focus": {
            outline: "none",
          },
          ".react-player": { background: "black!important" },
          ".react-flow__attribution": { display: "none" },
          //".react-flow__minimap-mask": { borderRadius: "10px", overflow: "hidden" },
          ".react-flow__controls": { borderRadius: "5px", overflow: "hidden" },
          ".react-flow__controls-button": {
            ":hover": {
              background: "rgba(14, 16, 21, 0)",
            },
            background: "rgba(14, 16, 21, 1)",
            borderColor: "rgba(0,0,0,0)",
            svg: {
              fill: "white",
            },
          },
          //body: themeParam.palette.mode === "dark" ? darkScrollbar() : null,
          "&::-webkit-scrollbar": { background: "rgba(0,0,0,0)", width: "10px!important" },
          "&::-webkit-scrollbar-track": { background: "rgba(0,0,0,0)", width: "10px!important" },
          "&::-webkit-scrollbar-thumb": { background: "rgba(125,125,125,1)", width: "10px!important", borderRadius: "10px" },
        }),
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            // overrides tooltip
          },
          popper: {
            // overrides the popper
            zIndex: 2500 + "!important",
            [`& .${tooltipClasses.tooltip}`]: {
              maxWidth: "100%",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          outlined: {
            root: { borderColor: "red" },

            "&:focus": {},
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: "#393D49",
              "&:hover": {
                backgroundColor: "#393D49",
              },
            },
          },
          head: {
            //for the head
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: { borderCollapse: "separate", borderSpacing: "0 10px", border: "transparent" },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "none",
          },
          head: {
            // color: theme.palette.text.secondary,
            paddingTop: "10px",
            paddingBottom: "10px",
            // color: "rgba(0, 0, 0, 0.87)",
            backgroundColor: "rgb(16, 185, 129)",
            "&:Mui-active": {
              color: "red",
            },

            "&:first-of-type": {
              paddingLeft: "20px",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              boxShadow: `inset 8px 0 0 rgb(16, 185, 129)`,
            },
            "&:last-of-type": {
              paddingRight: "20px",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
              boxShadow: `inset -8px 0 0 rgb(16, 185, 129)`,
            },
          },
          body: {
            paddingTop: "20px",
            paddingBottom: "20px",
            "&:first-of-type": {
              paddingLeft: "20px",
            },
            "&:last-of-type": {
              paddingRight: "20px",
            },
          },
        },
      },
    },
    typography: {
      fontFamily: ["Inter"].join(","),
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 500,
      fontWeightMedium: 500,
    },
    palette: {
      mode: "dark",
      primary: {
        main: "rgb(117, 130, 235)",
        dark: "rgba(117, 130, 235,.5)",
        light: "rgb(117, 130, 235)",
      },
      secondary: {
        main: "rgb(16, 185, 129)",
        dark: "rgba(16, 185, 129,.5)",
      },
      background: { default: "rgba(14, 16, 21, 1)", paper: "rgba(22, 26, 34, 1)" },
      text: {
        primary: "rgb(237, 255, 247)",
        secondary: "rgb(209, 213, 219)",
        disabled: "rgba(160,174,192,0.5)",
      },
      error: {
        main: "rgb(209, 67, 67)",
      },
      divider: "rgb(45, 55, 72)",
    },
  });

  const { authLoading, canAccessMainView, notAllowedError } = useValidateAuthenticator({
    authController,
    authentication: ({ user }) => {
      // console.log("Allowing access to", user?.email);
      return true;
    },
    dataSource,
    storageSource,
  });

  if (configError) {
    return <div> {configError} </div>;
  }

  if (firebaseConfigError) {
    return <div>It seems like the provided Firebase config is not correct. If you are using the credentials provided automatically by Firebase Hosting, make sure you link your Firebase app to Firebase Hosting.</div>;
  }

  if (firebaseConfigLoading || !firebaseApp) {
    return <CircularProgressCenter />;
  }

  return (
    <Router>
      <FireCMS
        authController={authController}
        collections={[jobsCollection, unrealProjectsCollection, assetsCollection, versionsCollection, usersCollection, logsCollection, webhooksCollection]}
        views={customViews}
        modeController={modeController}
        dataSource={dataSource}
        storageSource={storageSource}
        entityLinkBuilder={({ entity }) => `https://console.firebase.google.com/project/${firebaseApp.options.projectId}/firestore/data/${entity.path}/${entity.id}`}
      >
        {({ context, loading }) => {
          //console.log(context);
          let component;
          if (loading) {
            component = <CircularProgressCenter />;
          } else if (!canAccessMainView) {
            component = <FirebaseLoginView allowSkipLogin={false} signInOptions={signInOptions} firebaseApp={firebaseApp} authController={authController} logo={logo} />;
          } else {
            component = (
              <Box sx={{ overflow: "hidden" }}>
                <CssBaseline />
                <AppBar
                  position="fixed"
                  sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                  }}
                />

                <Box component="main" style={{ position: "absolute" }} sx={{ left: { sm: `${drawerWidth}px` }, height: "100%", width: { xs: "100%", sm: `calc(100% - ${drawerWidth}px)` } }}>
                  <NavDrawer jobsCollection={jobsCollection} usersCollection={usersCollection} />

                  <NavigationRoutes />

                  <SideDialogs />
                </Box>
              </Box>
            );
          }

          return (
            <Box style={{ position: "absolute", width: "100%", height: "100%" }}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                {component}
              </ThemeProvider>
            </Box>
          );
        }}
      </FireCMS>
    </Router>
  );
}
