import InputBase from "@mui/material/InputBase";
import { alpha } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

const TreeviewSearch = styled("div")(({ theme }) => ({
	position: "relative",
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	"&:hover": {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	marginRight: theme.spacing(0),
	marginLeft: 0,

	[theme.breakpoints.up("sm")]: {
		marginLeft: theme.spacing(0),
		width: "100%",
	},
}));

const TreeviewSearchInputIconWrapper = styled("div")(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: "100%",
	position: "absolute",
	pointerEvents: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
}));

const TreeviewSearchInput = styled(InputBase)(({ theme }) => ({
	color: "inherit",
	"& .MuiInputBase-input": {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create("width"),
	},
}));

export { TreeviewSearch, TreeviewSearchInputIconWrapper, TreeviewSearchInput };
