import { buildCollection, buildProperty, EntityReference } from "@camberi/firecms";

type Log = {
	timestamp: Date;
	event: string;
	status: number;
	success: boolean;
	message: string;
	user: EntityReference;
	ip: string;
};

export default buildCollection<Log>({
	icon: "Dns",
	name: "Logs",
	singularName: "Log",
	path: "logs",
	group: "Admin",
	initialSort: ["timestamp", "desc"],
	permissions: ({ authController }) => ({
		edit: false,
		create: false,
		// we have created the roles object in the navigation builder
		delete: false,
	}),
	properties: {
		timestamp: {
			dataType: "date",
			name: "timestamp",
			mode: "date_time",
		},
		event: {
			dataType: "string",
			name: "Event",
		},
		status: {
			dataType: "number",
			name: "Status",
		},
		success: {
			dataType: "boolean",
			name: "Success",
		},
		message: {
			dataType: "string",
			name: "Message",
		},
		user: buildProperty({
			name: "User",
			dataType: "reference",
			path: "users",
			previewProperties: ["email"],
		}),
		ip: {
			dataType: "string",
			name: "IP Address",
		},
	},
});
