import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import { useAuthController } from "@camberi/firecms";
import axios from "axios";

import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import LayersIcon from "@mui/icons-material/Layers";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";
import ListSubheader from "@mui/material/ListSubheader";
import Divider from "@mui/material/Divider";
import DownloadIcon from "@mui/icons-material/Download";

import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

export default function VersionMenu({ curFioAssetID, onClick, version }: { curFioAssetID: any; version: any; onClick: any }) {
	const authController = useAuthController();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const [versions, setVersions] = useState<any>([]);
	const [isDownloading, setIsDownloading] = useState<boolean>(false);

	const defaultPrevent = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
	};

	const handleClose = (event: any) => {
		defaultPrevent(event);
		setAnchorEl(null);
	};

	const handleClick = (event: any) => {
		//onClick();
		defaultPrevent(event);
		setAnchorEl(event.currentTarget);
	};

	const open = Boolean(anchorEl);
	let navigate = useNavigate();
	const theme = useTheme();

	const allowed_downloads = ["vid_h264_2160", "vid_h264_1080_best", "vid_h264_720", "vid_h264_540", "vid_h264_360", "img_high", "img_full", "img_small", "img_cover", "src"];
	let available_downloads: any = [];

	allowed_downloads.forEach((key: any) => {
		const currentValue = version?.values?.fio_asset_ids?.[curFioAssetID]?.asset_info?.file_sizes?.[key];
		if (typeof currentValue !== "undefined") {
			available_downloads.push({ file_path: key, file_size: currentValue });
		}
	});

	const VersionItem = (props: any) => {
		const isSelectedVersion = version?.id?.length && version?.id === props?.version?.ref?.id;

		return (
			<MenuItem
				sx={{ "&:hover": { opacity: 1 }, opacity: isSelectedVersion ? 1 : 0.25 }}
				autoFocus={isSelectedVersion}
				//sx={{ background: `${theme.palette.secondary.main}!important`, opacity: "1!important", pl: 2.5, pt: 1, pb: 1, m: 0, mt: -1 }}
				dense
				onClick={(evt) => {
					handleClose(evt);
				}}
			>
				<ListItemText sx={{ display: "flex", flexDirection: "column" }}>
					<b>v{props.number}</b>
					<Box sx={{ maxWidth: "300px", textOverflow: "ellipsis", overflow: "hidden" }}>{`${props?.version?.commit_message || ""}`}</Box>
				</ListItemText>
			</MenuItem>
		);
	};

	const formatBytes = (bytes: number, decimals = 2) => {
		if (!+bytes) return "0 Bytes";

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
	};

	const downloadFile = (url: any) => {
		const a = document.createElement("a");
		a.href = url;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	const handleDownload = (src: any) => {
		const assetId = version?.values?.asset?.id || "";
		const versionId = version?.id || "";
		const fio_asset_id = curFioAssetID || "";

		setIsDownloading(true);
		axios({
			url: `${authController?.extra?.apiUrl}/download/${assetId}/${versionId}/${fio_asset_id}/${src}`,
			method: "GET",
			headers: {
				Authorization: `boostToken ${authController?.extra?.user_data?.values?.boost_token}`,
			},
		})
			.then((res) => {
				setIsDownloading(false);

				if (res.data.success) {
					downloadFile(res.data.url);
					setAnchorEl(null);
				}
			})
			.catch((e) => {
				setIsDownloading(false);
			});
	};

	const DownloadItem = (props: any) => {
		const src = props.src;
		const size = props.size;
		const isVideo = src.includes("vid");
		const isImage = src.includes("img");

		return (
			<MenuItem
				disabled={props.disabled}
				sx={{ "&:hover": { opacity: 1 }, opacity: 1 }}
				key={src}
				//sx={{ background: `${theme.palette.secondary.main}!important`, opacity: "1!important", pl: 2.5, pt: 1, pb: 1, m: 0, mt: -1 }}
				dense
				onClick={(evt) => {
					handleDownload(src);
				}}
			>
				<ListItemText>
					<Box sx={{ display: "flex", flexDirection: "row", textTransform: "capitalize" }}>
						<Box sx={{ ml: 0.5 }}>
							<b>
								{props.src.replace("vid_h264_", "").replace("_best", "").replace("src", "Source File").replace("img_", "")}
								{isVideo ? "p" : ""}
								{isImage ? " Image" : ""}
							</b>
						</Box>
						<Box sx={{ ml: 1 }}>{formatBytes(size)}</Box>
					</Box>
				</ListItemText>
			</MenuItem>
		);
	};

	const available_vids = available_downloads?.filter((d: any) => d.file_path.includes("vid"));
	const available_imgs = available_downloads?.filter((d: any) => d.file_path.includes("img"));
	const available_src = available_downloads?.filter((d: any) => d.file_path.includes("src"));
	return (
		<div>
			<IconButton onClick={handleClick} disableRipple size="small">
				<DownloadIcon fontSize="inherit" sx={{ cursor: "pointer", zIndex: 50000 }} />
			</IconButton>

			<Menu
				onClick={defaultPrevent}
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
				PaperProps={{
					style: {
						maxHeight: "33%",
						left: "50%",
						transform: "translateY(10px)",
					},
				}}
			>
				<ListSubheader component="div" sx={{ width: "100%", p: 0, m: 0 }}>
					<MenuItem sx={{ position: "sticky", background: `${theme.palette.primary.main}!important`, opacity: "1!important", pl: 2.5, pt: 1, pb: 1, m: 0, mt: -1, mb: 0.5 }} dense disabled>
						{isDownloading ? (
							<Box sx={{ width: "100%" }}>
								<LinearProgress />
							</Box>
						) : (
							"Downloads"
						)}
					</MenuItem>
				</ListSubheader>
				{available_vids.length > 0 ? (
					<Box>
						<MenuItem sx={{ ml: 0.5 }} dense disabled>
							Videos
						</MenuItem>
					</Box>
				) : null}

				{/*{videos}*/}
				{available_vids.map((d: any, n: number) => {
					return <DownloadItem key={version?.id + d.file_path + "_selector"} disabled={isDownloading} src={d.file_path} size={d.file_size} />;
				})}

				{available_imgs.length > 0 ? (
					<Box>
						<MenuItem sx={{ ml: 0.5 }} dense disabled>
							Images
						</MenuItem>
					</Box>
				) : null}
				{/*{images}*/}
				{available_imgs.map((d: any, n: number) => {
					return <DownloadItem key={version?.id + d.file_path + "_selector"} disabled={isDownloading} src={d.file_path} size={d.file_size} />;
				})}

				{/*{src}*/}
				{available_src.length > 0 ? (
					<Box>
						<MenuItem sx={{ ml: 0.5 }} dense disabled>
							Source
						</MenuItem>
					</Box>
				) : null}
				{available_src.map((d: any, n: number) => {
					return <DownloadItem key={version?.id + d.file_path + "_selector"} disabled={isDownloading} src={d.file_path} size={d.file_size} />;
				})}
			</Menu>
		</div>
	);
}
