import { buildCollection, buildProperty, EntityReference } from "@camberi/firecms";

type Webhook = {
	team_id: string; // disabled: true,
	hook_id: string;
	hook_name: string;
	hook_type: string;
	hook_secret: string;
	created_on: Date;
	status: string;
};

export default buildCollection<Webhook>({
	icon: "AdminPanelSettings",
	name: "Webhooks",
	singularName: "Webhook",
	path: "webhooks",
	group: "Admin",
	permissions: ({ authController }) => ({
		edit: true,
		create: false,
		// we have created the roles object in the navigation builder
		delete: false,
	}),
	properties: {
		created_on: buildProperty({
			readOnly: true,
			dataType: "date",
			name: "created_on",
			mode: "date",
		}),
		hook_type: {
			name: "Hook Type",
			disabled: true,
			dataType: "string",
		},
		team_id: {
			name: "Team ID",
			disabled: true,
			dataType: "string",
		},
		hook_name: {
			name: "Hook Name",
			disabled: true,
			dataType: "string",
		},
		hook_id: {
			name: "Hook ID",
			disabled: true,
			dataType: "string",
		},
		hook_secret: {
			name: "Hook Secret",
			disabled: true,
			dataType: "string",
		},
		status: {
			name: "Status",
			validation: { required: true },
			dataType: "string",
			enumValues: {
				active: "Active",

				deleted: "Deleted",
			},
		},
	},
});
