import React, { useState } from "react";

import InfiniteScroll from "react-infinite-scroll-component";
import { Box } from "@mui/material";

import { EntityCollection } from "@camberi/firecms";

/**
 * Sample CMS view not bound to a collection, customizable by the developer
 * @constructor
 */

const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};
export default function InfiniteScrollView({
  assetsCollection,
  unrealprojectsCollection,
  usersCollection,
  versionsCollection,
}: {
  assetsCollection: EntityCollection;
  unrealprojectsCollection: EntityCollection;
  usersCollection: EntityCollection;
  versionsCollection: EntityCollection;
}) {
  const [items, setItems] = useState(Array.from({ length: 10 }));
  const [hasMore, setHasMore] = useState(true);

  const fetchMoreData = () => {
    if (items.length >= 500) {
      setHasMore(false);
      return;
    }
    // a fake async api call like which sends
    // 20 more records in .5 secs
    setTimeout(() => {
      setItems(items.concat(Array.from({ length: 20 })));
    }, 500);
  };

  return (
    <Box display="flex" width={"100%"} height={"100%"}>
      <div
        id="scrollableDiv"
        style={{
          width: "100%",
          overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        {/*Put the scroll bar always on the bottom*/}
        <InfiniteScroll
          dataLength={items.length}
          next={fetchMoreData}
          style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
          inverse={true} //
          hasMore={true}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableDiv"
          /*          refreshFunction={fetchMoreData}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          pullDownToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>}
          releaseToRefreshContent={<h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>}*/
        >
          {items.map((_, index) => (
            <div style={style} key={index}>
              div - #{index}
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </Box>
  );
}
