import { useState, useMemo, useCallback, useEffect, useRef } from "react";
import { Virtuoso, GroupedVirtuoso } from "react-virtuoso";
import VersionCard from "../../Components/VersionCard";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDebounce } from "usehooks-ts";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { groupBy, convertDateToText } from "../../utils/usehooks-ts";

import { Box } from "@mui/material";

import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { CircularProgressCenter } from "@camberi/firecms";

export const Scroller_v02 = ({ filteredAssets, selectedAssets, selected, assets, jobIdent, jobIdentShown, uploadVersion }: any) => {
	const theme = useTheme();
	const smMatch = useMediaQuery(theme.breakpoints.up("sm"));
	const [loading, setLoading] = useState<boolean>(true);
	const [selectedAssetNames, setSelectedAssetNames] = useState<any>([]);
	const prevSelection = useRef<any>(null);
	const [versionGroups, setVersionGroups] = useState<any>([]);
	const virtuosoRef = useRef(null);
	const [atBottom, setAtBottom] = useState<boolean>(false);
	const showButtonTimeoutRef = useRef<any>(null);
	const [showButton, setShowButton] = useState<boolean>(false);

	const selectedAssetsDebounce = useDebounce<any[]>(selectedAssets, 150);

	const versionGroupsDebounced = useDebounce<any>(versionGroups, 350);

	useEffect(() => {
		return () => {
			clearTimeout(showButtonTimeoutRef.current);
		};
	}, []);

	useEffect(() => {
		clearTimeout(showButtonTimeoutRef.current);
		if (!atBottom) {
			showButtonTimeoutRef.current = setTimeout(() => setShowButton(true), 500);
		} else {
			setShowButton(false);
		}
	}, [atBottom, setShowButton]);

	const generateGroupedUsers = (versions: any) => {
		//const users = versions;
		const groupVersions = groupBy(versions, (version: any) => version?.date_text);
		const groupCounts = Object.values(groupVersions).map((gVersions: any) => gVersions.length);
		const groups = Object.keys(groupVersions);

		return { versions, groupCounts, groups };
	};

	useEffect(() => {
		setLoading(true);
		let _selectedAssetNames: string[] = [];
		const versions = (!selectedAssets.length ? filteredAssets || [] : assets || [])
			?.filter((a: any) => (!selectedAssets.length && !selected.length) || selectedAssets?.includes(a.id))
			?.flatMap((a: any) => {
				_selectedAssetNames.push(jobIdentShown ? a?.values?.name : a?.values?.name?.replace(jobIdent + "_", ""));
				return a?.values?.versions?.map((av: any) => {
					return { asset: a, deleted: av?.deleted, ref: av.ref, date_text: convertDateToText(av?.created_on), date: av.created_on };
				});
			})
			?.filter((v: any) => v?.ref?.id)
			?.sort((a: any, b: any) => b.ref.id.localeCompare(a.ref.id));

		setSelectedAssetNames(_selectedAssetNames);
		setVersionGroups(generateGroupedUsers(versions));
	}, [filteredAssets, selectedAssets]);

	useEffect(() => {
		if (JSON.stringify(prevSelection.current) !== JSON.stringify(selectedAssetsDebounce)) {
			scrollToBottom();
		}
		prevSelection.current = selectedAssetsDebounce;
	}, [selectedAssetsDebounce]);

	useEffect(() => {
		//scrollToBottom();
		setLoading(false);
	}, [versionGroupsDebounced]);

	const scrollToBottom = () => {
		(virtuosoRef?.current as any).scrollToIndex({ index: versionGroups?.versions?.length - 1, behavior: "smooth" });
	};

	return (
		<Box
			id="scroller"
			sx={{
				width: "100%",
				height: "100%",
				paddingBottom: { sm: "75px", xs: "0px" },
				overflowX: "hidden",
				overflowY: "hidden",
				"&::-webkit-scrollbar": { background: "rgba(0,0,0,0)", width: "10px" },
				"&::-webkit-scrollbar-thumb": { background: "rgba(125,125,125,1)", borderRadius: "10px" },
			}}
		>
			{false ? (
				<Box sx={{ display: "flex", height: "25px", justifyContent: "center" }}>
					<Box> Assets shown: {selectedAssetNames?.join(", ")}</Box>
				</Box>
			) : null}

			{!versionGroups?.versions?.length ? (
				<Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
					<Box sx={{ width: 400, borderRadius: "25px", backgroundColor: "background.paper", overflow: "hidden" }}>
						<Box sx={{ height: 50, backgroundColor: "secondary.main", display: "flex", alignItems: "center", pl: 4 }}>
							<Typography variant="h6">{selectedAssetNames.length === 1 ? "Upload first version" : "Make your choice"}</Typography>
						</Box>
						<Box sx={{ pl: 4, pr: 4, pt: 3, display: "flex" }}>
							{selectedAssetNames.length !== 1 ? (
								<Typography variant="body2">Select one shot, asset or timeline to upload a first version.</Typography>
							) : (
								<Typography variant="body2">
									Hey there, it's time to start something great. <br />
									Upload the initial version for <b>{selectedAssetNames}</b>.
								</Typography>
							)}
						</Box>
						<Box sx={{ mb: 3, mt: 3, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
							<LoadingButton
								sx={{ whiteSpace: "nowrap", color: "text.primary", textTransform: "none", display: selectedAssetNames.length !== 1 ? "none" : "inherit" }}
								disabled={selectedAssetNames.length !== 1}
								loading={false}
								color={"secondary"}
								onClick={uploadVersion}
								size={"large"}
								loadingPosition="end"
								variant="contained"
								endIcon={<CloudUploadIcon />}
							>
								Upload files
							</LoadingButton>
							{selectedAssetNames.length === 1 ? (
								<Box fontSize={"0.7em"} sx={{ mt: 0.5, pt: "2px", opacity: 0.5 }}>
									(Filesize limit: <b>100MB</b>)
								</Box>
							) : null}
						</Box>
					</Box>
				</Box>
			) : null}

			<GroupedVirtuoso
				overscan={900}
				style={{ opacity: versionGroupsDebounced?.versions?.length ? 1 : 0, transition: ".5s cubic-bezier(.39,.41,.33,.99)" }}
				groupCounts={versionGroups?.groupCounts || []}
				groupContent={(index) => {
					return (
						<div style={{ width: "100%", display: "flex", padding: 0 }}>
							<Chip sx={{ width: "200px", mr: "auto", ml: "auto", backgroundColor: "secondary.main" }} avatar={<ArrowDropDownIcon />} label={versionGroups?.groups?.[index]} />
						</div>
					);
				}}
				alignToBottom
				ref={virtuosoRef}
				initialTopMostItemIndex={999}
				atBottomStateChange={(bottom) => {
					setAtBottom(bottom);
				}}
				itemSize={(index) => {
					//console.log(index);
					const dataItemIndex = index.getAttribute("data-item-index");
					const dataItemGroupIndex = index.getAttribute("data-item-group-index");
					//console.log(dataItemGroupIndex);

					const versionItem = versionGroups?.versions?.[dataItemIndex || 0];
					const isMarkedDeleted = versionItem?.deleted;

					let sizeToReturn = dataItemGroupIndex === null ? 35 : isMarkedDeleted ? 55 : smMatch ? 390 : 220;
					return sizeToReturn;
				}}
				itemContent={(index) => {
					//console.log(index);
					const versionItem = versionGroups?.versions?.[index];
					const isMarkedDeleted = versionItem?.deleted;

					return (
						<VersionCard
							markDeleted={isMarkedDeleted}
							jobIdent={jobIdent || undefined}
							isJobNameShown={jobIdentShown}
							sx={{ m: 0, p: 0, height: { sm: isMarkedDeleted ? 55 : 390, xs: isMarkedDeleted ? 55 : 220 }, position: "relative" }}
							key={"v_card_" + versionItem?.ref?.id}
							index={"v_card_" + versionItem?.ref?.id}
							asset={versionItem?.asset}
							versionId={versionItem?.ref?.id}
						/>
					);
				}}
				followOutput={"auto"}
			/>

			{showButton && (
				<>
					<IconButton aria-label="fingerprint" color="default" onClick={scrollToBottom} sx={{ borderRadius: "5px", float: "right", transform: "translate(-2rem, -2.5rem)" }}>
						<ArrowCircleDownOutlinedIcon />
					</IconButton>
				</>
			)}
		</Box>
	);
};
