import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import InputAdornment from "@mui/material/InputAdornment";
import Divider from "@mui/material/Divider";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import GitHubIcon from "@mui/icons-material/GitHub";

import { useAuthController, useSnackbarController } from "@camberi/firecms";
import { useNavigate } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useConfirmation } from "./Components/ConfirmationService";

import AutocompleteReferences from "./Components/AutocompleteReferences";
import SwitchPrivate from "./Components/SwitchPrivate";
import TextFieldPlus from "./Components/TextFieldPlus";
import { DeleteDialog } from "./Components/DeleteDialog";

import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";

//keyboard adorment
import { ProjectGenericIcon, AssetIcon, AssetShotIcon, AssetTimelineIcon, JobIcon } from "../../CustomIcons/CustomIcons";

import { useReducerWithCallback } from "../../utils/usehooks-ts";
import { ActionType, ReferenceType, AssetType, ProjectType } from "../../Types/types";
import { isEmpty, diff } from "../../utils/basicFunctions";

const adminCollaborator: ReferenceType = { id: "admin", name: "Admins", fixed_comment: "", type: "group" };

export const TreeViewMenuModalJob = React.forwardRef(function TreeViewMenuModalJob(
	{
		jobs,
	}: {
		jobs: any;
	},
	ref
) {
	const confirmTeam = useConfirmation();
	const deleteDialogRef = useRef<any>();
	const job_frameio_token_old = useRef<any>();
	const defaultValue = {
		action: { target: undefined, value: "create", error: " " },
		job_type: { target: undefined, value: "", error: " " },
		job_id: { target: undefined, value: "", error: " " },
		job_ident: { target: undefined, value: "", error: " " },
		job_title: { target: undefined, value: "", error: " " },
		job_frameio_token: { target: undefined, value: "", error: " " },
		job_frameio_team: { target: undefined, value: "", error: " " },
		job_frameio_reviewpassword: { target: undefined, value: "boost", error: " " },
		job_name: { target: undefined, value: "", error: " " },
		job_client: { target: undefined, value: "", error: " " },
		job_git_link: { target: undefined, value: "", error: " " },
		job_collaborators: { target: undefined, value: [], error: " " },
		job_owner: { target: undefined, values: {}, error: " " },
	};
	const [author, setAuthor] = useState<ReferenceType | boolean>(false);

	// eslint-disable-next-line  @typescript-eslint/no-unused-vars
	const [initialFormInput, setInitialFormInput] = useReducerWithCallback((state: any, newState: any) => ({ ...state, ...newState }), defaultValue);

	const [formInput, setFormInput] = useReducerWithCallback((state: any, newState: any) => ({ ...state, ...newState }), defaultValue);
	/*viewState*/
	const [onPostToDatabase, setOnPostToDatabase] = React.useState(false);

	/*hooks*/
	const snackbarController = useSnackbarController();
	const authController = useAuthController();
	// eslint-disable-next-line  @typescript-eslint/no-unused-vars
	const navigate = useNavigate();

	useEffect(() => {
		if (authController?.user) {
			const authorCollaborator: ReferenceType = { id: authController?.user?.uid || "", name: authController?.user?.email + " " || "", fixed_comment: "Owner" };
			setAuthor(authorCollaborator);
		} else {
			setAuthor(false);
		}
	}, [authController]);

	/*global*/
	const isActionCreate: boolean = formInput?.action?.value === "create";

	const color = "primary";

	const collectionIcon = <JobIcon />;
	const inputFocusOnOpen = useRef<HTMLInputElement>(null);

	//global modal states
	const [open, setOpen] = React.useState(false);
	const [firstTimeHelperExpired, setFirstTimeHelperExpired] = React.useState(!isActionCreate);

	useImperativeHandle(ref, () => ({
		openDialog: (originId: string, action: ActionType) => {
			const job = jobs.find((x: any) => x.id === originId);
			job_frameio_token_old.current = "";

			if (action === "create") {
				setFormInput(
					{
						action: { target: undefined, value: action, error: " " },
					},
					() => {
						setOpen(true);
						setTimeout(() => {
							inputFocusOnOpen?.current?.focus();
						}, 100);
					}
				);
			} else {
				let owner_entry;
				const owner_id = job.values?.creator?.id;
				if (owner_id === (author as ReferenceType)?.id) {
					//self owned
					owner_entry = { id: owner_id, name: (author as ReferenceType)?.name, fixed_comment: "owner" };
				} else {
					//foreign owned
					const owner_values = Object.entries(job?.values?.roles?.details || {}).find(([key, value]: any) => key === owner_id)?.[1] || {};
					owner_entry = { id: owner_id, name: !isEmpty(owner_values) ? (owner_values as any).name : owner_id, fixed_comment: "owner" };
				}

				const user_collaborators = Object.entries(job.values?.roles?.details || {})
					.filter(([key, value]: any) => value.role_paths?.includes("collab_job"))
					.map(([key, value]: any) => ({ id: key, name: value?.name }));

				setFormInput(
					{
						action: { target: undefined, value: "edit", error: " " },
						job_id: { target: undefined, value: job.id, error: " " },
						job_ident: { target: undefined, value: job.values?.ident, error: " " },
						job_title: { target: undefined, value: job.values?.title, error: " " },
						job_client: { target: undefined, value: job.values?.client, error: " " },
						job_name: { target: undefined, value: job.values?.name, error: " " },

						job_frameio_token: { target: undefined, value: job.values?.frameio_token, error: " " },
						job_frameio_team: { target: undefined, value: job.values?.frameio_team, error: " " },

						job_frameio_reviewpassword: { target: undefined, value: job.values?.frameio_reviewpassword, error: " " },
						job_git_link: { target: undefined, value: job.values?.git, error: " " },
						job_collaborators: { target: undefined, value: user_collaborators, error: " " },

						job_owner: { target: undefined, value: owner_entry, error: " " },
					},
					(inputState) => {
						setInitialFormInput({ ...inputState });

						setOpen(true);
						setTimeout(() => {
							inputFocusOnOpen?.current?.focus();
						}, 100);
					}
				);
			}
		},
	}));

	const hasChanged = () => {
		const curInput = Object.fromEntries(Object.entries(formInput || {}).map(([key, value]: any) => [`${key}`, JSON.stringify(value?.value)]));
		const initForm = Object.fromEntries(Object.entries(initialFormInput || {}).map(([key, value]: any) => [`${key}`, JSON.stringify(value?.value)]));

		//console.log("diff", diff(initForm, curInput));
		return Object.keys(diff(initForm, curInput));
	};

	const formIsInvalid = () => {
		return Object.values(formInput).filter((item: any) => item.error !== " ").length > 0 || !formInput.job_ident?.value?.length || !formInput.job_client?.value?.length || !formInput.job_title?.value?.length;
	};

	const handleClose = (forced = false) => {
		if (onPostToDatabase && !forced) return;
		setOpen(false);
		setTimeout(() => {
			setOnPostToDatabase(false);
			setFirstTimeHelperExpired(false);
			setFormInput(defaultValue);
		}, 150);
	};

	//add project owner, project collaborators, forced project owner, forced project collaborator

	//console.log(formInput?.project?.value?.values?.roles?.details);

	const fixed_collaborators: any = [];

	fixed_collaborators.sort((a: any, b: any) => {
		return (
			(b.fixed_comment === "job owner" ? 1 : 0) - (a?.fixed_comment === "job owner" ? 1 : 0) ||
			(b.fixed_comment === "job collaborator" ? 1 : 0) - (a?.fixed_comment === "job collaborator" ? 1 : 0) ||
			(b.fixed_comment === "edit owner" ? 1 : 0) - (a?.fixed_comment === "edit owner" ? 1 : 0) ||
			(b.fixed_comment === "edit collaborator" ? 1 : 0) - (a?.fixed_comment === "edit collaborator" ? 1 : 0) ||
			(b.fixed_comment === "scene owner" ? 1 : 0) - (a?.fixed_comment === "scene owner" ? 1 : 0) ||
			(b.fixed_comment === "scene collaborator" ? 1 : 0) - (a?.fixed_comment === "scene collaborator" ? 1 : 0) ||
			(b.fixed_comment === "project owner" ? 1 : 0) - (a?.fixed_comment === "project owner" ? 1 : 0) ||
			(b.fixed_comment === "project collaborator" ? 1 : 0) - (a?.fixed_comment === "project collaborator" ? 1 : 0) ||
			(b.fixed_comment === "collection owner" ? 1 : 0) - (a?.fixed_comment === "collection owner" ? 1 : 0) ||
			(b.fixed_comment === "collection collaborator" ? 1 : 0) - (a?.fixed_comment === "collection collaborator" ? 1 : 0) ||
			a?.name?.localeCompare(b?.name)
		);
	});

	const handleSubmit = async (evt?: any, data: any = formInput) => {
		evt.preventDefault();

		const error = formIsInvalid();
		if (error) return;

		setOnPostToDatabase(true);
		const job_id = data.job_id.value;
		const job_ident = data.job_ident.value;
		const job_name = data.job_name.value;
		const job_title = data.job_title.value;
		const job_client = data.job_client.value;
		const job_permissions = data.job_collaborators.value.map((user: any) => user.id);
		const job_git_link = data.job_git_link.value;
		const job_frameio_token = data.job_frameio_token.value;
		const job_frameio_team = data.job_frameio_team.value;
		const job_frameio_reviewpassword = data.job_frameio_reviewpassword.value;

		console.log("data_sent", { job_id, job_ident, job_name, job_title, job_client, job_permissions, job_git_link, job_frameio_token, job_frameio_team, job_frameio_reviewpassword });
		let respData;
		try {
			const response = await axios({
				url: `${authController?.extra?.apiUrl}/job/${isActionCreate ? "create" : "edit"}`,
				method: "POST",
				headers: {
					Authorization: `boostToken ${authController?.extra?.user_data?.values?.boost_token}`,
				},
				data: { job_id, job_ident, job_name, job_title, job_client, job_permissions, job_git_link, job_frameio_token, job_frameio_team, job_frameio_reviewpassword },
			});
			respData = await response.data;

			if (respData.success) {
				console.log("respData", respData);
				navigate(`/jobs/${respData.new_job_id}/topology`);
				handleClose(true);

				snackbarController.open({
					type: "success",
					title: "Done",
					message: `Job ${isActionCreate ? "created" : "edited"}`,
				});
			}
		} catch (err: any) {
			setOnPostToDatabase(false);

			const message = typeof err.response?.data?.message !== "undefined" ? err.response.data.message : `Failed to ${isActionCreate ? "creation" : "edit"} project`;

			snackbarController.open({
				type: "error",
				title: `Job ${isActionCreate ? "creation" : "edit"} failed`,
				message: message,
			});
			console.log(err);
		}
	};
	const handleInput = (evt: any) => {
		const name = evt.target.name;
		const newValue = evt.target.value;

		let error = " ";
		switch (name) {
			case "job_title":
				if (!/^[A-Za-z0-9_ ]*$/.test(newValue)) error = "Letters and numbers only.";
				break;
		}

		setFormInput({ [name]: { value: evt.target.value, target: evt.target, error: error } });
	};

	const handleBlur = async (evt: any, submit: boolean = false) => {
		if (!open) return;
		const name = evt.target.name;

		let newValue = evt.target.value;
		let startValue = newValue;

		let error = " ";
		let teams;
		let selected_team: string = "";
		if (name === "job_frameio_token") {
			if (newValue === job_frameio_token_old.current || !isActionCreate) return;
			setOnPostToDatabase(true);
			//get account
			let respData;
			let accountId;
			let teams;
			try {
				const responseTeams = await axios({
					url: `https://api.frame.io/v2/teams`,
					method: "GET",
					headers: {
						Authorization: `Bearer ${newValue}`,
					},
				});
				teams = await responseTeams.data;

				const confirmTeamDialog = await confirmTeam({
					variant: "danger",
					catchOnCancel: true,
					team_list: teams,
					title: "Select Frame.io Team",
					description: "",
				}).catch((e) => {
					setOnPostToDatabase(false);
					job_frameio_token_old.current = "";
					newValue = "";
				});
				job_frameio_token_old.current = newValue;
				selected_team = confirmTeamDialog;
				setOnPostToDatabase(false);
			} catch (e) {
				job_frameio_token_old.current = "";
				setOnPostToDatabase(false);
				newValue = "";
			}
		} else {
			newValue = newValue.trim().toLowerCase();
		}

		switch (name) {
			case "job_ident":
				if (newValue.length !== 5) error = "Must be 5 characters long";
				else if (!/^[a-z]{1}\d{4}$/i.test(newValue)) error = "One letter, four digits: s0001";
				break;
			case "job_client":
				newValue = newValue.replace("_ ", "_").replace(" _", "_").replace(/\s/g, "_").replace(/_+$/, "").replace(/^_/, "");
				if (newValue.length === 0) error = "Input required";
				else if (!/^[A-Za-z0-9_ ]*$/.test(newValue)) error = "Letters and numbers only.";
				break;
			case "job_title":
				newValue = newValue.replace("_ ", "_").replace(" _", "_").replace(/\s/g, "_").replace(/_+$/, "").replace(/^_/, "");
				if (newValue.length === 0) error = "Input required";
				else if (!/^[A-Za-z0-9_ ]*$/.test(newValue)) error = "Letters and numbers only.";
				break;
			case "job_git_link":
				if (!/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm.test(newValue) && newValue !== "") error = "The provided is not valid";
				break;
		}

		console.log(selected_team);
		if (submit) {
			setFormInput(
				{
					[name]: { value: newValue, target: evt.target, error: error },
					...(name === "job_frameio_token" &&
						newValue.length &&
						selected_team.length && {
							job_frameio_team: { value: selected_team, target: undefined, error: " " },
						}),
					...(name === "job_frameio_token" &&
						!newValue.length && {
							job_frameio_team: { value: "", target: undefined, error: " " },
						}),

					...(name === "job_title" &&
						formInput.job_ident.value.length &&
						formInput.job_ident.error === " " &&
						formInput.job_client.value.length &&
						formInput.job_client.error === " " && {
							job_name: { value: newValue.length > 0 ? `${formInput.job_ident.value}_${formInput.job_client.value}_${newValue}` : "", target: undefined, error: " " },
						}),
					...(name === "job_client" &&
						formInput.job_ident.value.length &&
						formInput.job_ident.error === " " &&
						formInput.job_title.value.length &&
						formInput.job_title.error === " " && {
							job_name: { value: newValue.length > 0 ? `${formInput.job_ident.value}_${newValue}_${formInput.job_title.value}` : "", target: undefined, error: " " },
						}),
					...(name === "job_ident" &&
						formInput.job_client.value.length &&
						formInput.job_client.error === " " &&
						formInput.job_title.value.length &&
						formInput.job_title.error === " " && {
							job_name: { value: newValue.length > 0 ? `${newValue}_${formInput.job_client.value}_${formInput.job_title.value}` : "", target: undefined, error: " " },
						}),
				},
				(newState) => {
					handleSubmit(evt, newState);
				}
			);
		} else {
			setFormInput({
				[name]: { value: newValue, target: evt.target, error: error },
				...(name === "job_frameio_token" &&
					newValue.length &&
					selected_team.length && {
						job_frameio_team: { value: selected_team, target: undefined, error: " " },
					}),
				...(name === "job_frameio_token" &&
					!newValue.length && {
						job_frameio_team: { value: "", target: undefined, error: " " },
					}),
				...(name === "job_title" &&
					formInput.job_ident.value.length &&
					formInput.job_ident.error === " " &&
					formInput.job_client.value.length &&
					formInput.job_client.error === " " && {
						job_name: { value: newValue.length > 0 ? `${formInput.job_ident.value}_${formInput.job_client.value}_${newValue}` : "", target: undefined, error: " " },
					}),
				...(name === "job_client" &&
					formInput.job_ident.value.length &&
					formInput.job_ident.error === " " &&
					formInput.job_title.value.length &&
					formInput.job_title.error === " " && {
						job_name: { value: newValue.length > 0 ? `${formInput.job_ident.value}_${newValue}_${formInput.job_title.value}` : "", target: undefined, error: " " },
					}),
				...(name === "job_ident" &&
					formInput.job_client.value.length &&
					formInput.job_client.error === " " &&
					formInput.job_title.value.length &&
					formInput.job_title.error === " " && {
						job_name: { value: newValue.length > 0 ? `${newValue}_${formInput.job_client.value}_${formInput.job_title.value}` : "", target: undefined, error: " " },
					}),
			});
		}
	};

	const addAuthorTag = (references: ReferenceType[]) => {
		return references?.map((ref: ReferenceType) => ({ ...ref, ...(ref.id === (author as ReferenceType)?.id && { name_adornment: " (You)" }) })) || [];
	};

	return (
		<Dialog
			open={open}
			maxWidth={"md"}
			sx={{
				borderRadius: "15px",
				boxShadow: 24,
				outline: "none",
			}}
			PaperProps={{
				sx: { borderRadius: "15px", height: "75%", width: "600px", backgroundColor: "background.paper", backgroundImage: "none" },
			}}
			onClose={(evt) => handleClose(false)}
			scroll={"paper"}
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
		>
			<DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "left", backgroundColor: isActionCreate ? "secondary.main" : "primary.main", color: "text.primary", p: 0, m: 0, height: "60px" }} component="h6">
				<Box sx={{ ml: 2, mr: 2, display: "flex", alignItems: "center" }}>{collectionIcon}</Box>
				{isActionCreate ? `New` : `Edit`}
				{isActionCreate ? ` Job` : ` ${formInput.job_name.value}`}
			</DialogTitle>
			<Divider />
			<DialogContent
				sx={{ overflowY: "scroll", "&::-webkit-scrollbar": { background: "rgba(0,0,0,0)", width: "10px" }, "&::-webkit-scrollbar-thumb": { background: "rgba(125,125,125,1)", borderRadius: "10px" } }}
				onKeyPress={(ev: any) => {
					if (ev.shiftKey && ev.key === "Enter") {
						ev.stopPropagation();
						ev.preventDefault();
						handleBlur(ev, true);
					}
				}}
			>
				<Box
					display={"flex"}
					sx={{
						mt: 2,
						pl: "40px",
						pr: "40px",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Typography variant="h6" sx={{ width: "100%", mb: 1 }}>
						{formInput.job_name?.value ? `Job Name: ` : `Enter Job Title`} <b> {formInput.job_name?.value}</b>
					</Typography>
					<Typography variant="body2" sx={{ width: "100%", mt: -1, mb: 2, opacity: 0.5 }}>
						{`Boost >`}
						{`${formInput?.project_parent?.value?.values ? ` ${formInput?.project_parent?.value?.values?.name} >` : ""}`}
						{formInput.job_name?.value ? ` ${formInput.job_name?.value}` : ` New Job`}
					</Typography>
					<TextFieldPlus
						refinput={inputFocusOnOpen}
						label={`Job Ident`}
						placeholder={"p0001"}
						name="job_ident"
						value={formInput.job_ident?.value}
						helpertext={formInput.job_ident?.error}
						inputprops={{
							readOnly: isActionCreate ? false : true,
							startAdornment: <Box />,
						}}
						disabled={onPostToDatabase}
						onBlur={handleBlur}
						errorvalue={formInput.job_ident?.error !== " " ? true.toString() : undefined}
						onChange={handleInput}
						onKeyDown={(ev: any) => {
							if (ev.key === "Enter" && !ev.shiftKey) {
								ev.stopPropagation();
								ev.preventDefault();
								handleBlur(ev);
							}
						}}
					/>
					<TextFieldPlus
						label={`Client`}
						placeholder={"Client name"}
						name="job_client"
						value={formInput.job_client?.value}
						helpertext={formInput.job_client?.error}
						inputprops={{
							readOnly: isActionCreate ? false : true,
							startAdornment: <Box />,
						}}
						disabled={onPostToDatabase}
						onBlur={handleBlur}
						errorvalue={formInput.job_client?.error !== " " ? true.toString() : undefined}
						onChange={handleInput}
						onKeyDown={(ev: any) => {
							if (ev.key === "Enter" && !ev.shiftKey) {
								ev.stopPropagation();
								ev.preventDefault();
								handleBlur(ev);
							}
						}}
					/>
					<TextFieldPlus
						label={`Title`}
						placeholder={"Job title"}
						name="job_title"
						value={formInput.job_title?.value}
						helpertext={formInput.job_title?.error}
						inputprops={{
							readOnly: isActionCreate ? false : true,
							startAdornment: <Box />,
						}}
						disabled={onPostToDatabase}
						onBlur={handleBlur}
						errorvalue={formInput.job_title?.error !== " " ? true.toString() : undefined}
						onChange={handleInput}
						onKeyDown={(ev: any) => {
							if (ev.key === "Enter" && !ev.shiftKey) {
								ev.stopPropagation();
								ev.preventDefault();
								handleBlur(ev);
							}
						}}
					/>

					<Typography sx={{ width: "100%", mb: 1, mt: 1 }}>{`Job`} Settings</Typography>

					<AutocompleteReferences
						name="job_collaborators"
						label="Permissions"
						placeholder={`Add Job colloborators`}
						noOptionsText={"Collaborator not found"}
						color={color}
						options={addAuthorTag([
							adminCollaborator,
							...(!isActionCreate && !isEmpty(formInput?.job_owner?.value || {}) ? [formInput?.job_owner?.value] : []),
							...(isActionCreate && author ? [author] : []),
							...(authController?.extra?.user_collaborators ? authController?.extra?.user_collaborators : []),
						]).sort((a: ReferenceType, b: ReferenceType) => a?.name?.localeCompare(b?.name))}
						fixedOptions={addAuthorTag([adminCollaborator, ...(!isActionCreate && !isEmpty(formInput?.job_owner?.value || {}) ? [formInput.job_owner.value] : []), ...(isActionCreate && author ? [author] : []), ...fixed_collaborators])}
						selectedOptions={addAuthorTag(formInput.job_collaborators?.value)}
						setSelectedOptions={handleInput}
						sx={{ width: "100%", marginTop: "15px" }}
						avatar={<PersonIcon sx={{ opacity: 0.5 }} />}
						groupAvatar={<GroupIcon sx={{ opacity: 0.5 }} />}
					/>
					<TextFieldPlus
						sx={{ mt: 2, width: "100%" }}
						label={"Frame.io Custom Token"}
						placeholder={""}
						name="job_frameio_token"
						value={formInput.job_frameio_token?.value}
						helpertext={formInput.job_frameio_token?.error}
						errorvalue={formInput.job_frameio_token?.error !== " " ? true.toString() : undefined}
						inputprops={{
							readOnly: isActionCreate ? false : true,
							startAdornment: <Box />,
						}}
						ispassword={"true"}
						disabled={onPostToDatabase}
						onBlur={handleBlur}
						onChange={handleInput}
						onKeyDown={(ev: any) => {
							if (ev.key === "Enter" && !ev.shiftKey) {
								ev.stopPropagation();
								ev.preventDefault();
								handleBlur(ev);
							}
						}}
					/>
					<TextFieldPlus
						sx={{ width: "100%" }}
						label={"Frame.io Custom Token Team"}
						placeholder={""}
						name="job_frameio_team"
						value={formInput.job_frameio_team?.value}
						helpertext={" "}
						//errorvalue={false.toString()}
						inputprops={{
							readOnly: true,
							startAdornment: <Box />,
						}}
						ispassword={"true"}
						disabled={onPostToDatabase}
						onKeyDown={(ev: any) => {
							if (ev.key === "Enter" && !ev.shiftKey) {
								ev.stopPropagation();
								ev.preventDefault();
								handleBlur(ev);
							}
						}}
					/>
					<TextFieldPlus
						label={"Frame.io Default Review Password"}
						placeholder={"password"}
						name="job_frameio_reviewpassword"
						value={formInput.job_frameio_reviewpassword?.value}
						helpertext={formInput.job_frameio_reviewpassword?.error}
						errorvalue={formInput.job_frameio_reviewpassword?.error !== " " ? true.toString() : undefined}
						inputprops={{
							readOnly: isActionCreate ? false : true,
							startAdornment: <Box />,
						}}
						ispassword={"true"}
						disabled={onPostToDatabase}
						onBlur={handleBlur}
						onChange={handleInput}
						onKeyDown={(ev: any) => {
							if (ev.key === "Enter" && !ev.shiftKey) {
								ev.stopPropagation();
								ev.preventDefault();
								handleBlur(ev);
							}
						}}
					/>
					<TextFieldPlus
						label="Git Link"
						placeholder={""}
						name="job_git_link"
						value={formInput.job_git_link?.value}
						helpertext={formInput.job_git_link?.error}
						errorvalue={formInput.job_git_link?.error !== " " ? true.toString() : undefined}
						inputprops={{
							readOnly: false,
							startAdornment: (
								<InputAdornment position="start">
									<GitHubIcon />
								</InputAdornment>
							),
						}}
						disabled={onPostToDatabase}
						onBlur={handleBlur}
						onChange={handleInput}
						onKeyDown={(ev: any) => {
							if (ev.key === "Enter" && !ev.shiftKey) {
								ev.stopPropagation();
								ev.preventDefault();
								handleBlur(ev);
							}
						}}
					/>

					{isActionCreate ? null : (
						<Box sx={{ pl: 2, pr: 2, display: "flex", justifyContent: "center" }}>
							<LoadingButton
								disabled={false}
								sx={{ width: "100%", whiteSpace: "nowrap", ml: "auto", mr: "auto", color: "rgba(255,255,255,.5)", textTransform: "none" }}
								color="error"
								size={"medium"}
								variant="outlined"
								onClick={() => {
									deleteDialogRef.current?.openDialog();
								}}
							>
								{`Delete Job`}
							</LoadingButton>
						</Box>
					)}
				</Box>
			</DialogContent>
			<Divider />
			<DialogActions sx={{ display: "flex", flexDirection: "column", height: "85px" }}>
				<LoadingButton
					disabled={!hasChanged().length || formIsInvalid()}
					sx={{ whiteSpace: "nowrap", color: "text.primary", textTransform: "none", pl: 10, pr: 10 }}
					loading={onPostToDatabase}
					color={isActionCreate ? "secondary" : "primary"}
					onClick={handleSubmit}
					size={"large"}
					loadingPosition="end"
					variant="contained"
					endIcon={<CloudUploadIcon />}
				>
					{!onPostToDatabase ? (isActionCreate ? `Create Job` : "Save Changes") : isActionCreate ? `Creating Job...` : "Saving Changes..."}
				</LoadingButton>
				<Box fontSize={"0.7em"} sx={{ pt: "2px", opacity: 0.5 }}>
					<b>Shift + Return</b> to {isActionCreate ? " create" : " save"} {` job`}
				</Box>
			</DialogActions>
			<DeleteDialog ref={deleteDialogRef} onCloseCallback={() => handleClose(true)} itemToDelete={{ job_ident: formInput.job_ident?.value, item_name: formInput.job_name?.value, item_id: formInput.job_id?.value, item_type: "job" }} />
		</Dialog>
	);
});
