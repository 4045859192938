import React, { useRef } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import YouTubeIcon from "@mui/icons-material/YouTube";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import Divider from "@mui/material/Divider";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import { DeleteDialog } from "./TreeView/Components/DeleteDialog";

export default function VersionMenu({ single_link, all_link, single_password, all_password, version, version_name, version_id, job_ident, version_number_string, fio_asset_ids, sx }: any) {
	const deleteDialogRef = useRef<any>();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const [showTooltip, setShowTooltip] = React.useState<boolean>(false);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const openInNewTab = (url: string) => {
		const openLink = () => {
			window.open(url, "_blank", "noopener,noreferrer");
			handleClose();
		};

		navigator.clipboard.writeText(single_password).then(
			() => {
				openLink();
			},
			() => {
				openLink();
			},
		);
	};

	const onCopy = (event: any) => {
		event.stopPropagation();
		event.preventDefault();

		//compile file names
		const all_filenames: any = [];
		Object.values(fio_asset_ids || {})?.forEach((fio_asset: any) => {
			all_filenames.push(fio_asset.file_name);
		});

		if (!(single_password && single_link)) return;

		const originalText = `Reviewlink '${version_name}'\n\nFiles: ${all_filenames.join(", ")}\n\nLink: ${single_link}\nPassword: ${single_password}`;
		const htmlText = `<h3 style="display:inline;">Reviewlink '${version_name}'</h3></br><b>Files:</b> ${all_filenames.join(", ")}</br></br><b>Link:</b> ${single_link}</br><b>Password:</b> ${single_password}`;
		const blobHtml = new Blob([htmlText], { type: "text/html" });
		const blobText = new Blob([originalText], { type: "text/plain" });
		const data = [
			new ClipboardItem({
				["text/plain"]: blobText,
				["text/html"]: blobHtml,
			}),
		];

		navigator.clipboard.write(data).then(
			() => {
				setShowTooltip(true);
			},
			() => {},
		);
	};

	const handleOnTooltipClose = () => {
		setShowTooltip(false);
	};

	return typeof single_link === "undefined" && typeof all_link === "undefined" ? null : (
		<Box sx={{ ...sx, typography: "body2" }}>
			<DeleteDialog ref={deleteDialogRef} onCloseCallback={handleClose} itemToDelete={{ job_ident: job_ident, item_name: version_name, item_id: version_id, item_type: "version" }} />
			<IconButton size="small" disableFocusRipple={false} id="basic-button" aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				{typeof single_link === "undefined" ? null : (
					<Tooltip title={`Open "${version_name}" and copy password`} placement="top" arrow>
						<MenuItem sx={{ mt: 0 }} dense onClick={() => openInNewTab(single_link)}>
							<ListItemIcon>
								<YouTubeIcon />
							</ListItemIcon>
							<ListItemText>Version {version_number_string}</ListItemText>
							<Tooltip open={showTooltip} title={"Copied to clipboard!"} leaveDelay={1500} onClose={handleOnTooltipClose}>
								<IconButton sx={{ ml: 1 }} onClick={onCopy}>
									<ContentCopyIcon sx={{ height: "12px", width: "12px" }} fontSize="inherit" />
								</IconButton>
							</Tooltip>
						</MenuItem>
					</Tooltip>
				)}
				{typeof all_link === "undefined" ? null : (
					<MenuItem sx={{ mt: 0 }} dense onClick={() => openInNewTab(all_link)}>
						<ListItemIcon>
							<SubscriptionsIcon />
						</ListItemIcon>
						<ListItemText>All Versions</ListItemText>
					</MenuItem>
				)}
				<MenuItem disabled>
					<Divider sx={{ p: 0, height: "2px", width: "100%", background: "rgba(255,255,255,0.25)", zIndex: 1 }} />
				</MenuItem>
				<MenuItem sx={{ color: "error.main", mt: 0, opacity: 0.5 }} dense onClick={deleteDialogRef.current?.openDialog}>
					<ListItemIcon sx={{ color: "error.main" }}>
						<LayersClearIcon />
					</ListItemIcon>
					<ListItemText>Mark Deleted</ListItemText>
				</MenuItem>
			</Menu>
		</Box>
	);
}
