import * as React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";

import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";

import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";

export interface ConfirmationOptions {
  catchOnCancel?: boolean;
  variant: "danger" | "info";
  title: string;
  team_list: any[];
  description: string;
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean;
  onSubmit: (value: any) => void;
  onClose: () => void;
}

const handleListItemClick = (value: string) => {
  console.log(value);
};

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, title, variant, description, onSubmit, onClose, team_list }) => {
  return (
    <Dialog
      PaperProps={{
        sx: { borderRadius: "15px", width: "400px", backgroundColor: "background.paper", backgroundImage: "none" },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="alert-dialog-title" sx={{ display: "flex", alignItems: "center", justifyContent: "left", backgroundColor: "secondary.main", color: "text.primary", p: 3, m: 0, height: "60px" }} component="h6">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {team_list?.map((team) => (
          <ListItem sx={{ borderRadius: "15px", background: "rgba(255,255,255,.1)" }} button onClick={() => onSubmit(team.id)} key={team.id}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={team.name} />
          </ListItem>
        ))}

        {/*    {variant === "danger" && (
          <>
            <Button color="primary" onClick={onSubmit}>
              Yes, I agree
            </Button>
            <Button color="primary" onClick={onClose} autoFocus>
              CANCEL
            </Button>
          </>
        )}

        {variant === "info" && (
          <Button color="primary" onClick={onSubmit}>
            OK
          </Button>
        )}*/}
      </DialogActions>
    </Dialog>
  );
};
