import React from "react";

/*mui*/
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import Popper from "@mui/material/Popper";
import Box from "@mui/material/Box";

/*mui styles*/
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";

/*types*/
import { ReferenceType } from "../../../Types/types";
import { SxProps } from "@mui/system";

/*icon*/
import BlockIcon from "@mui/icons-material/Block";

import Tooltip from "@mui/material/Tooltip";

declare module "@mui/system" {
	interface DefaultTheme extends Theme {}
}

const useStylesPrimary = makeStyles((theme) => ({
	////....
	option: {
		// Hover

		'&[data-focus="true"]': {
			backgroundColor: `${theme.palette.primary.main}!important`,
			borderColor: "transparent",
		},
		// Selected

		'&[aria-selected="true"]': {
			backgroundColor: `${theme.palette.primary.dark}!important`,
			borderColor: "transparent",
			"&:hover": {
				backgroundColor: `${theme.palette.primary.main}!important`,
			},
		},
	},
}));

const useStylesSecondary = makeStyles((theme) => ({
	////....
	option: {
		// Hover

		'&[data-focus="true"]': {
			backgroundColor: `${theme.palette.secondary.main}!important`,
			borderColor: "transparent",
		},
		// Selected

		'&[aria-selected="true"]': {
			backgroundColor: `${theme.palette.secondary.dark}!important`,
			borderColor: "transparent",
			"&:hover": {
				backgroundColor: `${theme.palette.secondary.main}!important`,
			},
			"&::M:": {
				backgroundColor: `${theme.palette.secondary.main}!important`,
			},
			"&.Mui-focusVisible": {
				backgroundColor: `${theme.palette.secondary.main}!important`,
			},
		},
	},
}));

export default function AutocompleteReferences(props: any) {
	const {
		sx,
		color,
		selectedOptions,
		setSelectedOptions,
		options,
		loading = false,
		fixedOptions = [],
		noOptionsText = "No options",
		name,
		label,
		placeholder,
		disabled,
		avatar,
		groupAvatar,
		keepfixed,
	}: {
		sx: SxProps;
		color: any;
		selectedOptions: ReferenceType[];
		options: ReferenceType[];
		fixedOptions?: ReferenceType[];
		loading?: boolean;
		setSelectedOptions: any;
		noOptionsText?: string;
		name: string;
		other: any;
		label: string;
		placeholder: string;
		disabled: boolean;
		avatar?: any;
		groupAvatar?: any;
		keepfixed?: boolean;
	} = props;
	const CustomerPopper = (props: any) => {
		const anchorEl = document.getElementById(name);

		return <Popper {...props} anchorEl={anchorEl} popperOptions={{ placement: "bottom-start" }} sx={{ pr: "15px" }} />;
	};

	const theme = useTheme();

	const stylesSecondary = useStylesSecondary();
	const stylesPrimary = useStylesPrimary();

	return (
		<Autocomplete
			PopperComponent={(props) => <CustomerPopper {...props} />}
			readOnly={disabled}
			onChange={(event, newValue) => {
				setSelectedOptions({ target: { name: name, value: [...newValue.filter((option) => fixedOptions.indexOf(option) === -1)] } });
			}}
			sx={{
				...sx,
				"&::-webkit-scrollbar": { background: "rgba(0,0,0,0)", width: "10px" },
				"&::-webkit-scrollbar-thumb": { background: "rgba(125,125,125,1)", borderRadius: "10px" },
				[`& .${autocompleteClasses.popupIndicator}`]: {
					...(disabled && { display: "none" }),
				},

				"& .MuiInputBase-input.MuiAutocomplete-input": {
					...(disabled && options.length > 0 && { display: "none" }),
					flexWrap: "wrap",
					...(!disabled && { order: -1 }),
					width: "100%",
				},
			}}
			getOptionDisabled={(option) => option?.disabled || false}
			disableClearable
			disabled={disabled || loading}
			multiple
			loading={loading}
			id={name}
			value={[...fixedOptions, ...selectedOptions]}
			noOptionsText={noOptionsText}
			options={[...options]}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			getOptionLabel={(option) => option.name}
			renderOption={(props: any, option: any) => {
				const alreadyInView = fixedOptions.filter((x) => x.id === option.id).length > 0 || selectedOptions.filter((x) => x.id === option.id).length > 0;
				const text = option?.tooltip?.length ? <div style={{ whiteSpace: "pre-line" }}>{option?.tooltip}</div> : "";
				return alreadyInView ? null : (
					<Tooltip title={text || ""} style={{ cursor: "grab!important" }}>
						<span>
							<li {...props} key={option.id}>
								<Box sx={{ fontWeight: "bold", display: "flex" }}>{option?.disabled ? <BlockIcon sx={{ ml: 0.5, mr: 0.5 }} /> : null}</Box>

								{option.name}

								<Box sx={{ fontWeight: "bold", ml: 0.5, display: "flex" }}>{typeof option.name_adornment !== "undefined" ? ` ${option.name_adornment}` : null}</Box>
							</li>
						</span>
					</Tooltip>
				);
			}}
			renderTags={(tagValue, getTagProps) =>
				tagValue.map((option, index) => (
					<Chip
						avatar={avatar ? <Avatar>{option.type === "group" ? groupAvatar : avatar} </Avatar> : undefined}
						title={option.name}
						color={disabled ? undefined : color}
						sx={{ color: disabled ? "inherit" : `${theme.palette.text.primary}` }}
						label={
							<div style={{ display: "flex" }}>
								{`${option.name} `} {typeof option.name_adornment !== "undefined" ? ` ${option.name_adornment}` : null}
								{typeof option.fixed_comment !== "undefined" ? <Box sx={{ pl: 1, fontWeight: "bold", textTransform: "capitalize" }}> {option.fixed_comment}</Box> : null}
							</div>
						}
						{...getTagProps({ index })}
						deleteIcon={fixedOptions.indexOf(option) !== -1 || option.disabled ? <div /> : undefined}
					/>
				))
			}
			renderInput={(params) => (
				<TextField
					color={color}
					{...params}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{loading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
					label={label}
					placeholder={placeholder}
				/>
			)}
		/>
	);
}
