import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import LayersIcon from "@mui/icons-material/Layers";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
export default function CollaborationMenu({ asset }: { asset: any }) {
	const isAsset = asset?.path === "assets" || false;
	const isJob = asset?.path === "jobs" || false;
	const assetId = asset?.id || "";
	const assetName = asset?.values?.name || "";
	const assetJobId = asset?.values?.job?.id || "";
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const defaultPrevent = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
	};
	const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
		defaultPrevent(event);
		setAnchorEl(null);
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		defaultPrevent(event);
		setAnchorEl(event.currentTarget);
	};

	const open = Boolean(anchorEl);
	let navigate = useNavigate();
	const theme = useTheme();
	return (
		<div>
			<IconButton onClick={handleClick}>
				<MoreVertIcon sx={{ cursor: "pointer" }} />
			</IconButton>
			<Menu
				onClick={defaultPrevent}
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
				PaperProps={{
					style: {
						left: "50%",
						transform: "translateY(10px)",
					},
				}}
			>
				<MenuItem sx={{ background: `${theme.palette.secondary.main}!important`, opacity: "1!important", pl: 2.5, pt: 1, pb: 1, m: 0, mt: -1 }} dense disabled>
					<ListItemText>Create Project</ListItemText>
				</MenuItem>
				<MenuItem
					sx={{ mt: 0.75 }}
					//sx={{ background: `${theme.palette.secondary.main}!important`, opacity: "1!important", pl: 2.5, pt: 1, pb: 1, m: 0, mt: -1 }}
					dense
					onClick={() => {
						if (!isJob) {
							navigate(`/jobs/${assetJobId}/versions/${assetName}`);
						} else {
							navigate(`/jobs/${asset.id}/versions`);
						}
					}}
				>
					<ListItemIcon>
						<LayersIcon fontSize="small" />
					</ListItemIcon>

					<ListItemText>Open version history</ListItemText>
				</MenuItem>
			</Menu>
		</div>
	);
}
