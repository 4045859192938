import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export default function PersonalDashboardSkeleton() {
	return (
		<Box>
			<Skeleton height={300} animation="wave" />
			<Skeleton height={50} animation="wave" />
			<Skeleton height={50} animation="wave" />
		</Box>
	);
}
