import React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import FolderIcon from "@mui/icons-material/Folder";
import TagIcon from "@mui/icons-material/Tag";
import RectIcon from "../../img/rectangle-2.svg";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import CollaborationMenu from "./CollaborationMenu";
import PermissionListPreviewImage from "../../Components/PermissionListPreviewImage";
import { format, isValid, compareDesc, isToday, isYesterday } from "date-fns";

import { useTheme } from "@mui/material";

const styles = {
	version_overlay: {
		display: "inline-flex",
		ml: "10px",
		pr: "5px",
		pl: "5px",
		background: "rgba(0,0,0,.5)",
		borderRadius: "5px",
	},
};
interface PermissionData {
	type: string;
	name: string;
	img: string;
	job: string;
	created_on: Date;
	last_mod: Date;
	role: string;
	raw: any;
	id: string;
}

function createPermissionData(id: string, img: string, name: string, type: string, job: string, created_on: Date, last_mod: Date, role: string, raw: any): PermissionData {
	return {
		name,
		type,
		img,
		job,
		created_on,
		last_mod,
		role,
		raw,
		id,
	};
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (orderBy === "created_on" || orderBy === "last_mod") {
		//isDate
		//console.log(isValid(a[orderBy]));

		const aValid = isValid(a[orderBy]);
		const bValid = isValid(b[orderBy]);

		if (aValid && bValid) {
			return compareDesc(a[orderBy] as Date, b[orderBy] as Date);
		} else if (aValid) {
			return -1;
		} else if (bValid) {
			return 1;
		}
		return 0;
	} else {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (a: { [key in Key]: number | string | Date }, b: { [key in Key]: number | string | Date }) => number {
	return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
	disablePadding: boolean;
	id: keyof PermissionData;
	label: string;
	numeric: boolean;
}

const headCells: readonly HeadCell[] = [
	{
		id: "type",
		numeric: false,
		disablePadding: true,
		label: "Type",
	},
	{
		id: "img",
		numeric: false,
		disablePadding: true,
		label: "Img",
	},
	{
		id: "name",
		numeric: false,
		disablePadding: true,
		label: "Name",
	},

	{
		id: "job",
		numeric: false,
		disablePadding: true,
		label: "Job",
	},
	{
		id: "created_on",
		numeric: true,
		disablePadding: true,
		label: "Creation Date",
	},
	{
		id: "last_mod",
		numeric: true,
		disablePadding: true,
		label: "Lastest Version",
	},
	{
		id: "role",
		numeric: false,
		disablePadding: true,
		label: "Role",
	},
];

interface EnhancedTableProps {
	numSelected: number;
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof PermissionData) => void;
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

	const createSortHandler = (property: keyof PermissionData) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell, index) => {
					if (headCell.id === "img") {
						return <TableCell key={"img"} align={"left"} padding={"none"}></TableCell>;
					} else {
						return (
							<TableCell key={headCell.id} align={"left"} padding={"none"} sortDirection={orderBy === headCell.id ? order : false}>
								<TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)} /*sx={{ color: "red", "&:hover": { color: "red!important" } }}*/>
									{headCell.label}
									{orderBy === headCell.id ? (
										<Box component="span" sx={visuallyHidden}>
											{order === "desc" ? "sorted descending" : "sorted ascending"}
										</Box>
									) : null}
								</TableSortLabel>
							</TableCell>
						);
					}
				})}
				<TableCell key={"action"} align={"right"} padding={"none"}></TableCell>
			</TableRow>
		</TableHead>
	);
}

interface EnhancedTableToolbarProps {
	numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
	const { numSelected } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
				}),
			}}
		>
			{numSelected > 0 ? (
				<Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
					{numSelected} selected
				</Typography>
			) : (
				<Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
					Nutrition
				</Typography>
			)}
			{numSelected > 0 ? (
				<Tooltip title="Delete">
					<IconButton>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title="Filter list">
					<IconButton>
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	);
};

export default function PermissionList({ userUUID, jobs, assets, projects }: any) {
	const [order, setOrder] = React.useState<Order>("desc");
	const [orderBy, setOrderBy] = React.useState<keyof PermissionData>("last_mod");
	const [selected, setSelected] = React.useState<readonly string[]>([]);
	const [page, setPage] = React.useState(0);
	const [dense, setDense] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [filter, setFilter] = React.useState("assets");

	const handleChange = (event: SelectChangeEvent) => {
		setFilter(event.target.value as string);
	};

	let rows: any[] = [];
	switch (filter) {
		case "all":
			jobs?.forEach((job: any) => {
				rows.push(createPermissionData(job.id, "imgsource", job.values?.name, "Job", job?.values?.ident, job.values?.created_on || undefined, job.values?.latest_version?.created_on || undefined, job.values?.roles?.details?.[userUUID].role, job));
			});

			assets?.forEach((asset: any) => {
				rows.push(
					createPermissionData(
						asset.id,
						"imgsource",
						asset.values?.name,
						"Asset",
						jobs.find((job: any) => job.id === asset?.values?.job?.id)?.values?.ident,
						asset.values?.created_on || undefined,
						asset.values?.latest_version?.created_on || undefined,
						asset.values?.roles?.details?.[userUUID]?.role || undefined,
						asset
					)
				);
			});

			projects?.forEach((project: any) => {
				rows.push(
					createPermissionData(
						project.id,
						"imgsource",
						project.values?.name,
						"Project",
						jobs.find((job: any) => job.id === project.values?.job?.id)?.values?.ident,
						project.values?.created_on || undefined,
						project.values?.latest_version?.created_on || undefined,
						project.values?.roles?.details?.[userUUID]?.role || undefined,
						project
					)
				);
			});
			break;
		case "jobs":
			jobs?.forEach((job: any) => {
				rows.push(createPermissionData(job.id, "imgsource", job.values?.name, "Job", job.values?.name, job.values?.created_on || undefined, job.values?.latest_version?.created_on || undefined, job.values?.roles?.details?.[userUUID]?.role, job));
			});
			break;
		case "projects":
			projects?.forEach((project: any) => {
				rows.push(
					createPermissionData(
						project.id,
						"imgsource",
						project.values?.name,
						"Project",
						jobs.find((job: any) => job.id === project.values?.job?.id)?.values?.ident,
						project.values?.created_on || undefined,
						project.values?.latest_version?.created_on || undefined,
						project.values?.roles?.details?.[userUUID]?.role || undefined,
						project
					)
				);
			});
			break;
		case "assets":
			assets?.forEach((asset: any) => {
				// console.log(asset.values?.job?.id);
				// console.log(jobs.find((job: any) => job.id === asset.values?.job?.id)?.values?.ident);
				rows.push(
					createPermissionData(
						asset.id,
						"imgsource",
						asset.values?.name,
						"Asset",
						jobs.find((job: any) => job.id === asset.values?.job?.id)?.values?.ident,
						asset.values?.created_on || undefined,
						asset.values?.latest_version?.created_on || undefined,
						asset.values?.roles?.details?.[userUUID]?.role || undefined,
						asset
					)
				);
			});
			break;
	}
	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof PermissionData) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = rows.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected: readonly string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDense(event.target.checked);
	};

	const isSelected = (name: string) => selected.indexOf(name) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = Math.max(0, (1 + page) * rowsPerPage - rows.length);

	const iconSwitch = (param: any) => {
		switch (param) {
			case "Project":
				return <FolderIcon />;
			case "Asset":
				return <TagIcon />;
			case "Job":
				return <img src={RectIcon} height={24} width={24} />;
			default:
				return null;
		}
	};
	const theme = useTheme();

	const DateToDisplay = (inputDate: Date, withTime: boolean = false) => {
		if (isToday(inputDate)) {
			return `Today ${withTime ? format(inputDate, " 'at' kk':'mm") : ""}`;
		} else if (isYesterday(inputDate)) {
			return `Yesterday ${withTime ? format(inputDate, " 'at' kk':'mm") : ""}`;
		} else {
			return `${format(inputDate, "dd'.' MMM yyyy")} ${withTime ? format(inputDate, " 'at' kk':'mm") : ""}`;
		}
	};
	const capitalize = ([first, ...rest]: any, lowerRest = false) => first.toUpperCase() + (lowerRest ? rest.join("").toLowerCase() : rest.join(""));

	const pageRows = rows
		.slice()
		.sort(getComparator(order, orderBy))
		.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	return (
		<Box>
			<Box display="flex" sx={{ left: 0 }}>
				<FormControl sx={{ width: { xs: "100%", sm: "50%", xl: "25%" } }} focused>
					<InputLabel id="demo-simple-select-label" color={"secondary"}>
						Filter
					</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={filter}
						color={"secondary"}
						label="Filter"
						onChange={handleChange}
						MenuProps={{
							disableScrollLock: true,
							sx: {
								"&& .Mui-selected": {
									backgroundColor: `${theme.palette.secondary.main}`,
								},
							},
						}}
					>
						<MenuItem value={"all"} color={"secondary"}>
							All
						</MenuItem>
						<MenuItem value={"jobs"}>Jobs</MenuItem>
						<MenuItem value={"projects"}>Projects</MenuItem>
						<MenuItem value={"assets"}>Assets</MenuItem>
					</Select>
				</FormControl>
			</Box>
			{/*<EnhancedTableToolbar numSelected={selected.length} />*/}
			<TableContainer>
				<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"} padding={"none"}>
					<EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={rows.length} />
					<TableBody>
						{pageRows.map((row, index) => {
							const isItemSelected = isSelected(row.name as string) || false;
							const labelId = `enhanced-table-checkbox-${index}`;
							const filterdVersions = row.raw?.values?.versions?.filter((v: any) => !v.deleted) || [];
							const latestVersion = filterdVersions[filterdVersions.length - 1] || undefined;
							const latestVersionID = latestVersion?.ref?.id || undefined;
							const version_number = row.raw?.values?.versions?.indexOf(latestVersion) + 1;
							return (
								<TableRow hover onClick={(event) => handleClick(event, row.name as string)} tabIndex={-1} key={row.id}>
									<TableCell align="left" width={"50px"}>
										<Tooltip title={row.type} placement="left">
											<Box style={{ paddingLeft: 5, paddingRight: 5, margin: 0 }}>
												<IconButton disableRipple>{iconSwitch(row.type)}</IconButton>
											</Box>
										</Tooltip>
									</TableCell>
									<TableCell align="left" width={"100px"}>
										{latestVersionID ? <PermissionListPreviewImage versionId={latestVersionID || undefined} /> : null}
									</TableCell>

									<TableCell align="left" width={"200px"}>
										{row.name}
									</TableCell>

									<TableCell align="left">{row.job}</TableCell>
									<TableCell align="left">
										<Box display="inline-flex">{row.created_on ? DateToDisplay(row.created_on, true) : ""}</Box>
									</TableCell>
									<TableCell align="left">
										<Box>
											{latestVersion?.created_on ? `${DateToDisplay(latestVersion?.created_on, true)}` : ""}
											{version_number > 0 ? (
												<Box sx={styles.version_overlay}>
													<Typography variant="body2" color="text.primary" align={"center"}>
														{`v${version_number}`}
													</Typography>
												</Box>
											) : null}
										</Box>
									</TableCell>
									<TableCell align="left">{row.role ? capitalize(row.role) : ""}</TableCell>
									<TableCell align="right">
										<CollaborationMenu asset={row.raw} />
										{/*			<Tooltip title={row.type} placement="right">
																				<IconButton style={{ paddingLeft: 5, paddingRight: 5, margin: 0, width: "24px", height: "24px" }}>
																					<MoreVertIcon />
																				</IconButton>
																			</Tooltip>*/}
									</TableCell>
								</TableRow>
							);
						})}
						{emptyRows > 0 && (
							<TableRow
								style={{
									height: 72 * emptyRows,
								}}
							>
								<TableCell colSpan={5} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination rowsPerPageOptions={[5, 10, 25]} component="div" count={rows.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
		</Box>
	);
}
