import React, { ReactElement, useState, useRef, useImperativeHandle } from "react";
import axios from "axios";
import { useAuthController, useStorageSource, useSnackbarController } from "@camberi/firecms";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Backdrop from "@mui/material/Backdrop";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { alpha } from "@mui/material/styles";
import { TextField } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { ButtonProps } from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { purple } from "@mui/material/colors";

import { capitalizeWords } from "../utils/basicFunctions";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "left",
	paddingLeft: "15px",
	color: theme.palette.text.secondary,
}));
const Search = styled("div")(({ theme }) => ({
	position: "relative",
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	"&:hover": {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	marginRight: theme.spacing(2),
	marginLeft: 1,

	[theme.breakpoints.up("sm")]: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
}));

const ColorButton = styled(LoadingButton)<ButtonProps>(({ theme }) => ({
	color: theme.palette.getContrastText(purple[500]),
	backgroundColor: purple[500],
	"&:hover": {
		backgroundColor: purple[700],
	},
}));

export const VersionUploader = React.forwardRef(function VersionUploader(
	{
		assetsFiltered,
		setForceRefresh,
	}: {
		assetsFiltered: any;
		setForceRefresh: any;
	},
	ref
) {
	const [files, setFiles] = React.useState<File[]>([]);

	const [onUpload, setOnUpload] = useState(false);
	const storage = useStorageSource();

	const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {};

	const handleClose = () => {
		/*setFiles([]);*/
	};

	const putStorageItem = (file: File, storagePath: string = "") => {
		return new Promise(function (resolve, reject) {
			const metadata = "";
			const fileName = file.name;
			// the return value will be a Promise

			return storage
				.uploadFile({
					file,
					fileName,
					path: storagePath,
					metadata,
				})
				.then(async (data) => {
					resolve({ file: file, data: data });
				})
				.catch((err) => {
					reject({ file: file, data: err });
				});
		});
	};

	const inputFile = useRef<HTMLInputElement | null>(null);
	const commentField = useRef<HTMLInputElement | null>(null);

	const authController = useAuthController();

	const onButtonClick = () => {
		// `current` points to the mounted file input element
		//inputFile.click();
		if (inputFile.current != null) {
			inputFile.current.click();
		}
	};

	useImperativeHandle(ref, () => ({
		openDialog: () => {
			onButtonClick();
		},
	}));

	const handleRemoveFromFiles = (Index: File) => {
		const indexToRemove = files.indexOf(Index);
		let copy = files;
		if (indexToRemove > -1) {
			// only splice files when item is found
			copy.splice(indexToRemove, 1); // 2nd parameter means remove one item only
		}
		setFiles(copy);
		setForceRefresh(new Date());
	};

	const requestNewVersion = async () => {
		/*		console.log(
			"testmap",
			files.map((file) => [file.name, file.type])
		);*/

		const asset_id: string = assetsFiltered[0].id,
			announced_filecount: number = files.length,
			announced_files: any = files.map((file) => [file.name, file.type]),
			commit_message: string = typeof commentField.current?.value === "undefined" ? "" : commentField.current?.value === null ? "" : commentField.current?.value,
			origin: string = "webapp";

		setOnUpload(true);
		let respData;
		try {
			const response = await axios({
				url: `${authController?.extra?.apiUrl}/version/create`,
				method: "POST",
				headers: {
					Authorization: `boostToken ${authController?.extra?.user_data?.values?.boost_token}`,
				},
				data: { asset_id, commit_message, announced_filecount, announced_files, origin },
			});

			respData = await response.data;

			if (respData.success) {
				if (typeof respData.bucket_id !== "undefined" && respData.bucket_id !== "") {
					const uploadPath = "versions/" + respData.bucket_id + "/source";

					//starting upload
					const allFileUpload = await uploadFiles(files, uploadPath);

					if (allFileUpload.success) {
						snackbarController.open({
							type: "success",
							title: "Done",
							message: `${allFileUpload.message}`,
						});
						setFiles([]);
						setOnUpload(false);
						setForceRefresh(new Date());
					} else {
						snackbarController.open({
							type: "error",
							title: "Upload failed",
							message: `${allFileUpload.message}`,
						});
						setOnUpload(false);
						setForceRefresh(new Date());
					}
				} else {
					snackbarController.open({
						type: "error",
						title: "Upload failed",
						message: `Bucketpath invalid`,
					});
					setOnUpload(false);
					setForceRefresh(new Date());
				}
			} else {
				snackbarController.open({
					type: "error",
					title: "Upload failed",
					message: `Error: ${respData.message}`,
				});
				setOnUpload(false);
				setForceRefresh(new Date());
			}
		} catch (err: any) {
			setOnUpload(false);
			setForceRefresh(new Date());

			const message = typeof err.response?.data?.message !== "undefined" ? err.response.data.message : "Failed to create version";

			snackbarController.open({
				type: "error",
				title: "Upload failed",
				message: message,
			});
			console.log(err);
		}
	};

	const uploadFiles = async (files: File[], storagePath: string) => {
		for (const file of files) {
			const newFile = await putStorageItem(file, storagePath)
				.then((path) => {
					const resp = { success: true, message: path };
					console.log(resp);

					return resp;
				})
				.catch((err) => {
					return { success: false, message: err.data.message };
				});

			if (!newFile.success) {
				return newFile;
			}
		}

		return { success: true, message: "All files uploaded" };
	};
	const findDuplicates = (filesName: string[]) => filesName.filter((item, index) => filesName.indexOf(item) !== index);

	var isValid = (fname: string) => {
		var rg1 = /^[^\\/:\*\?"<>\|]+$/; // forbidden characters \ / : * ? " < > |
		var rg2 = /^\./; // cannot start with dot (.)
		var rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names
		var rg4 = /[a-zA-Z0-9s\-_\\-]/;

		return rg4.test(fname);
	};

	const handleFileSelectionChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const maxAllowedFileSize = 300; //MB
		event.stopPropagation();
		event.preventDefault();
		let maxFileSize = 0;
		const filesName: string[] = [];
		const invalidFileNames: string[] = [];
		if (event.target.files != null) {
			if (event.target.files[0])
				for (let i = 0; i < event.target.files.length; i++) {
					if (maxFileSize < event.target.files[i].size) maxFileSize = event.target.files[i].size;
					const filenameIter = event.target.files[i].name;
					filesName.push(filenameIter);

					if (!/^[a-zA-Z0-9_ ().\-\]\[]*$/.test(filenameIter)) {
						invalidFileNames.push(filenameIter);
					}
				}
			if (maxFileSize / 1024 / 1024 > maxAllowedFileSize) {
				snackbarController.open({
					type: "error",
					title: "Upload Failed",
					message: `Maximum Filesize ${maxAllowedFileSize}MB`,
				});
				event.target.files = null;
				return;
			}

			if (invalidFileNames.length > 0) {
				snackbarController.open({
					type: "error",
					title: "Upload Failed",
					message: `Special characters are not allowed in filenames: ${invalidFileNames}`,
				});
				event.target.files = null;
				return;
			}

			const duplicatedFilenames = findDuplicates(filesName);

			if (duplicatedFilenames.length > 0) {
				snackbarController.open({
					type: "error",
					title: "Upload Failed",
					message: `Each file needs unique name. Duplicates found: ${duplicatedFilenames}`,
				});
				event.target.files = null;
				return;
			}
			if (files.length > 0) {
				let oldFiles = files;

				const newFiles = Array.from(event.target.files);

				for (const file of newFiles) {
					let alreadyExist = false;
					for (const oldFile of oldFiles) {
						if (oldFile.name === file.name) {
							alreadyExist = true;
						}
					}
					if (!alreadyExist) {
						oldFiles.push(file);
					}
				}

				setFiles(oldFiles);
				setForceRefresh(new Date());
			} else {
				setFiles(Array.from(event.target.files));
			}

			event.target.files = null;
			return;
		}
	};
	const snackbarController = useSnackbarController();

	const enabled = assetsFiltered?.length === 1;
	const emptyVersion = enabled ? (typeof assetsFiltered[0].values.latest_version !== "undefined" ? Object.keys(assetsFiltered[0].values.latest_version).length === 0 : true) : true;

	const nextVersion = emptyVersion ? 1 : assetsFiltered[0]?.values.latest_version ? assetsFiltered[0]?.values.latest_version?.number + 1 : 1;
	const nextVersionString = nextVersion < 10 ? "00" + nextVersion.toString() : nextVersion < 100 ? "0" + nextVersion.toString() : "" + nextVersion.toString();

	const author_name_arr = authController?.extra?.user_data?.values?.email?.split("@")[0]?.split(".");
	const author_caps = (author_name_arr?.length > 1 ? author_name_arr[0][0] + "" + author_name_arr[author_name_arr.length - 1][0] : typeof author_name_arr !== "undefined" ? author_name_arr[0] : "").toUpperCase();
	const author_name = typeof author_name_arr === "object" ? capitalizeWords(author_name_arr).join(" ") : "";

	return (
		<Box sx={{ maxWidth: "100%", position: "absolute", overflow: "hidden", bottom: "0px", mt: "auto", zIndex: 3 }}>
			<input type="file" accept="image/png, image/jpeg, image/gif, video/*" id="file" onChange={handleFileSelectionChange} ref={inputFile} multiple style={{ display: "none" }} />

			<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={files.length > 0} onClick={handleClose} />
			<Accordion sx={{ zIndex: (theme) => theme.zIndex.drawer + 2, borderRadius: "15px" }} disableGutters expanded={files.length > 0} onChange={handleChange("panel1")}>
				<AccordionSummary disabled={!enabled} color={"primary"} aria-controls="panel1bh-content" id="panel1bh-header" onClick={onButtonClick}>
					<Box sx={{ display: "flex", height: "50px", alignItems: "center" }} id="someRandomID">
						{!enabled ? null : (
							<Tooltip title="Add files">
								<IconButton color={"secondary"}>
									<AddCircleIcon />
								</IconButton>
							</Tooltip>
						)}
						<Box>
							{!enabled ? (
								<Typography align="center">Select single asset to upload version</Typography>
							) : (
								<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", pr: 1 }}>
									<Typography variant="body2">Upload</Typography>

									<Typography variant="body2" color="text.primary" align={"left"} sx={{ bgcolor: "rgba(0,0,0,0.5)", ml: 1, pl: 1, pr: 1, pt: 0.5, pb: 0.5, borderRadius: "4px" }}>
										<b>{assetsFiltered[0]?.values.name}</b>
									</Typography>
									<Typography variant="body2" color="text.primary" align={"left"} sx={{ bgcolor: "rgba(255,255,255,0.08)", ml: 0.5, pl: 1, pr: 1, pt: 0.5, pb: 0.5, borderRadius: "4px" }}>
										<b>v{nextVersion}</b>
									</Typography>
									{/*		<Typography>
																	Upload <b>v{nextVersionString}</b> of <b>#{assetsFiltered[0]?.values.name}</b>
																</Typography>*/}
								</Box>
							)}
						</Box>
					</Box>
				</AccordionSummary>
				<Divider />
				<AccordionDetails
					sx={{
						maxHeight: "350px",
						ml: "5px",
						pl: 2,
						pr: 2,
						alignItems: "center",
						overflowX: "hidden",
						overflowY: "scroll",
						"&::-webkit-scrollbar": { background: "rgba(0,0,0,0)", width: "5px" },
						"&::-webkit-scrollbar-thumb": { background: "rgba(125,125,125,1)", borderRadius: "5px" },
					}}
				>
					<Box component="span">
						{files?.map((file: File, Index) => {
							const fileSizeString = Math.floor(file.size < 1024 ? file.size : file.size < 1048576 ? file.size / 1024 : file.size / 1024 / 1024).toString();
							const fileSizeUnit = file.size < 1024 ? "Byte" : file.size < 1048576 ? "kB" : "MB";
							const fileType = file.type.split("/")[1];
							return (
								<Item key={file.name + "_" + Index}>
									<b>{file.name}</b>
									<br />
									<Typography variant="caption">
										{fileSizeString}
										{fileSizeUnit} &#183; {fileType}
									</Typography>
									<IconButton
										sx={{ height: "30px", width: "30px" }}
										onClick={() => {
											handleRemoveFromFiles(file);
										}}
									>
										{onUpload ? null : <HighlightOffIcon sx={{ height: "20px", width: "20px" }} />}
									</IconButton>
								</Item>
							);
						})}
					</Box>
				</AccordionDetails>
				<Divider sx={{ mb: 2 }} />
				<Box sx={{ pl: 3, pr: 3 }}>
					<TextField label="Optional version comment" color="primary" focused key="password" style={{ width: "100%", textAlign: "left" }} placeholder="(Optional) Description e.g. - changed lighting" multiline rows={5} inputRef={commentField} />
				</Box>

				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mb: 3, mt: 2 }}>
					<LoadingButton
						sx={{ whiteSpace: "nowrap", color: "text.primary", textTransform: "none", pl: 10, pr: 10 }}
						loading={onUpload}
						color="secondary"
						onClick={requestNewVersion}
						size={"medium"}
						loadingPosition="end"
						variant="contained"
						endIcon={<CloudUploadIcon />}
					>
						{!onUpload ? "Create Version" : "Uploading..."}
					</LoadingButton>
				</Box>
			</Accordion>
		</Box>
	);
});
