import { buildCollection, buildProperty, EntityReference } from "@camberi/firecms";

type Asset = {
	private: boolean;
	job: EntityReference;
	name: string;
	type: string;
	project: EntityReference;
	main_image: string;
	review_links: string[];
	creator: EntityReference;
	created_on: Date; //disabled: true,
	fio_root_asset_id: string;
	latest_version: {};
};

export default buildCollection<Asset>({
	icon: "Tag",
	path: "assets",
	name: "Assets",
	group: "Main",
	description: "List of all Assets",
	properties: {
		private: buildProperty({
			name: "Private",
			dataType: "boolean",
		}),
		job: buildProperty({
			name: "Job",
			description: "The job the asset belongs to e.g. s0025_nike_tvc",
			dataType: "reference",
			path: "jobs",
			previewProperties: ["name"],
			validation: { required: true },
		}),
		project: buildProperty({
			dataType: "reference",
			name: "Project",
			description: "Project this asset is in.",
			path: "projects",
			previewProperties: ["name"],
		}),
		name: buildProperty({
			name: "Asset name (generated)",
			description: "Name",
			dataType: "string",
			multiline: false,
			validation: { required: true },
		}),

		//type AssetType = "timeline" | "shot" | "prop" | "character" | "fx" | "environment" | "generic";
		type: buildProperty({
			name: "Type",
			description: "Asset Type",
			validation: { required: true },
			dataType: "string",
			enumValues: {
				timeline: "Sequence",
				shot: "Shot",
				character: "Character",
				prop: "Prop",
				fx: "FX",
				environment: "Environment",
				generic: "Generic",
				generic_render: "Generic Render",
			},
		}),
		review_links: buildProperty({
			name: "Review Links",
			description: "All Review Links",
			validation: { required: false },
			dataType: "array",
			of: {
				dataType: "string",
			},
		}),
		main_image: buildProperty({
			// The `buildProperty` method is a utility function used for type checking
			name: "Image",
			dataType: "string",
			storage: {
				storagePath: "images/assets",
				acceptedFiles: ["image/*"],
			},
		}),
		fio_root_asset_id: {
			name: "Frame.io Root Asset ID",
			dataType: "string",
		},
		latest_version: buildProperty({
			name: "Latest Version",
			dataType: "map",
			properties: {
				number: {
					name: "Version Number",
					dataType: "number",
				},
				reference: {
					name: "Reference",
					dataType: "reference",
					path: "versions",
					previewProperties: ["name"],
				},
			},
		}),
		created_on: buildProperty({
			readOnly: true,
			dataType: "date",
			name: "created_on",
			mode: "date",
		}),
		creator: {
			readOnly: true,
			name: "Creator",
			dataType: "reference",
			path: "users",
			previewProperties: ["email"],
		},
	},

	permissions: ({ user, authController }) => ({
		edit: true,
		create: true,
		delete: true,
	}),
});
