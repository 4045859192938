import React, { useEffect, useRef, useState } from "react";

import { useDataSource, useAuthController } from "@camberi/firecms";

import versionsCollection from "../Collections/versionsCollection";
import unrealProjectsCollection from "../Collections/unrealProjectsCollection";
import assetsCollection from "../Collections/assetsCollection";

import { Box } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Button from "@mui/material/Button";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import TextField from "@mui/material/TextField";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AccessTimeSharpIcon from "@mui/icons-material/AccessTimeSharp";
import PermissionList from "./PersonalDashboardComponents/PermissionList";
import PersonalDashSkeleton from "./PersonalDashboardComponents/PersonalDashSkeleton";

import { JobIcon, HomeIcon, LogoIcon } from "../CustomIcons/CustomIcons";

import { isEqualSimple, isUndefined } from "../utils/basicFunctions";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	backgroundImage: "none",

	//backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(3),
	borderRadius: "15px",
	textAlign: "center",
	color: theme.palette.text.secondary,
	display: "flex",
	flexDirection: "column",
}));

const TextFieldPlus = (props: any) => {
	const [showTooltip, setShowTooltip] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState<boolean>(false);

	const handleOnTooltipClose = () => {
		setShowTooltip(false);
	};

	const onCopy = (content: any) => {
		navigator.clipboard.writeText(props.value || "");
		setShowTooltip(true);
	};
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};
	return (
		<TextField
			sx={{ marginTop: "15px" }}
			label={props.label || ""}
			color="secondary"
			disabled={props.disabled || false}
			type={props.isPassword ? (showPassword ? "text" : "password") : "text"}
			InputProps={{
				readOnly: true,
				endAdornment: (
					<Box display="flex" sx={{ display: props.disabled ? "none" : "inherit" }}>
						<IconButton aria-label="toggle password visibility" sx={{ mr: "1px", display: props.isPassword ? "inherit" : "none" }} onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
							{showPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
						<Tooltip open={showTooltip} title={"Copied to clipboard!"} leaveDelay={1500} onClose={handleOnTooltipClose}>
							<IconButton sx={{ display: props.focused ? "inherit" : "none" }} onClick={onCopy}>
								<ContentCopyIcon />
							</IconButton>
						</Tooltip>
					</Box>
				),
			}}
			focused={props.focused || false}
			value={props.value || ""}
		/>
	);
};

export default function PersonalDashboard() {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [authUser, setAuthUser] = useState<any>();
	const [userData, setUserData] = useState<any>();

	const [userJobsData, setUserJobsData] = useState<any>();
	const [userProjectsData, setUserProjectsData] = useState<any>();
	const [userAssetsData, setUserAssetsData] = useState<any>();
	const [hasAnyCollaboration, setHasAnyCollaboration] = useState<boolean>(false);

	const unrealprojectsListener = useRef<any>();
	const assetCollabListener = useRef<any>();

	const dataSource = useDataSource();
	const authController = useAuthController();
	let steps: string[] = [];

	steps = ["Login with Google Account", authController?.extra?.user_data?.values?.role === "admin" ? "Create first project" : "Wait for Collaboration invites", "Create first asset version"];
	useEffect(() => {
		if (!isUndefined(unrealprojectsListener.current) && !isUndefined(assetCollabListener.current) && isLoading) {
			setIsLoading(false);
		}
	}, [unrealprojectsListener.current, assetCollabListener.current, isLoading]);

	useEffect(() => {
		if (authController?.user?.uid) {
			if (!isEqualSimple(authUser, authController?.user)) {
				setAuthUser(authController.user);
			}
		}
		if (authController?.extra?.user_data) {
			if (!isEqualSimple(authController?.extra?.user_data, userData)) {
				setUserData(authController?.extra?.user_data);
			}
		}
		if (!isEqualSimple(authController?.extra?.user_jobs, userJobsData)) {
			setUserJobsData(authController?.extra?.user_jobs);
			const any_collab = authController?.extra?.user_jobs?.length > 0 || false;
			if (any_collab !== hasAnyCollaboration) {
				setHasAnyCollaboration(any_collab);
			}
		}
	}, [authController.user, authController.extra?.user_data, authController.extra?.user_jobs, userJobsData, authUser, userData, hasAnyCollaboration]);

	useEffect(() => {
		const onUnrealProjectsCollabUpdate = (evt: any) => {
			if (!isEqualSimple(evt, userProjectsData)) {
				setUserProjectsData(evt);
			}
		};
		if (authController?.user?.uid && authController?.extra?.user_data?.values?.status) {
			if (isUndefined(unrealprojectsListener.current) && unrealProjectsCollection && dataSource.listenCollection) {
				const userIsAdmin = authController?.extra?.user_data?.values?.role === "admin";
				unrealprojectsListener.current = dataSource.listenCollection({
					path: unrealProjectsCollection.path,
					collection: unrealProjectsCollection,
					// @ts-expect-error
					filter: { ...(!userIsAdmin && { "roles.any": ["array-contains", authController?.user.uid] }) },
					onUpdate: onUnrealProjectsCollabUpdate,
				});
			}
		}
	}, [authController.user?.uid, authController.extra?.user_data?.values?.status, authController.extra?.user_data?.values?.role, unrealprojectsListener.current, dataSource, userProjectsData]);

	useEffect(() => {
		authController?.extra?.createAndSetMobileNavCenter("Dashboard", HomeIcon);
	}, [authController?.extra?.createAndSetMobileNavCenter]);

	useEffect(() => {
		const onAssetCollabUpdate = (evt: any) => {
			if (!isEqualSimple(evt, userAssetsData)) {
				setUserAssetsData(evt);
			}
		};

		if (authController?.user?.uid && authController?.extra?.user_data?.values?.status) {
			if (isUndefined(assetCollabListener.current) && assetsCollection && dataSource.listenCollection) {
				const userIsAdmin = authController?.extra?.user_data?.values?.role === "admin";
				console.log("assetCollab", assetCollabListener.current);

				assetCollabListener.current = dataSource.listenCollection({
					path: assetsCollection.path,
					collection: assetsCollection,
					// @ts-expect-error
					filter: { ...(!userIsAdmin && { "roles.any": ["array-contains", authController?.user.uid] }) },
					onUpdate: onAssetCollabUpdate,
				});
			}
		}
	}, [authController.user?.uid, authController.extra?.user_data?.values?.status, authController.extra?.user_data?.values?.role, assetCollabListener.current, dataSource, userAssetsData]);

	const userActive = authController?.extra?.user_data?.values?.status === "active";
	const GitLabActive = userActive && authController?.extra?.user_data?.values?.git_user_name;
	const userHasCreatedVersion = authController?.extra?.user_data?.values?.created_initial_version;
	const userIsCreated = authController?.extra?.user_data?.values?.status === "created";

	return (
		<Box height="100%" width="100%" sx={{ overflowY: "scroll" }}>
			<Container maxWidth="xl">
				<Toolbar sx={{ height: { sm: "192px", xs: "60px" } }}></Toolbar>
				<Typography variant="h6" align={"center"} gutterBottom>
					{authController?.user?.displayName + "'s" || " "} Dashboard
				</Typography>
				{!isLoading ? (
					<Grid container spacing={2}>
						{userHasCreatedVersion ? null : (
							<Grid xs={12}>
								<Item>
									<Typography variant="h6" align={"justify"} gutterBottom>
										Next Steps
									</Typography>
									<Typography variant="body2" align={"justify"} gutterBottom sx={{ marginTop: "15px" }}>
										Wonderful, you almost made it.
									</Typography>
									<Paper sx={{ p: { xs: 0, sm: 5 }, pt: { xs: 1 }, pb: { xs: 1 }, borderRadius: "15px", mt: "15px" }}>
										<Stepper activeStep={hasAnyCollaboration ? (userHasCreatedVersion ? 3 : 2) : 1} alternativeLabel>
											{steps.map((label) => (
												<Step
													sx={{
														"& .MuiStepLabel-root .Mui-completed": {
															color: "secondary.dark", // circle color (COMPLETED)
														},
														"& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
															color: "grey.500", // Just text label (COMPLETED)
														},
														"& .MuiStepLabel-root .Mui-active": {
															color: "primary.main", // circle color (ACTIVE)
														},
														"& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
															color: "common.white", // Just text label (ACTIVE)
														},
														"& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
															fill: "black", // circle's number (ACTIVE)
														},
													}}
													key={label}
												>
													<StepLabel>{label}</StepLabel>
												</Step>
											))}
										</Stepper>
									</Paper>
								</Item>
							</Grid>
						)}
						{userActive ? (
							<Grid xs={12}>
								<Item>
									<Typography variant="h6" align={"justify"} gutterBottom>
										Collaborations
									</Typography>

									{!hasAnyCollaboration ? (
										<Chip
											avatar={GitLabActive ? undefined : <AccessTimeSharpIcon />}
											color={GitLabActive ? "secondary" : "primary"}
											sx={{ height: "50px" }}
											label={<Typography>{GitLabActive ? "Active" : authController?.extra?.user_data?.values?.role === "admin" ? "Start by creating your first job." : "Wait for Collaboration invites..."} </Typography>}
										/>
									) : (
										<PermissionList userUUID={authController?.user?.uid} jobs={userJobsData} assets={userAssetsData} projects={userProjectsData} />
									)}
								</Item>
							</Grid>
						) : null}
						<Grid xs={12} sm={4}>
							<Item>
								<Typography variant="h6" align={"justify"} gutterBottom>
									Boost
								</Typography>
								<Chip
									avatar={userActive ? undefined : <AccessTimeSharpIcon />}
									color={userActive ? "secondary" : "primary"}
									sx={{ height: "50px" }}
									label={<Typography>{userActive ? "Active" : userIsCreated ? "Wait for invites..." : "Ask admin for activation..."} </Typography>}
								/>

								<TextFieldPlus label="Google Account Email" value={authController?.user?.email || " "} isPassword={false} />

								<TextFieldPlus label="Boost Token" value={authController?.extra?.user_data?.values?.boost_token || " "} isPassword={true} focused disabled={!userActive} />
								{userActive ? (
									<Typography variant="body2" align={"justify"} gutterBottom sx={{ marginTop: "15px" }}>
										Paste your <b>Basestatıon Token</b> into the <b>Unreal Engine 5 Render Manager</b> to enable auto-versioning.
									</Typography>
								) : null}
							</Item>
							<Item sx={{ mt: 2 }}>
								<Typography variant="h6" align={"justify"} gutterBottom>
									Say, goodbye.
								</Typography>
								<Box>
									<Button variant="outlined" color="error" onClick={authController.signOut}>
										Logout
									</Button>
								</Box>
							</Item>
						</Grid>

						{hasAnyCollaboration ? (
							<Grid xs={12} sm={4}>
								<Item>
									<Typography variant="h6" align={"justify"} gutterBottom>
										GitLab
									</Typography>
									{userActive ? (
										<Chip
											avatar={GitLabActive ? undefined : <AccessTimeSharpIcon />}
											color={GitLabActive ? "secondary" : "primary"}
											sx={{ height: "50px" }}
											label={<Typography>{GitLabActive ? "Active" : "Ask admin for activation..."} </Typography>}
										/>
									) : null}

									<TextFieldPlus label="GitLab Url" value={"https://git.stunning.studio"} isPassword={false} disabled={!GitLabActive} />
									<TextFieldPlus label="GitLab Username" value={GitLabActive ? authController?.extra?.user_data?.values?.git_user_name || " " : " "} isPassword={false} focused disabled={!GitLabActive} />
									<TextFieldPlus label="GitLab PAT (Personal Access Token)" value={GitLabActive ? authController?.extra?.user_data?.values?.git_token || " " : " "} isPassword={GitLabActive ? true : false} focused disabled={!GitLabActive} />
								</Item>
							</Grid>
						) : null}
					</Grid>
				) : (
					<PersonalDashSkeleton />
				)}
			</Container>
		</Box>
	);
}
