import React, { useEffect, useRef, useState, useCallback } from "react";

import { useDebounce, useStateCallback } from "../utils/usehooks-ts";
import { useParams } from "react-router-dom";
import { EntityReference, useDataSource, useAuthController } from "@camberi/firecms";

import versionsCollection from "../Collections/versionsCollection";
import unrealProjectsCollection from "../Collections/unrealProjectsCollection";
import assetsCollection from "../Collections/assetsCollection";

import { VersionUploader } from "../Components/VersionUploader";
import { TreeViewMenuModalCollection } from "../Components/TreeView/TreeViewMenuModalCollection";
import { TreeViewMenuModalAsset } from "../Components/TreeView/TreeViewMenuModalAsset";
import { FlowView } from "../Components/FlowView";
import ReactFlowView from "../Components/FlowView/ReactFlowView";
import StyledTreeItem from "../Components/TreeView/Components/StyledTreeItem";
import { TreeviewSearch, TreeviewSearchInputIconWrapper, TreeviewSearchInput } from "../Components/TreeView/Components/TreeviewSearch";
import FilterTabs from "../Components/TreeView/Components/FilterTabs";
import { Box } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeView from "@mui/lab/TreeView";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import TagIcon from "@mui/icons-material/Tag";
import SearchIcon from "@mui/icons-material/Search";
import Chip from "@mui/material/Chip";
import { Scroller } from "./VersionsView/Scroller";
import { Scroller_v02 } from "./VersionsView/Scroller_v02";
// Grid version 2

import LinkIcon from "@mui/icons-material/Link";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import AttachmentIcon from "@mui/icons-material/Attachment";
import RectIcon from "../img/rectangle-2.svg";
import { labelIconByAssetType, labelIconByProjectType, JobIcon } from "../CustomIcons/CustomIcons";

import { AssetType, ProjectType, ActionType, JobviewState } from "../Types/types";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { nonselect } from "../utils/basicFunctions";

import { useLocalStorage, useReadLocalStorage } from "usehooks-ts";
/**
 * Sample CMS view not bound to a collection, customizable by the developer
 * @constructor
 */

export default function VersionsView({ jobId, currentJobView }: { jobId: any; currentJobView: JobviewState }) {
  // hook to display custom snackbars
  const theme = useTheme();
  const smMatch = useMediaQuery(theme.breakpoints.up("sm"));

  const initValue = useReadLocalStorage("showJobName") || true;
  const [isJobNameShown, setIsJobNameShown] = useLocalStorage("showJobName", initValue as boolean);

  const [mobileSearch, setMobileSearch] = useState<boolean>(false);

  let { searchParam } = useParams();

  const collectionDialogRef = useRef<any>();
  const assetDialogRef = useRef<any>();
  const uploaderDialogRef = useRef<any>();
  const searchBarRef = useRef<any>();

  const dataSource = useDataSource();
  const authController = useAuthController();
  const [job, setJob] = useState<any[]>();
  const [jobFilter] = useState<EntityReference | undefined>(jobId !== "" ? new EntityReference(jobId, "jobs") : undefined);
  const [assets, setAssets] = useState<any[]>([]);
  const [assetCount, setAssetCount] = useState<number>(0);
  const [versionCalcCount, setVersionCalcCount] = useState<number>(5);
  const [projects, setProjects] = useState<any[]>([]);
  const [projectCount, setProjectCount] = useState<number>(0);

  /*  const [versions, setVersions] = useState<any[]>([]);
  const versionsDebounced = useDebounce<any[]>(versions, 100);*/

  const [query, setQuery] = useState<any>((searchParam as string) || "");
  const [assetsFiltered, setAssetsFiltered] = useState<any[]>([]);

  const [assetsSelected, setAssetsSelected] = useState<any[]>([]);

  const debouncedAssetsFiltered = useDebounce<any[]>(assetsFiltered, 300);

  const [filtered, setFiltered] = useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);

  //const [selected, setSelected] = React.useState<string[]>([]);
  const selectedDebounced = useDebounce<string[]>(selected, 300);
  const [forceRefresh, setForceRefresh] = useState<Date>(new Date());

  const [isLoading, setIsLoading] = useState(false);
  const isLoadingDebounced = useDebounce<boolean>(isLoading, 300);

  const [isMoreData, setIsMoreData] = useState<boolean>(false);
  const isMoreDataDebounced = useDebounce<boolean>(isMoreData, 300);

  const [defaultNumberOfAssetsShown] = useState<number>(2);
  const [numberOfVersionToShow, setNumberOfVersionToShow] = useState<number>(defaultNumberOfAssetsShown);
  const [oldestDate, setOldestDate] = useState<string>("Loading...");
  const debouncedQuery = useDebounce<string>(query, 300);
  const debouncedOldestDate = useDebounce<string>(oldestDate, 150);

  const [nodeTree, setNodeTree] = React.useState<any>({});
  const nodeTreeDebounced = useDebounce<any[]>(nodeTree, 300);

  const [hideFromMainTree, setHideFromMainTree] = React.useState<any>({ project_types: ["unrealscene"] });
  const [mainProjectTypeFilter, setMainProjectTypesFilter] = React.useState<string | null>(null);

  /*  const [versionsToListen, setVersionsToListen] = useStateCallback<string[]>([]);
  const [curVersionListeners, setCurVersionListeners] = useStateCallback<any[]>([]);
*/
  const getSelectedProjects = () => {
    let selectedProjects: string[] = [];
    selected.forEach((select) => {
      const splitNode = select.split(":");
      const nodeType = splitNode[0];
      const nodePath = splitNode[1];

      if (nodeType === "project") {
        selectedProjects.push(nodePath);
      }
    });
    return selectedProjects;
  };
  const getSelectedProjectMasters = () => {
    let selectedProjectsMasters: string[] = [];
    selected.forEach((select) => {
      const splitNode = select.split(":");
      const nodeType = splitNode[0];
      const nodePath = splitNode[1];

      if (nodeType === "project_m") {
        selectedProjectsMasters.push(nodePath);
      }
      if (nodeType === "project_sub_forced") {
        const subForceSplit = nodePath?.split("_");
        selectedProjectsMasters.push(subForceSplit?.[0]);
        selectedProjectsMasters.push(subForceSplit?.[1]);
      }
      if (nodeType === "asset_sub_forced") {
        const subForceSplit = nodePath?.split("_");
        selectedProjectsMasters.push(subForceSplit?.[1]);
        selectedProjectsMasters.push(subForceSplit?.[0]);
      }
    });
    return selectedProjectsMasters;
  };

  const getSelectedAssets = () => {
    let selectedAssets: string[] = [];
    selected.forEach((select) => {
      const splitNode = select.split(":");
      const nodeType = splitNode[0];
      const nodePath = splitNode[1];
      const assetId = nodePath?.split("_")?.[1];
      if (nodeType === "asset_m") selectedAssets.push(assetId);
      if (nodeType === "asset_sub_forced") {
        const subForceSplit = nodePath?.split("_");
        selectedAssets.push(subForceSplit?.[2]);
      }
    });
    return selectedAssets;
  };

  const getJob = (projects: any) => {
    const selectedProjects = getSelectedProjects();
    const selectedProjectsMasters = getSelectedProjectMasters();
    const selectedAssets = getSelectedAssets();
    const filter = [...selectedProjects, ...selectedAssets, ...selectedProjectsMasters];

    const filterSelected = false;
    let nodes: any = [];
    let jobData: any = { job_id: job?.[0]?.id, projects: [], data: { type: "job" } };

    nodes.push({ type: "customNode", id: job?.[0]?.id, data: { isJobNameShown: true, selected: true, type: "job", label: job?.[0]?.values?.name }, position: [0, 0] });

    for (const project of projects) {
      const mainSelected = [...selectedProjects].includes(project.id);
      const mainSubs = selectedProjects.flatMap((main) => {
        let mainSubs: string[] = [];
        projects
          ?.find((project: any) => main === project.id)
          ?.values?.used_projects?.forEach((sub: any) => {
            if (projects?.find((pro: any) => pro.id === sub.id)?.values?.type === "unrealscene") mainSubs.push(sub.id);
          });
        return mainSubs;
      });
      const projectIsMaster = selectedProjectsMasters?.includes(project.id);
      const projectHasSubMasterSelected = project?.values?.sub_projects?.find((sub: any) => selectedProjectsMasters?.includes(sub?.id));
      const projectHasSubSelected = project?.values?.sub_projects?.find((sub: any) => selectedProjects?.includes(sub?.id));
      const projectContainsSelectedAsset = project?.values?.used_assets?.find((asset: any) => selectedAssets?.includes(asset?.id));

      const isFilteredOut = hideFromMainTree?.project_types?.includes(project?.values?.type) || false;

      if (
        (!filterSelected || !filter.length || mainSelected) &&
        !isFilteredOut &&
        (mainProjectTypeFilter === project?.values?.type || !mainProjectTypeFilter || mainSelected || projectHasSubMasterSelected || projectIsMaster || projectContainsSelectedAsset || projectHasSubSelected)
      ) {
        let projectData = getUsedProjects(projects, project.id, nodes, job?.[0]?.id, selectedProjects, selectedProjectsMasters, selectedAssets, mainSelected, mainSubs);
        jobData.projects.push(projectData);
      }
    }
    return nodes;
  };

  //Recursive function to retrieve replies
  const getUsedProjects = (projects: any, project_id: string, nodes: any[], parentId: string, selectedProjects: string[], selectedProjectsMasters: string[], selectedAssets: string[], mainSelected: boolean, mainSubs: string[]) => {
    const selectionExists = [...selectedProjects, ...selectedAssets, ...selectedProjectsMasters]?.length > 0;
    const project = projects.find((x: any) => x.id === project_id) || {};

    let projectData: any = { id: project.id, data: { label: project.values?.name, project_type: project.values?.type } };

    const isTopLevelProject = parentId?.split("_").length === 1;
    const isSubLevelProject = parentId?.split("_").length === 2;

    const projectIsMaster = selectedProjectsMasters?.includes(project.id);
    const projectHasSubMasterSelected = project?.values?.sub_projects?.find((sub: any) => selectedProjectsMasters?.includes(sub?.id));
    const projectHasSubSelected = project?.values?.sub_projects?.find((sub: any) => selectedProjects?.includes(sub?.id));
    const projectContainsSelectedAsset = project?.values?.used_assets?.find((asset: any) => selectedAssets?.includes(asset?.id));

    const projectIsForcedSub = project?.values?.project?.id?.length;

    const projectContainsSelectedAsset_full = assets?.find((asset) => asset?.id === projectContainsSelectedAsset?.id);
    const projectIsSelectedAssetParent = projectContainsSelectedAsset_full?.values?.project?.id === project_id;
    const projectIsMainSub = mainSubs?.includes(project.id);

    const shouldBeSelected = mainSelected || selectedProjects?.includes(project.id) || projectIsMaster || projectHasSubSelected || projectHasSubMasterSelected || projectContainsSelectedAsset || projectIsMainSub;
    nodes.push({
      type: "customNode",
      id: parentId + "_" + project.id,
      parentId: parentId,
      data: {
        isJobNameShown,
        job_prefix: job?.[0]?.values?.ident,
        label: project?.values?.name,
        project_type: project?.values?.type,
        mainSelected:
          mainSelected ||
          (projectIsMaster && isTopLevelProject) ||
          (projectContainsSelectedAsset && projectIsSelectedAssetParent && isTopLevelProject) ||
          (projectIsForcedSub && projectIsMaster && parentId?.split("_")?.[1] === project?.values?.project?.id && isSubLevelProject),
        selected: shouldBeSelected || !selectionExists,
        type: isTopLevelProject ? "project" : parentId?.split("_").length === 2 ? "used_project" : "sub_project",
      },
    });

    projectData.used_projects = project.values?.used_projects
      ?.slice()
      ?.sort((ax: any, bx: any) => {
        const b = projects.find((x: any) => x.id === bx.id) || {};
        const a = projects.find((x: any) => x.id === ax.id) || {};
        return (b?.values?.type === "unrealedit" ? 1 : 0) - (a?.values?.type === "unrealedit" ? 1 : 0) || (b?.values?.type === "unrealasset" ? 1 : 0) - (a?.values?.type === "unrealasset" ? 1 : 0) || a?.values?.name?.localeCompare(b?.values?.name);
      })
      ?.map((used_project_ref: any) => {
        return getUsedProjects(projects, used_project_ref.id, nodes, parentId + "_" + project.id, selectedProjects, selectedProjectsMasters, selectedAssets, mainSelected, mainSubs);
      });
    return projectData;
  };

  useEffect(() => {
    const selected_ids = selected.flatMap((selected_node) => (selected_node?.split(":")?.[1]?.split("_")?.[0] !== job?.[0]?.id ? selected_node?.split(":")?.[1]?.split("_")?.[0] : []));

    const position = { x: 0, y: 0 };
    const edgeType = "smoothstep";
    const nodes = projects && job?.[0] ? getJob(projects.slice().sort((a: any, b: any) => b.values?.sub_projects?.length - a.values?.sub_projects?.length)).map((node: any) => ({ ...node, position })) : [];

    const edges = nodes
      ?.slice()
      .filter((node: any) => node && node?.parentId)
      .map((node: any) => ({
        id: node.id + "_" + node?.parentId,
        text: false ? node.id : "",
        source: node?.parentId,
        target: node?.id,
        type: edgeType,
        //hidden: !node?.data?.selected || false,
        zIndex: !node?.data?.selected || false ? 0 : node?.data?.mainSelected ? 10 : 9,
        animated: false,
        style: {
          strokeWidth: !node?.data?.selected || false ? 1 : selected_ids.length > 0 ? 2 : 1,
          stroke: !node?.data?.selected || false ? "rgb(100,100,100)" : selected_ids.length > 0 ? (node?.data?.mainSelected ? `${theme.palette.primary.main}` : "rgb(255,255,255)") : "rgb(255,255,255)",
        },
      }));
    setNodeTree({ nodes, edges });
  }, [projects, selectedDebounced, mainProjectTypeFilter, isJobNameShown]);

  const getSelectedAssetsVersionView = () => {
    let selectedProjectMastersAssets: string[] = [];
    selected.forEach((select) => {
      const splitNode = select.split(":");
      const nodeType = splitNode[0];
      const nodePath = splitNode[1];
      const assetId = nodePath?.split("_")?.[1];

      if (nodeType === "project") {
        projects?.filter((p: any) => p?.id === nodePath).map((p: any) => p?.values?.used_assets?.forEach((a: any) => selectedProjectMastersAssets.push(a.id)));
      }

      if (nodeType === "project_m") {
        assets?.filter((a: any) => {
          if (nodePath === a?.values?.project?.id) selectedProjectMastersAssets.push(a.id);
        });
      }
      if (nodeType === "project_sub_forced") {
        const subForceSplit = nodePath?.split("_");
        projects?.filter((p: any) => p?.id === subForceSplit?.[1]).map((p: any) => p?.values?.used_assets?.forEach((a: any) => selectedProjectMastersAssets.push(a.id)));
        /*  assets?.filter((a: any) => {
          if (subForceSplit?.[1] === a?.values?.project?.id) selectedProjectMastersAssets.push(a.id);
        });*/
      }

      if (nodeType === "asset_m") selectedProjectMastersAssets.push(assetId);
      if (nodeType === "asset_sub_forced") {
        const subForceSplit = nodePath?.split("_");
        selectedProjectMastersAssets.push(subForceSplit?.[2]);
      }
    });
    return selectedProjectMastersAssets;
  };

  useEffect(() => {
    const selectedProjectsMasters = getSelectedAssetsVersionView();

    const filter = [...selectedProjectsMasters];

    setAssetsSelected(filter);
  }, [selectedDebounced]);

  useEffect(() => {
    if (authController?.user?.uid) {
      const userIsAdmin = authController?.extra?.user_data?.values?.role === "admin";
      if (assetsCollection && dataSource.listenCollection) {
        dataSource.listenCollection({
          path: assetsCollection.path,
          collection: assetsCollection,
          filter: {
            ...(jobFilter && { job: ["==", jobFilter] }),
            ...(!userIsAdmin && { "roles.any": ["array-contains", authController?.user.uid] }),
          },
          onUpdate: onUpdateAssets,
        });
      }

      if (authController?.extra?.user_jobs) {
        const curJob = authController?.extra?.user_jobs?.find((x: any) => x.id === jobId);
        setJob(typeof curJob === "undefined" ? [] : [curJob]);
      }

      if (unrealProjectsCollection && dataSource.listenCollection) {
        dataSource.listenCollection({
          path: unrealProjectsCollection.path,
          collection: unrealProjectsCollection,
          filter: { ...(jobFilter && { job: ["==", jobFilter] }), ...(!userIsAdmin && { "roles.any": ["array-contains", authController?.user.uid] }) },
          onUpdate: onProjectUpdate,
        });
      }
    }
  }, [dataSource, assetsCollection, unrealProjectsCollection, versionsCollection, authController]);

  useEffect(() => {
    const jobsAssets = Array.from(new Set(assets.map((asset) => asset.values.job.id)));
  }, [assetCount, projectCount]);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    event.persist();
    let iconClicked = (event.target as HTMLTextAreaElement).closest(".MuiTreeItem-iconContainer");
    if (iconClicked) {
      setExpanded(Array.from(new Set(nodeIds)));
    }
  };

  const selectAndExpandNode = (nodeId: string, avoidSelect = false) => {
    if (nodeId.startsWith("job:")) {
      setSelected([]);
      return;
    }
    if (!avoidSelect) setSelected([nodeId]);

    if (nodeId.startsWith("project:")) {
      //alert("project");
      const nodeIdMaster = "project_m:" + nodeId?.split(":")?.[1];
      setExpanded(Array.from(new Set([...expanded, nodeId, nodeIdMaster])));
    } else {
      setExpanded(Array.from(new Set([...expanded, nodeId])));
    }
  };

  const handleSelect = (event: React.SyntheticEvent, nodeIds: string[]) => {
    let _nodeIds = [...nodeIds];
    event.persist();
    let iconClicked = (event.target as HTMLTextAreaElement).closest(".MuiTreeItem-iconContainer");
    if (!iconClicked) {
      if (_nodeIds.length === 1 && _nodeIds[0]?.split(":")?.[0] === "job") _nodeIds = [];

      let _nodesToUse: string[] = [];
      _nodeIds.forEach((node) => {
        const splitNode = node.split(":");
        const nodeType = splitNode[0];
        const nodePath = splitNode[1];

        let mainProjectId;
        let assetId;
        let parent;
        let parent_m;
        let currentExpanded = [...expanded];

        switch (nodeType) {
          case "asset_m":
          case "project_m":

          case "asset_sub_forced":
          case "project":
            break;
          case "project_sub":
            mainProjectId = nodePath?.split("_")?.[1];
            node = "project:" + mainProjectId;
            currentExpanded.push(node);

            parent_m = "project_m:" + mainProjectId;
            currentExpanded.push(parent_m);
            setExpanded(Array.from(new Set(currentExpanded)));
            break;
          case "project_sub_forced":
            mainProjectId = projects.find((project) => project?.id === nodePath?.split("_")?.[1])?.values?.project?.id;

            parent = "project:" + mainProjectId;
            currentExpanded.push(parent);

            node = "project_sub_forced:" + mainProjectId + "_" + nodePath?.split("_")?.[1];
            currentExpanded.push(node);
            setExpanded(Array.from(new Set(currentExpanded)));
            break;

          case "project_sub_forced_sub":
            mainProjectId = nodePath?.split("_")?.[2];
            node = "project:" + mainProjectId;
            currentExpanded.push(node);

            parent_m = "project_m:" + mainProjectId;
            currentExpanded.push(parent_m);
            setExpanded(Array.from(new Set(currentExpanded)));
            break;
          case "asset_sub":
            //_nodesToUse.push(node);
            mainProjectId = nodePath?.split("_")?.[1];
            assetId = nodePath?.split("_")?.[2];
            node = "asset_m:" + mainProjectId + "_" + assetId;

            parent = "project:" + mainProjectId;
            currentExpanded.push(parent);

            parent_m = "project_m:" + mainProjectId;
            currentExpanded.push(parent_m);
            setExpanded(Array.from(new Set(currentExpanded)));
            break;

          case "references":
            nodePath?.split?.("_")?.forEach((ref: any) => {
              node = "project:" + ref;
              currentExpanded.push(node);

              parent_m = "project_m:" + ref;
              currentExpanded.push(parent_m);
              setExpanded(Array.from(new Set(currentExpanded)));
              _nodesToUse.push(node);
            });

            break;
        }
        if (nodeType !== "references") _nodesToUse.push(node);
      });

      setSelected(_nodesToUse);
    }
  };

  const onUpdateAssets = (evt: any) => {
    setAssetCount(evt.length);
    setAssets(evt);
  };

  const onProjectUpdate = (evt: any) => {
    setProjectCount(evt.length);
    setProjects(evt);
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    const maxVersionsAvailable = assetsFiltered
      ?.map((asset: any) => asset?.values?.latest_version?.number)
      .reduce(function (s: number, v: number) {
        return s + (v || 0);
      }, 0);

    const maxVersions = assetsFiltered?.map((a) => a?.values?.versions?.length || 0).reduce((partialSum, a) => partialSum + a, 0);
    setVersionCalcCount(maxVersions);

    setNumberOfVersionToShow(defaultNumberOfAssetsShown);
    setIsMoreData(true);
  }, [debouncedAssetsFiltered]);

  const filterAssets = (queryInput: string, assetsInput: any[]) => {
    if (typeof queryInput === "undefined" || queryInput === "") {
      return [assetsInput];
    } else {
      const queryArr = queryInput.split(" ");

      const filteredAsset = assetsInput.filter((asset) => {
        const asset_project = projects?.find((project) => project?.id === asset?.values?.project?.id)?.values?.name || "";
        //items or projects that include the search query
        return queryArr.every((item) => asset.values?.name?.toLowerCase().includes(item.toLowerCase())) || queryArr.every((item) => asset_project.toLowerCase().includes(item.toLowerCase()));
      });

      return [filteredAsset];
    }
  };

  const setWindowUrl = (url: any) => {
    let searchParams;
    let newRelativePathQuery = url;
    if ("URLSearchParams" in window) {
      searchParams = new URLSearchParams(window.location.search);
      if (searchParams.toString().length) {
        newRelativePathQuery = window.location.pathname + "?" + searchParams.toString();
      }
    }
    window.history.replaceState(null, "", newRelativePathQuery);
  };

  useEffect(() => {
    if (debouncedQuery === "") {
      if (JSON.stringify(assetsFiltered) !== JSON.stringify(assets)) {
        setWindowUrl(`/jobs/${jobId}/${currentJobView}`);
        sortAssets(assets);
        setAssetsFiltered(assets);
        setFiltered(false);
      }
    } else {
      const queryFilter = filterAssets(query, assets);

      if (JSON.stringify(queryFilter[0]) !== JSON.stringify(assetsFiltered) || JSON.stringify(queryFilter[0]).length === JSON.stringify(assetsFiltered).length) {
        sortAssets(queryFilter[0]);
        setAssetsFiltered(queryFilter[0]);
        setFiltered(true);
        setForceRefresh(new Date());
        if (queryFilter[0].length > 0) {
          setWindowUrl(`/jobs/${jobId}/${currentJobView}/${debouncedQuery}`);
        } else {
          setWindowUrl(`/jobs/${jobId}/${currentJobView}`);
          setSelected([]);
        }
      }
    }
  }, [debouncedQuery, assets, projects, currentJobView]);

  const uploadVersionHandle = () => {
    uploaderDialogRef?.current?.openDialog();
  };

  const collectionDialogHandleOpen = (projectid: string, parenttype: ProjectType, action: ActionType) => {
    collectionDialogRef?.current?.openDialog(projectid, parenttype, action);
  };
  const assetDialogHandleOpen = (projectid: string, parenttype: AssetType, action: ActionType) => {
    assetDialogRef?.current?.openDialog(projectid, parenttype, action);
  };

  const handleOutsideClick = (evt: any) => {
    switch (evt?.target?.tagName?.toLowerCase() || "") {
      case "ul":
      case "li":
        setSelected([]);
        break;
    }
  };

  const sortAssets = (assetArray: any) => {
    assetArray.sort((a: any, b: any) => {
      return (
        (b?.values?.type === "timeline" ? 1 : 0) - (a?.values?.type === "timeline" ? 1 : 0) ||
        (b?.values?.type === "shot" ? 1 : 0) - (a?.values?.type === "shot" ? 1 : 0) ||
        (b?.values?.type === "prop" ? 1 : 0) - (a?.values?.type === "prop" ? 1 : 0) ||
        (b?.values?.type === "character" ? 1 : 0) - (a?.values?.type === "character" ? 1 : 0) ||
        (b?.values?.type === "environment" ? 1 : 0) - (a?.values?.type === "environment" ? 1 : 0) ||
        (b?.values?.type === "fx" ? 1 : 0) - (a?.values?.type === "fx" ? 1 : 0) ||
        (b?.values?.type === "generic" ? 1 : 0) - (a?.values?.type === "generic" ? 1 : 0) ||
        (b?.values?.type === "generic_render" ? 1 : 0) - (a?.values?.type === "generic_render" ? 1 : 0) ||
        a?.values?.name?.localeCompare(b?.values?.name)
      );
    });
  };

  const allAssetsWithoutProject = assetsFiltered.filter((a: any) => !projects.find((p: any) => p.id === a?.values?.project?.id));
  //display: { xs: "block", sm: "none" },
  return (
    <Box display="flex" width={"100%"} height={"100%"} flexDirection={{ xs: "column", sm: "row" }}>
      <Box
        sx={{ ...nonselect, display: { sm: "flex", xs: mobileSearch ? "flex" : "none", flexGrow: { sm: 0, xs: 0 } } }}
        width={{ sm: "20%", xs: "100%" }}
        minWidth={{ sm: 400, xs: "100%" }}
        paddingLeft={"10px"}
        paddingRight={"10px"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyItems={"center"}
      >
        <Box display="flex" width={"100%"} height={"50px"} marginTop={"15px"}>
          <TreeviewSearch
            sx={{
              display: "flex",
              height: "45px",
              width: "100%",
              overflowY: "auto",
              marginBottom: "10px",
            }}
          >
            <TreeviewSearchInputIconWrapper>
              <SearchIcon />
            </TreeviewSearchInputIconWrapper>
            <TreeviewSearchInput
              inputRef={searchBarRef}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck={false}
              placeholder="Search assets…"
              onChange={onSearchChange}
              inputProps={{ "aria-label": "search" }}
              value={query}
              sx={{
                width: "100%",
                overflowX: "hidden",

                "&::-webkit-scrollbar": { display: "none" },
              }}
              onKeyPress={(evt: any) => {
                if (evt.key === "Enter") {
                  // setMobileSearch(false);
                  searchBarRef?.current?.blur();
                }
              }}
            />
          </TreeviewSearch>
        </Box>
        <FilterTabs value={mainProjectTypeFilter} setMainProjectTypesFilter={setMainProjectTypesFilter} isJobNameShown={isJobNameShown} setIsJobNameShown={setIsJobNameShown} />
        <TreeView
          multiSelect
          aria-label="file system navigator"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={
            filtered
              ? [
                  "job:" + jobId,
                  ...projects.map((project, Index) => {
                    return "project:" + project.id;
                  }),
                  ...projects.map((project, Index) => {
                    return "project_m:" + project.id;
                  }),
                  //show hideFromMainTree'ed used projects within their parents
                  ...projects.flatMap((project, Index) => {
                    const sub_project_ids: any = [];
                    (project.values?.used_projects || []).forEach((used_project: any) => {
                      if (hideFromMainTree?.project_types?.includes(projects.find((x) => x.id === used_project.id)?.values?.type)) sub_project_ids.push(`project_sub_forced:${project.id}_${used_project.id}`);
                    });
                    return sub_project_ids;
                  }),
                ]
              : ["job:" + jobId, ...expanded]
          }
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
          onClick={handleOutsideClick}
          sx={{
            width: "100%",
            height: "100%",
            overflowX: "hidden",
            overflowY: "scroll",
            "&::-webkit-scrollbar": { background: "rgba(0,0,0,0)", width: "10px" },
            "&::-webkit-scrollbar-thumb": { background: "rgba(125,125,125,1)", borderRadius: "10px" },
          }}
        >
          {/*jobs*/}

          {job?.map((job_item, index_value) => {
            const jobOwnerRole =
              Object.entries(job_item.values?.roles?.details || {})
                .filter(([key, value]: any) => value?.role === "owner")
                .map(([key, value]: any) => {
                  return {
                    [`${key}`]: { ...value, role: "job owner" },
                  };
                })?.[0] || {};
            return (
              <StyledTreeItem
                treeFns={{ selectAndExpandNode, collectionDialogHandleOpen, assetDialogHandleOpen }}
                roles={{ ...job_item.values?.roles, ...{ details: { ...job_item.values?.roles?.details, ...jobOwnerRole } } }}
                key={job_item.id}
                nodeId={"job:" + job_item.id}
                parentid={job_item.id}
                labelText={job_item?.values?.name.replace(`_${job_item?.values?.client}_`, "_")}
                jobIdent={job_item?.values?.ident}
                jobIdentShown={isJobNameShown}
                labelSize={"small"}
                labelIcon={JobIcon}
                grouptype={"job"}
                boldText={true}
              >
                <TreeViewMenuModalCollection ref={collectionDialogRef} key={"CollectionDialog"} job={job_item} projects={projects} />
                <TreeViewMenuModalAsset ref={assetDialogRef} key={"AssetDialog"} job={job_item} projects={projects} assets={assets} />

                {/* main projects*/}
                {projects
                  ?.sort((a, b) => {
                    switch (mainProjectTypeFilter) {
                      case "unrealedit":
                      case null:
                        return (
                          (b.values.type === "unrealedit" ? 1 : 0) - (a?.values?.type === "unrealedit" ? 1 : 0) ||
                          (b.values.type === "unrealasset" ? 1 : 0) - (a?.values?.type === "unrealasset" ? 1 : 0) ||
                          a?.values.name?.localeCompare(b?.values.name)
                        );
                      case "unrealasset":
                        return (
                          (b.values.type === "unrealasset" ? 1 : 0) - (a?.values?.type === "unrealasset" ? 1 : 0) ||
                          (b.values.type === "unrealedit" ? 1 : 0) - (a?.values?.type === "unrealedit" ? 1 : 0) ||
                          a?.values.name?.localeCompare(b?.values.name)
                        );
                      case "generic":
                        return (b.values.type === "generic" ? 1 : 0) - (a?.values?.type === "generic" ? 1 : 0) || (b.values.type === "unrealedit" ? 1 : 0) - (a?.values?.type === "unrealedit" ? 1 : 0) || a?.values.name?.localeCompare(b?.values.name);
                    }
                  })
                  .map((project, index_value) => {
                    let numberOfAsset = filtered ? assetsFiltered?.filter((asset) => asset.values.project?.id === project.id).length : project.values?.used_assets?.length || 0;
                    numberOfAsset = numberOfAsset > 0 ? numberOfAsset : "";

                    let numberOfAssetMaster = assetsFiltered?.filter((asset) => asset.values.project?.id === project.id).length;

                    let numberOfAssetMaster_incl_filtered_used =
                      assetsFiltered?.filter((asset) => asset.values.project?.id === project.id).length +
                      assetsFiltered?.filter((asset) =>
                        project?.values?.used_projects?.find((x: any) => x?.id === asset?.values?.project?.id && hideFromMainTree?.project_types?.includes(projects?.find((y) => y?.id === x?.id)?.values?.type || "can't find project type"))
                      )?.length;

                    let used_projects_str = "";
                    project.values?.used_projects?.forEach((single: any, index: any) => {
                      const projectFull = projects.find((x) => x.id === single.id);
                      const lastItem = index === project.values?.used_projects.length - 1;

                      used_projects_str = used_projects_str + `${projectFull?.values?.name}${lastItem ? "" : " / "}`;
                    });

                    const isFilteredOut = (hideFromMainTree?.project_types?.includes(project?.values?.type || "can't find project type") && projects?.find((p: any) => project?.values?.project?.id === p.id)) || false; //filter from maintree only if parent_project is accessable

                    const isSelected = selected?.find((selectedItem: any) => {
                      const selectedSplit = selectedItem?.split(":");
                      const selectedType = selectedSplit?.[0];
                      const selectedPath = selectedSplit?.[1];
                      const selectedPathRoot = selectedPath?.split("_")?.[0];

                      return selectedPathRoot === project?.id;
                    })?.length;

                    const projectFilteredButSelected = mainProjectTypeFilter !== project?.values?.type && mainProjectTypeFilter && !isSelected;
                    const mainFilterButSelected = mainProjectTypeFilter !== project?.values?.type && mainProjectTypeFilter && isSelected;

                    const used_projects_filtered = project.values?.used_projects
                      ?.sort((a: any, b: any) => {
                        return project.values?.used_projects.find((x: any) => x.id === a.id) ? -1 : 1;
                      })
                      .filter((p: any) => {
                        const sub_projectFull = projects.find((x) => x.id === p.id);
                        const isFilteredOut = hideFromMainTree?.project_types?.includes(sub_projectFull?.values?.type) || false;
                        return isFilteredOut;
                      });

                    const used_projects_not_filtered = project.values?.used_projects
                      ?.sort((a: any, b: any) => {
                        return project.values?.used_projects.find((x: any) => x.id === a.id) ? -1 : 1;
                      })
                      .filter((p: any) => {
                        const sub_projectFull = projects.find((x) => x.id === p.id);
                        const isFilteredOut = hideFromMainTree?.project_types?.includes(sub_projectFull?.values?.type) || false;
                        return !isFilteredOut;
                      });

                    const str_used_projects_not_filtered = used_projects_not_filtered?.map((up: any) => up.id).join("_");

                    return (filtered && numberOfAssetMaster_incl_filtered_used < 1) || isFilteredOut || projectFilteredButSelected ? null : (
                      <div key={"project:" + project.id + "_container"}>
                        {mainFilterButSelected ? (
                          <div>
                            <Box sx={{ typography: "body2", width: "100%", borderBottom: 1, borderColor: "divider", paddingLeft: 1, mt: 1, mb: 1, display: "flex", alignItems: "center" }} />
                            Filtered:
                          </div>
                        ) : null}
                        <StyledTreeItem
                          grouptype={"project"}
                          projecttype={project?.values?.type}
                          treeFns={{ selectAndExpandNode, collectionDialogHandleOpen, assetDialogHandleOpen }}
                          roles={{ ...project.values?.roles, ...{ details: { ...project.values?.roles?.details, ...jobOwnerRole } } }}
                          key={"project:" + project.id}
                          nodeId={"project:" + project.id}
                          parentid={project.id}
                          labelText={project.values.name}
                          jobIdent={job_item?.values?.ident}
                          jobIdentShown={isJobNameShown}
                          labelSize={"small"}
                          labelIcon={labelIconByProjectType(project.values.type)}
                          statusInfo={project.values.status}
                          labelEndNumber={numberOfAsset}
                          boldText={true}
                        >
                          {filtered && numberOfAssetMaster < 1 ? null : (
                            <StyledTreeItem
                              treeFns={{ selectAndExpandNode, collectionDialogHandleOpen, assetDialogHandleOpen }}
                              key={"project_m:" + project.id}
                              nodeId={"project_m:" + project.id}
                              parentid={project.id}
                              labelText={project.values.name}
                              jobIdent={job_item?.values?.ident}
                              jobIdentShown={isJobNameShown}
                              labelIcon={labelIconByProjectType(project.values.type)}
                              statusInfo={project.values.status}
                              boldText={true}
                            >
                              {/* main project assets*/}
                              {assetsFiltered
                                ?.filter((asset: any) => asset.values.project?.id === project.id)
                                .map((asset: any, mainassetIndex: any) => {
                                  return (
                                    <StyledTreeItem
                                      grouptype={"asset"}
                                      treeFns={{ selectAndExpandNode, collectionDialogHandleOpen, assetDialogHandleOpen }}
                                      roles={{ ...asset.values?.roles, ...{ details: { ...asset.values?.roles?.details, ...jobOwnerRole } } }}
                                      key={`asset_m:${project.id}_${asset.id}`}
                                      nodeId={`asset_m:${project.id}_${asset.id}`}
                                      parentid={asset.id}
                                      labelText={asset.values.name}
                                      jobIdent={job_item?.values?.ident}
                                      jobIdentShown={isJobNameShown}
                                      labelIcon={labelIconByAssetType(asset.values.type)}
                                      labelEndNumber={"#" + asset.values.type}
                                    />
                                  );
                                })}
                            </StyledTreeItem>
                          )}
                          {/* used projects filtered out*/}
                          {used_projects_filtered
                            ?.sort((ax: any, bx: any) => {
                              const b = projects.find((x: any) => x.id === bx.id) || {};
                              const a = projects.find((x: any) => x.id === ax.id) || {};
                              const a_is_used = project.values?.used_projects.find((x: any) => x.id === a.id);
                              const b_is_used = project.values?.used_projects.find((x: any) => x.id === b.id);

                              return (b?.values?.project?.id === project.id ? 1 : 0) - (a?.values?.project?.id === project.id ? 1 : 0) || (b_is_used ? 1 : 0) - (a_is_used ? 1 : 0) || a?.values?.name?.localeCompare(b?.values?.name);
                            })
                            ?.map((sub_project: any, subIndex: any) => {
                              const sub_projectFull = projects.find((x) => x.id === sub_project.id);
                              const isFilteredOut = hideFromMainTree?.project_types?.includes(sub_projectFull?.values?.type) || false;

                              if (!sub_projectFull) return null;
                              const is_used_project = project.values?.used_projects.find((x: any) => x.id === sub_project.id) ? true : false;

                              const sub_project_assetsFiltered = assetsFiltered?.filter((asset) => asset.values.project?.id === sub_project.id);

                              let numberOfAsset = assetsFiltered?.filter((asset) => asset.values.project?.id === sub_projectFull?.id).length;
                              const mainProjectFixedRef = sub_projectFull?.values?.project?.id === project.id;

                              const all_used = sub_projectFull?.values?.used_projects?.map((up: any) => up.id).join("_");

                              return (!filtered || (filtered && isFilteredOut && numberOfAsset > 0)) && (mainProjectFixedRef || is_used_project) ? (
                                <StyledTreeItem
                                  grouptype={isFilteredOut ? "project" : "subproject"}
                                  projecttype={sub_projectFull?.values?.type}
                                  treeFns={{ selectAndExpandNode, collectionDialogHandleOpen, assetDialogHandleOpen }}
                                  sx={{ opacity: isFilteredOut && sub_projectFull?.values?.project?.id === project.id ? 1 : is_used_project ? 0.75 : 0.25 }}
                                  roles={sub_project.values?.roles}
                                  key={`${isFilteredOut ? "project_sub_forced" : "project_sub"}:${project.id}_${sub_project.id}`}
                                  nodeId={`${isFilteredOut ? "project_sub_forced" : "project_sub"}:${project.id}_${sub_project.id}`}
                                  parentid={sub_project.id}
                                  labelText={sub_projectFull?.values?.name}
                                  jobIdent={job_item?.values?.ident}
                                  jobIdentShown={isJobNameShown}
                                  labelIcon={labelIconByProjectType(sub_projectFull?.values?.type, true)}
                                  expandIcon={!isFilteredOut ? <Box /> : undefined}
                                >
                                  {/* sub_references*/}
                                  {!isFilteredOut || sub_projectFull?.values?.project?.id !== project.id
                                    ? null
                                    : sub_project_assetsFiltered.map((asset, Index) => {
                                        return (
                                          <StyledTreeItem
                                            grouptype={"subasset"}
                                            treeFns={{ selectAndExpandNode, collectionDialogHandleOpen, assetDialogHandleOpen }}
                                            key={`${isFilteredOut ? "asset_sub_forced" : "asset_sub"}:${project.id}_${sub_project.id}_${asset.id}`}
                                            nodeId={`${isFilteredOut ? "asset_sub_forced" : "asset_sub"}:${project.id}_${sub_project.id}_${asset.id}`}
                                            parentid={asset.id}
                                            labelText={asset.values.name}
                                            jobIdent={job_item?.values?.ident}
                                            jobIdentShown={isJobNameShown}
                                            labelIcon={labelIconByAssetType(asset?.values?.type)}
                                            labelEndNumber={"#" + asset.values.type}
                                          />
                                        );
                                      })}
                                  {isFilteredOut && sub_projectFull?.values?.project?.id === project.id && sub_projectFull?.values?.used_projects?.length && !filtered ? (
                                    <StyledTreeItem
                                      treeFns={{ selectAndExpandNode, collectionDialogHandleOpen, assetDialogHandleOpen }}
                                      key={"references:" + all_used}
                                      sx={{ opacity: 0.75 }}
                                      nodeId={"references:" + all_used}
                                      parentid={"references"}
                                      labelText={"References"}
                                      jobIdent={job_item?.values?.ident}
                                      jobIdentShown={isJobNameShown}
                                      labelIcon={LinkIcon}
                                      statusInfo={project.values.status}
                                      boldText={true}
                                    >
                                      {sub_projectFull?.values?.used_projects?.map((sub_sub_project: any) => {
                                        const sub_sub_projectFull = projects.find((x) => x?.id === sub_sub_project?.id);
                                        return (
                                          <StyledTreeItem
                                            grouptype={"subproject"}
                                            treeFns={{ selectAndExpandNode, collectionDialogHandleOpen, assetDialogHandleOpen }}
                                            key={`project_sub_forced_sub:${project?.id}_${sub_project?.id}_${sub_sub_projectFull.id}`}
                                            nodeId={`project_sub_forced_sub:${project?.id}_${sub_project?.id}_${sub_sub_projectFull.id}`}
                                            sx={{ opacity: 0.75 }}
                                            parentid={sub_sub_projectFull.id}
                                            labelText={sub_sub_projectFull.values.name}
                                            jobIdent={job_item?.values?.ident}
                                            jobIdentShown={isJobNameShown}
                                            labelIcon={labelIconByProjectType(sub_sub_projectFull?.values?.type, true)}
                                            //labelEndNumber={"#" + sub_sub_projectFull.values.type}
                                          />
                                        );
                                      })}
                                    </StyledTreeItem>
                                  ) : null}
                                </StyledTreeItem>
                              ) : null;
                            })}

                          {/* used projects notfiltered out*/}
                          {used_projects_not_filtered?.length ? (
                            <StyledTreeItem
                              treeFns={{ selectAndExpandNode, collectionDialogHandleOpen, assetDialogHandleOpen }}
                              key={"references:" + str_used_projects_not_filtered}
                              sx={{ opacity: 0.75 }}
                              nodeId={"references:" + str_used_projects_not_filtered}
                              parentid={"references"}
                              labelText={"References"}
                              jobIdent={job_item?.values?.ident}
                              jobIdentShown={isJobNameShown}
                              labelIcon={LinkIcon}
                              statusInfo={project?.values?.status}
                              boldText={true}
                            >
                              {used_projects_not_filtered
                                ?.sort((ax: any, bx: any) => {
                                  const b = projects.find((x: any) => x.id === bx.id) || {};
                                  const a = projects.find((x: any) => x.id === ax.id) || {};
                                  const a_is_used = project.values?.used_projects.find((x: any) => x.id === a.id);
                                  const b_is_used = project.values?.used_projects.find((x: any) => x.id === b.id);

                                  return (b?.values?.project?.id === project.id ? 1 : 0) - (a?.values?.project?.id === project.id ? 1 : 0) || (b_is_used ? 1 : 0) - (a_is_used ? 1 : 0) || a?.values?.name?.localeCompare(b?.values?.name);
                                })
                                ?.map((sub_project: any, subIndex: any) => {
                                  const sub_projectFull = projects.find((x) => x.id === sub_project.id);
                                  const isFilteredOut = hideFromMainTree?.project_types?.includes(sub_projectFull?.values?.type) || false;

                                  if (!sub_projectFull) return null;
                                  const is_used_project = project.values?.used_projects.find((x: any) => x.id === sub_project.id) ? true : false;

                                  const sub_project_assetsFiltered = assetsFiltered?.filter((asset) => asset.values.project?.id === sub_project.id);

                                  let numberOfAsset = assetsFiltered?.filter((asset) => asset.values.project?.id === sub_projectFull?.id).length;
                                  const mainProjectFixedRef = sub_projectFull?.values?.project?.id === project.id;

                                  const all_used = sub_projectFull?.values?.used_projects?.map((up: any) => up.id).join("_");

                                  return (!filtered || (filtered && isFilteredOut && numberOfAsset > 0)) && (mainProjectFixedRef || is_used_project) ? (
                                    <StyledTreeItem
                                      grouptype={isFilteredOut ? "project" : "subproject"}
                                      projecttype={sub_projectFull?.values?.type}
                                      treeFns={{ selectAndExpandNode, collectionDialogHandleOpen, assetDialogHandleOpen }}
                                      sx={{ opacity: isFilteredOut && sub_projectFull?.values?.project?.id === project.id ? 1 : is_used_project ? 0.75 : 0.25 }}
                                      roles={sub_project.values?.roles}
                                      key={`${isFilteredOut ? "project_sub_forced" : "project_sub"}:${project.id}_${sub_project.id}`}
                                      nodeId={`${isFilteredOut ? "project_sub_forced" : "project_sub"}:${project.id}_${sub_project.id}`}
                                      parentid={sub_project.id}
                                      labelText={sub_projectFull?.values?.name}
                                      jobIdent={job_item?.values?.ident}
                                      jobIdentShown={isJobNameShown}
                                      labelIcon={labelIconByProjectType(sub_projectFull?.values?.type, true)}
                                      expandIcon={!isFilteredOut ? <Box /> : undefined}
                                    >
                                      {/* sub_references*/}
                                      {!isFilteredOut || sub_projectFull?.values?.project?.id !== project.id
                                        ? null
                                        : sub_project_assetsFiltered.map((asset, Index) => {
                                            return (
                                              <StyledTreeItem
                                                grouptype={"subasset"}
                                                treeFns={{ selectAndExpandNode, collectionDialogHandleOpen, assetDialogHandleOpen }}
                                                key={`${isFilteredOut ? "asset_sub_forced" : "asset_sub"}:${project.id}_${sub_project.id}_${asset.id}`}
                                                nodeId={`${isFilteredOut ? "asset_sub_forced" : "asset_sub"}:${project.id}_${sub_project.id}_${asset.id}`}
                                                parentid={asset.id}
                                                labelText={asset.values.name}
                                                jobIdent={job_item?.values?.ident}
                                                jobIdentShown={isJobNameShown}
                                                labelIcon={labelIconByAssetType(asset?.values?.type)}
                                                labelEndNumber={"#" + asset.values.type}
                                              />
                                            );
                                          })}
                                      {isFilteredOut && sub_projectFull?.values?.project?.id === project.id && sub_projectFull?.values?.used_projects?.length && !filtered ? (
                                        <StyledTreeItem
                                          treeFns={{ selectAndExpandNode, collectionDialogHandleOpen, assetDialogHandleOpen }}
                                          key={"references:" + all_used}
                                          sx={{ opacity: 0.75 }}
                                          nodeId={"references:" + all_used}
                                          parentid={"references"}
                                          labelText={"References"}
                                          jobIdent={job_item?.values?.ident}
                                          jobIdentShown={isJobNameShown}
                                          labelIcon={LinkIcon}
                                          statusInfo={project.values.status}
                                          boldText={true}
                                        >
                                          {sub_projectFull?.values?.used_projects?.map((sub_sub_project: any) => {
                                            const sub_sub_projectFull = projects.find((x) => x.id === sub_sub_project.id);
                                            return (
                                              <StyledTreeItem
                                                grouptype={"subproject"}
                                                treeFns={{ selectAndExpandNode, collectionDialogHandleOpen, assetDialogHandleOpen }}
                                                key={`project_sub_forced_sub:${project.id}_${sub_project.id}_${sub_sub_projectFull.id}`}
                                                nodeId={`project_sub_forced_sub:${project.id}_${sub_project.id}_${sub_sub_projectFull.id}`}
                                                sx={{ opacity: 0.75 }}
                                                parentid={sub_sub_projectFull.id}
                                                labelText={sub_sub_projectFull.values.name}
                                                jobIdent={job_item?.values?.ident}
                                                jobIdentShown={isJobNameShown}
                                                labelIcon={labelIconByProjectType(sub_sub_projectFull?.values?.type, true)}
                                                //labelEndNumber={"#" + sub_sub_projectFull.values.type}
                                              />
                                            );
                                          })}
                                        </StyledTreeItem>
                                      ) : null}
                                    </StyledTreeItem>
                                  ) : null;
                                })}
                            </StyledTreeItem>
                          ) : null}
                        </StyledTreeItem>
                      </div>
                    );
                  })}

                {allAssetsWithoutProject.length ? (
                  <div>
                    <Box sx={{ typography: "body2", width: "100%", borderBottom: 1, borderColor: "divider", paddingLeft: 1, mt: 1, mb: 1, display: "flex", alignItems: "center" }} />
                    Asset Colloborations:
                  </div>
                ) : null}
                {allAssetsWithoutProject?.map((asset: any, mainassetIndex: any) => {
                  return (
                    <StyledTreeItem
                      grouptype={"asset"}
                      treeFns={{ selectAndExpandNode, collectionDialogHandleOpen, assetDialogHandleOpen }}
                      roles={{ ...asset.values?.roles, ...{ details: { ...asset.values?.roles?.details, ...jobOwnerRole } } }}
                      key={`asset_m:lonely_${asset.id}`}
                      nodeId={`asset_m:lonely_${asset.id}`}
                      parentid={asset.id}
                      labelText={asset.values.name}
                      jobIdent={job_item?.values?.ident}
                      jobIdentShown={isJobNameShown}
                      labelIcon={labelIconByAssetType(asset.values.type)}
                      labelEndNumber={"#" + asset.values.type}
                    />
                  );
                })}
              </StyledTreeItem>
            );
          })}
        </TreeView>
      </Box>

      <Box sx={{ display: { sm: "flex", xs: "flex" } }} style={{ borderRadius: "25px 25px 0px 0px", marginTop: 15, overflow: "hidden", width: "100%", flexGrow: 1 }} alignItems={"center"} justifyItems={"center"} flexDirection={"column"}>
        {currentJobView === "topology" ? <ReactFlowView nodeTree={nodeTreeDebounced} isJobNameShown={isJobNameShown} /> : null}

        {currentJobView === "versions" ? (
          <Scroller_v02
            uploadVersion={uploadVersionHandle}
            assets={assets}
            filteredAssets={assetsFiltered.slice().filter((asset) => {
              const asset_project = projects?.find((project) => project.id === asset.values?.project?.id) || {};
              const get_parent_project = projects?.find((project) => project.id === asset_project.values?.project?.id) || {};
              return mainProjectTypeFilter === asset_project?.values?.type || !mainProjectTypeFilter || (mainProjectTypeFilter === get_parent_project?.values?.type && hideFromMainTree?.project_types?.includes(asset_project?.values?.type));
            })}
            selected={selectedDebounced}
            selectedAssets={assetsSelected}
            jobIdent={job?.[0]?.values?.ident}
            jobIdentShown={isJobNameShown}
          />
        ) : null}

        {React.useMemo(() => {
          return (
            <>
              {currentJobView === "versions" && smMatch ? (
                <VersionUploader
                  ref={uploaderDialogRef}
                  assetsFiltered={
                    !selected.length
                      ? assetsFiltered.slice().filter((asset) => {
                          const asset_project = projects?.find((project) => project.id === asset.values?.project?.id) || {};
                          const get_parent_project = projects?.find((project) => project.id === asset_project.values?.project?.id) || {};
                          return (
                            mainProjectTypeFilter === asset_project?.values?.type || !mainProjectTypeFilter || (mainProjectTypeFilter === get_parent_project?.values?.type && hideFromMainTree?.project_types?.includes(asset_project?.values?.type))
                          );
                        })
                      : assets?.filter((a: any) => assetsSelected?.includes(a.id))
                  }
                  setForceRefresh={setForceRefresh}
                />
              ) : null}
            </>
          );
        }, [forceRefresh, assetsFiltered?.length, currentJobView, assetsSelected, mainProjectTypeFilter, smMatch])}
      </Box>
    </Box>
  );
}
