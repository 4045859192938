import { useState, useCallback, useEffect } from "react";
import ReactFlow, {
	ControlButton,
	ReactFlowInstance,
	ReactFlowProvider,
	addEdge,
	ConnectionLineType,
	FitViewOptions,
	applyNodeChanges,
	applyEdgeChanges,
	Node,
	Edge,
	NodeChange,
	EdgeChange,
	Connection,
	MiniMap,
	Controls,
	useNodesState,
	useEdgesState,
	Background,
	BackgroundProps,
	BackgroundVariant,
	useReactFlow,
	getRectOfNodes,
	useKeyPress,
} from "react-flow-renderer";
import dagre from "dagre";
import createGraphLayout from "./Components/createGraphLayout";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import CustomNode from "./Components/CustomNode";
import { nonselect } from "../../utils/basicFunctions";

const fitViewOptions: FitViewOptions = {
	padding: 0.2,
};

const reactFlowStyle = {
	//background: "red",

	width: "100%",
	height: "100%",
	overflow: "hidden",
};

const nodeTypes = {
	customNode: CustomNode,
};
const debounce = (n: number, fn: (...params: any[]) => any, immed: boolean = false) => {
	let timer: any | undefined = undefined;
	return function (this: any, ...args: any[]) {
		if (timer === undefined && immed) {
			fn.apply(this, args);
		}
		clearTimeout(timer);
		timer = setTimeout(() => fn.apply(this, args), n);
		return timer;
	};
};
const ReactFlowView = ({ nodeTree, isJobNameShown }: { nodeTree: any; isJobNameShown: boolean }) => {
	const nodeWidth = isJobNameShown ? 150 : 120;
	const nodeHeight = isJobNameShown ? 35 : 25;

	const theme = useTheme();
	const [nodes, setNodes, onNodesChange] = useNodesState([]);
	const [edges, setEdges, onEdgesChange] = useEdgesState([]);
	const [elements, setElements] = useState<any>([]);

	const fKeyPress = useKeyPress("f");

	const fitToBounds = (els: any = nodes, instant = false) => {
		let filterSelected = els.filter((el: any) => el?.data?.selected);
		filterSelected = filterSelected.length === els.length ? filterSelected : filterSelected.filter((el: any) => el?.data?.type !== "job");
		const rectBounds = { ...getRectOfNodes(filterSelected) };
		rectBounds.width = rectBounds.width + nodeWidth;
		rectBounds.height = rectBounds.height + nodeHeight;
		rectBounds.x = rectBounds.x;
		rectBounds.y = rectBounds.y;

		fitBounds(rectBounds, { padding: 0.2, duration: instant ? 0 : 750 });
	};
	const fitToBoundsDebounced = debounce(250, (els: any = nodes, instant = false) => fitToBounds(els, instant));
	useEffect(() => {
		fitToBounds(nodes);
	}, [fKeyPress]);

	useEffect(() => {
		createGraphLayout(nodeTree.nodes || [], nodeTree.edges || [], nodeWidth, nodeHeight)
			.then((els: any) => {
				if (JSON.stringify(els) !== JSON.stringify(nodes)) {
					setNodes(els);
					setEdges(nodeTree.edges);

					fitToBounds(els);
				}
			})
			.catch((err: any) => console.error(err));
	}, [nodeTree]);

	const { setViewport, fitBounds, zoomIn, zoomOut } = useReactFlow();

	return (
		<div style={{ ...reactFlowStyle, ...nonselect }} className="layoutflow">
			<ReactFlow
				nodeTypes={nodeTypes}
				defaultPosition={[0, 0]}
				nodes={nodes}
				edges={edges}
				connectionLineType={ConnectionLineType.SmoothStep}
				onInit={() => {
					//fitToBoundsDebounced(nodes, false);
				}}
				fitView
				fitViewOptions={fitViewOptions}
				nodesDraggable={false}
				elementsSelectable={false}
			>
				<Background variant={"dots" as BackgroundVariant} gap={25} />

				<MiniMap
					maskColor={`${theme.palette.background.default}`}
					style={{ width: 100, height: 75, background: `${theme.palette.background.paper}`, opacity: 0.75, pointerEvents: "none", borderRadius: "15px" }}
					nodeStrokeColor={(n: any) => {
						return n.data?.type === "job" ? `${theme.palette.secondary.main}` : n.data?.type === "project" ? `${theme.palette.primary.main}` : n.data?.type === "used_project" ? `${theme.palette.primary.dark}` : "black";
						if (n.type === "input") return "#0041d0";
						if (n.type === "selectorNode") return "#ff0072";
						if (n.type === "default") return "#1a192b";

						return "#eee";
					}}
					nodeColor={(n: any) => {
						return n.data?.type === "job" ? `${theme.palette.secondary.main}` : n.data?.type === "project" ? `${theme.palette.primary.main}` : n.data?.type === "used_project" ? `${theme.palette.primary.dark}` : "black";
						if (n.style?.background) return n.style.background;

						return "#fff";
					}}
					nodeBorderRadius={2}
				/>

				<Controls style={{ background: `${theme.palette.background.paper}` }} showInteractive={false}></Controls>
			</ReactFlow>
		</div>
	);
};

export default ({ nodeTree, isJobNameShown }: { nodeTree: any; isJobNameShown: boolean }) => (
	<ReactFlowProvider>
		<ReactFlowView nodeTree={nodeTree} isJobNameShown={isJobNameShown} />
		<Box fontSize={"0.7em"} sx={{ display: { sm: "inherit", xs: "none" }, position: "relative", mt: "auto", ml: "auto", right: "15px", height: "50px", zIndex: 1000, opacity: 0.5 }}>
			Press <b>F</b> to fit view
		</Box>
	</ReactFlowProvider>
);
