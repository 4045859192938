import React, { useEffect, useState } from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useLocation, useParams, Link } from "react-router-dom";
import { Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Toolbar from "@mui/material/Toolbar";

import { ReactComponent as Logo } from "../img/rectangle-2.svg";

import RectIcon from "../img/rectangle-2.svg";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import LayersIcon from "@mui/icons-material/Layers";
import FolderIcon from "@mui/icons-material/Folder";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";

import { useAuthController } from "@camberi/firecms";
import { capitalizeWord } from "../utils/basicFunctions";

import VersionsView from "../CustomViews/VersionsView";
import { JobIcon, HomeIcon, TopologyIcon } from "../CustomIcons/CustomIcons";
import { AssetType, ProjectType, ActionType, JobviewState } from "../Types/types";

import TooltipClipboard from "../Components/TreeView/Components/TooltipClipboard";

export default function JobView() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(1920));
  let { jobId } = useParams();
  let { jobView } = useParams();
  let { searchParam } = useParams();

  const location = useLocation();
  interface StyledTabsProps {
    children?: React.ReactNode;
    value: any;
  }

  const jobIdString: string = jobId || "";
  const authController = useAuthController();
  const jobData = authController?.extra?.user_jobs?.find((job: any) => job.id === jobIdString);
  const StyledTabs = styled((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />)({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#635ee7",
    },
  });

  interface StyledTabProps {
    label: string;
    component: any;
    to: any;
    value: any;
    icon?: any;
    iconPosition?: any;
  }

  const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    height: "40px",
    minHeight: "40px",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  }));

  useEffect(() => {
    //console.log("jobview: mount " + jobId);
    authController?.extra?.createAndSetMobileNavCenter(jobData?.values?.name.replace(`_${jobData?.values?.client}_`, "_"), JobIcon);

    return () => {
      //console.log("jobview: UNmount " + jobId);
    };
  }, [jobData?.values?.name]);

  const MenuIcon = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill={props.fill} className={props.class}>
      <Logo />
    </svg>
  );

  return (
    <Box height="100%" width="100%" display={"flex"} flexDirection={"column"}>
      <Paper sx={{ flexGrow: 0, flexShrink: 0, pt: { xs: "55px", sm: "25px", xl: "70px" }, display: "flex", flexDirection: "column", alignItems: { xs: "center", sm: "start" }, justifyContent: { xs: "center", sm: "end" }, overflow: "hidden" }}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <TooltipClipboard value={`${jobData?.values?.created_on?.getFullYear()} - ${capitalizeWord(jobData?.values?.client)} - ${jobData?.values?.name.replace(`_${jobData?.values?.client}_`, "_")}`}>
            <Box
              sx={{ cursor: "pointer", ml: { xs: 0, sm: 3.5 }, mt: 1, mb: 1, pr: 1, pl: 0.5, pt: 1, pb: 1, borderRadius: "5px", display: { xs: "none", sm: "flex" }, alignItems: "center", justifyItems: "center", backgroundColor: "secondary.main" }}
            >
              <JobIcon sx={{ mr: 1 }} />
              <Typography sx={{ mr: 1 }} variant="body2">
                {jobData?.values?.created_on?.getFullYear()} - {capitalizeWord(jobData?.values?.client)} - {jobData?.values?.name.replace(`_${jobData?.values?.client}_`, "_")}
              </Typography>
            </Box>
          </TooltipClipboard>
        </Box>

        <Tabs sx={{ ml: 2, height: 50 }} value={location.pathname.replaceAll("%20", " ")}>
          <StyledTab
            sx={{ height: 50 }}
            icon={<LayersIcon />}
            iconPosition="start"
            label="Version History"
            component={Link}
            to={`/jobs/${jobId}/versions${typeof searchParam !== "undefined" ? "/" + searchParam.replaceAll("%20", " ") : ""}`}
            value={`/jobs/${jobId}/versions${typeof searchParam !== "undefined" ? "/" + searchParam.replaceAll("%20", " ") : ""}`}
          />
          <StyledTab
            sx={{ height: 50 }}
            icon={<TopologyIcon />}
            iconPosition="start"
            label="Topology"
            component={Link}
            to={`/jobs/${jobId}/topology${typeof searchParam !== "undefined" ? "/" + searchParam.replaceAll("%20", " ") : ""}`}
            value={`/jobs/${jobId}/topology${typeof searchParam !== "undefined" ? "/" + searchParam.replaceAll("%20", " ") : ""}`}
          />
        </Tabs>
      </Paper>

      <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
        <VersionsView key={jobIdString} jobId={jobIdString} currentJobView={jobView as JobviewState} />
      </Box>
    </Box>
  );
}
