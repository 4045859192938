import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import UpdateIcon from "@mui/icons-material/Update";
import TreeViewMenu from "../TreeViewMenu";
import TooltipClipboard from "./TooltipClipboard";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { styled } from "@mui/material/styles";

import { GroupType, ProjectType } from "../../../Types/types";

type StyledTreeItemProps = TreeItemProps & {
	bgColor?: string;
	color?: string;
	labelIcon?: React.ElementType<SvgIconProps>;
	boldText?: boolean;
	labelInfo?: string;
	labelEndNumber?: number | string;
	labelText: string;
	statusIcon?: React.ElementType<SvgIconProps>;
	statusInfo?: string;
	expanded?: boolean;
	roles?: any;
	labelSize?: string;
	treeFns?: any;
	grouptype?: GroupType;
	projecttype?: ProjectType;
	parentid: string;
	jobIdent: string;
	jobIdentShown: boolean;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
	color: theme.palette.text.secondary,
	[`& .${treeItemClasses.content}`]: {
		color: theme.palette.text.secondary,
		borderRadius: theme.spacing(1),

		paddingRight: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		marginTop: 1,
		marginBottom: 1,
		fontWeight: theme.typography.fontWeightMedium,

		"&.Mui-expanded": {
			fontWeight: theme.typography.fontWeightRegular,
		},
		"&:hover": {
			backgroundColor: theme.palette.action.hover,
		},
		"&.Mui-selected, &.Mui-selected.Mui-focused": {
			backgroundColor: `var(--tree-view-bg-color, ${theme.palette.primary.dark})`,
			color: "var(--tree-view-color)",
		},
		[`& .${treeItemClasses.label}`]: {
			fontWeight: "inherit",
			color: "inherit",
		},
	},
}));

export default function StyledTreeItem(props: StyledTreeItemProps) {
	const { bgColor, color, labelIcon: LabelIcon, labelInfo, labelEndNumber, labelSize, labelText, statusIcon, statusInfo, boldText, roles, expanded, treeFns, grouptype, parentid, jobIdent, jobIdentShown, projecttype, ...other } = props;

	const { selectAndExpandNode, collectionDialogHandleOpen = () => {}, assetDialogHandleOpen = () => {} } = treeFns;
	const [hover, setHover] = useState<boolean>(false);
	const smallLabel = labelSize === "small";
	const messageStatus = statusInfo?.startsWith("pending") || statusInfo?.startsWith("error");

	const isJob = grouptype === "job";
	const isProject = grouptype === "project";
	const isAsset = grouptype === "asset" || grouptype === "subasset";

	const rolesSorted = Object.values(roles?.details || {}).sort((a: any, b: any) => {
		return (b.role === "job owner" ? 1 : 0) - (a.role === "job owner" ? 1 : 0) || (b.role === "owner" ? 1 : 0) - (a.role === "owner" ? 1 : 0) || a?.name?.localeCompare(b?.name);
	});

	return (
		<StyledTreeItemRoot
			collapseIcon={isJob ? <div /> : null}
			expandIcon={isJob ? <div /> : null}
			key={other.nodeId + "_1"}
			sx={{ ml: isAsset ? -1 : isJob ? -1 : 0, width: "100%" }}
			label={
				<Box
					onMouseMove={() => {
						setHover(true);
					}}
					onMouseLeave={() => {
						setHover(false);
					}}
					key={other.nodeId + "_2"}
					sx={{
						display: "flex",
						alignItems: "center",
						height: "35px",
						m: 0,

						p: 0.5,
						pr: 0,
						pl: 0,
					}}
				>
					{LabelIcon ? <Box component={LabelIcon} color={"inherit"} sx={{ ml: isJob ? -1 : "inherit", mr: 1, width: smallLabel ? 20 : 15 }} /> : null}
					<TooltipClipboard value={labelText}>
						<Box sx={{ typography: "body2", textOverflow: "ellipsis", overflow: "hidden", fontWeight: boldText ? "bold" : "inherit" }}>{isJob || jobIdentShown ? labelText : labelText.replaceAll(jobIdent + "_", "")}</Box>
					</TooltipClipboard>
					<Box sx={{ flexGrow: 1 }}>
						{typeof roles === "undefined" || typeof roles.details === "undefined" ? (
							<Typography sx={{ background: "rgba(255,255,255,.0)", pl: 0, borderRadius: 1 }} align={"center"} variant="caption" color="inherit" />
						) : (
							<Tooltip
								arrow
								enterDelay={500}
								enterNextDelay={500}
								title={rolesSorted.map((detail: any, i) => {
									return (
										<Box sx={{ display: "flex" }} key={i + "_roles"}>
											<Box>{detail?.["name"]}</Box>
											<Box sx={{ ml: 0.5, fontWeight: "bold", textTransform: "capitalize" }}>{detail?.["role"]}</Box>
										</Box>
									);
								})}
								placement="right"
							>
								<Typography sx={{ opacity: 1, background: "rgba(255,255,255,.25)", ml: "5px", pl: "5px", pr: "5px", mr: "5px", borderRadius: 1 }} align={"center"} variant="caption" color="inherit">
									{rolesSorted.length}
								</Typography>
							</Tooltip>
						)}
					</Box>

					{messageStatus ? (
						<Tooltip sx={{ display: messageStatus ? "inherit" : "none" }} title={statusInfo ? statusInfo : ""}>
							<IconButton sx={{ width: "20px", height: "20px" }}>
								{statusInfo?.startsWith("error") ? <ErrorOutlineIcon sx={{ color: "red", width: "20px" }} /> : statusInfo?.startsWith("pending") ? <UpdateIcon sx={{ color: "white", width: "20px" }} /> : null}
							</IconButton>
						</Tooltip>
					) : hover && (isProject || isJob || isAsset) ? (
						isJob ? (
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<TreeViewMenu
									grouptype={grouptype}
									projecttype={projecttype}
									parentid={parentid}
									actiontype={"create"}
									width={"20px"}
									onclose={() => {
										setHover(false);
									}}
									dialogCollection={collectionDialogHandleOpen}
									dialogAsset={assetDialogHandleOpen}
									onclick={() => {
										selectAndExpandNode(other.nodeId);
									}}
								/>
							</Box>
						) : isProject ? (
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<TreeViewMenu
									grouptype={grouptype}
									projecttype={projecttype}
									parentid={parentid}
									actiontype={"edit"}
									width={"20px"}
									onclose={() => {
										setHover(false);
									}}
									dialogCollection={collectionDialogHandleOpen}
									dialogAsset={assetDialogHandleOpen}
									onclick={() => {
										selectAndExpandNode(other.nodeId);
									}}
								/>

								<TreeViewMenu
									grouptype={grouptype}
									projecttype={projecttype}
									parentid={parentid}
									actiontype={"create"}
									width={"20px"}
									onclose={() => {
										setHover(false);
									}}
									dialogCollection={collectionDialogHandleOpen}
									dialogAsset={assetDialogHandleOpen}
									onclick={() => {
										selectAndExpandNode(other.nodeId);
									}}
								/>
							</Box>
						) : isAsset ? (
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<TreeViewMenu
									grouptype={grouptype}
									projecttype={projecttype}
									parentid={parentid}
									actiontype={"edit"}
									width={"20px"}
									onclose={() => {
										setHover(false);
									}}
									dialogCollection={collectionDialogHandleOpen}
									dialogAsset={assetDialogHandleOpen}
									onclick={() => {
										selectAndExpandNode(other.nodeId);
									}}
								/>
							</Box>
						) : null
					) : (
						<Box>
							<Typography variant="caption" sx={{ mr: "6px" }} color="inherit">
								{labelInfo}
								{labelEndNumber}
							</Typography>
						</Box>
					)}
				</Box>
			}
			style={{
				"--tree-view-color": color,
				"--tree-view-bg-color": bgColor,
			}}
			{...other}
		/>
	);
}
